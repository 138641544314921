import React from "react";
import {LuBellPlus} from "react-icons/lu";
import {Button} from "primereact/button";
import {SeverityType} from "../../models/types/severity.type";

interface ToastContentProps {
    severity: SeverityType;
    summary?: string;
    detail?: string;
    image?: string;
}

export const ToastContent: React.FC<ToastContentProps> = ({severity, summary, detail, image}) => {
    const navigateToSection = () => {
        console.log("Navigate to section");
    }

    return (
        <div className={`p-4 rounded-lg border-white bg-${severity}-800 w-full`}>
            <div className="flex flex-col justify-center items-center w-full">
                <LuBellPlus size={32} color="white"/>
                <div className="text-sm text-white text-center flex flex-col justify-center items-center w-full">
                    <p className="font-bold">{summary}</p>
                    <p>{detail}</p>

                    {
                        image && (
                            <img src={image} alt={summary} className="h-48 p-2"/>
                        )
                    }

                    <Button className="h-8" onClick={navigateToSection}>View</Button>
                </div>
            </div>
        </div>
    );
}

export default ToastContent;