import {consumeApi, GET, POST, USERS, USERS_CREDENTIAL} from "../../backend.service";
import {EditPinRequest} from "./requests/edit-pin.request";
import {EditPinResponse} from "./responses/edit-pin.response";
import {RecoverCredentialRequest} from "./requests/recover-credential.request";
import {RecoverCredentialResponse} from "./responses/recover-credential.response";
import {UserDataRequest} from "./requests/user-data.request";
import {UserDataResponse} from "./responses/user-data.response";
import {LoginRequest} from "./requests/login.request";
import {LogoutResponse} from "./responses/logout.response";
import {LoginResponse} from "./responses/login.response";
import {EditUserProfileRequest} from "./requests/edit-user-profile.request";
import {EditUserProfileResponse} from "./responses/edit-user-profile.response";
import {UserProfileRequest} from "./requests/user-profile.request";
import {UserProfileResponse} from "./responses/user-profile.response";
import {EditProfileRequest} from "./requests/edit-profile.request";
import {EditProfileResponse} from "./responses/edit-profile.response";
import { RecoverWebCredentialRequest } from "./requests/recover-web-credential";
import { ChangeWebCredentialRequest } from "./requests/change-web-credential.request";
import { ChangeWebCredentialResponse } from "./responses/change-web-credential.response";

export const login = async (request: LoginRequest): Promise<LoginResponse> => {
    return await consumeApi(POST, `${USERS}/login`, "Login", request)
};

export const logout = async (): Promise<LogoutResponse> => {
    return await consumeApi(POST, `${USERS}/logout`, "Logout", {}, true)
};

export const getProfile = async (): Promise<any> => {
    return await consumeApi(GET, `${USERS}/profile`, "Get Profile", {}, true)
};

export const getUserData = async (request: UserDataRequest): Promise<UserDataResponse> => {
    return await consumeApi(GET, `${USERS}/profile`, "Get User Data", request, true)
};

export const editProfile = async (request: EditProfileRequest): Promise<EditProfileResponse> => {
    return await consumeApi(POST, `${USERS}/edit`, "Edit Profile", request, true)
};

export const editUserProfile = async (request: EditUserProfileRequest): Promise<EditUserProfileResponse> => {
    return await consumeApi(POST, `${USERS}/hr/edit`, "Edit User Profile", request, true)
};

export const getUserProfile = async (request: UserProfileRequest): Promise<UserProfileResponse> => {
    return await consumeApi(POST, `${USERS}/hr/get-profile-of-user`, "User Profile", request, true)
};

export const editPin = async (request: EditPinRequest): Promise<EditPinResponse> => {
    return await consumeApi(POST, `${USERS}/edit/pin`, "Edit Pin", request, true);
};

export const recoverCredential = async (request: RecoverCredentialRequest): Promise<RecoverCredentialResponse> => {
    return await consumeApi(POST, `${USERS_CREDENTIAL}/recover`, "Recover Credential", request, true);
};

export const recoverWebCredential = async (request: RecoverWebCredentialRequest): Promise<RecoverCredentialResponse> => {
    return await consumeApi(POST, `${USERS}/manager/credential/recover`, "Recover Web Credential", request, true);
};

export const changeWebCredential = async (request: ChangeWebCredentialRequest): Promise<ChangeWebCredentialResponse> => {
    return await consumeApi(POST, `${USERS}/manager/credential/change`, "Change Web Credential", request, true);
};




