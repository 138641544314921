import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import UtilityCard from "./UtilityCard";
import {useNavigate} from "react-router-dom";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {LEAVES} from "../../utils/constants.utils";
import {FaCircle} from "react-icons/fa6";
import {LeaveRequest} from "../../models/leave-request.interface";
import {retrieveLeaveRequests} from "../../services/data-manager/data-manager.service";
import {oneYearAgo, today} from "../../utils/date.utils";

interface UserLeavesProps {
    userId?: string;
}

const UserLeaves: React.FC<UserLeavesProps> = ({userId}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {level} = useUserContext();

    const [leavesRequests, setLeavesRequests] = useState<LeaveRequest[]>([]);

    const menuOptions = [
        {
            label: t('moreInfo'),
            onClick: () => {
                navigate(`/${level}/${LEAVES}`, {
                    state: {userId}
                });
            }
        }
    ]

    const getStatusColor = (isPending: boolean) => {
        if (isPending) {
            return 'bg-yellow-500 text-black';
        } else {
            return 'bg-green-500 text-white';
        }
    }

    useEffect(() => {
        if (userId && userId.length > 0) {
            const request = {
                userId: userId,
                startDate: oneYearAgo(),
                endDate: today()
            }
            retrieveLeaveRequests(request).then((response) => {
                setLeavesRequests(response);
            });
        }
    }, [userId]);

    return (

        <UtilityCard title={t('userLeaves.title')} menuOptions={menuOptions}
                     content={
                         <div className="space-y-1">
                             {
                                 leavesRequests.map((leave, index) => (
                                     <div key={index}
                                          className='primary-container p-1 flex items-center space-x-4 justify-between'>
                                         <div className="flex items-center space-x-1">
                                             {leave.isNew &&
                                                 <div className="flex items-center space-x-1">
                                                     <FaCircle size={16} color="orange"/>
                                                     <span>New</span>
                                                 </div>
                                             }
                                             <span>{t(`userLeaves.categories.${leave.category}`)} {t('userLeaves.leaveDate')} {leave.date.toLocaleDateString()}</span>
                                         </div>
                                         <div className="whitespace-no-wrap space-x-1">
                                             <span
                                                 className={`rounded-md px-2 text-sm ${getStatusColor(leave.isPending)}`}>
                                                 {leave.isPending ? t('userLeaves.pending') : t('userLeaves.approved')}
                                             </span>
                                             <span>{leave.date.toLocaleTimeString()}</span>
                                         </div>
                                     </div>
                                 ))
                             }
                         </div>
                     }
        />
    )
}

export default UserLeaves;