import React from 'react'
import GraphSummaryDocument from "../components/GraphSummaryDocument";

interface TasksSummaryProps {
    data: any;
}

const TasksSummary: React.FC<TasksSummaryProps> = ({data}) => {
    data = data.map((item: any) => {
        return {
            name: item.name,
            value: item.value,
        }
    })
    return (
        <GraphSummaryDocument title="Tasks Summary" subtitle="Tasks Data" data={data}/>
    )
}
export default TasksSummary