import React, { createContext, useContext, useState, ReactNode } from "react";
import { UserRole } from "../../../models/enums/user-role.enum";
import {useNavigate} from "react-router-dom";

// Definizione del tipo per il contesto
interface ForgotPasswordStepperContextType {
  //LEVEL INFO
  level: string;
  setLevel: (value: string) => void;
  allowedLevels: string[];

  //EMAIL INFO
  email: string;
  emailError: boolean;
  setEmailError: (value: boolean) => void;
  setEmail: (value: string) => void;

  //PHONE INFO
  phone: string;
  setPhone: (value: string) => void;
  phoneError: boolean;
  setPhoneError: (value: boolean) => void;

  //PASSWORD INFO
  password: string;
  setPassword: (value: string) => void;
  confirmPassword: string;
  setConfirmPassword: (value: string) => void;

  //STEP INFO
  step: number;
  goNext: () => void;
  goBack: () => void;

  //FINAL STEP
  terminateStep: () => void;
}

// Creazione del contesto
const ForgotPasswordStepperContext = createContext<ForgotPasswordStepperContextType | undefined>(undefined);

// Provider del contesto
interface ForgotPasswordStepperProviderProps {
  children: ReactNode;
}

export const ForgotPasswordStepperProvider: React.FC<ForgotPasswordStepperProviderProps> = ({ children }) => {
  const allowedLevels = [UserRole.Operator, UserRole.Supervisor, UserRole.HR, UserRole.Client, UserRole.Provider];
  const [level, setLevel] = useState<string>(allowedLevels[0]);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(true);
  const [phone, setPhone] = useState<string>("");
  const [phoneError, setPhoneError] = useState<boolean>(true);
  const [step, setStep] = useState<number>(0);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const navigate = useNavigate();

  const terminateStep = () => {
    navigate('/');
    setLevel(allowedLevels[0]);
    setEmail("");
    setStep(0);
    setPassword("");
    setConfirmPassword("");
    setEmailError(true);
  };

  const MAX_STEP_INDEX = 3;
  const MIN_STEP_INDEX = 0;


  const goNext = () => {
    setStep(prevStep => {
      if (prevStep >= MAX_STEP_INDEX) return prevStep;
      return prevStep + 1;
    });
  };

  const goBack = () => {
    setStep(prevStep => {
      if (prevStep <= MIN_STEP_INDEX) return prevStep;
      return prevStep - 1;
    });
  };

  return (
    <ForgotPasswordStepperContext.Provider value={{ level, setLevel, email, setEmail, phone, setPhone, phoneError, setPhoneError, emailError, setEmailError, step, goNext, goBack, allowedLevels, password, setPassword, confirmPassword, setConfirmPassword, terminateStep }}>
      {children}
    </ForgotPasswordStepperContext.Provider>
  );
};

// Custom hook per utilizzare il contesto
export const useForgotStepper = (): ForgotPasswordStepperContextType => {
  const context = useContext(ForgotPasswordStepperContext);
  if (!context) {
    throw new Error("useForgotStepper deve essere utilizzato all'interno di ForgotPasswordStepperProvider");
  }
  return context;
};