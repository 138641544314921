import {useTranslation} from "react-i18next";
import React, {ReactElement, useState} from "react";
import {HiOutlineDotsHorizontal} from "react-icons/hi";

export interface CardMenuOption {
    label: string;
    onClick: () => void;
}

interface UtilityCardProps {
    title: string;
    content?: ReactElement;
    menuOptions?: CardMenuOption[];
}

const UtilityCard: React.FC<UtilityCardProps> = (props) => {

    const {t} = useTranslation();
    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

    const showMenu = (e: any) => {
        setIsMenuVisible(true);
    }

    return (
        <div className="p-6">
            <div className="flex justify-between">
                <p className="text-2xl font-semibold">{props.title}</p>
                <HiOutlineDotsHorizontal size={24} color="var(--text-color)" onClick={showMenu}/>

                <div
                    className="absolute primary-container p-2 border-2 border-cyan-500 min-w-48 translate-x-80 translate-y-6"
                     hidden={!isMenuVisible}
                >
                    <div
                        className="flex justify-center items-start flex-col overflow-auto text-[color:var(--text-color)] cursor-pointer">
                        {
                            props.menuOptions &&
                            props.menuOptions.map((option, index) => (
                                <div key={index} onClick={option.onClick} className="w-full">
                                    <p>{option.label}</p>
                                </div>
                            ))
                        }
                        <div onClick={() => setIsMenuVisible(false)}>
                            <p>{t('close')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overflow-auto max-h-28 p-1">
                {props.content}
            </div>
        </div>
    )
}

export default UtilityCard;