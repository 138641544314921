import {getToken, messaging} from "./firebaseConfig";

export const requestForToken = async () => {
    try {
        let currentToken = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_VAPID_KEY,
        });
        if (currentToken) {
            console.log("Current token for client: ", currentToken);
            return currentToken;
        } else {
            console.log("No registration token available. Request permission to generate one.");
        }
    } catch (err) {
        console.log("An error occurred while retrieving token. ", err);
    }
};

export const onMessageListener = () =>
    new Promise((resolve) => {

    });