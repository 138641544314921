import {WeekCalendarItemInterface} from "../models/week-calendar-item.interface";
import {SupervisorShiftTimes} from "../models/supervisor.interface";

export const today = () => {
    return new Date();
}

export const oneMonthAgo = () => {
    const now = today();
    return new Date(now.setMonth(now.getMonth() - 1));
}

export const oneYearAgo = () => {
    const now = today();
    return new Date(now.setFullYear(now.getFullYear() - 1));
}

export const midnight = (date?: Date) => {
    if (!date) {
        date = new Date();
    }
    return new Date(date.setHours(0, 0, 0, 0));
}

export const endOfDay = (date?: Date) => {
    if (!date) {
        date = new Date();
    }
    return new Date(date.setHours(23, 59, 59, 999));
}

export const formatDateTime = (dateTime?: Date, locale: string = 'en') => {
    if (!dateTime || isNaN(dateTime.getTime())) {
        return 'No date';
    }
    
    const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };

    // Usa 'en-US' per inglese e 'ar-EG' per arabo
    const datePart = dateTime.toLocaleDateString(locale === 'ar' ? 'ar-EG' : 'en-US', dateOptions);
    let timePart = dateTime.toLocaleTimeString(locale === 'ar' ? 'ar-EG' : 'en-US', timeOptions);

    // Sostituzione per AM/PM in arabo
    if (locale === 'ar') {
        timePart = timePart.replace('AM', 'ص').replace('PM', 'م');
    }

    return `${datePart} ${timePart}`;
};

export const formatDateTimeBeautify = (date?: Date, locale: string = 'en') => {
    if (!date || isNaN(date.getTime())) {
        return 'No date';
    }
    
    const options: Intl.DateTimeFormatOptions = { 
        weekday: 'short',
        month: 'short', 
        day: 'numeric',
        year: 'numeric'
    };

    return date.toLocaleDateString(locale === 'ar' ? 'ar-EG' : 'en-US', options);
};


export const formatDate = (date?: Date) => {
    if (!date || isNaN(date.getTime())) {
        return 'No date';
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};



export const formatDayAndMonthDate = (date: any, locale: string = 'en-US') => {
    return new Date(date).toLocaleString(locale, {
        day: "numeric",
        month: "numeric"
    });
};

export const formatDayDate = (date: any, locale: string = 'en-US') => {
    return new Date(date).toLocaleString(locale, {
        day: "numeric",
    });
};

export const formatTime = (time: any, locale: string = 'en-US') => {
    const formattedTime = new Date(time).toLocaleTimeString('en-US', {
        hour: "2-digit",
        minute: "2-digit",
    });

    if (locale !== 'en-US') {
        return formattedTime.replace('AM', 'ص').replace('PM', 'م');
    }
    return formattedTime;
};


export const isFreeDay = (date: Date, weeksData: WeekCalendarItemInterface[]) => {
    const selectedDay = weeksData.find(
        (day) => new Date(day.date).toDateString() === date.toDateString()
    );
    const thereIsNoWork = selectedDay?.shifts.every(
        (day) => day.label !== "Work"
    );
    return selectedDay?.shifts.length === 0 || thereIsNoWork;
};

export interface CalendarRequest {
    startTime: string;
    endTime: string;
    label: string;
}


export const getTimeDifference = (startDate: Date, endDate: Date): number => {
    return endDate.getTime() - startDate.getTime();
}

export const formatShiftTime = (shifts?: SupervisorShiftTimes[], filterDate?: Date) => {
    if (!shifts || shifts.length === 0) {
        return 'No shifts';
    }
    const shift = shifts.find(shift => {
        return shift.startTime.toDateString() === filterDate?.toDateString() || shift.endTime.toDateString() === filterDate?.toDateString()
    });
    if (!shift) {
        return 'No shifts';
    }
    return `${shift.startTime.toLocaleTimeString()} - ${shift.endTime.toLocaleTimeString()}`;
}