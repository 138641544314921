import {Toast, ToastMessage} from "primereact/toast";
import {createContext, useContext, useRef} from "react";

interface ToastContextType {
    showToast: (message: ToastMessage | ToastMessage[]) => void;
    infoToast: (message: string) => void;
    successToast: (message: string) => void;
    warnToast: (message: string) => void;
    errorToast: (message: string) => void;
    clearToasts: () => void;
}

const ToastContext = createContext(undefined);

export const ToastContextProvider = ({children}: any) => {
    const toastRef = useRef<Toast>(null);

    const showToast = (options: any) => {
        if (!toastRef.current) return;
        toastRef.current.show(options);
    };

    const infoToast = (message: string) => {
        showToast({severity: "info", summary: "Info", detail: message});
    }

    const successToast = (message: string) => {
        showToast({severity: "success", summary: "Success", detail: message});
    }

    const warnToast = (message: string) => {
        showToast({severity: "warn", summary: "Warning", detail: message});
    }

    const errorToast = (message: string) => {
        showToast({severity: "error", summary: "Error", detail: message});
    }

    const clearToasts = () => {
        if (!toastRef.current) return;
        toastRef.current.clear();
    }

    return (
        <ToastContext.Provider value={{showToast, infoToast, successToast, warnToast, errorToast, clearToasts} as any}>
            <Toast ref={toastRef} position="bottom-right"/>
            <div>{children}</div>
        </ToastContext.Provider>
    );
};

export const useToastContext = (): ToastContextType => {
    const context = useContext(ToastContext);

    if (!context) {
        throw new Error(
            "useToastContext have to be used within ToastContextProvider"
        );
    }

    return context;
};