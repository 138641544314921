import React from 'react'
import {Text,} from '@react-pdf/renderer';
import {Communication} from "../../../models/communication.interface";
import ClassicDocument from "../components/ClassicDocument";
import {formatDate, formatTime} from "../../../utils/date.utils";

interface CommunicationSummaryProps {
    communication: Communication;
}

const CommunicationSummary: React.FC<CommunicationSummaryProps> = ({communication}) => {
    return (
        <ClassicDocument
            title={`Communication ID: ${communication.id}`}
            subtitle={`Receiver: ${communication.receiver}`}
            content={
                <>
                    <Text>
                        {`
                            - Sent at: ${formatDate(communication.date)} - ${formatTime(communication.date)}
                            
                            - Message:
                            ${communication.content}
                        `}
                    </Text>


                </>
            }
        />
    )
}
export default CommunicationSummary