export const formatFileSize = (sizeInBytes: number): string => {
    const megabyte = 1024 * 1024;
    const gigabyte = megabyte * 1024;

    if (sizeInBytes < megabyte) {
        return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    } else if (sizeInBytes < gigabyte) {
        return `${(sizeInBytes / megabyte).toFixed(2)} MB`;
    } else {
        return `${(sizeInBytes / gigabyte).toFixed(2)} GB`;
    }
};

export const fileSizeToBytes = (size: any): number => {
    if (!size || size === "" || size === "0") {
        return 0;
    }
    try {
        const [value, unit] = size.split(" ");
        switch (unit) {
            case "KB":
                return parseFloat(value) * 1024;
            case "MB":
                return parseFloat(value) * 1024 * 1024;
            case "GB":
                return parseFloat(value) * 1024 * 1024 * 1024;
            default:
                return 0;
        }
    } catch (e) {
        return size;
    }

}

export const downloadFileByLink = async (link: string, name: string): Promise<void> => {
    return await fetch(link)
        .then((response) => response.blob())
        .then((blob) => downloadBlob(blob, name))
}

export const downloadBlob = (blob: Blob, name: string): void => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
}