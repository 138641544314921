import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Dialog} from "primereact/dialog";
import {Calendar, CalendarDateTemplateEvent, CalendarMonthChangeEvent} from "primereact/calendar";
import {Goal} from "../../models/goal.interface";
import {FormEvent} from "primereact/ts-helpers";
import {GoPlusCircle} from "react-icons/go";
import {FaAngleDown, FaAngleUp, FaCircle} from "react-icons/fa6";
import {HiOutlineUserCircle} from "react-icons/hi";
import {Supervisor} from "../../models/supervisor.interface";
import {OverlayPanel} from "primereact/overlaypanel";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";
import Map from "../../components/map/Map";
import {LatLng} from "leaflet";
import {
    retrieveProviderGoals,
    retrieveSupervisorsOnShiftByDay,
    retrieveTasksPreset
} from "../../services/data-manager/data-manager.service";
import {assignTaskSupervisor, createTask} from "../../services/backend/api/tasks/tasks.apis";
import {CreateTaskRequest} from "../../services/backend/api/tasks/requests/create-task.request";
import {AssignTaskSupervisorRequest} from "../../services/backend/api/tasks/requests/assign-task-supervisor.request";
import {
    SupervisorsOnShiftByDayRequest
} from "../../services/backend/api/tasks/requests/supervisors-on-shift-by-day.request";
import {formatShiftTime} from "../../utils/date.utils";
import {shortId} from "../../utils/text-formatting.utils";
import {TaskPreset} from "../../models/task-preset";
import Loader from "../../components/common/LoaderComponent";
import {useToastContext} from "../../contexts/ToastContext";

const TaskAssignmentPage: React.FC = () => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const {successToast, errorToast} = useToastContext();

    const [goalsDate, setGoalsDate] = useState<Date>(new Date());
    const [monthDate, setMonthDate] = useState<Date>(new Date());
    const [isNewTaskDialogVisible, setIsNewTaskDialogVisible] = useState<boolean>(false);
    const [visibleTasks, setVisibleTasks] = useState<string[]>([]);
    const [visibleAddAssigneeDialog, setVisibleAddAssigneeDialog] = useState<string[]>([]);
    const [isPresetTaskOverlayVisible, setPresetTaskOverlayVisible] = useState<boolean>(false);
    const [newTaskTitle, setNewTaskTitle] = useState<string>('');
    const [newTaskDescription, setNewTaskDescription] = useState<string>('');
    const [newTaskLocation, setNewTaskLocation] = useState<LatLng>();
    const [selectedPresetId, setSelectedPresetId] = useState<string>('');
    const addPresetOverlayPanel = useRef<OverlayPanel>(null);

    const [goals, setGoals] = useState<Goal[]>([]);
    const [selectedGoalId, setSelectedGoalId] = useState<string>('');
    const [selectedTaskId, setSelectedTaskId] = useState<string>('');
    const [filteredGoals, setFilteredGoals] = useState<Goal[]>([]);
    const [supervisorsOnShifts, setSupervisorsOnShifts] = useState<Supervisor[]>([]);
    const [presets, setPresets] = useState<TaskPreset[]>([]);

    const addAssigneeOverlayPanel = useRef<OverlayPanel>(null)
    const detailsOverlayPanel = useRef<OverlayPanel>(null)

    const onMonthChange = (event: CalendarMonthChangeEvent) => {
        const month = event.month;
        const year = event.year;
        const date = new Date();
        date.setMonth(month);
        date.setFullYear(year);
        if (date.getMonth() !== monthDate.getMonth()) {
            setMonthDate(date);
        }
        setMonthDate(date);
    }

    const onDateSelect = (event: FormEvent) => {
        const date: Date = event.value as Date || new Date();
        setGoalsDate(date);
    }

    const addTask = (goalId: string) => {
        setSelectedGoalId(goalId);
        setIsNewTaskDialogVisible(true);
    }

    const showAssigneeOverlayPanel = (e: React.MouseEvent, taskId: string) => {
        setSelectedTaskId(taskId);
        addAssigneeOverlayPanel?.current?.toggle(e);
    }

    const hasAssignee = (taskId: string): boolean => {
        const task = goals.flatMap(goal => goal.tasks).find(task => task.id === taskId);
        return task?.assignee !== undefined && task.assignee !== '' && task.assignee !== '-';
    }

    const isSupervisorAssignedToTask = (supervisorId: string): boolean => {
        const task = goals.flatMap(goal => goal.tasks).find(task => task.id === selectedTaskId);
        return task?.assignee === supervisorId;
    }

    const addSupervisorToTask = (e: React.ChangeEvent<HTMLInputElement>, supervisorId: string) => {
        if (!e.target.checked) {
            return;
        }
        console.log(`Adding supervisor to task: ${selectedTaskId} - ${supervisorId}`);
        const request: AssignTaskSupervisorRequest = {
            taskId: selectedTaskId,
            supervisorId: supervisorId
        }
        assignTaskSupervisor(request).then(response => {
            console.log('Supervisor assigned');
            const updatedGoals = goals.map(goal => {
                return {
                    ...goal,
                    tasks: goal.tasks.map(task => {
                        if (task.id === selectedTaskId) {
                            return {
                                ...task,
                                assignee: supervisorId,
                                assigneeName: supervisorsOnShifts.find(supervisor => supervisor.id === supervisorId)?.name
                            }
                        }
                        return task;
                    })
                }
            });
            setGoals(updatedGoals);
            setFilteredGoals(updatedGoals);
            successToast(t('messages.assignTaskSupervisorSuccess'));
        }).catch(error => {
            console.error('Error assigning supervisor');
            console.error(error);
            errorToast(t('messages.assignTaskSupervisorError'));
        })
        addAssigneeOverlayPanel?.current?.toggle(e);
    }

    const areGoalTasksVisible = (goalId: string): boolean => {
        return visibleTasks.includes(goalId);
    }

    const changeGoalTasksVisibility = (goalId: string) => {
        if (areGoalTasksVisible(goalId)) {
            setVisibleTasks(visibleTasks.filter(task => task !== goalId));
        } else {
            setVisibleTasks([...visibleTasks, goalId]);
        }
    }

    const handleAddPresetTaskClick = (e: any) => {
        addPresetOverlayPanel?.current?.toggle(e);
    }

    const onPresetSelected = (e: any) => {
        setSelectedPresetId(e.target.value);
        const selectedPreset = presets.find(preset => preset.id === e.target.value);
        setNewTaskTitle(selectedPreset?.title || '');
        setNewTaskDescription(selectedPreset?.description || '');
    }

    const cleanNewTaskForm = () => {
        setNewTaskTitle('');
        setNewTaskDescription('');
        setNewTaskLocation(undefined);
        setSelectedPresetId('');
    }

    const addNewTask = () => {
        if (newTaskTitle === '' || newTaskDescription === '' || !newTaskLocation) {
            return;
        }
        console.log(`Adding new task: ${newTaskTitle} - ${newTaskDescription} - ${newTaskLocation}`);

        const request: CreateTaskRequest = {
            taskName: newTaskTitle,
            taskDescription: newTaskDescription,
            lat: newTaskLocation.lat.toString(),
            lng: newTaskLocation.lng.toString(),
            supervisorId: undefined,
            isActive: "true",
            goalId: selectedGoalId,
        }
        createTask(request)
            .then(response => {
                cleanNewTaskForm();
                fetchGoals().then(() => console.log('Goals fetched'));
                successToast(t('messages.newTaskSuccess'));
            })
            .catch(error => {
                console.error('Error creating task');
                console.error(error);
                errorToast(t('messages.newTaskError'));
            })

        setIsNewTaskDialogVisible(false);
    }

    const formatDescription = (description: string): string => {
        return description.length > 50 ? description.substring(0, 50) + '...' : description;
    }

    const fetchGoals = useCallback(async () => {
        setIsLoading(true);
        const supervisorOnShiftRequest: SupervisorsOnShiftByDayRequest = {
            dateDate: goalsDate,
        }

        retrieveSupervisorsOnShiftByDay(supervisorOnShiftRequest).then(response => {
            setSupervisorsOnShifts(response);
        })

        retrieveProviderGoals().then(goals => {
            setGoals(goals);
            setFilteredGoals(goals);
            setIsLoading(false);
        })

        //  TODO: uncomment when the endpoint is available
        // retrieveMonthlyProviderGoals({date: monthDate}).then(goals => {
        //     setGoals(goals);
        //     setFilteredGoals(goals);
        // })

    }, [goalsDate])

    useEffect(() => {
        fetchGoals().then(() => console.log('Goals fetched'));
    }, [fetchGoals]);

    useEffect(() => {
        retrieveTasksPreset().then(presets => {
            setPresets(presets);
        })
    }, []);

    // TODO: uncomment this if goals need to be filtered by date
    // useEffect(() => {
    //     setFilteredGoals(goals.filter(goal => {
    //         return goal.date.toDateString() === goalsDate.toDateString();
    //     }));
    // }, [goals, goalsDate]);

    const dateTemplate = (event: CalendarDateTemplateEvent) => {
        const date = new Date(event.year, event.month, event.day)

        const highlightDate = goals.some(goal => {
            return goal.dueDate?.toDateString() === date.toDateString();
        });

        return (
            <div className="flex justify-center items-center flex-col">
                {event.day}
                {highlightDate && <FaCircle size={8} color="cyan" className="date-marker"/>}
            </div>
        );
    };

    return (

        <div className="page-container ">
            <div className="page-title">
                <h2>{t('taskAssignmentPage.title')}</h2>
                <div className="flex justify-center items-center">
                    <Dialog className="overflow-hidden"
                            visible={isNewTaskDialogVisible}
                            draggable={false} modal={true}
                            style={{width: '82vw', height: '94vh'}}
                            onHide={() => {
                                if (!isNewTaskDialogVisible) return;
                                setIsNewTaskDialogVisible(false);
                            }}>
                        <div className="flex flex-col justify-center items-center w-full ">
                            <div className="flex flex-col justify-center items-center space-y-2 w-full ">
                                <h2>{t('taskAssignmentPage.newTaskHeader')}</h2>
                                <div className="flex flex-col justify-center items-center w-full max-w-4xl">
                                    <Button label={t('taskAssignmentPage.addPresetTask')} className="uppercase w-max"
                                            onClick={handleAddPresetTaskClick}
                                    />
                                </div>
                                <OverlayPanel ref={addPresetOverlayPanel} showCloseIcon
                                              className="primary-container p-2 border-2 border-cyan-500 translate-x-56">
                                    <div
                                        className="flex justify-center items-start flex-col overflow-auto text-[color:var(--text-color)] max-h-80">
                                        {
                                            presets.map((preset, index) => (
                                                <div key={index} className="p-1">
                                                    <Checkbox inputId={preset.id} name={preset.id} value={preset.id}
                                                              onChange={onPresetSelected}
                                                              checked={selectedPresetId === preset.id}/>
                                                    <label htmlFor={preset.id} className="ml-2">
                                                        {preset.title}
                                                        {preset.description !== "" &&
                                                            <>
                                                                <span>: </span>
                                                                <span
                                                                    className="text-xs">{formatDescription(preset.description)}</span>
                                                            </>
                                                        }
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </OverlayPanel>
                                <div
                                    className="flex flex-col justify-center items-center space-y-2 w-full text-[color:var(--text-color-secondary)] max-w-4xl">
                                    <InputText className="w-full" style={{
                                        backgroundColor: 'whitesmoke',
                                        color: 'var(--text-color-secondary)'
                                    }}
                                               placeholder={t('taskAssignmentPage.newTaskTitlePlaceholder')}
                                               value={newTaskTitle}
                                               onChange={(e) => setNewTaskTitle(e.target.value)}/>
                                    <InputTextarea className="w-full"
                                                   value={newTaskDescription} cols={30}
                                                   placeholder={t('taskAssignmentPage.newTaskDescriptionPlaceholder')}
                                                   onChange={(e) => setNewTaskDescription(e.target.value)}/>
                                </div>
                                <div className="flex flex-col justify-center items-center w-full p-2">
                                    <div className="rounded">
                                        <Map onLocationSelected={setNewTaskLocation}/>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center items-center w-full max-w-4xl">
                                    <Button label={t('taskAssignmentPage.confirmTask')} className="uppercase w-max"
                                            onClick={addNewTask}
                                    />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>

            <div className="page-content">
                <div className='w-full grid grid-cols-7 gap-4 pr-8 visible-height'>
                    <ul className="col-span-4 secondary-container padded-m space-y-2">
                        {
                            isLoading ? <Loader/> :
                                filteredGoals.map((goal, index) => (
                                    <li key={index}>
                                        <div className='flex items-center justify-between py-2 padded-s'>
                                            <div
                                                className='flex items-center p-2 text-2xl font-semibold flex-grow max-w-[38rem]'>
                                                <p className='ml-2 semi ellipsis'>{t("goal")}. {goal.content}</p>
                                            </div>
                                            <div className='flex flex-row items-center justify-center space-x-2 mr-12 '
                                                 onClick={() => addTask(goal.id)}
                                            >
                                                <button>
                                                    {t('taskAssignmentPage.addTask')}
                                                </button>
                                                <GoPlusCircle size={24}/>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => changeGoalTasksVisibility(goal.id)}
                                                >
                                                    <div className="flex justify-center items-center gap-2 ">
                                                        {
                                                            areGoalTasksVisible(goal.id)
                                                                ? <>
                                                                    {t('taskAssignmentPage.hideTasks')}
                                                                    <FaAngleUp size={24}/>
                                                                </>
                                                                : <>
                                                                    {t('taskAssignmentPage.showTasks')}
                                                                    <FaAngleDown size={24}/>
                                                                </>
                                                        }
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            areGoalTasksVisible(goal.id) && (
                                                <ul
                                                    className='flex flex-col items-center justify-center padded-s tree ml-12'>
                                                    <>
                                                        {
                                                            goal.tasks.map(task => (
                                                                <li className='flex items-center p-2 primary-container w-full justify-between m-1'
                                                                    key={task.id}
                                                                >
                                                                    <div className="flex-grow">
                                                                        <p className='ml-2 ellipsis max-w-[28rem]'>{t("task")}. {task.content}</p>
                                                                    </div>
                                                                    <div
                                                                        className="flex flex-row justify-center items-center gap-2 cursor-pointer px-4"
                                                                        style={
                                                                            hasAssignee(task.id)
                                                                                ? {opacity: 0.5}
                                                                                : {}
                                                                        }
                                                                        onClick={(e) => showAssigneeOverlayPanel(e, task.id)}
                                                                    >
                                                                        {
                                                                            hasAssignee(task.id)
                                                                                ? <>
                                                                                    <div className="ellipsis max-w-[20rem]">
                                                                                        {t('taskAssignmentPage.assignedTo')}{" "}{task.assigneeName}
                                                                                    </div>
                                                                                    <HiOutlineUserCircle size={24}/>
                                                                                </>
                                                                                : <>
                                                                                    {t('taskAssignmentPage.assignToTask')}
                                                                                    <GoPlusCircle size={24}/>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <button
                                                                            className="primary-button uppercase bordered-button h-8"
                                                                            onClick={(e) => detailsOverlayPanel?.current?.toggle(e)}
                                                                        >
                                                                            {t('taskAssignmentPage.viewDetails')}
                                                                        </button>
                                                                    </div>
                                                                    <OverlayPanel ref={detailsOverlayPanel}
                                                                                  showCloseIcon
                                                                                  className="primary-container p-0 border-yellow-500">
                                                                        <div
                                                                            className="flex justify-center flex-col p-4 text-[color:var(--text-color)] text-center">
                                                                            <p className="font-bold">{shortId(task.id)}</p>
                                                                            <p>{task.content}</p>
                                                                            <p>{t("taskAssignmentPage.details.subtitle")}</p>
                                                                            <p className="font-bold">{task.assigneeName}</p>
                                                                            <p>{t("taskAssignmentPage.details.message")}</p>
                                                                            <p className="font-bold">
                                                                                {
                                                                                    task.operators && task.operators?.length > 0
                                                                                        ? task.operators?.map(operator => `${operator.name} ${operator.surname}`).join(', ')
                                                                                        : t('taskAssignmentPage.details.noOperators')
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </OverlayPanel>
                                                                </li>
                                                            ))
                                                        }

                                                        {
                                                            <OverlayPanel ref={addAssigneeOverlayPanel}
                                                                          showCloseIcon
                                                                          className="primary-container p-0 border-yellow-500">
                                                                <div className="">
                                                                    <div
                                                                        className="bg-yellow-500 text-[var(--text-color-secondary)] p-2 rounded-[var(--border-radius)]">
                                                                        <p>{t('taskAssignmentPage.addSupervisor')}</p>
                                                                    </div>
                                                                    <div
                                                                        className="overflow-auto max-h-32 space-y-2 mt-2">
                                                                        {
                                                                            supervisorsOnShifts.map(supervisor => (
                                                                                <div key={supervisor.id}
                                                                                     className='flex items-center justify-between padded-s secondary-container'>
                                                                                    <div
                                                                                        className='flex items-center text-primary'>
                                                                                        <HiOutlineUserCircle
                                                                                            size={18}></HiOutlineUserCircle>
                                                                                        <p className='ml-2 semi'>{supervisor.name}{" "}{supervisor.surname}</p>
                                                                                    </div>
                                                                                    {/* TODO: add check logic*/}
                                                                                    <input
                                                                                        onChange={(e) => addSupervisorToTask(e, supervisor.id)}
                                                                                        className="w-6 h-6 rounded border-2 border-white"
                                                                                        checked={isSupervisorAssignedToTask(supervisor.id)}
                                                                                        type="checkbox"
                                                                                    />
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </OverlayPanel>
                                                        }
                                                    </>
                                                </ul>
                                            )
                                        }
                                    </li>
                                ))
                        }
                    </ul>
                    <div className="col-span-3 secondary-container padded-m visible-height">
                        <Calendar inline className='w-full px-2' onChange={onDateSelect}
                                  onMonthChange={onMonthChange} selectionMode="single" value={goalsDate}
                                  dateTemplate={dateTemplate}
                        />
                        <div className="min-h-32">
                            <p className="font-semibold text-2xl">{t('taskAssignmentPage.supervisorsOnShift')}</p>
                            <div className="space-y-2 max-h-40 overflow-auto p-2">
                                {
                                    isLoading ? <Loader/> :
                                        supervisorsOnShifts.map(supervisor => (
                                            <div key={supervisor.id}
                                                 className='flex items-center justify-between py-2 padded-s primary-container'>
                                                <div className='flex items-center p-2 justify-start w-full'>
                                                    <HiOutlineUserCircle size={24}/>
                                                    <p className='ml-2 semi'>{supervisor.name}{" "}{supervisor.surname}</p>
                                                </div>
                                                <div className='flex items-center p-2 justify-start w-full'>
                                                    <p>{shortId(supervisor.id)}</p>
                                                </div>
                                                <div className='flex items-center p-2 justify-center w-full'>
                                                    <p>{formatShiftTime(supervisor.shifts, goalsDate)}</p>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaskAssignmentPage;
