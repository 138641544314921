import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState,} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {BiLogOutCircle} from "react-icons/bi";
import {CiBellOn} from "react-icons/ci";
import {NOTIFICATION} from "../../utils/constants.utils";
import {HiOutlineUserCircle} from "react-icons/hi";
import {logout} from "../../services/backend/api/general/general.apis";
import {deleteToken} from "@firebase/messaging";
import {messaging} from "../../services/push-notification/firebaseConfig";
import {retrieveNotReadNotificationCount} from "../../services/data-manager/data-manager.service";

interface OperatorBarContextType {
    setActive: (active: boolean) => void;
    setScreenLabelBgColor: (color: string) => void;
}


const UserBarContext = createContext<OperatorBarContextType | undefined>(
    undefined
);

interface OperatorBarProps {
    children: ReactNode;
}

const logoutOperator = async () => {
    try {
        window.location.href = "/";
        await logout();
        await deleteToken(messaging);
        localStorage.clear();
    } catch (error) {
        console.error("Error during logout", error);
    }
};

const UserBar: React.FC<OperatorBarProps> = ({children}) => {
    const {name} = useUserContext();

    const [isActive, setIsActive] = useState<boolean>(true);
    const [screenName, setScreenName] = useState<string>("");
    const [screenLabelBgColor, setScreenLabelBgColor] = useState<string>("#1E1E1E");
    const [showNotificationCounter, setShowNotificationCounter] = useState<boolean>(false);
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const {level, id} = useUserContext();

    const setActive = (active: boolean) => {
        setIsActive(active);
    };

    const handleClick = () => {
        if (window.history?.length && window.history.length > 1) {
            navigate(-1);
        } else {
            navigate(`/${level}/`, {replace: true});
        }
    };

    const showNotificationPage = () => {
        navigate(`/${level}/${NOTIFICATION}`);
    };

    useEffect(() => {
        setScreenName(t('secAppTitle'));
    }, [t]);

    const handleLanguageChange = () => {
        const newLanguage = i18n.language === 'en' ? 'ar' : 'en';
        i18n.changeLanguage(newLanguage).then(() => console.log("Language changed to ", newLanguage));
    };

    // Aggiungi uno stato per controllare se la pagina corrente è la pagina di login
    const [isLoginPage, setIsLoginPage] = useState<boolean>(false);

    useEffect(() => {
        // Imposta isLoginPage in base alla pagina corrente
        setIsLoginPage(location.pathname === "/");
    }, [location.pathname]);

    const getNotifications = useCallback(async () => {
        const userId = id;
        if (!userId) return;
        retrieveNotReadNotificationCount({userId}).then(counter => {
            setShowNotificationCounter(counter > 0);
        });
    }, [id]);

    useEffect(() => {
        const interval = setInterval(() => getNotifications(), 10000)
        return () => {
            clearInterval(interval);
        }
    }, [getNotifications]);

    return (
        <UserBarContext.Provider value={{setActive, setScreenLabelBgColor}}>
            {isActive && (
                <div style={{backgroundColor: "#191C1E"}}>
                    <div
                        style={{
                            backgroundColor: "#2F2A2B",
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                            padding: "10px",
                        }}
                    >
                        <div
                            className={`flex items-center ${
                                isLoginPage ? "justify-center" : "justify-between"
                            }`}
                            style={{
                                backgroundColor: screenLabelBgColor,
                                borderRadius: "10px",
                            }}
                        >

                            {!isLoginPage && <div className="cursor-pointer px-8 py-2" onClick={handleClick}>&lt;</div>}
                            <div className="inline-flex items-center justify-start flex-grow">
                                <img className="h-full p-0" src="/logo.png" alt="logo" width={50}/>
                                <span className="text-white font-bold ml-3 text-3xl">{screenName}</span>
                            </div>
                            <div className="flex items-center">
                                {!isLoginPage &&
                                    <div className="flex items-center space-x-4">
                                        <button onClick={showNotificationPage}
                                                className={`${showNotificationCounter ? 'notification-icon-active' : ''}`}
                                        >
                                            <CiBellOn
                                                size={24} color="whitesmoke"
                                            />
                                            {showNotificationCounter && <div className="notification"></div>}
                                        </button>
                                        <div className="flex justify-center items-center space-x-2">
                                            <HiOutlineUserCircle
                                                size={24} color="whitesmoke"
                                            />
                                            <span className="text-white font-bold">{name}</span>
                                        </div>
                                        <button
                                            onClick={logoutOperator}
                                            className="primary-button uppercase bordered-button"
                                            style={{
                                                fontSize: "14px",
                                                padding: "0.2rem 0.4rem",
                                            }}
                                        >
                                            <BiLogOutCircle
                                                style={{marginRight: 10}}
                                                size={24} color="whitesmoke"
                                            />

                                            {t("logoutButton")}
                                        </button>
                                    </div>
                                }

                                <div className="pr-4">
                                    <button className="changeLng" onClick={handleLanguageChange}>
                                        {i18n.language === 'en' ? 'AR' : 'EN'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div style={{flex: 1}}>{children}</div>
        </UserBarContext.Provider>
    );
};

// Hook custom per utilizzare il contesto dell'operatore
const useOperatorBar = () => {
    const context = useContext(UserBarContext);
    if (!context) {
        throw new Error(
            "useOperatorBar must be used within an OperatorBarProvider"
        );
    }
    return context;
};

export {UserBar, useOperatorBar};
