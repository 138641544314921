import React, { useEffect } from 'react';
import { ForgotPasswordStepperProvider } from '../../components/common/ForgotPassword/ForgotPasswordStepperContext';
import { ForgotStepper } from '../../components/common/ForgotPassword/ForgotStepper';


const ForgotPasswordPage = () => {

  return (
    <div className="height-page flex items-center justify-center m-auto" style={{ width: '330px' }}>
      <ForgotPasswordStepperProvider>
        <ForgotStepper />
      </ForgotPasswordStepperProvider>
    </div>
  );
};

export default ForgotPasswordPage;
