import {CLAIMS, consumeApi, GET, POST} from "../../backend.service";
import {OperatorClaimsResponse} from "./responses/operator-claims.response";
import {ClaimsHistoryResponse} from "./responses/claims-history.response";
import {CreateClaimRequest} from "./requests/create-claim.request";
import {CreateClaimResponse} from "./responses/create-claim.response";
import {HandleClaimForwardRequest} from "./requests/handle-claim-forward.request";
import {HandleClaimForwardRequestResponse} from "./responses/handle-claim-forward.response";
import {HrClaimsHistoryResponse} from "./responses/hr-claims-history.response";
import {ClaimsHistoryRequest} from "./requests/claims-history.request";
import {ClaimRequest} from "./requests/claim.request";
import {ClaimResponse} from "./responses/claim.response";
import {UpdateClaimRequest} from "./requests/update-claim.request";
import {UpdateClaimResponse} from "./responses/update-claim.response";
import {formatDate} from "../../../../utils/date.utils";
import {ClaimsByEmployeeResponse} from "./responses/claims-by-employee.response";
import {ClaimsByEmployeeRequest} from "./requests/claims-by-employee.request";
import {AddClaimDetailsRequest} from "./requests/add-claim-details.request";
import {AddClaimDetailsResponse} from "./responses/add-claim-details.response";
import {HrClaimsHistoryRequest} from "./requests/hr-claims-history.request";


export const getOperatorClaims = async (): Promise<OperatorClaimsResponse> => {
    const data = {}
    return await consumeApi(GET, `${CLAIMS}/list`, "Operator Claims", data, true);
};

export const getClaimsHistory = async (request: ClaimsHistoryRequest): Promise<ClaimsHistoryResponse> => {
    return await consumeApi(POST, `${CLAIMS}/history`, "Claims History", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate)
    }, true);
};

export const forwardClaim = async (claimId: string): Promise<any> => {
    const data = {claimId}
    return await consumeApi(POST, `${CLAIMS}/forward`, "Request Claim", data, true);
};

export const createClaim = async (request: CreateClaimRequest): Promise<CreateClaimResponse> => {
    return await consumeApi(POST, `${CLAIMS}/create`, "Create Claim", request, true);
};

export const handleClaimForward = async (request: HandleClaimForwardRequest): Promise<HandleClaimForwardRequestResponse> => {
    return await consumeApi(POST, `${CLAIMS}/forward/handle`, "Handle Claim Forward", request, true);
};

export const getClaimsByEmployee = async (request: ClaimsByEmployeeRequest): Promise<ClaimsByEmployeeResponse> => {
    return await consumeApi(POST, `${CLAIMS}/hr/get-by-operator`, "Get Employee Claim", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate)
    }, true);
};

export const getHRClaimsHistory = async (request: HrClaimsHistoryRequest): Promise<HrClaimsHistoryResponse> => {
    // TODO: change endpoint to /hr/history when backend is ready
    return await consumeApi(POST, `${CLAIMS}/hr/hystory`, "Get HR Claim History", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate)
    }, true);
};

export const getClaim = async (request: ClaimRequest): Promise<ClaimResponse> => {
    return await consumeApi(POST, `${CLAIMS}/get`, "Get Claim", request, true);
};

export const updateClaim = async (request: UpdateClaimRequest): Promise<UpdateClaimResponse> => {
    return await consumeApi(POST, `${CLAIMS}/update`, "Update Claim", request, true);
};

export const addClaimDetails = async (request: AddClaimDetailsRequest): Promise<AddClaimDetailsResponse> => {
    return await consumeApi(POST, `${CLAIMS}/forward/notes`, "Add Claim Details", request, true);
};