import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Employee} from "../../models/employee.interface";
import {WorkReport} from "../../models/work-reports.interface";
import EndShiftReports from "../../components/work-reports/EndShiftReports";
import IncidentNotificationReports from "../../components/work-reports/IncidentNotificationReports";
import {
    retrieveEmployee,
    retrieveIncidentsNotificationReports,
    retrieveUserWorkReports
} from "../../services/data-manager/data-manager.service";
import {useLocation} from "react-router-dom";
import {EmployeeWorkReportsRequest} from "../../services/backend/api/shifts/requests/employee-work-reports.request";
import {oneYearAgo, today} from "../../utils/date.utils";
import Loader from "../../components/common/LoaderComponent";

const WorkReportsPage: React.FC = () => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    const [employee, setEmployee] = useState<Employee>({} as Employee);
    const {state} = useLocation();

    const [incidentNotificationReports, setIncidentNotificationReports] = useState<WorkReport[]>([]);
    const [endShiftReports, setEndShiftReports] = useState<WorkReport[]>([]);

    useEffect(() => {
        if (state?.userId) {
            const userId = state.userId;
            retrieveEmployee({userId}).then(employee => {
                setEmployee(employee);
                const request: EmployeeWorkReportsRequest = {
                    userId: userId,
                    startDate: oneYearAgo(),
                    endDate: today()
                }

                retrieveUserWorkReports(request).then(reports => {
                    setEndShiftReports(reports);
                });

                retrieveIncidentsNotificationReports(request).then(reports => {
                    setIncidentNotificationReports(reports);
                    setIsLoading(false);
                })
            });
        } else {
            // TODO: This should never happen (?)
            console.error('No userId provided');
        }
    }, [state?.userId]);

    return (
        <div className="page-container mr-12">
            <div className="page-title">
                <h2>{`${employee.name} ${employee.surname}`} &gt; {t('workReportsPage.title')}</h2>
            </div>
            <div className='w-full grid grid-cols-2 gap-8'>
                <div className="col-span-1">
                    {isLoading ? <Loader/> :
                        <IncidentNotificationReports employee={employee} reports={incidentNotificationReports}/>
                    }
                </div>
                <div className="col-span-1">
                    {isLoading ? <Loader/> :
                        <EndShiftReports employee={employee} reports={endShiftReports}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default WorkReportsPage;