import React from "react";
import { useForgotStepper } from "./ForgotPasswordStepperContext";
import { useTranslation } from "react-i18next";
import { BiArrowBack, BiLogInCircle } from "react-icons/bi";
import { Dropdown } from "primereact/dropdown";
import { useToastContext } from "../../../contexts/ToastContext";

export const ForgotLevelStep = () => {
    const { level, setLevel, allowedLevels, goNext } = useForgotStepper();
    const {errorToast} = useToastContext()
    const { t } = useTranslation();

    const handleLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (allowedLevels.includes(e.target.value)) {
            console.log(e.target.value);
            setLevel(e.target.value);
        }
    };

    const handleNext = () => {
        if (level && allowedLevels.includes(level)) {
            goNext();
        } else {
            errorToast(t('selectRole'))
        }
    };


    return <div
        className="flex flex-col items-center justify-center gap-6 bg-[#2D2D2D] py-4 px-10 rounded-lg min-w-[300px] shadow-md"
    >
        <h1 className="text-2xl font-bold">{t('forgotPassword')}</h1>
        <p className="text-sm">{t('forgotPasswordDescription')}</p>
        {allowedLevels && <Dropdown
            className="w-full"
            value={level}
            options={allowedLevels?.map(level => ({label: t(level), value: level}))}
            onChange={(e) => handleLevelChange({target: {value: e.value}} as React.ChangeEvent<HTMLSelectElement>)}
            placeholder={t('selectRole')}
        />}
        <button
            onClick={handleNext}
            className={`primary-button uppercase border-l-2 border-r-2 border-red-500 ${level && allowedLevels.includes(level) ? 'opacity-100' : 'opacity-40'}`}
        >
            <BiArrowBack
                style={{ marginRight: 10, transform: "rotate(180deg)" }}
                size={28} color="whitesmoke" 
            />
            {t('next')}
        </button>
    </div>
};