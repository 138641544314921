import {Document, Page, StyleSheet, View} from "@react-pdf/renderer";
import {defaultStyles} from "../styles/default.styles";
import Header from "./Header";
import Footer from "./Footer";
import React from "react";

const styles = StyleSheet.create({
    ...defaultStyles
});

interface BasePageProps {
    children: any;
}

const BaseDocument: React.FC<BasePageProps> = ({children}) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Header/>
                <View style={styles.container}>
                    {children}
                </View>
                <Footer/>
            </Page>
        </Document>
    )
}

export default BaseDocument