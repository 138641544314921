import {CLAIMS, consumeApi, GET, INCIDENTS, POST, TASKS, USERS} from "../../backend.service";
import {formatDate} from "../../../../utils/date.utils";
import {TasksGraphByEmployeeRequest} from "./requests/tasks-graph-by-employee.request";
import {IncidentsGraphByEmployeeRequest} from "./requests/incidents-graph-by-employee.request";
import {TasksGraphByCategoryResponse} from "./responses/tasks-graph-by-category.response";
import {IncidentsGraphByCategoryResponse} from "./responses/incidents-graph-by-category.response";
import {IncidentsTypologiesResponse} from "./responses/incidents-typologies.response";
import {IncidentsGraphRequest} from "./requests/incidents-graph.request";
import {TasksGraphRequest} from "./requests/tasks-graph.request";

export const incidentsGraph = async (request: IncidentsGraphRequest): Promise<any> => {
    return await consumeApi(POST, `${INCIDENTS}/general/graph`, "Incident Graph", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate)
    }, true);
};

export const reportsGraph = async (startDate: string, endDate: string): Promise<any> => {
    const data = {startDate, endDate}
    return await consumeApi(POST, `${USERS}/general/reports/graph`, "Reports Graph", data, true);
};

export const presenceGraph = async (startDate: string, endDate: string): Promise<any> => {
    const data = {startDate, endDate}
    return await consumeApi(POST, `${USERS}/general/presence/graph`, "Presence Graph", data, true);
};

export const claimsGraph = async (startDate: string, endDate: string): Promise<any> => {
    const data = {startDate, endDate}
    return await consumeApi(POST, `${CLAIMS}/general/managed-graph`, "Claims Graph", data, true);
};

export const leavesGraph = async (startDate: string, endDate: string): Promise<any> => {
    const data = {startDate, endDate}
    return await consumeApi(POST, `${USERS}/general/leaves/graph`, "Leaves Graph", data, true);
};

export const workersGraph = async (): Promise<any> => {
    return await consumeApi(POST, `${USERS}/general/workers/overview`, "Workers Graph", {}, true);
};

export const goalGraph = async (startDate: string, endDate: string): Promise<any> => {
    const data = {startDate, endDate}
    return await consumeApi(POST, `${TASKS}/general/goal/overview`, "Goal Graph", data, true);
};

export const tasksGraph = async (request: TasksGraphRequest): Promise<any> => {
    return await consumeApi(POST, `${TASKS}/general/task-graph`, "Task Graph", request, true);
};

export const getTasksGraphByEmployee = async (request: TasksGraphByEmployeeRequest): Promise<TasksGraphByCategoryResponse> => {
    return await consumeApi(POST, `${TASKS}/general/task-graph-by-operator`, "Tasks Graph By Employee", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate)
    }, true);
};

export const getIncidentsGraphByEmployee = async (request: IncidentsGraphByEmployeeRequest): Promise<IncidentsGraphByCategoryResponse> => {
    return await consumeApi(POST, `${INCIDENTS}/get/reporting-by-operator`, "Incidents Graph By Employee", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate)
    }, true);
};

export const getIncidentsTypologies = async (): Promise<IncidentsTypologiesResponse> => {
    return await consumeApi(GET, `${INCIDENTS}/get`, "Incidents Typologies", {}, true);
};