import {consumeApi, NOTIFICATIONS, POST} from "../../backend.service";
import {CenterNotificationsResponse} from "./responses/center-notifications.response";
import {SetNotificationReadRequest} from "./requests/set-notification-read.request";
import {SetNotificationReadResponse} from "./responses/set-notification-read.response";
import {NotReadNotificationsCountRequest} from "./requests/not-read-notifications-counter.request";
import {NotReadNotificationsCountResponse} from "./responses/not-read-notifications-counter.response";
import {ManagerNotificationCenterRequest} from "./requests/manager-notification-center.request";
import {formatDate} from "../../../../utils/date.utils";

export const getCenterNotifications = async (): Promise<CenterNotificationsResponse> => {
    return await consumeApi(POST, `${NOTIFICATIONS}/get`, "Center Notifications", {}, true);
};

export const getManagerCenterNotifications = async (request: ManagerNotificationCenterRequest): Promise<CenterNotificationsResponse> => {
    return await consumeApi(POST, `${NOTIFICATIONS}/manager/get`, "Manager Center Notifications", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate)
    }, true);
};

export const setNotificationRead = async (request: SetNotificationReadRequest): Promise<SetNotificationReadResponse> => {
    return await consumeApi(POST, `${NOTIFICATIONS}/set`, "Ser Notification Read", request, true);
};

export const getNotReadNotificationCount = async (request: NotReadNotificationsCountRequest): Promise<NotReadNotificationsCountResponse> => {
    return await consumeApi(POST, `${NOTIFICATIONS}/notRead`, "Not Read Notification Count", request, true);
};