import React from "react";
import "../../assets/Loader.css";

const Loader = () => {
  return (
    <div className="w-full flex justify-center content-center">
        <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        </div>
    </div>
  );
};

export default Loader;
