import React, {useEffect, useState} from 'react';
import {Cell, Pie, PieChart} from 'recharts';
import Loader from "../common/LoaderComponent";
import {useTranslation} from 'react-i18next';
import {BlobProvider} from "@react-pdf/renderer";
import {FiDownload} from "react-icons/fi";
import ReportSummary from "../pdf/summaries/ReportSummary";
import FilterButton from "./FilterButton";
import {leavesGraph} from "../../services/backend/api/graphs/graphs.apis";
import LeavesSummary from '../pdf/summaries/LeavesSummary';

const LeavesGraph: React.FC = () => {
    const {t, i18n} = useTranslation();
    const [activeTab, setActiveTab] = useState(t("provider_graph.Today"));
    const [rangeOptions, setRangeOptions] = useState([
        t("provider_graph.Today"),
        t("provider_graph.ThisWeek"),
        t("provider_graph.ThisMonth"),
        t("provider_graph.ThisYear")
    ]);

    const [graphData, setGraphData] = useState<any>([]);
    const [isDataAvaible, setIsDataAvaible] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const formatDate = (date: Date): string => {
        return date.toISOString().split('T')[0];
    };

    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const [endDate, setEndDate] = useState(formatDate(new Date()));

    const setDateRangeFromString = (tab: string) => {
        const today = new Date();
        setActiveTab(tab);
        switch (tab) {
            case t("provider_graph.Today"):
                setStartDate(formatDate(today));
                setEndDate(formatDate(today));
                break;
            case t("provider_graph.ThisWeek"):
                const startWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
                setStartDate(formatDate(startWeek));
                setEndDate(formatDate(today));
                break;
            case t("provider_graph.ThisMonth"):
                const startMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                setStartDate(formatDate(startMonth));
                setEndDate(formatDate(today));
                break;
            case t("provider_graph.ThisYear"):
                const startYear = new Date(today.getFullYear(), 0, 1);
                setStartDate(formatDate(startYear));
                setEndDate(formatDate(today));
                break;
            default:
                console.log("Invalid tab");
                setStartDate(formatDate(today));
                setEndDate(formatDate(today));
                break;
        }
    };

    useEffect(() => {
        const fetchLeavesGraph = async () => {
            try {
                setIsLoading(true);
                setIsDataAvaible(false)
                console.log("Fetching presence Graph data from ", startDate, " to ", endDate);
                const reportData = await leavesGraph(startDate, endDate);
                setGraphData([
                    {name: 'Personal Health', value: reportData?.personalHealthCounters || 0},
                    {name: 'Emergency Leave', value: reportData?.emergencyLeaveCounters || 0},
                    {name: 'Annual Leave', value: reportData?.annualLeaves || 0},
                ]);

                setIsDataAvaible(reportData?.personalHealthCounters > 0 || reportData?.emergencyLeaveCounters > 0 || reportData?.annualLeaves > 0);
                setIsLoading(false);
            } catch (error) {
                console.error("Errore durante il recupero dei dati delle Presenze:", error);
                setGraphData([
                    {name: 'Personal Health', value: 0},
                    {name: 'Emergency Leave', value: 0},
                    {name: 'Annual Leaves', value: 0},
                ]);
                setIsDataAvaible(false);
                setIsLoading(false);
            }
        };

        if (startDate && endDate) {
            fetchLeavesGraph();
        }
    }, [startDate, endDate]);


    const data = [
        {name: 'Personal Health', value: 50},
        {name: 'Emergency Leave', value: 30},
        {name: 'Annual Leaves', value: 20},
    ];

    const COLORS = ['#E0E0E0', '#FF9800', '#FFC107'];

    useEffect(() => {
        const handleLanguageChange = () => {
            setRangeOptions(
                [t("provider_graph.Today"), t("provider_graph.ThisWeek"), t("provider_graph.ThisMonth"), t("provider_graph.ThisYear")]
            )
        };
        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };

    }, [i18n, t]);

    return (
        <div className="card-container">
            <h2 className="card-title">{t("provider_graph.Leaves")}</h2>
            <div className="options-selector">
                {rangeOptions.map((tab) => (
                    <FilterButton
                        key={tab}
                        label={tab}
                        isActive={activeTab === tab}
                        onClick={() => setDateRangeFromString(tab)}
                    />
                ))}
            </div>
            <div className="card-content">
                <div className="graph">
                    {
                        isLoading &&
                        <div style={{width: 200, height: 200}} className='flex justify-center items-center'>
                            <Loader/>
                        </div>
                    }

                    {
                        !isLoading && isDataAvaible && <PieChart width={200} height={200}>
                            <Pie
                                data={graphData}
                                cx={100}
                                cy={100}
                                innerRadius={60}
                                outerRadius={80}
                                paddingAngle={5}
                                dataKey="value"
                                stroke="#4E4E4E"
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                ))}
                            </Pie>
                        </PieChart>
                    }

                    {
                        !isLoading && !isDataAvaible &&
                        <div style={{width: 200, height: 200}} className='flex justify-center items-center'>
                            {t("provider_graph.NoData")}
                        </div>
                    }


                    <div className="text-white">
                        <div className="flex items-center mb-2">
                            <div className="w-4 h-4 mr-2" style={{backgroundColor: COLORS[0]}}></div>
                            <span className="text-sm">{t("provider_graph.personalHealth")}</span>
                        </div>
                        <div className="flex items-center mb-2">
                            <div className="w-4 h-4 mr-2" style={{backgroundColor: COLORS[1]}}></div>
                            <span className="text-sm">{t("provider_graph.emergencyLeave")}</span>
                        </div>
                        <div className="flex items-center mb-2">
                            <div className="w-4 h-4 mr-2" style={{backgroundColor: COLORS[2]}}></div>
                            <span className="text-sm">{t("provider_graph.annualLeaves")}</span>
                        </div>
                    </div>
                </div>
            </div>

            <BlobProvider document={<LeavesSummary data={graphData}/>}>
                {({url, blob}) => (
                    url &&
                    <a href={url} target="_blank" className="primary-button uppercase bordered-button "
                       rel="noreferrer">
                        <FiDownload size={20} className="mx-2"/>
                        {t("documentPage.download")}
                    </a>
                )}
            </BlobProvider>
        </div>
    );
};

export default LeavesGraph;
