import React from 'react'
import {LeaveRequest} from "../../../models/leave-request.interface";
import {Text} from "@react-pdf/renderer";
import ClassicDocument from "../components/ClassicDocument";
import {formatDate} from "../../../utils/date.utils";

interface LeaveSummaryProps {
    leave: LeaveRequest;
}

const LeaveRequestDocument: React.FC<LeaveSummaryProps> = ({leave}) => {
    return (
        <ClassicDocument
            title={"Leave"}
            subtitle={`ID: ${leave.id}`}
            content={
                <Text>
                    {`
                        - Leave Details:
                        User ID: ${leave.userId}
                        Category: ${leave.category}
                        Date: ${formatDate(new Date(leave.date))}
                    `}
                </Text>
            }
        />
    )
}
export default LeaveRequestDocument