import React from 'react';
import {Check} from 'lucide-react';
import {useTranslation} from 'react-i18next';
import {UserType} from '../../models/types/user.type';

interface UserTasksProps {
  user: UserType;
}

const WorkerTask: React.FC<UserTasksProps> = ({ user }) => {
  const { i18n } = useTranslation();
  const id = user.userId

  if (!user.tasks || user.tasks.length === 0 || user.tasks[0]?.operatorIds?.[id].taskStatus === 'waiting') {
    return null;
  }

  // @ts-ignore
  const taskName = user.tasks[0]?.taskName[i18n.language] || user.tasks[0]?.taskName['en'];
  const taskStatus = user.tasks[0]?.operatorIds?.[id].taskStatus;

  const isCompleted = taskStatus === 'completed';
  const isSuspended = taskStatus === 'suspend';

  return (
      <div className="flex items-center space-x-4 w-full justify-end">
        <span className="text-sm text-gray-300">{taskName}</span>
        <div className="flex items-center space-x-4">
          <div
              className={`w-8 h-8 rounded-md flex items-center justify-center overflow-visible border-2 border-white-600 bg-white`}>
            {<Check size={24} className="text-gray-800" strokeWidth={3}/>}
          </div>
          <div
              className={`w-8 h-8 rounded-md flex items-center justify-center overflow-visible border-2 border-green-600 ${isCompleted ? 'bg-green-600' : 'bg-unset'}`}>
            {isCompleted && <Check size={24} className="text-gray-800" strokeWidth={3}/>}
          </div>
          <div
              className={`w-8 h-8 rounded-md flex items-center justify-center overflow-visible border-2 border-yellow-600 ${isSuspended ? 'bg-yellow-600' : 'bg-unset'}`}>
            {isSuspended && <Check size={24} className="text-gray-800" strokeWidth={3}/>}
          </div>
        </div>
      </div>
  );
};

export default WorkerTask;