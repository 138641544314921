import {consumeApi, GET, POST, TASKS,} from "../../backend.service";
import {CreateTaskRequest} from "./requests/create-task.request";
import {CreateTasksResponse} from "./responses/create-tasks.response";
import {UpdateTaskRequest} from "./requests/update-task.request";
import {UpdateTasksResponse} from "./responses/update-tasks.response";
import {DeleteTasksResponse} from "./responses/delete-tasks.response";
import {DeleteTaskRequest} from "./requests/delete-task.request";
import {AssignTaskSupervisorRequest} from "./requests/assign-task-supervisor.request";
import {AssignTaskSupervisorResponse} from "./responses/assign-task-supervisor.response";
import {OperatorTasksResponse} from "./responses/operator-tasks.response";
import {UpdateOperatorTaskRequest} from "./requests/update-operator-task.request";
import {UpdateOperatorTaskResponse} from "./responses/update-operator-task.response";
import {TaskTemplatesResponse} from "./responses/task-templates.response";
import {SupervisorsOnShiftByDayRequest} from "./requests/supervisors-on-shift-by-day.request";
import {SupervisorsOnShiftByDayResponse} from "./responses/supervisors-on-shift-by-day.response";
import {formatDate} from "../../../../utils/date.utils";
import {OperatorsOnShiftByDayRequest} from "./requests/operators-on-shift-by-day.request";
import {OperatorsOnShiftByDayResponse} from "./responses/operators-on-shift-by-day.response";

export const createTask = async (request: CreateTaskRequest): Promise<CreateTasksResponse> => {
    return await consumeApi(POST, `${TASKS}/provider/hses/create`, "Create Task", request, true);
};

export const updateTask = async (request: UpdateTaskRequest): Promise<UpdateTasksResponse> => {
    return await consumeApi(POST, `${TASKS}/provider/hses/update`, "Update Task", {}, true);
};

export const deleteTask = async (request: DeleteTaskRequest): Promise<DeleteTasksResponse> => {
    return await consumeApi(POST, `${TASKS}/provider/hses/delete`, "Delete Task", {}, true);
};

export const assignTaskSupervisor = async (request: AssignTaskSupervisorRequest): Promise<AssignTaskSupervisorResponse> => {
    return await consumeApi(POST, `${TASKS}/provider/hses/assign`, "Assign Supervisor Operator", request, true);
};

export const getOperatorTasks = async (): Promise<OperatorTasksResponse> => {
    return await consumeApi(GET, `${TASKS}/operators/get`, "Operator Tasks", {}, true);
};

export const updateOperatorTasks = async (request: UpdateOperatorTaskRequest): Promise<UpdateOperatorTaskResponse> => {
    return await consumeApi(POST, `${TASKS}/operators/update`, "Update Operator Task", request, true);
};

export const getTaskTemplates = async (): Promise<TaskTemplatesResponse> => {
    return await consumeApi(GET, `${TASKS}/provider/templates`, "Provider Template", {}, true);
};

export const getSupervisorsOnShiftByDay = async (request: SupervisorsOnShiftByDayRequest): Promise<SupervisorsOnShiftByDayResponse> => {
    return await consumeApi(POST, `${TASKS}/goal/provider/supervisor-by-day`, "Supervisors On Shift By Day", {
        ...request,
        dateDate: formatDate(request.dateDate)
    }, true);
}

export const getOperatorsOnShiftByDay = async (request: OperatorsOnShiftByDayRequest): Promise<OperatorsOnShiftByDayResponse> => {
    return await consumeApi(POST, `${TASKS}/goal/hr/operators-by-day`, "Operators On Shift By Day", {
        ...request,
        dateDate: formatDate(request.dateDate)
    }, true);
}