import React from 'react'
import {Text} from "@react-pdf/renderer";
import ClassicDocument from "../components/ClassicDocument";
import {ClaimLog} from "../../../services/backend/api/documents/models/claim-file.interface";
import {formatDate} from "../../../utils/date.utils";

interface ClaimDocumentProps {
    log: ClaimLog;
}

const ClaimDocument: React.FC<ClaimDocumentProps> = ({log}) => {
    return (
        <ClassicDocument
            title={"Claim"}
            subtitle={`ID: ${log.claimId}`}
            content={
                <Text>
                    {`
                        - Claim Details:
                        Created At: ${formatDate(new Date(log.createdAt))}
                        Log ID: ${log.logId}
                        Claim Status: ${log.claimStatus}
                        Operator ID: ${log.operatorId}
                    `}
                </Text>
            }
        />
    )
}
export default ClaimDocument