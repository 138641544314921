import React from 'react'
import {StyleSheet, Text, View} from '@react-pdf/renderer';
import ClassicDocument from "./ClassicDocument";
import {defaultStyles} from "../styles/default.styles";
import {camelCaseToTitleCase} from "../../../utils/text-formatting.utils";
import {WorkReport} from "../../../models/work-reports.interface";
import {LeaveRequest} from "../../../models/leave-request.interface";


const styles = StyleSheet.create({
    ...defaultStyles,
});


interface ReportsSummaryDocumentProps {
    title: string;
    subtitle: string;
    documents: WorkReport[] | LeaveRequest[];
    printableKeys: string[];
}

const SummaryDocument: React.FC<ReportsSummaryDocumentProps> = ({title, subtitle, documents, printableKeys}) => {
    const formatValue = (value: any) => {
        switch (typeof value) {
            case 'boolean':
                return value ? 'Yes' : 'No';
            case 'object':
                if (value instanceof Date) {
                    return value.toLocaleDateString();
                }
                return value.toString();

            case "string":
                return value;

            default:
                return value.toString();
        }
    }

    return (
        <ClassicDocument
            title={title}
            subtitle={subtitle}
            content={
                <>
                    <View style={styles.table}>
                        <View style={styles.tableHeader}>
                            {
                                documents && documents.length > 0 &&
                                Object.keys(documents[0]).map((key: string) => (
                                    printableKeys.includes(key) &&
                                    <Text style={styles.headerCell} key={key}>{camelCaseToTitleCase(key)}</Text>
                                ))
                            }
                        </View>


                        {
                            documents && documents.length > 0 &&
                            documents.map((item: any, index: any) => (
                                <View style={styles.tableRow} key={index}>
                                    {
                                        Object.keys(item).map((key: string) => (
                                            printableKeys.includes(key) &&
                                            <Text style={styles.rowCell} key={key}>
                                                {
                                                    item[key] && formatValue(item[key])
                                                }
                                            </Text>
                                        ))
                                    }
                                </View>
                            ))
                        }

                    </View>
                </>
            }
        />
    )
}
export default SummaryDocument