import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import Loader from "../../components/common/LoaderComponent";
import { Employee } from "../../models/employee.interface";
import { USER } from "../../utils/constants.utils";
import { HiOutlineUserCircle } from "react-icons/hi";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/OperatorContextProvider";
import { retrieveTerminatedEmployees } from "../../services/data-manager/data-manager.service";
import { shortId } from "../../utils/text-formatting.utils";
import { isContained } from "../../utils/generic.utils";
import { formatDateTime } from "../../utils/date.utils";
import i18next from "i18next";
import { UserRole } from "../../models/enums/user-role.enum";
import { FaCircle } from "react-icons/fa6";

const TerminatedWorkerPage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { level } = useUserContext();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [startDate, setStartDate] = useState<string>(new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState<string>(new Date(Date.now() + 86400000).toISOString().split('T')[0]);
    const [filter, setFilter] = useState(t('employeesManagementPage.filters.all'));

    const SUPERVISOR_FILTER = t('employeesManagementPage.filters.supervisor')
    const OPERATOR_FILTER = t('employeesManagementPage.filters.operator')
    const NO_FILTER = t('employeesManagementPage.filters.all')

    const filters = [
        NO_FILTER,
        SUPERVISOR_FILTER,
        OPERATOR_FILTER
    ]

    const [ln, setLn] = useState<string>(i18next.language || 'en'); // Imposta la lingua iniziale
    useEffect(() => {
        const handleLanguageChange = (lng: string) => setLn(lng);
        i18next.on("languageChanged", handleLanguageChange);

        return () => {
            i18next.off("languageChanged", handleLanguageChange);
        };
    }, []);

    const viewEmployeeProfile = (id: string) => () => {
        navigate(`/${level}/${USER}/`, {
            state: {
                data: {
                    userId: id
                }
            }
        });
    };

    const searchEmployee = (e: any) => {
        setSearchValue(e.target.value.trim());
    };

    const filterEmployees = useCallback(() => {
        let filtered = employees.filter(employee => {
            if (filter === NO_FILTER) return true
            if (filter === SUPERVISOR_FILTER && employee.type === UserRole.Supervisor) return true
            if (filter === OPERATOR_FILTER && employee.type === UserRole.Operator) return true
        });
        if (searchValue) {
            filtered = filtered.filter(employee => {
                return isContained(employee.name, searchValue)
                    || isContained(employee.surname, searchValue)
                    || isContained(employee.id, searchValue)
            })
        }
        setFilteredEmployees(filtered);
    }, [NO_FILTER, OPERATOR_FILTER, SUPERVISOR_FILTER, employees, filter, searchValue]);

    const handleFilterChange = useCallback((option: string) => {
        if (option === filter) {
            setFilter(NO_FILTER)
        } else {
            setFilter(option);
        }
    }, [NO_FILTER, filter]);

    useEffect(() => {
        setIsLoading(true);
        retrieveTerminatedEmployees(startDate, endDate).then(employees => {
            setEmployees(employees);
            setIsLoading(false);
        });
    }, [startDate, endDate]);

    useEffect(() => {
        filterEmployees();
    }, [filter, filterEmployees]);

    return (
        <div className="page-container mr-12">
            <div className="page-title flex">
                <h2 className="flex-grow">{t('employeesManagementPage.deactivedTitle')}</h2>
            </div>
            <div className="rounded-container w-full">
                <div className="page-content max-w-6xl">
                    <div className="bg-[var(--primary-color)] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-3 p-2 mb-4 rounded-lg w-full">
                        {/* Buttons */}
                        {filters.map((option, index) => (
                            <button
                                key={index}
                                className={`px-3 py-1 rounded-lg ellipsis flex items-center justify-center ${filter === option
                                        ? 'bg-white border-2 border-white-500 text-gray-900'
                                        : 'bg-unser border-2 border-white-500 text-white'
                                    }`}
                                onClick={() => handleFilterChange(option)}
                            >
                                <div className="flex justify-center items-center">
                                    <span className="truncate">{option}</span>
                                </div>
                            </button>
                        ))}

                        {/* Date Inputs */}
                        <input
                            type="date"
                            className="rounded-lg p-2 border border-white text-white bg-transparent [&::-webkit-calendar-picker-indicator]:invert"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                        <input
                            type="date"
                            className="rounded-lg p-2 border border-white text-white bg-transparent [&::-webkit-calendar-picker-indicator]:invert"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />

                        {/* Search Bar */}
                        <div className="flex items-center gap-2 relative">
                            <span className="absolute left-2 top-1/2 transform -translate-y-1/2">
                                <CiSearch size={24} />
                            </span>
                            <input
                                type="search"
                                className="pl-10 pr-2 py-1 rounded-lg border border-white text-white bg-transparent w-full h-full"
                            />
                        </div>
                    </div>
                </div>

                <div className="space-y-2 w-full">
                    {isLoading ? <Loader /> :
                        <>
                            {filteredEmployees && filteredEmployees?.length > 0 ? (
                                filteredEmployees?.map((employee, index) => (
                                    <div key={index} className="primary-container">
                                        <div
                                            key={employee.id}
                                            className="bg-[var(--primary-color)] rounded-lg p-3 flex items-center justify-start flex-col gap-4 lg:flex-row lg:gap-0"
                                        >
                                            <div className="flex flex-grow items-center space-x-2 flex-col gap-4 md:flex-row md:gap-0">
                                                <HiOutlineUserCircle size={24} className="text-gray-400" />
                                                <div className="w-28 ellipsis">
                                                    <span className="text-gray-300 text-sm">{shortId(employee.id)}</span>
                                                </div>
                                                <div className="overflow-hidden flex items-center justify-center">
                                                    <span className="whitespace-no-wrap overflow-hidden overflow-ellipsis block ml-6">
                                                        {employee.name} {employee.surname}
                                                    </span>
                                                </div>
                                                <div className="overflow-hidden flex items-center justify-center">
                                                    <span className="whitespace-no-wrap overflow-hidden overflow-ellipsis block ml-6">
                                                        {employee.terminatedAt ? formatDateTime(new Date(employee.terminatedAt), ln) : "-"}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex justify-center items-center space-x-8 text-sm">
                                                <Button className="h-6" onClick={viewEmployeeProfile(employee.id)}>
                                                    {t('employeesManagementPage.viewProfile')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='text-center py-10'>{t('employeesManagementPage.noData')}</div>
                            )}
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default TerminatedWorkerPage;