import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Cell, LabelList, Pie, PieChart, ResponsiveContainer} from 'recharts';
import Loader from "../common/LoaderComponent";
import {useNavigate} from "react-router-dom";
import {WORKER_OVERVIEW} from "../../utils/constants.utils";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {workersGraph} from "../../services/backend/api/graphs/graphs.apis";

const ACTIVE = 'Active';
const ON_BREAK = 'On break';
const ON_LEAVE = 'On leave';
const ABSENT = 'Absent';

const ACTIVE_COLOR = '#4CAF50';
const ON_BREAK_COLOR = '#FFC107';
const ON_LEAVE_COLOR = '#CBAD55';
const ABSENT_COLOR = '#9E9E9E';

interface WorkersOverviewEntry {
    name: string
    value: number
    color: string
}

const opData: WorkersOverviewEntry[] = [
    {name: ACTIVE, value: 0, color: ACTIVE_COLOR},
    {name: ABSENT, value: 0, color: ABSENT_COLOR},
    {name: ON_LEAVE, value: 0, color: ON_LEAVE_COLOR},
    {name: ON_BREAK, value: 0, color: ON_BREAK_COLOR},
];

const supData: WorkersOverviewEntry[] = [
    {name: ACTIVE, value: 0, color: ACTIVE_COLOR},
    {name: ABSENT, value: 0, color: ABSENT_COLOR},
    {name: ON_LEAVE, value: 0, color: ON_LEAVE_COLOR},
];

interface WorkersOverviewProps {
    onDataLoad?: (data: any) => void
    onShowDetail?: (state: boolean) => void
}

const WorkersOverview: React.FC<WorkersOverviewProps> = ({onDataLoad, onShowDetail}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [workersOverview, setWorkersOverview] = useState<any>([]);
    const [operatorsData, setOperatorsData] = useState<WorkersOverviewEntry[]>(opData);
    const [supervisorsData, setSupervisorsData] = useState<WorkersOverviewEntry[]>(supData);

    const [isLoading, setIsLoading] = useState(false);
    const {level} = useUserContext();

    const fetchWorkerOverview = useCallback(async (showLoading: boolean) => {
        try {
            setIsLoading(showLoading);
            console.log("Fetching worker overview...");
            const overviewData = await workersGraph();
            let operatorsOverview: WorkersOverviewEntry[] = [
                {name: ACTIVE, value: overviewData.cakeData.operator.active, color: ACTIVE_COLOR},
                {name: ABSENT, value: overviewData.cakeData.operator.absent, color: ABSENT_COLOR},
                {name: ON_LEAVE, value: overviewData.cakeData.operator.onLeave, color: ON_LEAVE_COLOR},
                {name: ON_BREAK, value: overviewData.cakeData.operator.onBreak, color: ON_BREAK_COLOR},
            ];

            let supervisorsOverview: WorkersOverviewEntry[] = [
                {name: ACTIVE, value: overviewData.cakeData.supervisor.active, color: ACTIVE_COLOR},
                {name: ABSENT, value: overviewData.cakeData.supervisor.absent, color: ABSENT_COLOR},
                {name: ON_LEAVE, value: overviewData.cakeData.supervisor.onLeave, color: ON_LEAVE_COLOR},
            ];

            operatorsOverview = operatorsOverview.filter((entry: any) => entry.value > 0);
            supervisorsOverview = supervisorsOverview.filter((entry: any) => entry.value > 0);
            if (operatorsOverview.length === 0) {
                operatorsOverview = [{name: ABSENT, value: 1, color: ABSENT_COLOR}];
            }

            if (supervisorsOverview.length === 0) {
                supervisorsOverview = [{name: ABSENT, value: 1, color: ABSENT_COLOR}];
            }

            setOperatorsData(operatorsOverview);
            setSupervisorsData(supervisorsOverview);

            if (onDataLoad) {
                onDataLoad(overviewData)
            }

            setWorkersOverview(overviewData);

            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching worker overview:", error);
            setIsLoading(false);
        }
    }, [setIsLoading, setOperatorsData, setSupervisorsData, onDataLoad, setWorkersOverview])

    useEffect(() => {
        fetchWorkerOverview(true).then(() => console.log('Worker overview fetched'));
    }, [fetchWorkerOverview]);

    // Auto update table
    useEffect(() => {
        const interval = setInterval(() => fetchWorkerOverview(false), 1000 * 20);
        return () => clearInterval(interval);
    }, [fetchWorkerOverview]);

    const renderPieChart = (data: typeof operatorsData, totalLabel: string) => (
        <div className="relative w-80 h-80">
            <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                    <Pie
                        data={data.filter((entry: any) => entry.value > 0)}
                        cx="50%"
                        cy="50%"
                        innerRadius={0}
                        outerRadius={90}
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        blendStroke={false}
                        stroke="#4E4E4E"
                        strokeWidth={3}
                    >
                        <LabelList dataKey="name" position="inside" stroke="none" style={{
                            fontFamily: "Helvetica",
                            fontSize: 12,
                            fill: "white",
                            fontWeight: 'normal',
                        }}/>
                        {data.map((entry: any, index: number) => (
                            <Cell key={`cell-${index}`} fill={entry.color}/>
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );


    const viewDetail = () => {
        navigate(`/${level}/${WORKER_OVERVIEW}`, {
            state: {
                data: workersOverview
            }
        });
    };

    return (
        <div className="card-container">
            <h2 className="card-title">{t("provider_graph.WorkerOverview")}</h2>
            <div className="card-content">

                <div
                    className="flex h-full flex-col lg:flex-row items-center w-full mb-6 space-y-6 lg:space-y-0 justify-around">
                    <div className="text-center">
                        <h3 className="text-white text-lg md:text-xl">{t("provider_graph.Operators")}</h3>
                        {isLoading ?
                            <div style={{width: 200, height: 200}} className='flex justify-center items-center'>
                                <Loader/>
                            </div> :
                            renderPieChart(operatorsData, "130")
                        }
                    </div>

                    <div
                        className="flex lg:flex-col items-center justify-center lg:items-center space-x-4 lg:space-x-0 lg:space-y-2">
                        <div className="grid">
                            <div className="flex items-center">
                                <div className="w-4 h-4 mr-2" style={{backgroundColor: ACTIVE_COLOR}}></div>
                                <span className="text-white">{t("provider_graph.Active")}</span>
                            </div>
                            <div className="flex items-center">
                                <div className="w-4 h-4 mr-2" style={{backgroundColor: ON_BREAK_COLOR}}></div>
                                <span className="text-white">{t("provider_graph.OnBreak")}</span>
                            </div>
                            <div className="flex items-center">
                                <div className="w-4 h-4 mr-2" style={{backgroundColor: ON_LEAVE_COLOR}}></div>
                                <span className="text-white">{t("provider_graph.OnLeave")}</span>
                            </div>
                            <div className="flex items-center">
                                <div className="w-4 h-4 mr-2" style={{backgroundColor: ABSENT_COLOR}}></div>
                                <span className="text-white">{t("provider_graph.absent")}</span>
                            </div>
                        </div>
                        <button
                            onClick={() => viewDetail()}
                            className="primary-button uppercase min-w-32"
                            style={{
                                borderLeft: "4px solid white",
                                borderRight: "4px solid white",
                                width: "12rem",
                            }}
                        >
                            {t("provider_graph.ShowDetail")}
                        </button>
                    </div>

                    <div className="text-center">
                        <h3 className="text-white text-lg md:text-xl">{t("provider_graph.Supervisors")}</h3>
                        {
                            isLoading ?
                                <div style={{width: 200, height: 200}} className='flex justify-center items-center'>
                                    <Loader/>
                                </div> :
                                renderPieChart(supervisorsData, "70")}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default WorkersOverview;