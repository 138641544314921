import {useTranslation} from "react-i18next";
import React from "react";
import CreateCommunication from "../../components/communication/CreateCommunication";
import CommunicationArchive from "../../components/communication/CommunicationArchive";

const CommunicationPage: React.FC = () => {
    const {t} = useTranslation();

    return (
        <>
            <div className="page-container">
                <div className="page-title">
                    <h2>{t('communicationPage.title')}</h2>
                </div>
                <div className="page-content space-y-20">
                    <CreateCommunication/>
                    <CommunicationArchive/>
                </div>
            </div>
        </>
    );
};

export default CommunicationPage;
