export const capitalize = (s: string | null) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const camelCaseToTitleCase = (s: string | null) => {
    if (typeof s !== 'string') return ''

    if (s === s.toLowerCase()) {
        return capitalize(s)
    }

    if (s === s.toUpperCase()) {
        return capitalize(s.toLowerCase())
    }

    return s.replace(/([A-Z0-9])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
    });
}

export const toCamelCase = (s: string | null) => {
    if (typeof s !== 'string') return ''
    return s.replace(/^\w|[A-Z]|\b\w/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

export const shortId = (id?: string) => {
    if (typeof id !== 'string') return ''
    return id.split('-')[0] + '...'
}


export const shortDocumentName = (name?: string) => {
    if (typeof name !== 'string') return ''
    let result = name.split('_')[1]
    if (result.length < 3) {
        result = name
    }
    if (result.length > 16) {
        result = result.slice(0, 16) + '...'
    }
    return result
}


export const formatSeverity = (severity: string) => {
    switch (severity) {
        case '0':
            return "Minor";
        case '1':
            return "Medium";
        case '2':
            return "Major";
        default:
            return "Unknown";
    }
}

export const formatBool = (value: string) => {
    return value === "true" ? "Yes" : "No";
}