import {Navigate, Route, Routes} from "react-router-dom";
import MenuNav from "../components/common/MenuNav";
import {
    CALENDAR,
    COMMUNICATION,
    COMMUNICATION_HR,
    COMMUNICATION_HSES_CLIENT,
    COMMUNICATION_HSES_PROVIDER,
    COMMUNICATION_OPERATOR,
    COMMUNICATION_SUPERVISOR,
    DEFAULT_PATH,
    DOCUMENTS,
    DOCUMENTS_CONTRACTS,
    DOCUMENTS_HR,
    DOCUMENTS_HR_CLAIMS,
    DOCUMENTS_HR_OPERATOR_CONTRACT,
    DOCUMENTS_HR_SUPERVISOR_CONTRACT,
    DOCUMENTS_INSURANCES,
    DOCUMENTS_MEDICAL,
    DOCUMENTS_PAYROLLS,
    DOCUMENTS_REPORT,
    DOCUMENTS_REPORT_INCIDENT_NOTIFICATION,
    DOCUMENTS_REPORT_INCIDENT_VERIFICATION,
    DOCUMENTS_REPORT_OPERATOR_SHIFT,
    DOCUMENTS_REPORT_SUPERVISOR_SHIFT,
    HOME,
    NOTIFICATION,
    TASK,
    WORKER_OVERVIEW
} from "../utils/constants.utils";
import TaskAssignmentPage from "../pages/provider/TaskAssignmentPage";
import HomePage from "../pages/common/HomePage";
import CommunicationPage from "../pages/common/CommunicationPage";
import WorkerOverViewPage from "../pages/provider/WorkerOverViewPage";
import CommunicationSectionPage from "../pages/common/CommunicationSectionPage";
import NotificationPage from "../pages/common/NotificationPage";
import ShiftAssignmentPage from "../pages/provider/ShiftAssignmentPage";
import DocumentsArchivePage from "../pages/common/DocumentsArchivePage";
import {DocumentCategory} from "../models/enums/document-category.enum";

const ProviderLayout = () => {
    return (
        <div className="flex">
            <div className="my-10 mx-6">
                <MenuNav/>
            </div>
            <div className="w-full">
                <Routes>
                    <Route path={DEFAULT_PATH} element={<Navigate to={HOME} replace={true}/>}/>
                    <Route path={HOME} element={<HomePage/>}/>
                    <Route path={CALENDAR} element={<ShiftAssignmentPage/>}/>
                    <Route path={TASK} element={<TaskAssignmentPage/>}/>
                    <Route path={WORKER_OVERVIEW} element={<WorkerOverViewPage/>}/>

                    <Route path={NOTIFICATION} element={<NotificationPage/>}/>
                    <Route path={COMMUNICATION} element={<CommunicationPage/>}/>
                    <Route path={COMMUNICATION_OPERATOR} element={<CommunicationSectionPage/>}/>
                    <Route path={COMMUNICATION_SUPERVISOR} element={<CommunicationSectionPage/>}/>
                    <Route path={COMMUNICATION_HR} element={<CommunicationSectionPage/>}/>
                    <Route path={COMMUNICATION_HSES_PROVIDER} element={<CommunicationSectionPage/>}/>
                    <Route path={COMMUNICATION_HSES_CLIENT} element={<CommunicationSectionPage/>}/>
                    <Route path={DOCUMENTS}
                           element={<DocumentsArchivePage/>}/>
                    <Route path={DOCUMENTS_CONTRACTS}
                           element={<DocumentsArchivePage category={DocumentCategory.Contracts}/>}/>
                    <Route path={DOCUMENTS_PAYROLLS}
                           element={<DocumentsArchivePage category={DocumentCategory.Payrolls}/>}/>
                    <Route path={DOCUMENTS_MEDICAL}
                           element={<DocumentsArchivePage category={DocumentCategory.LeaveMedicalReport}/>}/>
                    <Route path={DOCUMENTS_INSURANCES}
                           element={<DocumentsArchivePage category={DocumentCategory.Insurances}/>}/>
                    <Route path={DOCUMENTS_REPORT}
                           element={<DocumentsArchivePage category={DocumentCategory.Reports}/>}/>
                    <Route path={DOCUMENTS_REPORT_INCIDENT_NOTIFICATION}
                           element={<DocumentsArchivePage category={DocumentCategory.IncidentNotifications}/>}/>
                    <Route path={DOCUMENTS_REPORT_INCIDENT_VERIFICATION}
                           element={<DocumentsArchivePage category={DocumentCategory.IncidentVerifications}/>}/>
                    <Route path={DOCUMENTS_REPORT_SUPERVISOR_SHIFT}
                           element={<DocumentsArchivePage category={DocumentCategory.SupervisorShifts}/>}/>
                    <Route path={DOCUMENTS_REPORT_OPERATOR_SHIFT}
                           element={<DocumentsArchivePage category={DocumentCategory.OperatorShifts}/>}/>
                    <Route path={DOCUMENTS_HR}
                           element={<DocumentsArchivePage category={DocumentCategory.HR}/>}/>
                    <Route path={DOCUMENTS_HR_CLAIMS}
                           element={<DocumentsArchivePage category={DocumentCategory.Claims}/>}/>
                    <Route path={DOCUMENTS_HR_OPERATOR_CONTRACT}
                           element={<DocumentsArchivePage category={DocumentCategory.OperatorContracts}/>}/>
                    <Route path={DOCUMENTS_HR_SUPERVISOR_CONTRACT}
                           element={<DocumentsArchivePage category={DocumentCategory.SupervisorContracts}/>}/>
                </Routes>
            </div>
        </div>
    );
};

export default ProviderLayout