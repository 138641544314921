import {useTranslation} from "react-i18next";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {LeaveRequest} from "../../models/leave-request.interface";
import {FaCircle} from "react-icons/fa6";
import {Button} from "primereact/button";
import {validateShift} from "../../services/backend/api/shifts/shifts.apis";
import {ValidateShiftRequest} from "../../services/backend/api/shifts/requests/validate-shift.request";

interface UserLeaveRequestProps {
    leaveRequest: LeaveRequest;
    onChange?: () => void;
}

const UserLeaveRequest: React.FC<UserLeaveRequestProps> = ({leaveRequest, onChange}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {level} = useUserContext();

    const viewCalendar = () => {
        navigate(`/${level}/calendar`, {state: {leaveRequest}});
    }

    const changeRequestStatus = (accept: boolean) => {
        const request: ValidateShiftRequest = {
            requestId: leaveRequest.id,
            accept
        }
        validateShift(request).then(() => {
            console.log('Request status changed');
            if (onChange) {
                onChange();
            }
        }).catch((error) => {
            console.error('Error denying request', error);
        })
    }

    const denyRequest = () => {
        console.log('Deny Request');
        changeRequestStatus(false);
    }

    const approveRequest = () => {
        console.log('Approve Request');
        changeRequestStatus(true);
    }

    return (
        <div className="secondary-container py-4 px-8 flex flex-col items-center">
            <div className='w-full flex items-center space-x-4'>
                <FaCircle size={16} color="orange"/>
                <span className="font-semibold text-2xl">{t('leaveRequest.title')}</span>
            </div>
            <div className='w-full flex items-center space-x-4 justify-between py-2'>
                <div className='flex items-center space-x-4 w-full text-xl'>
                    <div>
                        {t('leaveRequest.leaveCategory') + ': '}
                        <span className="font-semibold">{leaveRequest.category}</span>
                    </div>
                    <div>
                        {t('leaveRequest.leaveDate') + ': '}
                        <span className="font-semibold">{leaveRequest.date.toDateString()}</span>
                    </div>
                </div>
                <div className="w-44">
                    <Button className="h-8" onClick={viewCalendar}
                    >{t('leaveRequest.viewCalendar')}</Button>
                </div>
            </div>
            <div className='w-full flex items-center space-x-4 '>
                <div className="w-44">
                    <Button className="h-10 w-44" onClick={denyRequest}
                            style={{borderColor: "darkred"}}
                    >
                        {t('leaveRequest.deny')}
                    </Button>
                </div>
                <div className="w-44">
                    <Button className="h-10" onClick={approveRequest}
                            style={{borderColor: "darkgreen"}}
                    >
                        {t('leaveRequest.approve')}
                    </Button>
                </div>

            </div>

        </div>
    )
}

export default UserLeaveRequest;