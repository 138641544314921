import {consumeApi, GET, POST, USERS, USERS_CREDENTIAL} from "../../backend.service";
import {CreateUserResponse} from "./responses/create-user.response";
import {CreateUserRequest} from "./requests/create-user.request";
import {PersonalInfoRequestsResponse} from "./responses/personal-info-requests.response";
import {HandlePersonalInfoRequestRequest} from "./requests/handle-personal-info-requests.request";
import {CredentialRecoverRequestsResponse} from "./responses/credential-recover-requests.response";
import {ApproveCredentialRecoverRequest} from "./requests/approve-credential-recover.request";
import {ApproveCredentialRecoverResponse} from "./responses/approve-credential-recover.response";
import {EmployeesListResponse} from "./responses/employeesListResponse";
import {TerminatedListResponse} from "./responses/terminatedEmployeesListResponse";
import {WorkReportsListResponse} from "./responses/work-reports-list.response";

export const createUser = async (user: CreateUserRequest): Promise<CreateUserResponse> => {
    return await consumeApi(POST, `${USERS}/create`, "HR Create User", user, true);
};

export const getPersonalInfoRequests = async (): Promise<PersonalInfoRequestsResponse> => {
    return await consumeApi(GET, `${USERS}/personal/info/request`, "HR Personal Info Request", {}, true);
};

export const handlePersonalInfoRequest = async (request: HandlePersonalInfoRequestRequest): Promise<PersonalInfoRequestsResponse> => {
    return await consumeApi(POST, `${USERS}/personal/info/request/handle`, "HR Personal Info Request Handle", request, true);
};

export const getCredentialRecoverRequests = async (): Promise<CredentialRecoverRequestsResponse> => {
    return await consumeApi(GET, `${USERS_CREDENTIAL}/recover/list`, "HR Credential Recover Requests", {}, true);
};

export const approveCredentialRecover = async (request: ApproveCredentialRecoverRequest): Promise<ApproveCredentialRecoverResponse> => {
    if (!request.password && !request.pin) {
        throw new Error("Password or Pin must be provided to approve credential recover request");
    }
    return await consumeApi(GET, `${USERS_CREDENTIAL}/approve`, "HR Approve Credential Recover Request", {}, true);
};

export const getEmployeesList = async (): Promise<EmployeesListResponse> => {
    return await consumeApi(GET, `${USERS}/hr/employee-list-managment`, "HR Employees List", {}, true);
};

export const getTerminatedEmployeesList = async (startDate: string, endDate: string): Promise<TerminatedListResponse> => {
    return await consumeApi(POST, `${USERS}/hr/terminated-employee-list-managment`, "HR Terminated Employees List", {startDate, endDate}, true);
};

export const getWorkReportsList = async (): Promise<WorkReportsListResponse> => {
    // TODO: check endpoint
    return await consumeApi(GET, `${USERS}/work-reports`, "HR Work Report List", {}, true);
};

