import React, {useState} from 'react';
import {FaAngleDown, FaCircle} from "react-icons/fa6";
import {HiOutlineUserCircle} from "react-icons/hi";
import {Notification} from "../../models/notification.interface";
import {setNotificationRead} from "../../services/backend/api/notifications/notifications.apis";

interface NotificationTableRowProps {
    notification: Notification;
    onRead: (notification: Notification) => void;
}

const NotificationTableRow: React.FC<NotificationTableRowProps> = ({notification, onRead}) => {

    const [isNotificationExpanded, setNotificationExpanded] = useState(false)

    const handleNotificationClick = () => {
        setNotificationExpanded(!isNotificationExpanded)
        if (!notification.isRead && isNotificationExpanded) {
            setNotificationRead({notificationId: notification.id}).then(response => {
                onRead(notification)
            })
        }
    }

    return (
        <div key={notification.id}
             className={`bg-[var(--primary-color)] rounded-lg p-3 flex flex-row flex-wrap notification-item`}>
            <div
                className="flex justify-between items-center space-x-2 flex-col gap-4 md:flex-row md:gap-0 text-sm whitespace-nowrap w-full">
                <div
                    className="flex justify-between items-center space-x-2 flex-col gap-4 md:flex-row md:gap-0 text-sm whitespace-nowrap">
                    <FaCircle size={16} color={`${notification.isRead ? 'darkgreen' : 'darkred'}`}/>

                    <HiOutlineUserCircle size={24} className="text-gray-400"/>
                    <div className="w-28 ellipsis">
                        <span className="text-gray-300 text-sm">{notification.operatorName}</span>
                    </div>
                    <div className="w-28">
                        {notification.date.toLocaleDateString()}
                    </div>
                    <div className="w-28">
                        {notification.date.toLocaleTimeString()}
                    </div>
                </div>
                <div className="ellipsis w-max">
                    {notification.content}
                </div>

                <div className="flex justify-center items-center space-x-2">
                    <div>
                        <FaAngleDown size={20} onClick={handleNotificationClick}/>
                    </div>
                </div>
            </div>

            {
                isNotificationExpanded &&
                <div className="accordion-container mt-2 text-center">
                    <p>{notification.content}</p>
                </div>
            }
        </div>
    )
}

export default NotificationTableRow