import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Calendar, CalendarDateTemplateEvent, CalendarMonthChangeEvent} from "primereact/calendar";
import {FormEvent} from "primereact/ts-helpers";
import {Goal, Task} from "../../models/goal.interface";
import {Button} from "primereact/button";
import {GoPlusCircle, GoTrash} from "react-icons/go";
import {InputTextarea} from "primereact/inputtextarea";
import {OverlayPanel} from "primereact/overlaypanel";
import {retrieveClientMonthlyGoals} from "../../services/data-manager/data-manager.service";
import {CreateGoalRequest} from "../../services/backend/api/client/requests/create-goal.request";
import {createGoal, deleteGoal, setGoalDueDate} from "../../services/backend/api/client/client.apis";
import {SetGoalDueDateRequest} from "../../services/backend/api/client/requests/set-goal-due-date.request";
import Loader from "../../components/common/LoaderComponent";
import {useToastContext} from "../../contexts/ToastContext";
import {FaRegEdit} from "react-icons/fa";
import {DeleteGoalRequest} from "../../services/backend/api/client/requests/delete-goal.request";
import {FaCircle} from "react-icons/fa6";


const GoalAssignmentPage: React.FC = () => {
    const {t} = useTranslation();
    const {successToast, errorToast} = useToastContext();
    const [isLoading, setIsLoading] = useState(true);
    const [goalsDate, setGoalsDate] = useState<Date>(new Date());
    const [monthDate, setMonthDate] = useState<Date>(new Date());

    const [goals, setGoals] = useState<Goal[]>([]);
    const [filteredGoals, setFilteredGoals] = useState<Goal[]>([]);
    const [newGoalText, setNewGoalText] = useState<string>("");
    const newGoalOverlayPanel = useRef<OverlayPanel>(null);
    const addDateOverlayPanel = useRef<OverlayPanel>(null);
    const deleteGoalOverlayPanel = useRef<OverlayPanel>(null);

    const [dateToAdd, setDateToAdd] = useState<Date>(new Date());
    const [goalIdToEdit, setGoalIdToEdit] = useState<string>('');

    const onMonthChange = (event: CalendarMonthChangeEvent) => {
        const month = event.month;
        const year = event.year;
        const date = new Date();
        date.setMonth(month);
        date.setFullYear(year);
        if (date.getMonth() !== monthDate.getMonth()) {
            setMonthDate(date);
        }
        setMonthDate(date);
    }

    const onDateSelect = (event: FormEvent) => {
        const date: Date = event.value as Date || new Date();
        setGoalsDate(date);
    }

    const setGoalDate = (e: any) => {
        const date: Date = e.value as Date;
        const goalId: string = goalIdToEdit;
        console.log(`Adding date ${date?.toDateString()} to: ${goalId}`);
        const request: SetGoalDueDateRequest = {
            goalId: goalId,
            dueDate: date
        }
        setGoalDueDate(request)
            .then(response => {
                console.log(`Due date set: ${response}`);
                addDateOverlayPanel?.current?.toggle(e)
                retrieveGoalsData();
                successToast(t('messages.addGoalDueDateSuccess'));
            })
            .catch(error => {
                console.error(`Error setting due date: ${error}`);
                errorToast(t('messages.addGoalDueDateError'));
            });
    }

    const handleNewGoalClick = (e: any) => {
        if (newGoalOverlayPanel?.current?.isVisible()) {
            console.log(`Creating new goal: ${newGoalText}`);
            const request: CreateGoalRequest = {
                goalName: newGoalText
            }
            createGoal(request)
                .then(async response => {
                    console.log(`New goal created: ${response}`);
                    retrieveGoalsData()
                    successToast(t('messages.newGoalSuccess'));
                })
                .catch(error => {
                    console.error(`Error creating new goal: ${error}`);
                    errorToast(t('messages.newGoalError'));
                });
            setNewGoalText("");
        }
        newGoalOverlayPanel?.current?.toggle(e);
    }

    const handleAddDateClick = (e: any, goalId: string) => {
        setGoalIdToEdit(goalId);
        addDateOverlayPanel?.current?.toggle(e)
    }

    const handleDeleteGoalClick = (e: any, goalId: string) => {
        setGoalIdToEdit(goalId);
        deleteGoalOverlayPanel?.current?.toggle(e)
    }

    const removeGoal = () => {
        const goalId: string = goalIdToEdit;
        console.log(`Deleting goal: ${goalId}`);
        const request: DeleteGoalRequest = {
            goalId: goalId
        }
        deleteGoal(request)
            .then(async response => {
                console.log(`Goal deleted: ${response}`);
                retrieveGoalsData()
                successToast(t('messages.deleteGoalSuccess'));
            })
            .catch(error => {
                console.error(`Error deleting goal: ${error}`);
                errorToast(t('messages.deleteGoalError'));
            });
    }

    const getGoalsTasks = (goalId: string): Task[] => {
        return goals.find(goal => goal.id === goalId)?.tasks || [];
    }

    const retrieveGoalsData = useCallback(() => {
        setIsLoading(true);
        retrieveClientMonthlyGoals({date: monthDate}).then(goals => {
            setGoals(goals);
            setIsLoading(false);
        })
    }, [monthDate]);

    useEffect(() => {
        const filtered = goals.filter(goal => {
            return !goal.dueDate || goal.dueDate.toDateString() === goalsDate.toDateString();
        })
        setFilteredGoals(filtered.sort((a, b) => a.dueDate ? 1 : -1));
    }, [goals, goalsDate]);

    useEffect(() => {
        retrieveGoalsData();
    }, [retrieveGoalsData]);

    const dateTemplate = (event: CalendarDateTemplateEvent) => {
        const date = new Date(event.year, event.month, event.day)

        const highlightDate = goals.some(goal => {
            return goal.dueDate?.toDateString() === date.toDateString();
        });

        return (
            <div className="flex justify-center items-center flex-col">
                {event.day}
                {highlightDate && <FaCircle size={8} color="cyan" className="date-marker"/>}
            </div>
        );
    };
    
    return (
        <>
            <div className="page-container ">
                <div className="page-title">
                    <h2>{t('goalAssignmentPage.title')}</h2>
                    <div className="flex justify-center items-center">
                        <Button label={t('goalAssignmentPage.newGoalButton')} className="uppercase"
                                onClick={handleNewGoalClick}
                        />
                        <OverlayPanel ref={newGoalOverlayPanel} showCloseIcon
                                      className="primary-container p-0 border-none">
                            <InputTextarea value={newGoalText} rows={10} cols={30}
                                           onChange={(e) => setNewGoalText(e.target.value)}/>
                        </OverlayPanel>
                    </div>
                </div>

                <div className="page-content">
                    <div className='w-full grid grid-cols-7 gap-4 pr-8 visible-height'>
                        <div className="col-span-4 secondary-container padded-m space-y-2">
                            {
                                isLoading ? <Loader/> :
                                    filteredGoals.map(goal => (
                                        <div key={goal.id}
                                             className='flex items-center justify-between py-2 primary-container padded-s'>
                                            <div className='flex items-center p-2 flex-grow'>
                                                <p className='ml-2 semi'>{t("goal")}. {goal.content}</p>
                                            </div>
                                            {
                                                <div onClick={(e) => handleAddDateClick(e, goal.id)}
                                                     className="flex flex-row items-center justify-end space-x-2 mr-8 cursor-pointer text-right"
                                                >
                                                    {
                                                        goal.dueDate
                                                            ? <>
                                                                <FaRegEdit size={24}/>
                                                                <p>{goal.dueDate.toDateString()}</p>
                                                            </>
                                                            : <>
                                                                <GoPlusCircle size={24}/>
                                                                <p>{t('goalAssignmentPage.addDate')}</p>
                                                            </>
                                                    }
                                                </div>
                                            }
                                            <div
                                                className='flex flex-row items-center justify-center space-x-2 mr-2 cursor-pointer '
                                                onClick={(e) => handleDeleteGoalClick(e, goal.id)}
                                            >
                                                <GoTrash size={24}/>
                                                <p>{t('goalAssignmentPage.deleteGoal')}</p>
                                            </div>

                                        </div>
                                    ))
                            }
                            <OverlayPanel ref={addDateOverlayPanel} showCloseIcon
                                          className="primary-container p-0 border-none">
                                <Calendar value={dateToAdd}
                                          className="primary-container"
                                          onChange={(e) => setGoalDate(e)}
                                          selectionMode='single'/>
                            </OverlayPanel>
                            <OverlayPanel ref={deleteGoalOverlayPanel} showCloseIcon
                                          className="secondary-container justify-center items-center p-2">
                                <div className="flex justify-center items-center flex-col text-primary">
                                    <p>{t('goalAssignmentPage.deleteGoalDisclaimer')}</p>

                                    {
                                        getGoalsTasks(goalIdToEdit).map((task, index) => (
                                            <p key={index}>"{task?.content}"</p>
                                        ))
                                    }

                                    <br/>
                                    <p className="font-semibold">{t('goalAssignmentPage.deleteGoalConfirmation')}</p>
                                    <p>"{goals.find((goal) => goal.id === goalIdToEdit)?.content}"</p>

                                    <div className='w-full flex justify-center items-center space-x-4 pt-4'>
                                        <div className="w-44">
                                            <Button className="h-10 w-44"
                                                    onClick={deleteGoalOverlayPanel?.current?.toggle}
                                                    style={{borderColor: "darkred"}}
                                            >
                                                {t('goalAssignmentPage.abort')}
                                            </Button>
                                        </div>
                                        <div className="w-44">
                                            <Button className="h-10" onClick={(e) => {
                                                removeGoal();
                                                deleteGoalOverlayPanel?.current?.toggle(e);
                                            }}
                                                    style={{borderColor: "darkgreen"}}
                                            >
                                                {t('goalAssignmentPage.confirm')}
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                            </OverlayPanel>
                        </div>
                        <div className="col-span-3 secondary-container padded-m visible-height">
                            <Calendar inline className='w-full px-2' onChange={onDateSelect}
                                      onMonthChange={onMonthChange} value={goalsDate} selectionMode="single"
                                      dateTemplate={dateTemplate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
        ;
};

export default GoalAssignmentPage;
