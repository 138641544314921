import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import {CiSearch} from "react-icons/ci";
import Loader from "../../components/common/LoaderComponent";
import {Employee} from "../../models/employee.interface";
import {USER, USER_CREATE} from "../../utils/constants.utils";
import {FaCircle} from "react-icons/fa6";
import {HiOutlineUserCircle} from "react-icons/hi";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {UserRole} from "../../models/enums/user-role.enum";
import {retrieveEmployees} from "../../services/data-manager/data-manager.service";
import {shortId} from "../../utils/text-formatting.utils";
import {isContained} from "../../utils/generic.utils";

const EmployeesManagementPage: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {level} = useUserContext();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [employees, setEmployees] = useState<Employee[]>([])
    const [filter, setFilter] = useState(t('employeesManagementPage.filters.all'));
    const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);
    const [searchValue, setSearchValue] = useState<string>();

    const SUPERVISOR_FILTER = t('employeesManagementPage.filters.supervisor')
    const OPERATOR_FILTER = t('employeesManagementPage.filters.operator')
    const PENDING_REQUEST_FILTER = t('employeesManagementPage.filters.pendingRequest')
    const NO_FILTER = t('employeesManagementPage.filters.all')

    const filters = [
        NO_FILTER,
        SUPERVISOR_FILTER,
        OPERATOR_FILTER,
        PENDING_REQUEST_FILTER,
    ]

    const userLevel = useCallback((employee: Employee) => {
        if (employee.type === UserRole.Operator) {
            return t("Operator")
        } else if (employee.type === UserRole.Supervisor) {
            return t("Supervisor")
        } else {
            return ''
        }
    }, [t])

    const viewEmployeeProfile = (id: string) => () => {
        navigate(`/${level}/${USER}/`, {
                state: {
                    data: {
                        userId: id
                    }
                }
            }
        )
    }

    const navigateToNewUser = () => {
        navigate(`/${level}/` + USER_CREATE)
    }

    const searchEmployee = (e: any) => {
        let value = e.target.value
        value = value.trim()
        if (!value || value === '') {
            value = undefined
        }
        setSearchValue(value)
    }

    const filterEmployees = useCallback(() => {
        let filtered = employees.filter(employee => {
            if (filter === NO_FILTER) return true
            if (filter === SUPERVISOR_FILTER && employee.type === UserRole.Supervisor) return true
            if (filter === OPERATOR_FILTER && employee.type === UserRole.Operator) return true
            return (filter === PENDING_REQUEST_FILTER && employee.hasRequestPending);
        });
        if (searchValue) {
            filtered = filtered.filter(employee => {
                return isContained(employee.name, searchValue)
                    || isContained(employee.surname, searchValue)
                    || isContained(employee.id, searchValue)
            })
        }
        setFilteredEmployees(filtered);
    }, [NO_FILTER, OPERATOR_FILTER, PENDING_REQUEST_FILTER, SUPERVISOR_FILTER, employees, filter, searchValue]);

    const handleFilterChange = useCallback((option: string) => {
        if (option === filter) {
            setFilter(NO_FILTER)
        } else {
            setFilter(option);
        }
    }, [NO_FILTER, filter]);

    useEffect(() => {
        setIsLoading(true);
        retrieveEmployees().then((employees) => {
            setEmployees(employees);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        filterEmployees();
    }, [filter, filterEmployees]);

    return (
        <div className="page-container mr-12">
            <div className="page-title flex">
                <h2 className="flex-grow">{t('employeesManagementPage.title')}</h2>
                <Button onClick={navigateToNewUser}>
                    {t('employeesManagementPage.addNewUser')}
                </Button>
            </div>
            <div className="rounded-container w-full">
                <div className='page-content max-w-3xl'>

                    <div className='page-content max-w-3xl'>
                        <div
                            className="bg-[var(--primary-color)] grid grid-cols-2 sm:grid-cols-3 w-full lg:w-auto md:grid-cols-5 gap-3 p-2 mb-4 rounded-lg">
                            {filters.map((option, index) => (
                                <button
                                    key={index}
                                    className={`px-3 py-1 rounded-lg ellipsis flex items-center justify-center
                                    ${filter === option ?
                                        'bg-white border-2 border-white-500 text-gray-900'
                                        :
                                        'bg-unser border-2 border-white-500 text-white'}`
                                    }
                                    onClick={() => handleFilterChange(option)}
                                >
                                    <div className="flex justify-center items-center">
                                        {
                                            option === PENDING_REQUEST_FILTER &&
                                            <span className='mr-1 text-yellow-500'>
                                                <FaCircle size={16}/>
                                            </span>
                                        }
                                        <span className="truncate">
                                            {option}
                                        </span>
                                    </div>
                                </button>
                            ))}
                            <div className="flex items-center justify-center search-bar">
                                <span className="search-icon"><CiSearch size={32}/></span>
                                <input type='search' className="search-input" onInput={searchEmployee}/>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="space-y-2 w-full">
                    {
                        isLoading ? <Loader/> :
                            <>
                                {filteredEmployees.length > 0 ? (
                                    filteredEmployees.map((employee, index) => (
                                        <div key={index} className="primary-container">
                                            <div
                                                key={employee.id}
                                                className={`bg-[var(--primary-color)] rounded-lg p-3 flex items-center justify-start flex-col gap-4 lg:flex-row lg:gap-0`}
                                            >
                                                <div
                                                    className="flex flex-grow items-center space-x-2 flex-col gap-4 md:flex-row md:gap-0 ">
                                                    <HiOutlineUserCircle size={24} className="text-gray-400"/>
                                                    <div className="w-28 ellipsis">
                                                        <span
                                                            className="text-gray-300 text-sm">{shortId(employee.id)}</span>
                                                    </div>

                                                    <div
                                                        className="bg-[var(--secondary-color)] border-2 text-center px-2 py-1 rounded-lg text-xs w-24 overflow-hidden uppercase">
                                                        {userLevel(employee)}
                                                    </div>

                                                    <div className="overflow-hidden flex items-center justify-center">
                                                        <span
                                                            className="whitespace-no-wrap overflow-hidden overflow-ellipsis block ml-6">
                                                            {employee.name} {employee.surname}
                                                        </span>
                                                    </div>

                                                </div>
                                                <div className="flex justify-center items-center space-x-8 text-sm">
                                                    {
                                                        employee.hasRequestPending &&
                                                        <div
                                                            className="flex items-center justify-center space-x-2 whitespace-nowrap">
                                                            <span>
                                                                {t("employeesManagementPage.pendingRequest")}
                                                            </span>
                                                            <span className='text-yellow-500'>
                                                                <FaCircle size={16}/>
                                                            </span>
                                                        </div>
                                                    }
                                                    <Button className="h-6"
                                                            onClick={viewEmployeeProfile(employee.id)}
                                                    >{t('employeesManagementPage.viewProfile')}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='text-center py-10'>{t('employeesManagementPage.noData')}</div>
                                )
                                }
                            </>
                    }
                </div>
            </div>
        </div>
    );
};

export default EmployeesManagementPage;
