import { useForgotStepper } from "./ForgotPasswordStepperContext";
import { useTranslation } from "react-i18next";
import { BiArrowBack, BiPhone } from "react-icons/bi";
import { UserRole } from "../../../models/enums/user-role.enum";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import parsePhoneNumberFromString, { CountryCode } from "libphonenumber-js";

export const ForgotPhoneStep = () => {
    const {
        level,
        phone, setPhone, phoneError, setPhoneError,
        goBack, goNext
    } = useForgotStepper();

    const { t } = useTranslation();

    const handlePhoneChange = (value: string) => {
        setPhone(value);
    };

    const handleNext = () => {
        if (phone && !phoneError) {
            goNext();
        }
    };

    const validatePhoneInput = (value: string, country: any) => {
        try {
            const iso = country.iso2.toUpperCase() as CountryCode;
            const phoneNumber = parsePhoneNumberFromString(value, iso);
            if (phoneNumber && phoneNumber.isValid()) {
                setPhoneError(false);
                return true;
            }
        } catch (e) {
            console.error('Error parsing phone number:', e);
            setPhoneError(true);
        }
        setPhoneError(true);
        return t('invalidPhone');
    }

    return <div
        className="flex flex-col items-center justify-center gap-6 bg-[#2D2D2D] py-4 px-10 rounded-lg min-w-[300px] shadow-md"
    >
        <h1 className="text-2xl font-bold">{(level === UserRole.Operator || level === UserRole.Supervisor) ? t('insertPhone') : t('insertEmail')}</h1>
        <p className="text-sm">{(level === UserRole.Operator || level === UserRole.Supervisor) ? t('forgotPhoneDescription') : t('forgotEmailDescription')}</p>

        <div className="flex justify-center">
            <BiPhone className="h-[50px] w-[50px] text-white" />
        </div>

        <PhoneInput
            country={'us'}
            value={phone}
            onChange={handlePhoneChange}
            isValid={validatePhoneInput}
            inputStyle={{color: 'white', backgroundColor: '#2D2D2D', fontSize: '16px'}}
            dropdownStyle={{color: 'black'}}
            searchStyle={{color: 'black'}}
            containerStyle={{backgroundColor: '#2D2D2D'}}
            buttonStyle={{backgroundColor: '#2D2D2D'}}
            countryCodeEditable={true}
            placeholder={t('enterPhoneNumber')}
            enableSearch={true}
            enableAreaCodes={true}
        />

        <button
            onClick={goBack}
            className={`primary-button uppercase border-l-2 border-r-2 border-red-500`}
        >
            <BiArrowBack
                style={{ marginRight: 10 }}
                size={28}
                color="whitesmoke"
            />
            {t('go_back')}
        </button>
        <button
            onClick={handleNext}
            className={
                `primary-button uppercase border-l-2 border-r-2 border-red-500 
                ${!phoneError ? '' : 'opacity-50 cursor-not-allowed'}`
            }
        >
            <BiArrowBack
                style={{ marginRight: 10, transform: "rotate(180deg)" }}
                size={28} color="whitesmoke"
            />
            {t('next')}
        </button>
    </div>
};

