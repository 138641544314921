import {Image, StyleSheet, View} from "@react-pdf/renderer";
import {defaultStyles} from "../styles/default.styles";
import logo from "../assets/logo.png"
import React from "react";

const styles = StyleSheet.create({
    ...defaultStyles
});

const Header: React.FC = () => {
    return (
        <View style={styles.header}>
            <Image src={logo} style={styles.logo}/>
        </View>
    )
}

export default Header