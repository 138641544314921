import {Navigate, Route, Routes} from "react-router-dom";
import MenuNav from "../components/common/MenuNav";
import {
    CALENDAR,
    CLAIM_MANAGEMENT,
    TERMINATED_WORKER,
    COMMUNICATION,
    COMMUNICATION_HR,
    COMMUNICATION_HSES_CLIENT,
    COMMUNICATION_HSES_PROVIDER,
    COMMUNICATION_OPERATOR,
    COMMUNICATION_SUPERVISOR,
    DEFAULT_PATH,
    DOCUMENTS,
    DOCUMENTS_CONTRACTS,
    DOCUMENTS_INSURANCES,
    DOCUMENTS_MEDICAL,
    DOCUMENTS_PAYROLLS,
    DOCUMENTS_TIMESHEET,
    EMPLOYEES,
    HOME,
    LEAVES,
    NOTIFICATION,
    USER,
    USER_CREATE,
    WORK_REPORTS,
    WORKER_OVERVIEW
} from "../utils/constants.utils";
import EmployeesManagementPage from "../pages/hr/EmployeesManagementPage";
import HomePage from "../pages/common/HomePage";
import CommunicationPage from "../pages/common/CommunicationPage";
import WorkerOverViewPage from "../pages/provider/WorkerOverViewPage";
import CommunicationSectionPage from "../pages/common/CommunicationSectionPage";
import NotificationPage from "../pages/common/NotificationPage";
import ClaimManagementPage from "../pages/hr/ClaimManagementPage";
import ShiftCalendarPage from "../pages/hr/ShiftCalendarPage";
import NewUserPage from "../pages/hr/NewUserPage";
import UserDetailPage from "../pages/hr/UserDetailPage";
import LeavesPage from "../pages/hr/LeavesPage";
import WorkReportsPage from "../pages/hr/WorkReportsPage";
import DocumentsArchivePage from "../pages/common/DocumentsArchivePage";
import {DocumentCategory} from "../models/enums/document-category.enum";
import TerminatedWorkerPage from "../pages/hr/TerminatedWorkerPage";

const HRLayout = () => {

  return (
    <div className="flex">
        <div className="my-10 mx-6">
            <MenuNav/>
        </div>
        <div className="w-full">
            <Routes>
                <Route path={DEFAULT_PATH} element={<Navigate to={HOME} replace={true}/>}/>
                <Route path={HOME} element={<HomePage/>}/>
                <Route path={CALENDAR} element={<ShiftCalendarPage/>}/>
                <Route path={EMPLOYEES} element={<EmployeesManagementPage/>}/>

                <Route path={LEAVES} element={<LeavesPage/>}/>
                <Route path={WORK_REPORTS} element={<WorkReportsPage/>}/>
                <Route path={USER} element={<UserDetailPage/>}/>
                <Route path={USER_CREATE} element={<NewUserPage/>}/>
                <Route path={WORKER_OVERVIEW} element={<WorkerOverViewPage/>}/>
                <Route path={CLAIM_MANAGEMENT} element={<ClaimManagementPage/>}/>
                <Route path={TERMINATED_WORKER} element={<TerminatedWorkerPage/>}/>

                <Route path={NOTIFICATION} element={<NotificationPage/>}/>
                <Route path={COMMUNICATION} element={<CommunicationPage/>}/>
                <Route path={COMMUNICATION_OPERATOR} element={<CommunicationSectionPage/>}/>
                <Route path={COMMUNICATION_SUPERVISOR} element={<CommunicationSectionPage/>}/>
                <Route path={COMMUNICATION_HR} element={<CommunicationSectionPage/>}/>
                <Route path={COMMUNICATION_HSES_PROVIDER} element={<CommunicationSectionPage/>}/>
                <Route path={COMMUNICATION_HSES_CLIENT} element={<CommunicationSectionPage/>}/>

                <Route path={DOCUMENTS}
                       element={<DocumentsArchivePage/>}/>
                <Route path={DOCUMENTS_CONTRACTS}
                       element={<DocumentsArchivePage category={DocumentCategory.Contracts}/>}/>
                <Route path={DOCUMENTS_PAYROLLS}
                       element={<DocumentsArchivePage category={DocumentCategory.Payrolls}/>}/>
                <Route path={DOCUMENTS_MEDICAL}
                       element={<DocumentsArchivePage category={DocumentCategory.LeaveMedicalReport}/>}/>
                <Route path={DOCUMENTS_INSURANCES}
                       element={<DocumentsArchivePage category={DocumentCategory.Insurances}/>}/>
                <Route path={DOCUMENTS_TIMESHEET}
                       element={<DocumentsArchivePage category={DocumentCategory.Timesheet}/>}/>
            </Routes>
        </div>
    </div>
  );
};

export default HRLayout