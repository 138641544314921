import React, {useCallback, useEffect, useState} from 'react';
import {UserType} from '../../models/types/user.type';
import {useTranslation} from 'react-i18next';
import WorkerTableRow from '../../components/worker-overview/WorkerTableRow';
import {useLocation} from "react-router-dom";
import Loader from "../../components/common/LoaderComponent";
import {workersGraph} from "../../services/backend/api/graphs/graphs.apis";

const WorkerOverViewPage: React.FC = () => {
    const {t, i18n} = useTranslation();
    const {state} = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    const [filterLabels, setFilterLabels] = useState([
        t("provider_graph.All"),
        t("provider_graph.Supervisors"),
        t("provider_graph.Operators"),
        t("provider_graph.Active"),
        t("provider_graph.OnBreak")
    ]);

    useEffect(() => {
        const handleLanguageChange = () => {
            setFilterLabels(
                [t("provider_graph.All"),
                    t("provider_graph.Supervisors"),
                    t("provider_graph.Operators"),
                    t("provider_graph.Active"),
                    t("provider_graph.OnBreak")]
            )
        };
        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };

    }, [i18n, t]);


    const [filter, setFilter] = useState(t("provider_graph.All"));
    const [filteredUsers, setFilteredUsers] = useState<UserType[]>([]);

    const filterUsers = useCallback((users: UserType[], currentFilter: string) => {
        return users.filter(user => {
            if (currentFilter === t("provider_graph.All")) return true;
            if (currentFilter === t("provider_graph.Supervisors")) return user.level === 'supervisor';
            if (currentFilter === t("provider_graph.Operators")) return user.level === 'operator';
            if (currentFilter === t("provider_graph.Active")) return user.isActive;
            if (currentFilter === t("provider_graph.OnBreak")) return user.isOnBreak;
            return true;
        });
    }, [t]);

    const setData = useCallback((data: any) => {
        if (data?.filterByOpAndSup) {
            const filtered = filterUsers(data.filterByOpAndSup, filter);
            filtered.sort((a: UserType, b: UserType) => a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1);
            setFilteredUsers(filtered);
        }
        setIsLoading(false);
    }, [filter, filterUsers])

    useEffect(() => {
        let data = state?.data;
        if (!data) {
            workersGraph().then((data) => {
                setData(data);
            }).catch(() => {
                console.log('Error fetching workers graph');
            });
        } else {
            setData(data);
        }
    }, [filter, filterUsers, setData, state?.data]);

    useEffect(() => {
        const interval = setInterval(() => {
            workersGraph().then((data) => {
                setData(data);
            }).catch(() => {
                console.log('Error fetching workers graph');
            });
        }, 1000 * 5);
        return () => clearInterval(interval);
    }, [setData]);

    const handleFilterChange = useCallback((option: string) => {
        setFilter(option);
    }, []);

    return (
        <div className="page-container">
            <div className="rounded-container w-full">
                <div className='page-content max-w-3xl'>
                    <div
                        className="bg-[var(--primary-color)] grid grid-cols-2 sm:grid-cols-3 w-full lg:w-auto md:grid-cols-5 gap-3 p-2 mb-4 rounded-lg">
                        {filterLabels.map((option, index) => (
                            <button
                                key={index}
                                className={`px-3 py-1 rounded-lg ellipsis flex items-center justify-center
              ${filter === option ?
                                    'bg-white border-2 border-white-500 text-gray-900'
                                    :
                                    'bg-unser border-2 border-white-500 text-white'}`
                                }
                                onClick={() => handleFilterChange(option)}
                            >
              <span className="truncate">
                {option}
              </span>
                            </button>
                        ))}
                    </div>
                </div>

                <div className="space-y-2 w-5/6">
                    {
                        isLoading ? <Loader/> :
                            <>
                                {filteredUsers.length > 0 ? (
                                    filteredUsers.map((user, index) => <WorkerTableRow key={index} user={user}/>)
                                ) : (
                                    <div className='text-center py-10'>{t('provider_graph.NoData')}</div>
                                )}
                            </>
                    }
                </div>
            </div>
        </div>
    );
};

export default WorkerOverViewPage;