import React, {useEffect, useState} from "react";
import {FaRegTimesCircle} from "react-icons/fa";

const ErrorComponent = ({ message }: { message: string }) => {
  const [modalVisible, setModalVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalVisible(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (!modalVisible) {
    return null;
  }

  return (
    <div className="modal-err" style={{ display: modalVisible ? "flex" : "none" }}>
      <div className="modal-content-err">
        <span className="close" onClick={() => setModalVisible(false)}>
          &times;
        </span>
        <div className="modal-body-err">
          <p className="message">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorComponent;
