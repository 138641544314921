import React from "react";
import { useForgotStepper } from "./ForgotPasswordStepperContext";
import { useTranslation } from "react-i18next";
import { BiArrowBack, BiEnvelope } from "react-icons/bi";
import { InputText } from "primereact/inputtext";
import { isValidEmail } from "../../../utils/validation.utils";
import { UserRole } from "../../../models/enums/user-role.enum";

export const ForgotEmailStep = () => {
    const {
        level,
        email, setEmail, emailError, setEmailError,
        goBack, goNext
    } = useForgotStepper();

    const { t } = useTranslation();

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        setEmail(e.target.value);
        setEmailError(!isValidEmail(e.target.value));
    };

    const handleNext = () => {
        if (isValidEmail(email)) {
            goNext();
        }
    };

    return <div
        className="flex flex-col items-center justify-center gap-6 bg-[#2D2D2D] py-4 px-10 rounded-lg min-w-[300px] shadow-md"
    >
        <h1 className="text-2xl font-bold">{(level === UserRole.Operator || level === UserRole.Supervisor) ? t('insertPhone') : t('insertEmail')}</h1>
        <p className="text-sm">{(level === UserRole.Operator || level === UserRole.Supervisor) ? t('forgotPhoneDescription') : t('forgotEmailDescription')}</p>

        <div className="flex justify-center">
            <BiEnvelope className="h-[50px] w-[50px] text-white" />
        </div>

        <InputText
            type="email"
            style={{ borderColor: emailError ? 'red' : 'white' }}
            value={email}
            onChange={handleEmailChange}
            className="w-full"
            placeholder={t('insertEmail')}
        />

        <button
            onClick={goBack}
            className={`primary-button uppercase border-l-2 border-r-2 border-red-500`}
        >
            <BiArrowBack
                style={{ marginRight: 10 }}
                size={28}
                color="whitesmoke"
            />
            {t('go_back')}
        </button>
        <button
            onClick={handleNext}
            className={
                `primary-button uppercase border-l-2 border-r-2 border-red-500 
                ${isValidEmail(email) ? '' : 'opacity-50 cursor-not-allowed'}`
            }
        >
            <BiArrowBack
                style={{ marginRight: 10, transform: "rotate(180deg)" }}
                size={28} color="whitesmoke"
            />
            {t('next')}
        </button>
    </div>
};

