import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa6";
import {OverlayPanel} from "primereact/overlaypanel";
import {Calendar} from "primereact/calendar";
import {FormEvent} from "primereact/ts-helpers";
import {UserRole} from "../../../models/enums/user-role.enum";
import {Dropdown} from "primereact/dropdown";
import {Employee} from "../../../models/employee.interface";
import {MultiSelect} from "primereact/multiselect";
import {FaRegEdit} from "react-icons/fa";
import {Button} from "primereact/button";
import {Shift} from "../../../models/shift.interface";
import {HiOutlineUserCircle} from "react-icons/hi";
import {
    retrieveAvailableWorkersBetweenDates,
    retrieveShiftsWeekByDate,
    retrieveWorkersByDate
} from "../../../services/data-manager/data-manager.service";
import {WorkersByDateRequest} from "../../../services/backend/api/shifts/requests/workers-by-date.request";
import {
    AvailableWorkersBetweenDatesRequest
} from "../../../services/backend/api/shifts/requests/available-workers-between-dates.request";
import {updateCalendar} from "../../../services/backend/api/shifts/shifts.apis";
import {UpdateCalendarRequest} from "../../../services/backend/api/shifts/requests/update-calendar.request";
import {ShiftItem} from "../../../services/backend/api/shifts/models/shift-item.interface";
import {formatDate, today} from "../../../utils/date.utils";
import Loader from "../../common/LoaderComponent";
import {useToastContext} from "../../../contexts/ToastContext";

interface WeeklyCalendarProps {
    onChange: (date: Date) => void;
    onVisibleShiftsChange: (shifts: Shift[]) => void;
}

const WEEK_DAYS = 7;
const START_HOUR = 0;
const END_HOUR = 23;
const HOURS = END_HOUR - START_HOUR + 1;
const DEFAULT_SHIFT_HOURS_DURATION = 8;

const STEP_MINUTE = 15;

const WeeklyCalendar: React.FC<WeeklyCalendarProps> = ({onChange, onVisibleShiftsChange}) => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const {successToast, errorToast} = useToastContext();

    const [firstWeekDayDate, setFirstWeekDayDate] = useState<Date>(today());
    const [lastWeekDayDate, setLastWeekDayDate] = useState<Date>(today());
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [startTime, setStartTime] = useState<Date>(new Date());
    const [endTime, setEndTime] = useState<Date>(new Date());

    const [selectedSupervisor, setSelectedSupervisor] = useState<Employee>();
    const [selectedOperators, setSelectedOperators] = useState<Employee[]>();
    const [availableSupervisors, setAvailableSupervisors] = useState<Employee[]>([]);
    const [availableOperators, setAvailableOperators] = useState<Employee[]>([]);

    const [availableShifts, setAvailableShifts] = useState<Shift[]>([]);
    const [visibleShifts, setVisibleShifts] = useState<Shift[]>([]);

    const [selectedShift, setSelectedShift] = useState<Shift>();

    const [shiftOperatorsVisible, setShiftOperatorsVisible] = useState<Employee[]>([]);

    const assignShiftOverlayPanel = useRef<OverlayPanel>(null)
    const shiftOperatorsOverlayPanel = useRef<OverlayPanel>(null)

    const previousWeek = () => {
        setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - WEEK_DAYS)));
    };

    const nextWeek = () => {
        setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + WEEK_DAYS)));
    };

    const handleShiftSelection = (shift: Shift) => {
        setSelectedShift(shift);
        setStartTime(shift.startDate);
        setEndTime(shift.endDate);
        setSelectedSupervisor(shift.supervisor);
        setSelectedOperators(shift.operators);
    }

    const handleNewShiftForm = (hour: number, day: number) => {
        setSelectedShift(undefined);
        let shiftDate = new Date(firstWeekDayDate);
        shiftDate.setDate(shiftDate.getDate() + day);
        shiftDate.setHours(START_HOUR + hour);
        const start = new Date(shiftDate);
        const end = new Date(shiftDate);
        end.setHours(start.getHours() + DEFAULT_SHIFT_HOURS_DURATION);
        setStartTime(new Date(start));
        setEndTime(new Date(end));
        setSelectedSupervisor(undefined);
        setSelectedOperators([]);
    }

    const showAssignShiftOverlayPanel = (e: any, hour: number, day: number) => {
        console.log(hour, day);
        console.log(firstWeekDayDate);
        const weekDate = new Date(firstWeekDayDate);
        weekDate.setDate(weekDate.getDate() + day);
        weekDate.setHours(START_HOUR + hour);
        setSelectedDate(weekDate);
        assignShiftOverlayPanel.current?.toggle(e)
        const shift = getAssignedShift(hour, day);
        if (shift) {
            handleShiftSelection(shift);
        } else {
            handleNewShiftForm(hour, day);
        }
    };

    const onHideAssignShiftOverlayPanel = () => {
        setStartTime(new Date(firstWeekDayDate));
        setEndTime(new Date(firstWeekDayDate));
    }

    const onlyHourDate = (date: Date): Date => {
        const newDate = new Date(date);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        return newDate;
    }

    const setStartHour = (e: FormEvent<(Date | null)>) => {
        const date = new Date(e.target.value || firstWeekDayDate);
        if (date.getHours() < START_HOUR) {
            return;
        }
        console.log(date);
        setStartTime(date);
    };

    const setEndHour = (e: FormEvent<(Date | null)>) => {
        let date = new Date(e.target.value || startTime);
        if (date < startTime || isSlotAssigned(date.getHours(), date.getDay())) {
            date = new Date(startTime);
        }
        if (date.getHours() > END_HOUR + 1) {
            return;
        }
        console.log(date);
        setEndTime(date);
    };

    const handleSupervisorSelection = (e: any) => {
        setSelectedSupervisor(e.value);
    };

    const saveShift = () => {
        if (!startTime || !endTime || !selectedSupervisor || !selectedOperators || startTime >= endTime) {
            return;
        }
        let isUpdate = false;
        let shift: Shift;
        if (selectedShift !== undefined) {
            isUpdate = true;
            shift = selectedShift;
        } else {
            shift = {id: ""} as Shift;
        }
        shift.startDate = startTime;
        shift.endDate = endTime;
        shift.supervisor = selectedSupervisor;
        shift.operators = selectedOperators;

        for (let i = 0; i < shift.endDate.getHours() - shift.startDate.getHours(); i++) {
            if (isSlotAssigned(shift.startDate.getHours() + i, shift.startDate.getDay())) {
                assignShiftOverlayPanel.current?.hide();
                return;
            }
        }
        const shiftsItems: ShiftItem[] = []
        const employees = selectedOperators.concat(selectedSupervisor);
        for (let i = 0; i < employees.length; i++) {
            const employee = employees[i];
            const shiftItem: ShiftItem = {
                shiftId: isUpdate ? shift.id : undefined,
                proposal: {},
                endDate: formatDate(shift.endDate) || '',
                label: 'Work',
                proposalStatus: 'false',
                userStart: '',
                startTime: shift.startDate.toISOString(),
                endTime: shift.endDate.toISOString(),
                userEnd: '',
                userId: employee.id,
                startDate: formatDate(shift.startDate) || '',
            }
            shiftsItems.push(shiftItem);
        }

        const request: UpdateCalendarRequest = {
            shiftsAdd: !isUpdate ? shiftsItems : [],
            shiftsUpdate: isUpdate ? shiftsItems : [],
            shiftsDelete: []
        }
        updateCalendar(request).then((response) => {
            setSelectedShift(undefined);
            assignShiftOverlayPanel.current?.hide();
            retrieveShiftsWeekByDate({date: selectedDate}).then(shifts => {
                setAvailableShifts(shifts);
                successToast(t('messages.calendarUpdatedSuccess'));
            })
        }).catch(() => {
            console.log("Error saving shift");
            errorToast(t('messages.calendarUpdateError'));
        });
    }

    const getAssignedShift = (hour: number, day: number): Shift | undefined => {
        const actualDate = new Date(firstWeekDayDate);
        actualDate.setDate(actualDate.getDate() + day);
        actualDate.setHours(START_HOUR + hour);

        return availableShifts.find(shift => {
            return actualDate >= shift.startDate && actualDate < shift.endDate
        });
    }

    const isSlotAssigned = (hour: number, day: number) => {
        return getAssignedShift(hour, day) !== undefined;
    }

    const isShiftSelected = (hour: number, day: number) => {
        const shift = getAssignedShift(hour, day);
        return selectedShift && selectedShift.startDate === shift?.startDate && selectedShift.endDate === shift?.endDate;
    }

    const isEditing = (hour: number, day: number): boolean => {
        if (!startTime || !endTime || (startTime === endTime) || startTime >= endTime) {
            return false;
        }
        if (startTime.getDay() !== day && endTime.getDay() !== day) {
            return false
        }
        return startTime.getHours() <= hour && endTime.getHours() > hour;
    }

    const isFirstDayHour = (hour: number, day: number) => {
        const shift = getAssignedShift(hour, day);
        if (!shift) {
            return false;
        }
        return hour === START_HOUR || shift.startDate.getHours() === hour;
    }

    const getSlotSize = (hour: number, day: number) => {
        const shift = getAssignedShift(hour, day);
        if (!shift) {
            return 1;
        }

        if (shift.endDate.getDay() > day) {
            return END_HOUR
        }
        if (shift.startDate.getDay() < day) {
            return shift.endDate.getHours()
        }

        return shift.endDate.getHours() - shift.startDate.getHours();
    }

    const selectedEmployeeItem = (option: Employee) => {
        return (
            <div className="flex items-center justify-center">
                <div>{option?.name} {option?.surname}</div>
            </div>
        );
    };

    const showShiftOperators = (e: any, operators: Employee[]) => {
        setShiftOperatorsVisible(operators)
        shiftOperatorsOverlayPanel.current?.toggle(e)
        e.stopPropagation()
    }

    const hideShiftOperators = () => {
        setShiftOperatorsVisible([])
        shiftOperatorsOverlayPanel.current?.hide()
    }

    useEffect(() => {
        const request: AvailableWorkersBetweenDatesRequest = {
            startTime: startTime,
            endTime: endTime
        }
        retrieveAvailableWorkersBetweenDates(request).then((employees) => {
            setAvailableSupervisors(employees.filter(employee => employee.type === UserRole.Supervisor));
            setAvailableOperators(employees.filter(employee => employee.type === UserRole.Operator));
        });
    }, [endTime, startTime]);

    useEffect(() => {
        const weekday = onlyHourDate(selectedDate);
        setStartTime(weekday);
        setEndTime(weekday);
        const firstWeekDay = weekday.getDate() - weekday.getDay();
        const firstDay = new Date();
        const lastDay = new Date();
        firstDay.setDate(firstWeekDay);
        lastDay.setDate(firstWeekDay + WEEK_DAYS - 1);
        setFirstWeekDayDate(firstDay);
        setLastWeekDayDate(lastDay);

        const request: WorkersByDateRequest = {
            dateDate: selectedDate
        };
        retrieveWorkersByDate(request).then((employees) => {
            setAvailableSupervisors(employees.filter(employee => employee.type === UserRole.Supervisor));
            setAvailableOperators(employees.filter(employee => employee.type === UserRole.Operator));
        });
        retrieveShiftsWeekByDate({date: selectedDate}).then(shifts => {
            setAvailableShifts(shifts);
            setIsLoading(false);
        })
    }, [selectedDate]);

    useEffect(() => {
        setVisibleShifts(availableShifts.filter(
            shift => shift.startDate >= firstWeekDayDate && shift.endDate < lastWeekDayDate)
        );
    }, [firstWeekDayDate, lastWeekDayDate, availableShifts]);

    useEffect(() => {
        onChange(selectedDate);
    }, [onChange, selectedDate]);

    useEffect(() => {
        onVisibleShiftsChange(visibleShifts);
    }, [onVisibleShiftsChange, visibleShifts]);

    const ShiftItem: React.FC<{ shift?: Shift }> = ({shift}) => {
        return (
            <>
                {!shift
                    ? <div></div>
                    :
                    <div className="flex flex-col items-center justify-center">
                        <div>
                            <div>{shift.startDate.toLocaleString('default', {hour: 'numeric'})}
                                {" - "}
                                {shift.endDate.toLocaleString('default', {hour: 'numeric'})}
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <p>{t('weeklyCalendar.supervisor')}</p>
                            <HiOutlineUserCircle size={24} className="text-gray-400"/>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <p>{t('weeklyCalendar.operators')}</p>
                            <div className="flex items-center justify-center">
                                <HiOutlineUserCircle size={24} className="text-gray-400"
                                                     onClick={(e) => showShiftOperators(e, shift.operators)}
                                />
                            </div>
                        </div>
                        {
                            <OverlayPanel ref={shiftOperatorsOverlayPanel}
                                          showCloseIcon
                                          onHide={hideShiftOperators}
                                          onClick={e => e.stopPropagation()}
                                          className={`primary-container p-2 border-4 translate-y-[32rem]`}
                            >
                                <div
                                    className="flex flex-col justify-center items-center p-4 space-y-2 w-full text-[var(--text-color)]">
                                    <div className="flex items-center justify-center">
                                        <p className="text-lg font-semibold">{t('weeklyCalendar.operators')}</p>
                                    </div>
                                    {
                                        shiftOperatorsVisible.map((operator, index) => {
                                            return (
                                                <div key={index} className="flex items-center justify-center">
                                                    <div>{operator?.name} {operator?.surname}</div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </OverlayPanel>
                        }
                    </div>
                }
            </>
        );
    }


    return (
        <div className="">
            <div className="flex justify-between items-center py-4 px-14">
                <div className="text-3xl font-semibold">
                    {t('weeklyCalendar.week')}: {firstWeekDayDate.getDate()} - {lastWeekDayDate.getDate()}
                    {" "}
                    {firstWeekDayDate.toLocaleString('default', {month: 'long'})} {firstWeekDayDate.getFullYear()}
                </div>
                <div className="flex justify-between items-center w-28">
                    <div>
                        <FaAngleLeft size={28} onClick={previousWeek}/>
                    </div>
                    <div>
                        <FaAngleRight size={28} onClick={nextWeek}/>
                    </div>
                </div>
            </div>

            {
                isLoading ? <Loader/> :
                    <div className="w-full">
                        <table>
                            <thead>
                            <tr>
                                <th></th>
                                {
                                    Array.from({length: WEEK_DAYS}, (_, day) => {
                                        const date = new Date(firstWeekDayDate);
                                        date.setDate(date.getDate() + day);
                                        return (
                                            <th key={day} className={`p-2 border border-gray-300 cursor-pointer 
                                    ${date.getDate() === selectedDate.getDate() ? 'bg-[var(--primary-color-text)] text-[color:var(--primary-color)]' : ''}
                                    `}
                                                onClick={() => {
                                                    setSelectedDate(date)
                                                }}>
                                                {date.toLocaleString('default', {weekday: 'short'}) + ' ' + date.getDate()}
                                            </th>
                                        );
                                    })
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Array.from({length: HOURS}, (_, hour) => {
                                    return (
                                        <tr key={hour} className="">
                                            <td>
                                                {START_HOUR + hour > 12 ? START_HOUR + hour - 12 : START_HOUR + hour} {START_HOUR + hour >= 12 ? 'PM' : 'AM'}
                                            </td>
                                            {
                                                Array.from({length: WEEK_DAYS}, (_, day) => {
                                                    return (
                                                        <>
                                                            {
                                                                !isSlotAssigned(hour, day)
                                                                    ? <td key={`${hour}-${day}`}
                                                                          onClick={(e) => showAssignShiftOverlayPanel(e, hour, day)}
                                                                          className={`p-2 border border-gray-300 overflow-auto 
                                                                  ${isEditing(hour, day) ? 'bg-yellow-500' : ''} 
                                                                  `}
                                                                          rowSpan={1}>
                                                                        <div className={`min-h-6 w-28 `}></div>
                                                                    </td>
                                                                    : isFirstDayHour(hour, day) &&
                                                                    <td key={`${hour}-${day}`}
                                                                        onClick={(e) => showAssignShiftOverlayPanel(e, hour, day)}
                                                                        className={`p-2 border border-gray-300 overflow-auto bg-[var(--primary-color)] cursor-pointer`}
                                                                        rowSpan={getSlotSize(hour, day)}>
                                                                        <div
                                                                            className={`min-h-6 w-28 bg-[var(--primary-color)] var(--text-color) rounded-lg py-4 my-1 h-[100%]`}>
                                                                            {
                                                                                <ShiftItem
                                                                                    shift={getAssignedShift(hour, day)}/>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                            }
                                                        </>
                                                    );
                                                })
                                            }
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                        {
                            <OverlayPanel ref={assignShiftOverlayPanel}
                                          showCloseIcon
                                          onHide={onHideAssignShiftOverlayPanel}
                                          className={`primary-container p-0 min-w-[18rem] min-h-[20rem] border-4
                                    ${selectedShift ? 'border-white' : 'border-yellow-500'}
                                  `}
                            >
                                <div
                                    className="flex flex-col justify-center items-center p-4 space-y-2 w-[18rem] text-[var(--text-color)]">
                                    <div className="w-full space-y-1">
                                        <div>{t('weeklyCalendar.pickStartTime')}</div>
                                        <Calendar className="w-full"
                                                  timeOnly selectionMode="single"
                                                  value={startTime}
                                                  onChange={setStartHour}
                                                  stepMinute={STEP_MINUTE}
                                                  placeholder={t('weeklyCalendar.pickStartTime')}
                                        />
                                    </div>
                                    <div className="w-full space-y-1">
                                        <div>{t('weeklyCalendar.pickEndTime')}</div>
                                        <Calendar className="w-full"
                                                  timeOnly selectionMode="single"
                                                  value={endTime}
                                                  onChange={setEndHour}
                                                  stepMinute={STEP_MINUTE}
                                                  placeholder={t('weeklyCalendar.pickEndTime')}
                                        />
                                    </div>
                                    <div className="w-full space-y-1">
                                        <div>{t('weeklyCalendar.selectSupervisor')}</div>
                                        <Dropdown className="w-full min-h-11"
                                                  value={selectedSupervisor}
                                                  options={availableSupervisors}
                                                  optionLabel="id"
                                                  placeholder={t('weeklyCalendar.selectSupervisor')}
                                                  checkmark={true} highlightOnSelect={false}
                                                  onChange={(e) => handleSupervisorSelection(e)}
                                                  itemTemplate={selectedEmployeeItem}
                                                  valueTemplate={selectedEmployeeItem}
                                        />
                                    </div>
                                    <div className="w-full space-y-1">
                                        <div>{t('weeklyCalendar.selectOperators')}</div>
                                        <MultiSelect className="w-full min-h-11"
                                                     value={selectedOperators}
                                                     onChange={(e) => setSelectedOperators(e.value)}
                                                     options={availableOperators} optionLabel="id"
                                                     placeholder={t('weeklyCalendar.selectOperators')}
                                                     itemTemplate={selectedEmployeeItem}
                                                     selectedItemTemplate={selectedEmployeeItem}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button className="space-x-1 h-8" onClick={saveShift}>
                                            <FaRegEdit size={20}></FaRegEdit>
                                            {
                                                !selectedShift
                                                    ? <p>{t('weeklyCalendar.save')}</p>
                                                    : <p>{t('weeklyCalendar.edit')}</p>
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </OverlayPanel>
                        }
                    </div>
            }
        </div>
    );
};

export default WeeklyCalendar;