import React, {useCallback, useEffect, useState} from "react";
import {format} from "date-fns";
import {arSA, enUS} from 'date-fns/locale';
import Loader from "../../components/common/LoaderComponent";
import {useTranslation} from "react-i18next";
import {IoDocumentTextOutline} from "react-icons/io5";
import {forwardClaim, getClaimsHistory, getOperatorClaims} from "../../services/backend/api/claims/claims.apis";
import {ClaimsHistoryRequest} from "../../services/backend/api/claims/requests/claims-history.request";
import {RiLoader3Fill, RiLoader4Line} from "react-icons/ri";

const SEVEN_DAYS: number = 7;
const ONE_MONTH: number = 30;
const ONE_YEAR: number = 365;

const ClaimPage: React.FC = () => {
    const {t, i18n} = useTranslation();
    const [claimsData, setClaimsData] = useState<any[]>([]);
    const [historyClaimsData, setHistoryClaimsData] = useState<any[]>([]);
    const [selectedButton, setSelectedButton] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingHistory, setIsLoadingHistory] = useState<boolean>(false);
    const [confirmClaim, setConfirmClaim] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [days, setDays] = useState<number>(SEVEN_DAYS);
    const locale = i18n.language === 'ar' ? arSA : enUS;

    const fetchClaimsData = async () => {
        try {
            setIsLoading(true);
            const claims = await getOperatorClaims();
            setClaimsData(claims.claims);
        } catch (error) {
            console.error(
                "Errore durante il recupero dei dati del profilo:",
                error
            );
            setErrorMessage(
                "Si è verificato un errore durante il recupero dei dati del profilo."
            );
        } finally {
            setIsLoading(false);
        }
    };

    const fetchHistoryClaimsData = useCallback(async () => {
        try {
            setIsLoadingHistory(true);
            const claimHistoryRequest: ClaimsHistoryRequest = {
                days,
            };
            const historyClaimsResult = await getClaimsHistory(claimHistoryRequest);
            historyClaimsResult.data.sort((a: any, b: any) => {
                return (
                    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                );
            });

            setHistoryClaimsData(historyClaimsResult.data);
        } catch (error) {
            console.error(
                "Errore durante il recupero dei dati dei reclami storici:GoalAssignmentPage.tsx",
                error
            );
            setErrorMessage(
                "Si è verificato un errore durante il recupero dei dati dei reclami storici."
            );
        } finally {
            setIsLoadingHistory(false);
        }
    }, [days]);

    useEffect(() => {
        fetchClaimsData().then(() => console.log("Data fetched"));
        fetchHistoryClaimsData().then(() => console.log("Data fetched"));
    }, [fetchHistoryClaimsData]);

    const handleButtonPress = (index: number) => {
        setSelectedButton(index);
    };

    const confirmButtonPress = () => {
        setConfirmClaim(true);
    }

    const handleConfirmPress = async () => {
        if (selectedButton !== null) {
            try {
                setIsLoading(true);
                //da collegare
                const response = await forwardClaim(
                    claimsData[selectedButton].claimId
                );
                console.log("response", response);
                await fetchHistoryClaimsData();

                console.log("Richiesta del claim:", claimsData[selectedButton]);
                setConfirmClaim(false);
                setSelectedButton(null);
            } catch (error) {
                console.error("Errore durante la richiesta del claim:", error);
                setErrorMessage(
                    "Si è verificato un errore durante la richiesta del claim."
                );
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="page-container">
            <div className="page-title">
                <h2> {t("claim")}</h2>
            </div>
            <div className="page-content">
                <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">

                    <div className={`w-1/4  container-box-generic ${confirmClaim ? "justify-between" : ""}`}>
                        {isLoading && <Loader/>}
                        {!isLoading && claimsData.length > 0 && (
                            <>
                                <div className="mb-6 w-full text-left">
                                    <p className="text-sm">{t("claimPage.newClaim")}</p>
                                </div>
                                {!confirmClaim ? (
                                    <div className="bottom-container">
                                        {claimsData.map((claim, index) => (
                                            <button
                                                key={index}
                                                onClick={() => handleButtonPress(index)}
                                                className={`custom-button ${
                                                    selectedButton === index ? "selected" : ""
                                                }`}
                                            >
                                                {claim.claimName[i18n.language] || "N/A"}
                                            </button>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center" style={{minHeight: 200}}>
                                        {t("claimPage.confirClaimText")}
                                    </div>
                                )
                                }
                                {!confirmClaim ? (
                                    <button
                                        onClick={() => confirmButtonPress()}
                                        disabled={selectedButton === null}
                                        className="primary-button uppercase w-full"
                                        style={{
                                            borderLeft:
                                                selectedButton === null ? "2px solid red" : "2px solid #45A00D",
                                            borderRight:
                                                selectedButton === null ? "2px solid red" : "2px solid #45A00D",
                                            opacity: selectedButton === null ? 0.4 : 1,
                                        }}
                                    >
                                        {t("confirmButton")}
                                    </button>
                                ) : (
                                    <div className="flex justify-between w-full">
                                        <button
                                            onClick={() => setConfirmClaim(false)}
                                            disabled={selectedButton === null}
                                            className="primary-button uppercase w-50"
                                            style={{
                                                borderLeft: "2px solid red",
                                                borderRight: "2px solid red",
                                            }}
                                        >
                                            {t("noButton")}
                                        </button>
                                        <button
                                            onClick={() => handleConfirmPress()}
                                            disabled={selectedButton === null}
                                            className="primary-button uppercase w-50 ml-3"
                                            style={{
                                                borderLeft: "2px solid #45A00D",
                                                borderRight: "2px solid #45A00D",
                                            }}
                                        >
                                            {t("yesButton")}
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className="w-2/4 container-box-generic">
                        <div className="mb-6 w-full text-left flex justify-between">
                            <p className="text-sm">{t("claimPage.yourClaims")}</p>
                            <div className="flex flex-row gap-4">
                                <button
                                    className={`px-3 py-1 rounded-lg ellipsis flex items-center justify-center
                                        ${days === SEVEN_DAYS ?
                                        'bg-white border-2 border-white-500 text-gray-900'
                                        :
                                        'bg-unser border-2 border-white-500 text-white'}`
                                    }
                                    onClick={() => setDays(SEVEN_DAYS)}
                                >
                                    {t("claimPage.filter7days")}
                                </button>
                                <button
                                    className={`px-3 py-1 rounded-lg ellipsis flex items-center justify-center
                                         ${days === ONE_MONTH ?
                                        'bg-white border-2 border-white-500 text-gray-900'
                                        :
                                        'bg-unser border-2 border-white-500 text-white'}`
                                    }
                                    onClick={() => setDays(ONE_MONTH)}
                                >
                                    {t("claimPage.filter1month")}
                                </button>
                                <button
                                    className={`px-3 py-1 rounded-lg ellipsis flex items-center justify-center
                                         ${days === ONE_YEAR ?
                                        'bg-white border-2 border-white-500 text-gray-900'
                                        :
                                        'bg-unser border-2 border-white-500 text-white'}`
                                    }
                                    onClick={() => setDays(ONE_YEAR)}
                                >
                                    {t("claimPage.filterAllTime")}
                                </button>
                            </div>
                        </div>
                        <div className="mb-6 w-full">
                            {isLoadingHistory && <Loader/>}
                            {!isLoadingHistory && (
                                <div className="box-history-claims">
                                    {historyClaimsData.length === 0 ? (
                                        <p>{t("claimPage.noClaim")}</p>
                                    ) : (
                                        historyClaimsData.map((claim, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center justify-between mb-5"
                                            >
                                                <div className="flex items-center space-x-2">
                                                    <IoDocumentTextOutline
                                                        size={28} color="burlywood"
                                                    />
                                                    {/* Nome reclamo */}
                                                    <p className="text-sm">
                                                        {claim.claimInfo.claimName[i18n.language] || "N/A"}
                                                    </p>
                                                </div>
                                                {/* Data reclamo */}
                                                <p className="text-sm flex items-center">
                                                    {" "}
                                                    {format(new Date(claim.createdAt), "MMMM dd, yyyy", {locale})}
                                                    {claim.claimStatus === 'onwork'
                                                        ?
                                                        <RiLoader4Line
                                                            className="ml-3"
                                                            size={28} color="burlywood"
                                                        />
                                                        :
                                                        <RiLoader3Fill
                                                            className="ml-3 w-6 h-6"
                                                            size={28} color="burlywood"
                                                        />
                                                    }
                                                </p>

                                            </div>
                                        ))
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
            </div>
        </div>
    );
};

export default ClaimPage;
