import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {User} from "../../models/user.interface";
import {Button} from "primereact/button";
import UserInput from "./UserInput";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Dropdown} from "primereact/dropdown";
import {COUNTRIES, findCountryCodeByName, findCountryNameByCode} from "../../utils/countries.utils";
import {Calendar} from "primereact/calendar";
import {FileUpload, FileUploadHandlerEvent} from "primereact/fileupload";
import {UserRole} from "../../models/enums/user-role.enum";
import {CreateUserRequest} from "../../services/backend/api/hr/requests/create-user.request";
import {createUser} from "../../services/backend/api/hr/hr.apis";
import {uploadFile} from "../../services/backend/api/documents/documents.apis";
import {UploadFileRequest} from "../../services/backend/api/documents/requests/upload-file.request";
import {formatDate} from "../../utils/date.utils";
import {retrieveUserProfile} from "../../services/data-manager/data-manager.service";
import {editUserProfile} from "../../services/backend/api/general/general.apis";
import {EditUserProfileRequest} from "../../services/backend/api/general/requests/edit-user-profile.request";
import {useToastContext} from "../../contexts/ToastContext";
import {v4 as uuidv4} from 'uuid';

interface UserProfileProps {
    userId?: string
    onSubmit?: (user: User) => void
    compact?: boolean
}

const UserProfile: React.FC<UserProfileProps> = ({onSubmit, userId, compact}) => {
    const INPUT_SIZE = 'medium';
    const ENGLISH = 'English';
    const ARABIC = 'Arabic';
    const LANGUAGES = [
        ENGLISH,
        ARABIC
    ];

    const {t} = useTranslation();
    const {successToast, errorToast} = useToastContext();

    const [user, setUser] = useState<User>({} as User);
    const [isNewUser, setIsNewUser] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [userPicture, setUserPicture] = useState<string>();
    const [userPictureMimeType, setUserPictureMimeType] = useState<string>();
    const [availableRoles, setAvailableRoles] = useState<UserRole[]>(Object.values(UserRole));

    let userPictureFile: File | undefined;

    const NAME = 'name';
    const SURNAME = 'surname';
    const SECONDARY_MIDDLE_NAME = 'secondaryMiddleName';
    const FAMILY_NAME = 'familyName';
    const PASSWORD = 'password';
    const ROLE = 'role';
    const LANGUAGE = 'language';
    const [editingField, setEditingField] = useState<string | null>(null);

    const isEditing = (field: string) => {
        if (isNewUser) {
            return true;
        }
        return editingField === field;
    }

    const uploadProfilePicture = async (presignedUrl: string) => {
        console.log("Uploading file to presigned url");
        if (!userPictureFile) return;
        const uploadFileRequest: UploadFileRequest = {
            url: presignedUrl,
            file: userPictureFile
        }
        await uploadFile(uploadFileRequest).then((response) => {
            console.log("File uploaded successfully");
            successToast(t('messages.uploadUserPictureFileSuccess'));
            userPictureFile = undefined
            setUserPicture(undefined);
        }).catch((error) => {
            console.log(error);
            errorToast(t('messages.uploadUserPictureFileError'));
        })
    }

    const getSelectedUserLanguage = (): string => {
        return user.language === ENGLISH ? 'en' : 'ar'
    }

    const updateUserProfile = () => {
        const request: EditUserProfileRequest = {
            userId: user.id,
            firstName: user.name,
            lastName: user.surname,
            password: confirmPassword.length > 1 ? user.password : undefined,
            confirmPassword: confirmPassword.length > 1 ? confirmPassword : undefined,
            level: user.role,
            nationality: user.nationality,
            language: getSelectedUserLanguage(),
            mimetype: userPictureFile?.type || 'image/png',
            secondary_middle_name: user.secondaryMiddleName || '',
            family_name: user.familyName || '',
        };
        editUserProfile(request).then(async (response) => {
            console.log("Profile updated successfully");
            const presignedUrl = response.presignedReplacePicture;
            await uploadProfilePicture(presignedUrl);
            setEditingField(null);
            successToast(t('messages.updateUserProfileSuccess'));
            retrieveUserProfile({userId: user.id}).then((user) => {
                setUser(user);
            });
        }).catch((error) => {
            console.log("Error updating profile");
            console.log(error);
            errorToast(t('messages.updateUserProfileError'));
        })
    }

    const handleEditingField = (field: string) => {
        if (isEditing(field)) {
            console.log(`Saving field ${field}`);
            updateUserProfile();
        } else {
            setEditingField(field);
        }
    }

    const isFormValid = (): boolean => {
        // TODO: do validation using Forms
        const keys = Object.keys(user);
        for (let key of keys) {
            const value = user[key as keyof User];
            console.log(`Field ${key} has value ${value}`);
            if (!value && key !== 'email') {
                console.log(`Field ${key} is empty`);
                return false;
            }
        }
        if (user.password !== confirmPassword) {
            console.log('Passwords do not match');
            return false;
        }
        console.log('Form is valid');
        return true;
    }

    const getUserNationality = () => {
        if (isNewUser || !user.nationality) {
            return user.nationality;
        } else {
            return findCountryNameByCode(user.nationality);
        }
    }

    const handleCreateNewUserClick = () => {
        if (!isFormValid() || !userPicture || !userPictureMimeType) return;
        const country = findCountryCodeByName(user.nationality);
        const birthDay = formatDate(user.birthDate);
        const mimetype = userPictureMimeType;
        userPictureFile = new File([userPictureFile!], 'user_photo', {type: mimetype});

        if (!country || !birthDay) return;

        const request: CreateUserRequest = {
            firstName: user.name,
            lastName: user.surname,
            userName: user.username,
            password: user.password,
            confirmPassword: confirmPassword,
            level: user.role,
            email: user.email,
            nationality: country.code.toLowerCase(),
            language: getSelectedUserLanguage(),
            dateOfBirth: birthDay,
            photo: 'user_photo',
            mimetype: mimetype,
            secondary_middle_name: user.secondaryMiddleName || '',
            family_name: user.familyName || '',
        }

        console.log("Creating new user");
        createUser(request).then(async (response) => {
            console.log(response);
            const presignedUrl = response.presignedProfilePicture;
            await uploadProfilePicture(presignedUrl);
            setUserPicture(undefined);
            setUser({} as User);
            successToast(t('messages.newUserSuccess'));
        }).catch((error) => {
            console.log(error);
            errorToast(t('messages.newUserError'));
        })
    }

    const uploadHandler = async (event: FileUploadHandlerEvent) => {
        console.log("Uploading file");
        userPictureFile = event.files[0]
        setUserPictureMimeType(userPictureFile.type);
        setUserPicture(URL.createObjectURL(event.files[0]));

        if (isNewUser) return;
        updateUserProfile()
    };

    const userHasValidPicture = () => {
        return !userPicture && user.picture && user.picture.startsWith('https://')
    }

    const isValidEmail = (email?: string) => {
        if (!email) return false;
        const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/;
        return emailRegex.test(email);
    }

    useEffect(() => {
        if (userId) {
            console.log(`User profile for user with id ${userId}`);
            retrieveUserProfile({userId}).then((user) => {
                setUser(user);
            });
            setIsNewUser(false);
            setAvailableRoles([UserRole.Operator, UserRole.Supervisor]);
        } else {
            const newUser: User = {
                id: uuidv4(),
            } as User;
            setUser(newUser);
            setIsNewUser(true);
        }
    }, [userId])

    return (
        <div
            style={{
                padding: compact ? '1rem' : '2rem',
            }}
        >
            <div className="page-title flex">
                <h2 className="flex-grow">{t('userProfile.title')}</h2>
                {
                    isNewUser && (
                        <Button onClick={handleCreateNewUserClick}>
                            {t('userProfile.confirm')}
                        </Button>
                    )
                }
            </div>

            <div className="rounded-container w-full flex-wrap"
                 style={{
                     padding: compact ? '0.2rem 1rem 0rem 1rem' : '1rem',
                 }}
            >
                <div className={`space-y-2 ${compact ? ' flex-wrap' : 'flex flex-wrap'} `}
                     style={{
                         columnCount: compact ? 2 : 1,
                         columnFill: "auto",
                         columnGap: "4rem",
                         height: compact ? '18rem' : '100%'
                     }}
                >
                    <div className="w-full">
                        <UserInput label={t('userProfile.name')} editable={!isNewUser}
                                   onEdit={() => handleEditingField(NAME)}
                                   children={
                                       <InputText value={user.name} size={INPUT_SIZE} className="w-full"
                                                  disabled={!isEditing(NAME)}
                                                  placeholder={t('userProfile.placeholders.name')}
                                                  onChange={(e) => setUser({...user, name: e.target.value})}/>
                                   }/>
                    </div>

                    <div className="w-full">
                        <UserInput label={t('userProfile.surname')} editable={!isNewUser}
                                   onEdit={() => handleEditingField(SURNAME)}
                                   children={
                                       <InputText value={user.surname} size={INPUT_SIZE} className="w-full"
                                                  disabled={!isEditing(SURNAME)}
                                                  placeholder={t('userProfile.placeholders.surname')}
                                                  onChange={(e) => setUser({...user, surname: e.target.value})}/>
                                   }/>
                    </div>

                    <div className="w-full">
                        <UserInput label={t('userProfile.secondaryMiddleName')} editable={!isNewUser}
                                   onEdit={() => handleEditingField(SECONDARY_MIDDLE_NAME)}
                                   children={
                                       <InputText value={user.secondaryMiddleName} size={INPUT_SIZE} className="w-full"
                                                  disabled={!isEditing(SECONDARY_MIDDLE_NAME)}
                                                  placeholder={t('userProfile.placeholders.secondaryMiddleName')}
                                                  onChange={(e) => setUser({
                                                      ...user,
                                                      secondaryMiddleName: e.target.value
                                                  })}/>
                                   }/>
                    </div>

                    <div className="w-full">
                        <UserInput label={t('userProfile.familyName')} editable={!isNewUser}
                                   onEdit={() => handleEditingField(FAMILY_NAME)}
                                   children={
                                       <InputText value={user.familyName} size={INPUT_SIZE} className="w-full"
                                                  disabled={!isEditing(FAMILY_NAME)}
                                                  placeholder={t('userProfile.placeholders.familyName')}
                                                  onChange={(e) => setUser({...user, familyName: e.target.value})}/>
                                   }/>
                    </div>

                    <div className="w-full">
                        <UserInput label={t('userProfile.username')} editable={false} children={
                            <InputText value={user.username} size={INPUT_SIZE} className="w-full"
                                       disabled={!isNewUser}
                                       placeholder={t('userProfile.placeholders.username')}
                                       onChange={(e) => setUser({...user, username: e.target.value})}/>
                        }/>
                    </div>

                    <div className={`${isNewUser || isEditing(PASSWORD) ? 'w-full flex flex-wrap' : 'w-full'}`}>
                        <div className={`${isNewUser || isEditing(PASSWORD) ? 'w-2/4' : 'w-full'}`}>
                            <UserInput label={t('userProfile.password')} editable={!isNewUser && !isEditing(PASSWORD)}
                                       compact={isNewUser || isEditing(PASSWORD)}
                                       onEdit={() => handleEditingField(PASSWORD)}
                                       editLabel={t('userProfile.edit')}
                                       children={
                                           <Password
                                               className={`${isNewUser || isEditing(PASSWORD) ? 'input-icon-field' : ''}`}
                                               value={user.password}
                                               disabled={!isNewUser && !isEditing(PASSWORD)}
                                               feedback={isNewUser || isEditing(PASSWORD)}
                                               toggleMask={isNewUser || isEditing(PASSWORD)}
                                               style={{width: "100%"}}
                                               placeholder={t('userProfile.placeholders.password')}
                                               onChange={(e) => setUser({...user, password: e.target.value})}/>
                                       }/>
                        </div>
                        {
                            (isNewUser || isEditing(PASSWORD)) &&
                            <div className="w-2/4">
                                <UserInput label={t('userProfile.confirmPassword')} editable={!isNewUser}
                                           onEdit={() => handleEditingField(PASSWORD)}
                                           editLabel={t('userProfile.save')}
                                           compact={true}
                                           children={
                                               <Password
                                                   className={`${isNewUser || isEditing(PASSWORD) ? 'input-icon-field' : ''}`}
                                                   value={confirmPassword} feedback={false} toggleMask
                                                   style={{width: "100%"}}
                                                   placeholder={t('userProfile.placeholders.confirmPassword')}
                                                   onChange={(e) => setConfirmPassword(e.target.value)}/>
                                           }/>
                            </div>
                        }
                    </div>

                    <div className={`w-full ${compact ? '' : 'pb-8'}`}>
                        <UserInput label={t('userProfile.role')} editable={!isNewUser}
                                   onEdit={() => handleEditingField(ROLE)}
                                   children={
                                       <Dropdown value={user.role} options={availableRoles}
                                                 disabled={!isEditing(ROLE)}
                                                 placeholder={t('userProfile.placeholders.role')}
                                                 checkmark={true} highlightOnSelect={false} className="w-full"
                                                 onChange={(e) => setUser({...user, role: e.target.value})}
                                       />
                                   }/>
                        {
                            isNewUser && [UserRole.Client, UserRole.Provider, UserRole.HR].includes(user.role) &&
                            <div className="w-full pt-2">
                                <UserInput label={t('userProfile.email')} editable={false} children={
                                    <InputText value={user.email} size={INPUT_SIZE} className="w-full"
                                               disabled={!isNewUser}
                                               invalid={!isValidEmail(user.email)}
                                               placeholder={t('userProfile.placeholders.email')}
                                               onChange={(e) => setUser({...user, email: e.target.value})}/>
                                }/>
                            </div>
                        }
                    </div>

                    <div className="w-full flex flex-wrap justify-center items-center">
                        <div className="w-2/4 pb-2">
                            <UserInput label={t('userProfile.nationality')} editable={false} compact={true}
                                       children={
                                           <Dropdown value={getUserNationality()} options={COUNTRIES} showClear={false}
                                                     showFilterClear={false}
                                                     placeholder={t('userProfile.placeholders.nationality')} filter
                                                     disabled={!isNewUser}
                                                     checkmark={true} highlightOnSelect={false} className="w-full"
                                                     onChange={(e) => setUser({...user, nationality: e.target.value})}
                                           />
                                       }/>
                        </div>
                        <div className="w-2/4">
                            <UserInput label={t('userProfile.language')} editable={!isNewUser} compact={true}
                                       onEdit={() => handleEditingField(LANGUAGE)}
                                       children={
                                           <Dropdown value={user.language} options={LANGUAGES}
                                                     disabled={!isEditing(LANGUAGE)}
                                                     placeholder={t('userProfile.placeholders.language')}
                                                     checkmark={true} highlightOnSelect={false} className="w-full"
                                                     onChange={(e) => setUser({...user, language: e.target.value})}
                                           />
                                       }/>

                        </div>
                        <div className="w-2/4 ">
                            <UserInput label={t('userProfile.birthDate')} editable={false} compact={true} children={
                                <Calendar value={user.birthDate} className="w-full"
                                          placeholder={t('userProfile.placeholders.birthDate')}
                                          disabled={!isNewUser}
                                          onChange={(e) => setUser({
                                              ...user,
                                              birthDate: e.target.value || new Date()
                                          })}
                                          selectionMode='single'/>
                            }/>
                        </div>
                        <div className="w-2/4">
                            <UserInput label={t('userProfile.id')} editable={false} compact={true} children={
                                <InputText value={user.id} size={INPUT_SIZE} className="w-full"
                                           disabled={!isNewUser}
                                           placeholder={t('userProfile.placeholders.id')}
                                           onChange={(e) => setUser({...user, id: e.target.value})}/>
                            }/>
                        </div>

                    </div>

                    <div className="w-full items-center grid grid-cols-8 gap-4">
                        <div className="col-span-1 font-semibold text-lg">
                            <p>{t('userProfile.picture')}</p>
                        </div>

                        <FileUpload className="col-span-2 space-x-1"
                                    mode="basic" accept=".png,.jpg,.jpeg"
                                    onValidationFail={() => errorToast(t('messages.sizeUploadError'))}
                                    maxFileSize={1000000} chooseLabel={t('userProfile.uploadPicture')}
                                    auto={true} multiple={false} customUpload={true}
                                    uploadHandler={uploadHandler}
                        />

                        {
                            !userHasValidPicture()
                                ? (
                                    <div
                                        className="col-span-4 flex text-sm justify-center items-center text-center align-center">
                                        {
                                            userPicture
                                                ?
                                                <img src={userPicture} alt="User Avatar"
                                                     className="profile-picture"/>
                                                :
                                                <p>{t('userProfile.pictureRequirements')}</p>
                                        }
                                    </div>
                                )
                                : (
                                    <img src={user.picture} alt="User Avatar" className="profile-picture"/>
                                )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfile;