import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ToastContextProvider} from "./contexts/ToastContext";
import DesignSystem from "./utils/design-system.utils";
import {twMerge} from "tailwind-merge";
import {PrimeReactProvider} from "primereact/api";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <PrimeReactProvider value={{
        unstyled: true, pt: DesignSystem,
        ptOptions: {mergeSections: true, mergeProps: true, classNameMergeFunction: twMerge}
    }}>
        <React.StrictMode>
            <ToastContextProvider>
                <App/>
            </ToastContextProvider>
        </React.StrictMode>
    </PrimeReactProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
