import React from 'react'
import GraphSummaryDocument from "../components/GraphSummaryDocument";

interface LeavesSummaryProps {
    data: any;
}

const LeavesSummary: React.FC<LeavesSummaryProps> = ({data}) => {
    return (
        <GraphSummaryDocument title="Leaves Summary" subtitle="Report Data" data={data}/>
    )
}
export default LeavesSummary