import React, {useCallback, useEffect, useRef, useState} from "react";
import {FaAngleDown, FaAngleUp, FaCircle} from "react-icons/fa6";
import {CiSearch} from "react-icons/ci";
import Loader from "../common/LoaderComponent";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {Employee} from "../../models/employee.interface";
import {USER} from "../../utils/constants.utils";
import {Claim} from "../../models/claim.interface";
import {IoDocumentTextOutline} from "react-icons/io5";
import {BlobProvider} from "@react-pdf/renderer";
import {FiDownload} from "react-icons/fi";
import ClaimsHistorySummary from "../pdf/summaries/ClaimsHistorySummary";
import {useNavigate} from "react-router-dom";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {UserRole} from "../../models/enums/user-role.enum";
import {addClaimDetails, handleClaimForward} from "../../services/backend/api/claims/claims.apis";
import {OverlayPanel} from "primereact/overlaypanel";
import {InputTextarea} from "primereact/inputtextarea";
import {HandleClaimForwardRequest} from "../../services/backend/api/claims/requests/handle-claim-forward.request";
import {isContained} from "../../utils/generic.utils";
import {useToastContext} from "../../contexts/ToastContext";
import {formatDateTimeBeautify} from "../../utils/date.utils";
import i18next from "i18next";

interface ClaimHistoryProps {
    claimHistory: Claim[];
}

const ClaimHistory: React.FC<ClaimHistoryProps> = ({ claimHistory }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { level } = useUserContext();
    const { successToast, errorToast } = useToastContext();

    const [isLoading, setIsLoading] = useState(true);
    const [claims, setClaims] = useState(claimHistory)
    const [filter, setFilter] = useState(t('claimManagementPage.filters.all'));
    const [filteredClaims, setFilteredClaims] = useState<Claim[]>([]);
    const [visibleClaimDetails, setVisibleClaimDetails] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<string>();

    const addNotesOverlayPanel = useRef<OverlayPanel>(null);
    const [notes, setNotes] = useState<string>('');

    const NO_FILTER = t('claimManagementPage.filters.all')
    const PENDING_FILTER = t('claimManagementPage.filters.pending')
    const MANAGED_FILTER = t('claimManagementPage.filters.managed')

    const filters = [
        NO_FILTER,
        PENDING_FILTER,
        MANAGED_FILTER,
    ]

    const areClaimDetailsVisible = (id?: string) => {
        if (!id) return;
        return visibleClaimDetails.includes(id);
    }

    const handleFilterChange = (option: string) => {
        if (option === filter) {
            setFilter(NO_FILTER)
        } else {
            setFilter(option);
        }
    };

    const userLevel = useCallback((employee: Employee) => {
        if (employee.type === UserRole.Operator) {
            return t("Operator")
        } else if (employee.type === UserRole.Supervisor) {
            return t("Supervisor")
        } else {
            return ''
        }
    }, [t])

    const viewEmployeeProfile = (id: string) => () => {
        navigate(`/${level}/${USER}/`, {
            state: {
                data: {
                    userId: id
                }
            }
        }
        )
    }

    const showDetail = (claim: Claim) => {
        if (!claim.logId) return;
        setVisibleClaimDetails([...visibleClaimDetails, claim.logId])
    }
    const hideDetail = (claim: Claim) => {
        setVisibleClaimDetails(visibleClaimDetails.filter((id) => id !== claim.logId))
        if (!claim.logId) return;
        setClaimManaged(claim.logId)
    }

    const setClaimManaged = (logId: string) => {
        const request: HandleClaimForwardRequest = { logId }
        handleClaimForward(request)
            .then(() => {
                console.log('Claim managed');
                setClaims(claims.map(claim => {
                    if (claim.logId === logId) {
                        return {
                            ...claim,
                            isPending: false,
                            isManaged: true
                        }
                    }
                    return claim;
                }))
            })
            .catch((error) => {
                console.error('Error managing claim', error);
            })
    }

    const addDetails = async (e: React.MouseEvent<HTMLButtonElement>, logId?: string) => {
        if (!logId) return;
        if (addNotesOverlayPanel?.current?.isVisible()) {
            await addClaimDetails({ logId: logId, note: notes }).then(() => {
                console.log("Claim details added");
                setNotes('');
                successToast(t('messages.addClaimDetailsSuccess'));
            }).catch((error) => {
                console.log("Error adding claim details");
                console.error(error);
                errorToast(t('messages.addClaimDetailsError'));
            });
        }
        addNotesOverlayPanel?.current?.toggle(e);
    }

    const searchClaims = (e: any) => {
        let value = e.target.value
        value = value.trim()
        if (!value || value === '') {
            value = undefined
        }
        setSearchValue(value)
    }

    const filterClaims = useCallback(() => {
        let filtered = claims.filter(claim => {
            if (filter === NO_FILTER) return true
            if (filter === PENDING_FILTER && claim.isPending) return true
            return filter === MANAGED_FILTER && claim.isManaged;
        });
        if (searchValue) {
            console.log('searchValue', searchValue)
            filtered = filtered.filter(claim => {
                return isContained(claim.employee.name, searchValue)
                    || isContained(claim.employee.surname, searchValue)
                    || isContained(claim.type, searchValue)
                    || isContained(claim.date.toDateString(), searchValue)
            })
        }
        setFilteredClaims(filtered)
        setIsLoading(false)
    }, [claims, searchValue, filter, NO_FILTER, PENDING_FILTER, MANAGED_FILTER]);

    useEffect(() => {
        filterClaims();
    }, [filter, filterClaims]);

    useEffect(() => {
        setClaims(claimHistory);
    }, [claimHistory]);

    const [ln, setLn] = useState<string>(i18next.language || 'en'); // Imposta la lingua iniziale
    useEffect(() => {
        const handleLanguageChange = (lng: string) => setLn(lng);
        i18next.on("languageChanged", handleLanguageChange);

        return () => {
            i18next.off("languageChanged", handleLanguageChange);
        };
    }, []);

    return (
        <div className='page-content flex items-center justify-between'>
            <div className="flex items-center flex-col w-full">
                <div className="page-title uppercase w-full text-center">
                    <h4 className="text-center">{t('claimManagementPage.claimHistory')}</h4>
                </div>
                <div className='page-content max-w-2xl'>
                    <div
                        className="bg-[var(--primary-color)] grid w-full lg:w-auto md:grid-cols-4 gap-3 p-2 mb-4 rounded-lg">
                        {filters.map((option, index) => (
                            <button
                                key={index}
                                className={`px-3 py-1 rounded-lg ellipsis flex items-center justify-center
                                    ${filter === option ?
                                        'bg-white border-2 border-white-500 text-gray-900'
                                        :
                                        'bg-unser border-2 border-white-500 text-white'}`
                                }
                                onClick={() => handleFilterChange(option)}
                            >
                                <div className="flex justify-center items-center">
                                    <span className="truncate">{option}</span>
                                </div>
                            </button>
                        ))}
                        <div className="flex items-center justify-center search-bar">
                            <span className="search-icon"><CiSearch size={32} /></span>
                            <input type='search' className="search-input" onInput={searchClaims} />
                        </div>
                    </div>
                </div>


                <div className="space-y-2 w-full overflow-x-auto">
                    {
                        isLoading ? <Loader /> :
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead>
                                        <tr className="bg-[var(--primary-color)] text-white">
                                            <th className="p-3 text-left">{t('claimManagementPage.Level')}</th>
                                            <th className="p-3 text-left">{t('claimManagementPage.Employee')}</th>
                                            <th className="p-3 text-left">{t('claimManagementPage.Actions')}</th>
                                            <th className="p-3 text-left">{t('claimManagementPage.Date')}</th>
                                            <th className="p-3 text-left">{t('claimManagementPage.Type')}</th>
                                            <th className="p-3 text-left">{t('claimManagementPage.Details')}</th>
                                            <th className="p-3 text-left">{t('claimManagementPage.managementDate')}</th>
                                            <th className="p-3 text-left">{t('claimManagementPage.Status')}</th>
                                            <th className="p-3 text-left"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredClaims.length > 0 ? (
                                            filteredClaims.map((claim, index) => (
                                                <>
                                                    <tr className="bg-[var(--primary-color)] border-b">
                                                        <td className="p-3">
                                                            <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                                                                {userLevel(claim.employee)}
                                                            </div>
                                                        </td>
                                                        <td className="p-3">
                                                            <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                                                                {claim.employee.name} {claim.employee.surname}
                                                            </div>
                                                        </td>
                                                        <td className="p-3">
                                                            <div className="w-32">
                                                                <Button
                                                                    className="h-auto"
                                                                    onClick={viewEmployeeProfile(claim.employee.id)}
                                                                >
                                                                    {t('claimManagementPage.viewProfile')}
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        <td className="p-3">
                                                            <div className="whitespace-nowrap">
                                                                {formatDateTimeBeautify(claim.date, ln)}
                                                            </div>
                                                        </td>
                                                        <td className="p-3">
                                                            <div className="flex items-center capitalize">
                                                                <IoDocumentTextOutline
                                                                    size={24}
                                                                    color="var(--primary-color)"
                                                                    className="rounded-full bg-[burlywood] p-1 mr-2"
                                                                />
                                                                {claim.type}
                                                            </div>
                                                        </td>
                                                        <td className="p-3">
                                                            <div className="w-32">
                                                                <Button
                                                                    className="h-auto"
                                                                    onClick={(e) => addDetails(e, claim.logId)}
                                                                >
                                                                    {t('claimManagementPage.addDetails')}
                                                                </Button>
                                                            </div>
                                                            <OverlayPanel
                                                                ref={addNotesOverlayPanel}
                                                                showCloseIcon
                                                                className="primary-container p-0 border-none"
                                                            >
                                                                <InputTextarea
                                                                    value={notes}
                                                                    rows={10}
                                                                    cols={30}
                                                                    onChange={(e) => setNotes(e.target.value)}
                                                                />
                                                            </OverlayPanel>
                                                        </td>
                                                        <td className="p-3">
                                                            <div className="whitespace-nowrap flex flex-row items-center justify-center">
                                                                {claim.managedAt ? formatDateTimeBeautify(claim.managedAt, ln) : '-'}
                                                            </div>
                                                        </td>
                                                        <td className="p-3">
                                                            <div className="flex items-center">
                                                                <FaCircle
                                                                    size={18}
                                                                    color={claim.isPending ? "orange" : "darkgreen"}
                                                                    className="mr-2"
                                                                />
                                                                {claim.isPending
                                                                    ? t('claimManagementPage.pending')
                                                                    : t('claimManagementPage.managed')
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className="p-3">
                                                            <div
                                                                className="cursor-pointer"
                                                                onClick={() => areClaimDetailsVisible(claim.logId)
                                                                    ? hideDetail(claim)
                                                                    : showDetail(claim)}
                                                            >
                                                                {areClaimDetailsVisible(claim.logId)
                                                                    ? <FaAngleUp size={24} />
                                                                    : <FaAngleDown size={24} />
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {areClaimDetailsVisible(claim.logId) && (
                                                        <tr key={`types_${index}`}>
                                                            <td colSpan={9} className="p-3">
                                                                <div className="bg-gray-50 p-4 rounded flex flex-row items-center justify-center">
                                                                    <div className="pr-4">
                                                                        <p className="mb-2 text-black">Log ID: {claim.logId}</p>
                                                                        <p className="mb-2 text-black">Type: {claim.type}</p>
                                                                        <p className="text-black">Notes: {claim.notes}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={9} className="text-center py-10">
                                                    {t('claimManagementPage.noData')}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </div>
            <div className="flex justify-center w-full py-4">
                {
                    <BlobProvider document={<ClaimsHistorySummary claims={filteredClaims} />}>
                        {({ url, blob }) => (
                            url &&
                            <a href={url} target="_blank" className="primary-button bordered-button " rel="noreferrer">
                                <FiDownload size={20} className="mx-2" />
                                {t("documentPage.download")}
                            </a>
                        )}
                    </BlobProvider>
                }
            </div>
        </div>
    );
};

export default ClaimHistory;