import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import FilterButton from "../graphs/FilterButton";
import TasksGraph from "../graphs/TasksGraph";
import IncidentsGraph from "../graphs/IncidentsGraph";
import ClaimOverview from "../claim/ClaimOverview";
import {Claim} from "../../models/claim.interface";
import {
    retrieveClaimsByEmployee,
    retrieveIncidentsGraphByEmployee,
    retrieveTasksGraphByEmployee
} from "../../services/data-manager/data-manager.service";
import {oneMonthAgo, today} from "../../utils/date.utils";

interface UserGraphsProps {
    userId?: string;
}

const UserGraphs: React.FC<UserGraphsProps> = ({userId}) => {
    const {t} = useTranslation();

    const TASK = t('userGraphs.task');
    const NOTIFIED_INCIDENTS = t('userGraphs.notifiedIncidents');
    const CLAIMS = t('userGraphs.claims');

    const options = [TASK, NOTIFIED_INCIDENTS, CLAIMS];

    const [selectedOption, setSelectedOption] = useState<string>(TASK);
    // TODO: Add interfaces for graph data
    const [tasksGraphData, setTasksGraphData] = useState<any[]>([]);
    const [incidentGraphData, setIncidentGraphData] = useState<any[]>([]);
    const [claimHistory, setClaimHistory] = useState<Claim[]>([]);

    const [startDate, setStartDate] = useState<Date>(oneMonthAgo());
    const [endDate, setEndDate] = useState<Date>(today());

    const getTasks = useCallback(async () => {
        if (!userId) return;
        const request = {startDate: startDate, endDate: endDate, userId}
        await retrieveTasksGraphByEmployee(request).then((graphData) => {
            setTasksGraphData(graphData as any[]);
        });
    }, [endDate, startDate, userId]);

    const getIncidents = useCallback(async () => {
        if (!userId) return;
        const request = {startDate, endDate, userId}
        retrieveIncidentsGraphByEmployee(request).then((graphData) => {
            setIncidentGraphData(graphData as any[]);
        });
    }, [endDate, startDate, userId]);

    const getClaims = useCallback(async () => {
        if (!userId) return;
        const request = {startDate: startDate, endDate: endDate, userId}
        await retrieveClaimsByEmployee(request).then((claims) => {
            setClaimHistory(claims);
        });
    }, [endDate, startDate, userId]);

    useEffect(() => {
        if (userId) {
            console.log("Fetching data for user with id: ", userId);
            getTasks().then(() => console.log("Tasks fetched"));
            getIncidents().then(() => console.log("Incidents fetched"));
            getClaims().then(() => console.log("Claims fetched"));
        }
    }, [getClaims, getIncidents, getTasks, userId]);

    return (
        <div className="card-container">
            <div className="options-selector" style={{backgroundColor: "transparent"}}>
                {options.map((option, index) => (
                    <div>
                        <FilterButton
                            key={index}
                            label={
                                <div className="flex justify-center items-center">
                                    {option}
                                </div>
                            }
                            isActive={option === selectedOption}
                            onClick={() => setSelectedOption(option)}
                        />
                    </div>
                ))}
            </div>
            <div>
                {
                    selectedOption === TASK && (
                        <div>
                            <TasksGraph compact={true} tasksGraphData={tasksGraphData}/>
                        </div>
                    )
                }
                {
                    selectedOption === NOTIFIED_INCIDENTS && (
                        <div>
                            <IncidentsGraph compact={true} incidentGraphData={incidentGraphData}/>
                        </div>
                    )
                }
                {
                    selectedOption === CLAIMS && (
                        <div>
                            <ClaimOverview compact={true} claimHistory={claimHistory}/>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default UserGraphs;