import {useCallback, useEffect, useMemo} from "react";
import {useMap} from "react-leaflet";
import {Control, LatLng} from "leaflet";
import 'leaflet-geosearch/dist/geosearch.css';
import {GeoSearchControl} from "leaflet-geosearch";
import AbstractProvider from "leaflet-geosearch/src/providers/provider";

interface SearchLocationProps {
    provider: AbstractProvider;
    onLocationSelected?: (location: LatLng) => void;
}

const SearchLocation = (props: SearchLocationProps) => {
    const {provider, onLocationSelected} = props;

    const searchControl: Control = useMemo(() => new (GeoSearchControl as any)({
        provider,
        showMarker: false
    }) as Control, [provider]);

    const searchEventHandler = useCallback((event: any) => {
        if (onLocationSelected && event?.location) {
            const location = new LatLng(event.location.y, event.location.x);
            onLocationSelected(location);
        }
    }, [onLocationSelected])

    const map = useMap();
    useEffect(() => {
        map.on('geosearch/showlocation', searchEventHandler);
        map.addControl(searchControl);
        return () => {
            map.removeControl(searchControl);
        };
    }, [map, searchControl, searchEventHandler]);

    return null;
}

export default SearchLocation;