import React, { useEffect, useState } from "react";
import { useForgotStepper } from "./ForgotPasswordStepperContext";
import { UserRole } from "../../../models/enums/user-role.enum";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RecoverWebCredentialRequest } from "../../../services/backend/api/general/requests/recover-web-credential";
import { recoverCredential, recoverWebCredential } from "../../../services/backend/api/general/general.apis";
import Loader from "../LoaderComponent";
import { RecoverCredentialRequest } from "../../../services/backend/api/general/requests/recover-credential.request";
import { useToastContext } from "../../../contexts/ToastContext";


export const ForgotFinalStep = () => {
    const { level, email, phone, terminateStep } = useForgotStepper();
    const [isLoading, setIsLoading] = React.useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {errorToast, successToast} = useToastContext()
    const [errorMessage, setErrorMessage] = useState("");

    const goToHome = () => {
        terminateStep();
        navigate('/');
    }

    const handleRecoverWebCredential = async () => {
        if (level === UserRole.HR || level === UserRole.Provider || level === UserRole.Client) {
            await managerRecover();
        } else {
            await workerRecover();
        }
    }

    const managerRecover = async () => {
        setErrorMessage("");
        try {
            setIsLoading(true);
            const request: RecoverWebCredentialRequest = { email }
            const res = await recoverWebCredential(request)
            console.log('Web credential recovered', res);
            successToast(t('textForgotPasswordSuccessDescriptionWithEmail'))
        } catch (error: any) {
            console.error('Error recovering web credential', error);
            setErrorMessage(error.message)
            errorToast(error.message)
        } finally {
            setIsLoading(false);
        }
    }

    const workerRecover = async () => {
        setErrorMessage("");
        try {
            setIsLoading(true);
            const request: RecoverCredentialRequest = { phone }
            const res = await recoverCredential(request)
            console.log('Web credential recovered', res);
            successToast(t('textForgotPasswordSuccessDescriptionWithoutEmail'))
        } catch (error: any) {
            console.error('Error recovering web credential', error);
            setErrorMessage(error.message)
            errorToast(error.message)
        } finally {
            setIsLoading(false);
        }
    }

    let preventDoubleExecution = 0
    useEffect(() => {
        if (preventDoubleExecution === 0) {
            handleRecoverWebCredential()
            preventDoubleExecution++
        }
    }, []);

    return <div className="flex flex-col items-center justify-center gap-6 bg-[#2D2D2D] py-4 px-10 rounded-lg min-w-[300px] shadow-md">
        {
            (!isLoading && !errorMessage && (level === UserRole.Operator || level === UserRole.Supervisor)) && (
                <>
                    <h1 className="text-2xl font-bold">{t('textForgotPasswordSuccess')}</h1>
                    <p className="text-sm">{t('textForgotPasswordSuccessDescriptionWithoutEmail')}</p>
                </>
            )
        }
        {
            (!isLoading && !errorMessage && (level === UserRole.HR || level === UserRole.Provider || level === UserRole.Client)) && (
                <>
                    <h1 className="text-2xl font-bold">{t('textForgotPasswordSuccess')}</h1>
                    <p className="text-sm">{t('textForgotPasswordSuccessDescriptionWithEmail')}</p>
                </>
            )
        }

        {
            !isLoading && (
                <button
                    onClick={goToHome}
                    className={`primary-button uppercase border-l-2 border-r-2 border-red-500`}
                >
                    <BiArrowBack
                        style={{ marginRight: 10 }}
                        size={28} color="whitesmoke"
                    />
                    {t('go_home')}
                </button>
            )
        }

        {
            isLoading && (
                <div style={{ width: 200, height: 200 }} className='flex justify-center items-center'>
                    <Loader key="loader" />
                </div>
            )
        }

    </div>
};