import {Notification} from "../../models/notification.interface";
import {Communication} from "../../models/communication.interface";
import {Goal} from "../../models/goal.interface";
import {Supervisor} from "../../models/supervisor.interface";
import {Operator} from "../../models/operator.interface";
import {UserRole} from "../../models/enums/user-role.enum";
import {PendingRequest} from "../../models/pending-request.interface";
import {UserEntity} from "../../models/entities/user-entity.interface";
import {GroupMember} from "../../models/group-member.interface";
import {LeaveRequest} from "../../models/leave-request.interface";
import {LeaveRequestCategory} from "../../models/enums/leave-request-category.enum";
import {Claim} from "../../models/claim.interface";
import {ClaimCategory} from "../../models/enums/claim-category.enum";
import {Employee} from "../../models/employee.interface";
import {UploadedDocument} from "../../models/uploaded-document.interface";
import {DocumentCategory} from "../../models/enums/document-category.enum";
import {WorkReport} from "../../models/work-reports.interface";
import {Severity} from "../../models/enums/severity.enum";
import {WorkReportCategory} from "../../models/enums/work-report-category.enum";
import {randomId} from "../../utils/generate.utils";
import {Shift} from "../../models/shift.interface";
import {PendingRequestType} from "../../models/enums/pending-request-type.enum";
import {ShiftsWeekStatsResponse} from "../backend/api/shifts/responses/shifts-week-stats.response";
import {User} from "../../models/user.interface";
import {TaskPreset} from "../../models/task-preset";
import {UserData} from "../backend/shared/user-data.interface";
import {IncidentTypology} from "../../models/incident-typology.interface";

export const getUserStub = (): User => {
    return {
        id: randomId(),
        birthDate: new Date(),
        language: "en",
        nationality: "it",
        password: "",
        name: "Lastname",
        surname: "Firstname",
        role: UserRole.Operator,
        picture: "photo",
        username: "userName",
        familyName: "familyName",
        secondaryMiddleName: "secondaryMiddleName",
    }
}

export const getUserEntityStub = (): UserEntity => {
    return {
        name: "Lastname",
        surname: "Firstname",
        phoneNumber: "3333333333",
        level: UserRole.Operator,
        photo: "photo",
        active: "active",
        username: "userName",
        type: "type",
        userId: randomId(),
        email: "email",
        createdAt: 123456789,
    }
}

export const getUserDataStub = (): UserData => {
    return {
        lastName: "Name",
        firstName: "Surname",
        phoneNumber: '3333333333',
        pinHystory: [],
        level: UserRole.Operator,
        photo: 'photo',
        active: 'true',
        userName: 'userName',
        type: 'type',
        userId: randomId(),
        email: "email",
    }
}

export const getEmployeeStub = (): Employee => {
    return {
        id: randomId(),
        name: 'Name',
        surname: 'Surname',
        type: UserRole.Operator,
        hasRequestPending: false,
        isOnLeave: false,
        assigned: false
    }
}


export const getNotificationsStub = (): Notification[] => {
    return [
        {
            id: randomId(),
            operatorName: 'Operator 1',
            date: new Date('2024-08-06'),
            content: 'Notification content 1',
            isRead: false
        },
        {
            id: randomId(),
            operatorName: 'Operator 2',
            date: new Date('2024-08-05'),
            content: 'Notification content 2',
            isRead: true
        },
        {
            id: randomId(),
            operatorName: 'Operator 3',
            date: new Date('2024-08-04'),
            content: 'Notification content 3',
            isRead: false
        },
        {
            id: randomId(),
            operatorName: 'Operator 4',
            date: new Date('2024-07-02'),
            content: 'Notification content 4',
            isRead: false
        },
        {
            id: randomId(),
            operatorName: 'Operator 5',
            date: new Date('2024-02-01'),
            content: 'Notification content 5',
            isRead: true
        }
    ]
}

export const getCommunicationArchiveStub = (): Communication[] => {
    return [
        {
            id: randomId(),
            content: 'content1',
            receiver: 'receiver1',
            date: new Date()
        },
        {
            id: randomId(),
            content: 'content2',
            receiver: 'receiver2',
            date: new Date()
        },
        {
            id: randomId(),
            content: 'content3',
            receiver: 'receiver3',
            date: new Date()
        },
        {
            id: randomId(),
            content: 'content4',
            receiver: 'receiver4',
            date: new Date()
        },
        {
            id: randomId(),
            content: 'content5',
            receiver: 'receiver5',
            date: new Date()
        }
    ]
}

export const getGoalsStub = (): Goal[] => {
    return [
        {
            id: randomId(),
            content: 'Goal 1',
            date: new Date(),
            dueDate: null,
            tasks: [
                {
                    id: randomId(),
                    content: 'Task 1',
                    date: new Date()
                },
                {
                    id: randomId(),
                    content: 'Task 2',
                    date: new Date()
                },
                {
                    id: randomId(),
                    content: 'Task 3',
                    date: new Date()
                }
            ]
        },
        {
            id: randomId(),
            content: 'Goal 2',
            date: new Date(),
            dueDate: null,
            tasks: [
                {
                    id: randomId(),
                    content: 'Task 4',
                    date: new Date()
                },
                {
                    id: randomId(),
                    content: 'Task 5',
                    date: new Date()
                },
                {
                    id: randomId(),
                    content: 'Task 6',
                    date: new Date()
                }
            ]
        },
        {
            id: randomId(),
            content: 'Goal 3',
            date: new Date(),
            dueDate: null,
            tasks: [
                {
                    id: randomId(),
                    content: 'Task 7',
                    date: new Date()
                },
                {
                    id: randomId(),
                    content: 'Task 8',
                    date: new Date()
                },
                {
                    id: randomId(),
                    content: 'Task 9',
                    date: new Date()
                }
            ]
        },
        {
            id: randomId(),
            content: 'Goal 4',
            date: new Date(Date.now() - 86400000),
            dueDate: null,
            tasks: [
                {
                    id: randomId(),
                    content: 'Task 10',
                    date: new Date()
                },
                {
                    id: randomId(),
                    content: 'Task 11',
                    date: new Date()
                },
                {
                    id: randomId(),
                    content: 'Task 12',
                    date: new Date()
                }
            ]
        },
        {
            id: randomId(),
            content: 'Goal 5',
            date: new Date(Date.now() - 86400000),
            dueDate: null,
            tasks: [
                {
                    id: randomId(),
                    content: 'Task 13',
                    date: new Date()
                },
                {
                    id: randomId(),
                    content: 'Task 14',
                    date: new Date()
                },
                {
                    id: randomId(),
                    content: 'Task 15',
                    date: new Date()
                }
            ]
        },
        {
            id: randomId(),
            content: 'Goal 6',
            date: new Date(Date.now() - 86400000 * 2),
            dueDate: null,
            tasks: [
                {
                    id: randomId(),
                    content: 'Task 16',
                    date: new Date()
                },
                {
                    id: randomId(),
                    content: 'Task 17',
                    date: new Date()
                },
                {
                    id: randomId(),
                    content: 'Task 18',
                    date: new Date()
                }
            ]
        }
    ]
}

export const getEmployeesStub = (): Employee[] => {
    return [
        {
            id: randomId(),
            type: UserRole.Supervisor,
            name: 'name1',
            surname: 'surname1',
            hasRequestPending: true,
            isOnLeave: false,
            assigned: true
        },
        {
            id: randomId(),
            type: UserRole.Supervisor,
            name: 'name2',
            surname: 'surname2',
            hasRequestPending: false,
            isOnLeave: false,
            assigned: true
        },
        {
            id: randomId(),
            type: UserRole.Operator,
            name: 'name3',
            surname: 'surname3',
            hasRequestPending: true,
            isOnLeave: false,
            assigned: false
        },
        {
            id: randomId(),
            type: UserRole.Supervisor,
            name: 'name4',
            surname: 'surname4',
            hasRequestPending: false,
            isOnLeave: true,
            assigned: false
        },
        {
            id: randomId(),
            type: UserRole.Operator,
            name: 'name5',
            surname: 'surname5',
            hasRequestPending: true,
            isOnLeave: true,
            assigned: false
        }

    ]
}

export const getTerminatedEmployeesStub = (): Employee[] => {
    return [{
        id: randomId(),
        type: UserRole.Operator,
        name: 'name5',
        surname: 'surname5',
        hasRequestPending: true,
        isOnLeave: true,
        assigned: false,
        terminatedAt: "2024-10-29T11:37:13.138Z"
    }]
}

export const getEmployeesOnShiftStub = (): Employee[] => {
    return [
        {
            id: randomId(),
            type: UserRole.Supervisor,
            name: 'name1',
            surname: 'surname1',
            hasRequestPending: true,
            isOnLeave: false,
            assigned: true
        },
        {
            id: randomId(),
            type: UserRole.Supervisor,
            name: 'name2',
            surname: 'surname2',
            hasRequestPending: false,
            isOnLeave: false,
            assigned: true
        },
        {
            id: randomId(),
            type: UserRole.Operator,
            name: 'name3',
            surname: 'surname3',
            hasRequestPending: true,
            isOnLeave: false,
            assigned: false
        },
        {
            id: randomId(),
            type: UserRole.Supervisor,
            name: 'name4',
            surname: 'surname4',
            hasRequestPending: false,
            isOnLeave: true,
            assigned: false
        },
        {
            id: randomId(),
            type: UserRole.Operator,
            name: 'name5',
            surname: 'surname5',
            hasRequestPending: true,
            isOnLeave: true,
            assigned: false
        }

    ]
}

export const getSupervisorsOnShiftStub = (): Supervisor[] => {
    return [
        {
            id: randomId(),
            name: 'John',
            surname: 'Doe',
            level: UserRole.Supervisor
        },
        {
            id: randomId(),
            name: 'Jane',
            surname: 'Russel',
            level: UserRole.Supervisor
        },
        {
            id: randomId(),
            name: 'Alice',
            surname: 'Powell',
            level: UserRole.Supervisor
        },
        {
            id: randomId(),
            name: 'Bob',
            surname: 'Dylan',
            level: UserRole.Supervisor

        },
        {
            id: randomId(),
            name: 'Charlie',
            surname: 'Parker',
            level: UserRole.Supervisor
        },
        {
            id: randomId(),
            name: 'David',
            surname: 'Gilmour',
            level: UserRole.Supervisor
        },
        {
            id: randomId(),
            name: 'Eddie',
            surname: 'Vedder',
            level: UserRole.Supervisor
        },
        {
            id: randomId(),
            name: 'Freddy',
            surname: 'Mercury',
            level: UserRole.Supervisor
        },
        {
            id: randomId(),
            name: 'George',
            surname: 'Harrison',
            level: UserRole.Supervisor
        },
        {
            id: randomId(),
            name: 'Harry',
            surname: 'Potter',
            level: UserRole.Supervisor
        }
    ]
}

export const getOperatorsOnShiftStub = (): Operator[] => {
    return [
        {
            id: randomId(),
            name: 'John',
            surname: 'Doe',
            level: UserRole.Operator
        },
        {
            id: randomId(),
            name: 'Jane',
            surname: 'Russel',
            level: UserRole.Operator
        },
        {
            id: randomId(),
            name: 'Alice',
            surname: 'Powell',
            level: UserRole.Operator
        },
        {
            id: randomId(),
            name: 'Bob',
            surname: 'Dylan',
            level: UserRole.Operator

        },
        {
            id: randomId(),
            name: 'Charlie',
            surname: 'Parker',
            level: UserRole.Operator
        },
        {
            id: randomId(),
            name: 'David',
            surname: 'Gilmour',
            level: UserRole.Operator
        },
        {
            id: randomId(),
            name: 'Eddie',
            surname: 'Vedder',
            level: UserRole.Operator
        },
        {
            id: randomId(),
            name: 'Freddy',
            surname: 'Mercury',
            level: UserRole.Operator
        },
        {
            id: randomId(),
            name: 'George',
            surname: 'Harrison',
            level: UserRole.Operator
        },
        {
            id: randomId(),
            name: 'Harry',
            surname: 'Potter',
            level: UserRole.Operator
        }
    ]
}

export const getShiftStub = (): Shift[] => {
    const employees = getEmployeesOnShiftStub();
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today.getTime() + 86400000);
    return [
        {
            id: randomId(),
            startDate: new Date(today.setHours(10, 0, 0, 0)),
            endDate: new Date(today.setHours(18, 0, 0, 0)),
            supervisor: employees[0],
            operators: [employees[1], employees[2]]
        },
        {
            id: randomId(),
            startDate: new Date(tomorrow.setHours(13, 0, 0, 0)),
            endDate: new Date(tomorrow.setHours(21, 0, 0, 0)),
            supervisor: employees[3],
            operators: [employees[4], employees[5]]
        }
    ]
}

export const getPendingRequestsStub = (): PendingRequest[] => {
    return [
        {
            id: randomId(),
            operatorName: 'Operator 1',
            userId: randomId(),
            requestType: PendingRequestType.Leaves,
        },
        {
            id: randomId(),
            operatorName: 'Operator 2',
            userId: randomId(),
            requestType: PendingRequestType.Leaves,
        },
        {
            id: randomId(),
            operatorName: 'Operator 3',
            userId: randomId(),
            requestType: PendingRequestType.Leaves,
        },
        {
            id: randomId(),
            operatorName: 'Operator 4',
            userId: randomId(),
            requestType: PendingRequestType.Claims,
        },
        {
            id: randomId(),
            operatorName: 'Operator 5',
            userId: randomId(),
            requestType: PendingRequestType.Claims,
        }
    ]
}

export const getSupervisorsGroupMemberStub = (): GroupMember[] => {
    return [
        {
            id: randomId(),
            name: 'Supervisor 1',
            role: UserRole.Supervisor
        },
        {
            id: randomId(),
            name: 'Supervisor 2',
            role: UserRole.Supervisor
        },
        {
            id: randomId(),
            name: 'Supervisor 3',
            role: UserRole.Supervisor
        },
        {
            id: randomId(),
            name: 'Supervisor 4',
            role: UserRole.Supervisor
        },
        {
            id: randomId(),
            name: 'Supervisor 5',
            role: UserRole.Supervisor
        }
    ]
}

export const getOperatorsGroupMemberStub = (): GroupMember[] => {
    return [
        {
            id: randomId(),
            name: 'Operator 1',
            role: UserRole.Operator
        },
        {
            id: randomId(),
            name: 'Operator 2',
            role: UserRole.Operator
        },
        {
            id: randomId(),
            name: 'Operator 3',
            role: UserRole.Operator
        },
        {
            id: randomId(),
            name: 'Operator 4',
            role: UserRole.Operator
        },
        {
            id: randomId(),
            name: 'Operator 5',
            role: UserRole.Operator
        }
    ]
}

export const getLeaveRequestsStub = (): LeaveRequest[] => {
    return [
        {
            id: randomId(),
            userId: '1',
            category: LeaveRequestCategory.SickLeave,
            isPending: true,
            date: new Date(),
            isNew: true
        },
        {
            id: randomId(),
            userId: '2',
            category: LeaveRequestCategory.SickLeave,
            isPending: false,
            date: new Date(),
            isNew: false
        },
        {
            id: '3',
            userId: '3',
            category: LeaveRequestCategory.SickLeave,
            isPending: false,
            date: new Date(),
            isNew: false
        },
        {
            id: randomId(),
            userId: '4',
            category: LeaveRequestCategory.SickLeave,
            isPending: false,
            date: new Date(),
            isNew: false
        },
        {
            id: randomId(),
            userId: '5',
            category: LeaveRequestCategory.SickLeave,
            isPending: true,
            date: new Date(),
            isNew: false
        },
    ]
}

export const getClaimsStub = (): Claim[] => {
    return [
        {
            id: randomId(),
            employee: {
                id: randomId(),
                name: 'Name1',
                surname: 'Surname1',
                type: UserRole.Operator,
                hasRequestPending: false,
                isOnLeave: false,
                assigned: false
            },
            type: ClaimCategory.PersonalWellBeing,
            date: new Date(),
            isPending: false,
            isManaged: true
        },
        {
            id: randomId(),
            employee: {
                id: randomId(),
                name: 'Name2',
                surname: 'Surname2',
                type: UserRole.Operator,
                hasRequestPending: true,
                isOnLeave: false,
                assigned: false
            },
            type: ClaimCategory.PersonalWellBeing,
            date: new Date(),
            isPending: true,
            isManaged: false
        },
        {
            id: randomId(),
            employee: {
                id: randomId(),
                name: 'Name3',
                surname: 'Surname3',
                type: UserRole.Operator,
                hasRequestPending: false,
                isOnLeave: false,
                assigned: false
            },
            type: ClaimCategory.PersonalWellBeing,
            date: new Date(Date.now() - 86400000 * 2),
            isPending: false,
            isManaged: true
        },
        {
            id: randomId(),
            employee: {
                id: randomId(),
                name: 'Name4',
                surname: 'Surname4',
                type: UserRole.Operator,
                hasRequestPending: false,
                isOnLeave: false,
                assigned: false
            },
            type: ClaimCategory.Bullying,
            date: new Date(Date.now() - 86400000 * 2),
            isPending: false,
            isManaged: true
        },
        {
            id: randomId(),
            employee: {
                id: randomId(),
                name: 'Name5',
                surname: 'Surname5',
                type: UserRole.Operator,
                hasRequestPending: false,
                isOnLeave: false,
                assigned: false
            },
            type: ClaimCategory.IssuesWithColleagues,
            date: new Date(),
            isPending: false,
            isManaged: true
        },
    ]
}

export const getUploadedDocumentsStub = (): UploadedDocument[] => {
    return [
        {
            id: randomId(),
            name: 'Document 1.pdf',
            date: new Date(),
            category: DocumentCategory.Contracts
        },
        {
            id: randomId(),
            name: 'Document 2.docx',
            date: new Date(),
            category: DocumentCategory.Contracts
        },
        {
            id: randomId(),
            name: 'Document 3.jpeg',
            date: new Date(),
            category: DocumentCategory.Contracts
        },
        {
            id: randomId(),
            name: 'Document 4.jpg',
            date: new Date(),
            category: DocumentCategory.Contracts
        },
        {
            id: randomId(),
            name: 'Document 5.xls',
            date: new Date(),
            category: DocumentCategory.Contracts
        }
    ]
}

export const getWorkReportsStub = (): WorkReport[] => {
    const today = new Date();
    const startDate = new Date();
    const endDate = new Date();

    today.setHours(0, 0, 0, 0);
    startDate.setHours(9, 0, 0, 0);
    endDate.setHours(17, 0, 0, 0);

    return [
        {
            id: randomId(),
            name: 'Incident Report 1',
            date: new Date(),
            isNew: true,
            severity: Severity.High,
            category: WorkReportCategory.IncidentNotification,
            confirmed: true,
            declined: false,
            startDate: startDate,
            endDate: endDate,
        },
        {
            id: randomId(),
            name: 'Incident Report 2',
            date: new Date(),
            isNew: true,
            severity: Severity.Minor,
            category: WorkReportCategory.IncidentNotification,
            confirmed: false,
            declined: true,
            startDate: startDate,
            endDate: endDate,
        },
        {
            id: randomId(),
            name: 'Incident Report 3',
            date: new Date(),
            isNew: true,
            severity: Severity.Medium,
            category: WorkReportCategory.IncidentNotification,
            confirmed: false,
            declined: false,
            startDate: startDate,
            endDate: endDate,
        },
        {
            id: randomId(),
            name: 'Shift Report 1',
            date: new Date(),
            isNew: false,
            severity: Severity.Minor,
            category: WorkReportCategory.EndShift,
            confirmed: true,
            declined: false,
            startDate: new Date(startDate.getTime() + 60000 * 5),
            endDate: new Date(endDate.getTime() + 60000 * 5),
        },
        {
            id: randomId(),
            name: 'Shift Report 2',
            date: new Date(),
            isNew: false,
            severity: Severity.Medium,
            category: WorkReportCategory.EndShift,
            confirmed: false,
            declined: true,
            startDate: new Date(startDate.getTime() - 60000 * 35),
            endDate: new Date(endDate.getTime() + 60000),
        },
        {
            id: randomId(),
            name: 'Shift Report 3',
            date: new Date(),
            isNew: false,
            severity: Severity.High,
            category: WorkReportCategory.EndShift,
            confirmed: false,
            declined: false,
            startDate: new Date(startDate.getTime() + 60000),
            endDate: new Date(endDate.getTime() - 60000 * 35),
        },
        {
            id: randomId(),
            name: 'Incident Report 1',
            date: new Date(),
            isNew: true,
            severity: Severity.High,
            category: WorkReportCategory.IncidentNotification,
            confirmed: true,
            declined: false,
            startDate: new Date(Date.now() - 86400000 * 2 + 600000 * 5),
            endDate: new Date(Date.now() - 86400000 * 2 + 3600000 * 8 - 600000 * 5),
        },
        {
            id: randomId(),
            name: 'Incident Report 2',
            date: new Date(),
            isNew: true,
            severity: Severity.Minor,
            category: WorkReportCategory.IncidentNotification,
            confirmed: false,
            declined: true,
            startDate: new Date(Date.now() - 86400000 * 2 + 600000 * 5),
            endDate: new Date(Date.now() - 86400000 * 2 + 3600000 * 8 - 600000 * 5),
        },
        {
            id: randomId(),
            name: 'Incident Report 3',
            date: new Date(),
            isNew: true,
            severity: Severity.Medium,
            category: WorkReportCategory.IncidentNotification,
            confirmed: false,
            declined: false,
            startDate: new Date(Date.now() - 86400000 * 2 + 600000 * 5),
            endDate: new Date(Date.now() - 86400000 * 2 + 3600000 * 8 - 600000 * 5),
        },
        {
            id: randomId(),
            name: 'Shift Report 1',
            date: new Date(),
            isNew: false,
            severity: Severity.Minor,
            category: WorkReportCategory.EndShift,
            confirmed: true,
            declined: false,
            startDate: new Date(Date.now() - 86400000 * 2 + 600000 * 5),
            endDate: new Date(Date.now() - 86400000 * 2 + 3600000 * 8 - 600000 * 5),
        },
        {
            id: randomId(),
            name: 'Shift Report 2',
            date: new Date(),
            isNew: false,
            severity: Severity.Medium,
            category: WorkReportCategory.EndShift,
            confirmed: false,
            declined: true,
            startDate: new Date(Date.now() - 86400000 * 2 + 600000 * 5),
            endDate: new Date(Date.now() - 86400000 * 2 + 3600000 * 8),
        },
        {
            id: randomId(),
            name: 'Shift Report 3',
            date: new Date(),
            isNew: false,
            severity: Severity.High,
            category: WorkReportCategory.EndShift,
            confirmed: false,
            declined: false,
            startDate: new Date(Date.now() - 86400000 * 2),
            endDate: new Date(Date.now() - 86400000 * 2 + 3600000 * 8 + 600000 * 35),
        }
    ]
}

export const getShiftsWeekStatsStub = (): ShiftsWeekStatsResponse => {
    return {
        month: {
            total: 120,
            worked: 80
        },
        week: {
            total: 40,
            worked: 30
        }
    } as ShiftsWeekStatsResponse
}

export const getTaskPresetStub = (): TaskPreset[] => {
    return [
        {
            id: '1',
            title: 'Surveillance Monitoring',
            description: 'Continuous monitor CCTV and other'
        },
        {
            id: '2',
            title: 'Access Control',
            description: 'Manage and control entry and exit'
        },
        {
            id: '3',
            title: 'Regular patrol',
            description: ""
        },
        {
            id: '4',
            title: 'Risk Assessment',
            description: ""
        },
        {
            id: '5',
            title: 'Law Enforcement Coordination',
            description: ""
        },
        {
            id: '6',
            title: 'System Maintenance',
            description: ""
        },
        {
            id: '7',
            title: 'Visitor Management',
            description: ""
        },
        {
            id: '8',
            title: 'Asset Protection',
            description: ""
        },
        {
            id: '9',
            title: 'Area Inspection',
            description: ""
        },
        {
            id: '10',
            title: 'Perimeter Patrol',
            description: ""
        }
    ]
}

export const getIncidentTypologiesStub = (): IncidentTypology[] => {
    return [
        {
            severity: 0,
            createdAt: 0,
            incidentId: 'IncidentId1',
            isActive: true,
            incidentName: "Incident 1",
        },
        {
            severity: 1,
            createdAt: 0,
            incidentId: 'IncidentId2',
            isActive: true,
            incidentName: "Incident 2",
        },
        {
            severity: 2,
            createdAt: 0,
            incidentId: 'IncidentId3',
            isActive: false,
            incidentName: "Incident 3",
        },
        {
            severity: 3,
            createdAt: 0,
            incidentId: 'IncidentId4',
            isActive: false,
            incidentName: "Incident 4",
        },
        {
            severity: 4,
            createdAt: 0,
            incidentId: 'IncidentId5',
            isActive: true,
            incidentName: "Incident 5",
        },
    ]
}