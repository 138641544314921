import {
    getCenterNotifications,
    getManagerCenterNotifications,
    getNotReadNotificationCount
} from "../backend/api/notifications/notifications.apis";
import {Notification} from "../../models/notification.interface";

import {Communication} from "../../models/communication.interface";
import {
    countCommunicationsByCategory,
    getClientCommunicationArchive,
    getCommunicationArchive,
    getGroupMembers,
    notReadCommunicationsCounter,
    notReadManagerCommunicationsCounter
} from "../backend/api/communications/communications.apis";
import {Goal} from "../../models/goal.interface";
import {
    getClientGoals,
    getClientMonthlyGoals,
    getProviderGoals,
    getProviderMonthlyGoals
} from "../backend/api/client/client.apis";
import {Supervisor} from "../../models/supervisor.interface";
import {Operator} from "../../models/operator.interface";
import {
    getAvailableWorkersBetweenDates,
    getEmployeeWorkReports,
    getEndShiftReports,
    getIncidentsNotificationReports,
    getMonthShifts,
    getPendingRequests,
    getShiftsBetweenDates,
    getShiftsWeekByDate,
    getShiftsWeekStats,
    getUserLeaves,
    getWeekShifts,
    getWorkersByDate
} from "../backend/api/shifts/shifts.apis";
import {MonthShiftsRequest} from "../backend/api/shifts/requests/month-shifts.request";
import {ShiftCalendar} from "../backend/api/shifts/models/shift-calendar.interface";
import {UserRole} from "../../models/enums/user-role.enum";
import {UserEntity} from "../../models/entities/user-entity.interface";
import {PendingRequest} from "../../models/pending-request.interface";
import {GroupMember} from "../../models/group-member.interface";
import {GroupMembersRequest} from "../backend/api/communications/requests/group-members.request";
import {getUserData, getUserProfile} from "../backend/api/general/general.apis";
import {LeaveRequest} from "../../models/leave-request.interface";
import {Claim} from "../../models/claim.interface";
import {Employee} from "../../models/employee.interface";
import {getClaimsByEmployee, getClaimsHistory, getHRClaimsHistory} from "../backend/api/claims/claims.apis";
import {ClaimsHistoryRequest} from "../backend/api/claims/requests/claims-history.request";
import {getEmployeesList, getTerminatedEmployeesList, getWorkReportsList} from "../backend/api/hr/hr.apis";
import * as MapperService from "./mapper.service";
import {UploadedDocument} from "../../models/uploaded-document.interface";
import {getUserFiles} from "../backend/api/documents/documents.apis";
import {UserFilesRequest} from "../backend/api/documents/requests/user-files.request";
import {WorkReport} from "../../models/work-reports.interface";
import {EmployeeWorkReportsRequest} from "../backend/api/shifts/requests/employee-work-reports.request";
import * as Stubs from "./stub-provider.service"
import {randomInteger} from "../../utils/generate.utils";
import {Shift} from "../../models/shift.interface";
import {GetCommunicationArchiveRequest} from "../backend/api/communications/requests/get-communication-archive.request";
import {
    CountCommunicationsByCategoryRequest
} from "../backend/api/communications/requests/count-communications-by-category.request";
import {ClaimsByEmployeeRequest} from "../backend/api/claims/requests/claims-by-employee.request";
import {
    getIncidentsGraphByEmployee,
    getIncidentsTypologies,
    getTasksGraphByEmployee
} from "../backend/api/graphs/graphs.apis";
import {TasksGraphByEmployeeRequest} from "../backend/api/graphs/requests/tasks-graph-by-employee.request";
import {IncidentsGraphByEmployeeRequest} from "../backend/api/graphs/requests/incidents-graph-by-employee.request";
import {
    ManagerNotificationCenterRequest
} from "../backend/api/notifications/requests/manager-notification-center.request";
import {
    NotReadNotificationsCountRequest
} from "../backend/api/notifications/requests/not-read-notifications-counter.request";
import {PendingRequestsRequest} from "../backend/api/shifts/requests/pending-requests.request";
import {PendingRequestType} from "../../models/enums/pending-request-type.enum";
import {UserData} from "../backend/shared/user-data.interface";
import {SupervisorsOnShiftByDayRequest} from "../backend/api/tasks/requests/supervisors-on-shift-by-day.request";
import {getOperatorsOnShiftByDay, getSupervisorsOnShiftByDay, getTaskTemplates} from "../backend/api/tasks/tasks.apis";
import {IncidentNotificationReportsRequest} from "../backend/api/shifts/requests/incident-notification-reports.request";
import {EndShiftReportsRequest} from "../backend/api/shifts/requests/end-shift-reports.request";
import {OperatorsOnShiftByDayRequest} from "../backend/api/tasks/requests/operators-on-shift-by-day.request";
import {ShiftsWeekStatsRequest} from "../backend/api/shifts/requests/shifts-week-stats.request";
import {ShiftsWeekStatsResponse} from "../backend/api/shifts/responses/shifts-week-stats.response";
import {ShiftsWeekByDateRequest} from "../backend/api/shifts/requests/shifts-week-by-date.request";
import {WorkersByDateRequest} from "../backend/api/shifts/requests/workers-by-date.request";
import {
    AvailableWorkersBetweenDatesRequest
} from "../backend/api/shifts/requests/available-workers-between-dates.request";
import {ShiftsBetweenDatesRequest} from "../backend/api/shifts/requests/shifts-between-dates.request";
import {HrClaimsHistoryRequest} from "../backend/api/claims/requests/hr-claims-history.request";
import {UserProfileRequest} from "../backend/api/general/requests/user-profile.request";
import {User} from "../../models/user.interface";
import {TaskPreset} from "../../models/task-preset";
import {ArchiveCommunication} from "../backend/api/communications/responses/get-communications.response";
import {ClientMonthlyGoalsRequest} from "../backend/api/client/requests/client-monthly-goals.request";
import {ProviderMonthlyGoalsRequest} from "../backend/api/client/requests/provider-monthly-goals.request";
import {UserLeavesRequest} from "../backend/api/shifts/requests/user-leaves.request";
import {IncidentTypology} from "../../models/incident-typology.interface";


const useStub = process.env.REACT_APP_USE_STUBS === 'true'

export const retrieveUser = async (userId: string): Promise<UserEntity> => {
    if (useStub) {
        return Stubs.getUserEntityStub()
    }
    return await getUserData({userId}).then(response => {
        const userData = response.userData
        return MapperService.mapUserDataToUserEntity(userData)
    }).catch(error => {
        console.error(error)
        return {} as UserEntity
    })
}

export const retrieveUserData = async (request: UserProfileRequest): Promise<UserData> => {
    if (useStub) {
        return Stubs.getUserDataStub()
    }
    return await getUserProfile(request)
        .then(response => response.user)
        .catch(error => {
            console.error(error)
            return {} as UserData
        })
}

export const retrieveEmployee = async (request: UserProfileRequest): Promise<Employee> => {
    if (useStub) {
        return Stubs.getEmployeeStub()
    }
    return await getUserProfile(request).then(response => {
        return MapperService.mapUserDataToEmployee(response.user)
    }).catch(error => {
        console.error(error)
        return {} as Employee
    })
}


export const retrieveCenterNotifications = async (): Promise<Notification[]> => {
    if (useStub) {
        return Stubs.getNotificationsStub()
    }
    return await getCenterNotifications().then(response => {
        return response.notifications.map(notification => {
            return MapperService.mapCenterNotificationToNotification(notification)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveManagerCenterNotifications = async (request: ManagerNotificationCenterRequest): Promise<Notification[]> => {
    if (useStub) {
        return Stubs.getNotificationsStub()
    }
    return await getManagerCenterNotifications(request).then(response => {
        return response.notifications.map(notification => {
            return MapperService.mapCenterNotificationToNotification(notification)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveCommunicationArchive = async (request: GetCommunicationArchiveRequest, userLevel?: UserRole): Promise<Communication[]> => {
    if (useStub) {
        return Stubs.getCommunicationArchiveStub()
    }
    try {
        let notifications: ArchiveCommunication[]
        let response
        if (userLevel && userLevel === UserRole.Client) {
            response = await getClientCommunicationArchive(request)
        } else {
            response = await getCommunicationArchive(request)
        }
        notifications = response.notifications
        return notifications.map(archiveCommunication => {
            return MapperService.mapArchiveCommunicationToCommunication(archiveCommunication)
        })
    } catch (error) {
        console.error(error)
        return []
    }
}

export const retrieveClientGoals = async (): Promise<Goal[]> => {
    if (useStub) {
        return Stubs.getGoalsStub()
    }
    return await getClientGoals().then(response => {
        return response.goals.map(clientGoal => {
            return MapperService.mapClientGoalToGoal(clientGoal)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveClientMonthlyGoals = async (request: ClientMonthlyGoalsRequest): Promise<Goal[]> => {
    if (useStub) {
        return Stubs.getGoalsStub()
    }
    return await getClientMonthlyGoals(request).then(response => {
        return response.goals.map(clientGoal => {
            return MapperService.mapClientGoalToGoal(clientGoal)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveProviderGoals = async (): Promise<Goal[]> => {
    if (useStub) {
        return Stubs.getGoalsStub()
    }
    return await getProviderGoals().then(response => {
        return response.goals.map(providerGoal => {
            return MapperService.mapProviderGoalToGoal(providerGoal)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveMonthlyProviderGoals = async (request: ProviderMonthlyGoalsRequest): Promise<Goal[]> => {
    if (useStub) {
        return Stubs.getGoalsStub()
    }
    return await getProviderMonthlyGoals(request).then(response => {
        return response.goals.map(providerGoal => {
            return MapperService.mapProviderGoalToGoal(providerGoal)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

const filterEmployeesOnShift = async (request: MonthShiftsRequest, roles: UserRole[] = []): Promise<Employee[]> => {
    if (!roles || roles.length === 0) {
        roles = [UserRole.Operator, UserRole.Supervisor]
    }
    return await getMonthShifts(request).then(response => {
        const monthCalendar: ShiftCalendar[] = response.monthCalendar
        const employees: Employee[] = []

        monthCalendar.forEach(calendar => {
            calendar.shifts.forEach(shift => {
                const shiftUser: UserData = shift.user as UserData
                if (roles.includes(shiftUser.level)) {
                    const employee = MapperService.mapUserDataToEmployee(shiftUser)
                    employees.push(employee)
                }
            })
        })
        return employees
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveEmployees = async (): Promise<Employee[]> => {
    if (useStub) {
        return Stubs.getEmployeesStub()
    }
    return await getEmployeesList().then(response => {
        return response.employees.map(employee => {
            return MapperService.mapEmployeeProfileToEmployee(employee)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveTerminatedEmployees = async (startDate: string, endDate: string): Promise<Employee[]> => {
    if (useStub) {
        return Stubs.getTerminatedEmployeesStub()
    }
    return await getTerminatedEmployeesList(startDate, endDate).then(response => {
        return response.terminated_employees.map(employee => {
            return MapperService.mapTerminatedEmployeeProfileToEmployee(employee)
        })
    })
}

export const retrieveEmployeesOnShift = async (request: MonthShiftsRequest): Promise<Employee[]> => {
    if (useStub) {
        return Stubs.getEmployeesOnShiftStub()
    }
    return await filterEmployeesOnShift(request)
}

export const retrieveSupervisorsOnShift = async (request: MonthShiftsRequest): Promise<Supervisor[]> => {
    if (useStub) {
        return Stubs.getSupervisorsOnShiftStub()
    }
    return await filterEmployeesOnShift(request, [UserRole.Supervisor]) as unknown as Promise<Supervisor[]>
}

export const retrieveOperatorsOnShift = async (request: MonthShiftsRequest): Promise<Operator[]> => {
    if (useStub) {
        return Stubs.getOperatorsOnShiftStub()
    }
    return await filterEmployeesOnShift(request, [UserRole.Operator]) as unknown as Promise<Operator[]>
}

export const retrieveSupervisorsOnShiftByDay = async (request: SupervisorsOnShiftByDayRequest): Promise<Supervisor[]> => {
    if (useStub) {
        return Stubs.getSupervisorsOnShiftStub()
    }
    return await getSupervisorsOnShiftByDay(request).then(response => {
        return response.data.workers.supervisors.map(employee => {
            return MapperService.mapSupervisorWithShiftsToSupervisor(employee)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveOperatorsOnShiftByDay = async (request: OperatorsOnShiftByDayRequest): Promise<Operator[]> => {
    if (useStub) {
        return Stubs.getOperatorsOnShiftStub()
    }
    return await getOperatorsOnShiftByDay(request).then(response => {
        return response.data.workers.operators.map(employee => {
            return MapperService.mapOperatorWithShiftsToOperator(employee)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveWeekShifts = async (): Promise<Shift[]> => {
    if (useStub) {
        return Stubs.getShiftStub()
    }
    const shifts: Shift[] = []
    return await getWeekShifts().then(response => {
        response.weekCalendar.forEach(shiftCalendar => {
            shiftCalendar.shifts.forEach(shiftItem => {
                const shift = MapperService.mapShiftItemToShift(shiftItem)
                shifts.push(shift)
            })
        })
        return shifts
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrievePendingRequests = async (request: PendingRequestsRequest): Promise<PendingRequest[]> => {
    if (useStub) {
        return Stubs.getPendingRequestsStub()
    }
    const pendingRequests: PendingRequest[] = []
    return await getPendingRequests(request).then(async response => {
        for (const pendingRequest of response.data.requests) {
            const pending = MapperService.mapEmployeePendingRequestToPendingRequest(pendingRequest, PendingRequestType.Leaves)
            pendingRequests.push(pending)
        }
        for (const pendingRequest of response.data.claims) {
            const pending = MapperService.mapEmployeePendingRequestToPendingRequest(pendingRequest, PendingRequestType.Claims)
            pendingRequests.push(pending)
        }
        return pendingRequests
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveGroupMembers = async (request: GroupMembersRequest): Promise<GroupMember[]> => {
    if (useStub) {
        const supervisors = Stubs.getSupervisorsGroupMemberStub()
        const operators = Stubs.getOperatorsGroupMemberStub()
        return [...supervisors, ...operators]
    }

    return await getGroupMembers(request).then(response => {
        return response.users.map(member => {
            return MapperService.mapMemberToGroupMember(member, request.level)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveLeaveRequests = async (request: UserLeavesRequest): Promise<LeaveRequest[]> => {
    if (useStub) {
        return Stubs.getLeaveRequestsStub()
    }
    return getUserLeaves(request).then(response => {
        return response.data.map(leave => {
            return MapperService.mapUserLeaveToLeaveRequest(leave)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveTasksGraphByEmployee = async (request: TasksGraphByEmployeeRequest): Promise<any[]> => {
    if (useStub) {
        return []
    }
    return await getTasksGraphByEmployee(request).then(response => {
        // TODO: Add mapping
        return response.data
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveIncidentsGraphByEmployee = async (request: IncidentsGraphByEmployeeRequest): Promise<any[]> => {
    if (useStub) {
        return []
    }
    return await getIncidentsGraphByEmployee(request).then(response => {
        // TODO: Add mapping
        return response.data
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveClaimsByEmployee = async (request: ClaimsByEmployeeRequest): Promise<Claim[]> => {
    if (useStub) {
        return Stubs.getClaimsStub()
    }
    return await getClaimsByEmployee(request).then(response => {
        // TODO: Check this on the backend
        return response.claims.map(claim => {
            return MapperService.mapEmployeeClaimToClaim(claim)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveHRClaimsHistory = async (request: HrClaimsHistoryRequest): Promise<Claim[]> => {
    if (useStub) {
        return Stubs.getClaimsStub()
    }
    return await getHRClaimsHistory(request).then(response => {
        return response.claims.map(claim => {
            return MapperService.mapHRClaimToClaim(claim)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveClaimsHistory = async (request: ClaimsHistoryRequest): Promise<Claim[]> => {
    if (useStub) {
        return Stubs.getClaimsStub()
    }
    return await getClaimsHistory(request).then(response => {
        // TODO: Check this on the backend
        return response.data.map(claimHistoryItem => {
            return MapperService.mapClaimHistoryItemToClaim(claimHistoryItem)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveUploadedDocuments = async (request: UserFilesRequest): Promise<UploadedDocument[]> => {
    if (useStub) {
        return Stubs.getUploadedDocumentsStub()
    }

    return await getUserFiles(request).then(response => {
        return response.content.map(userFile => {
            return MapperService.mapRealFileToUploadedDocument(userFile)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveWorkReports = async (): Promise<WorkReport[]> => {
    if (useStub) {
        return Stubs.getWorkReportsStub()
    }
    return await getWorkReportsList().then(response => {
        return response.reports.map(report => {
            return MapperService.mapWorkReportItemToWorkReport(report)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveUserWorkReports = async (request: EmployeeWorkReportsRequest): Promise<WorkReport[]> => {
    if (useStub) {
        return Stubs.getWorkReportsStub()
    }

    return await getEmployeeWorkReports(request).then(response => {
        return response.shifts.map(report => {
            return MapperService.mapEmployeeWorkReportToWorkReport(report)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveIncidentsNotificationReports = async (request: IncidentNotificationReportsRequest): Promise<WorkReport[]> => {
    if (useStub) {
        return Stubs.getWorkReportsStub()
    }

    return await getIncidentsNotificationReports(request).then(response => {
        return response.data.map(report => {
            return MapperService.mapIncidentsNotificationReportsToWorkReport(report)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveEndShiftReports = async (request: EndShiftReportsRequest): Promise<WorkReport[]> => {
    if (useStub) {
        return Stubs.getWorkReportsStub()
    }

    return await getEndShiftReports(request).then(response => {
        return response.shifts.map(report => {
            return MapperService.mapEmployeeWorkReportToWorkReport(report)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveIncidentsTypologies = async (): Promise<IncidentTypology[]> => {
    if (useStub) {
        return Stubs.getIncidentTypologiesStub()
    }

    return await getIncidentsTypologies().then(response => {
        return response.incidents.map(incident => {
            return MapperService.mapRawIncidentTypologyToIncidentTypology(incident)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveCommunicationsCounterByCategory = async (request: CountCommunicationsByCategoryRequest): Promise<number> => {
    if (useStub) {
        return randomInteger(0, 40)
    }
    return await countCommunicationsByCategory(request).then(response => {
        return response.notifications
    }).catch(error => {
        console.error(error)
        return 0
    })
}

export const retrieveNotReadCommunicationsCounter = async (): Promise<number> => {
    if (useStub) {
        return randomInteger(0, 40)
    }
    return await notReadCommunicationsCounter().then(response => {
        return response.notifications
    }).catch(error => {
        console.error(error)
        return 0
    })
}

export const retrieveNotReadManagerCommunicationsCounter = async (): Promise<number> => {
    if (useStub) {
        return randomInteger(0, 40)
    }
    return await notReadManagerCommunicationsCounter().then(response => {
        return response.notifications
    }).catch(error => {
        console.error(error)
        return 0
    })
}

export const retrieveNotReadNotificationCount = async (request: NotReadNotificationsCountRequest): Promise<number> => {
    if (useStub) {
        return randomInteger(0, 40)
    }
    return await getNotReadNotificationCount(request).then(response => {
        return response.notRead
    }).catch(error => {
        console.error(error)
        return 0
    })
}

export const retrieveShiftsWeekStats = async (request: ShiftsWeekStatsRequest): Promise<ShiftsWeekStatsResponse> => {
    if (useStub) {
        return Stubs.getShiftsWeekStatsStub()
    }
    return await getShiftsWeekStats(request)
}

export const retrieveShiftsWeekByDate = async (request: ShiftsWeekByDateRequest): Promise<Shift[]> => {
    if (useStub) {
        return Stubs.getShiftStub()
    }
    return await getShiftsWeekByDate(request).then(response => {
        const shifts: Shift[] = []
        response.data.forEach(shiftsWeekData => {
            const dayShifts = MapperService.mapWeekCalendarDataToShifts(shiftsWeekData.calendar)
            shifts.push(...dayShifts)
        })
        return shifts
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveWorkersByDate = async (request: WorkersByDateRequest): Promise<Employee[]> => {
    if (useStub) {
        return Stubs.getEmployeesStub()
    }
    return await getWorkersByDate(request).then(response => {
        return MapperService.mapShiftsWeekDataToEmployees(response.data)
    }).catch(error => {
        console.error(error)
        return []
    })
}


export const retrieveAvailableWorkersBetweenDates = async (request: AvailableWorkersBetweenDatesRequest): Promise<Employee[]> => {
    if (useStub) {
        return Stubs.getEmployeesStub()
    }
    return await getAvailableWorkersBetweenDates(request).then(response => {
        return MapperService.mapAvailableUsersToEmployees(response.data.availableUsers)
    }).catch(error => {
        console.error(error)
        return []
    })
}

export const retrieveShiftsEmployeesByDay = async (request: ShiftsBetweenDatesRequest): Promise<Array<Supervisor | Operator>> => {
    return await getShiftsBetweenDates(request).then(response => {
        return MapperService.mapShiftUsersToSupervisorsAndOperators(response.users)
    }).catch(error => {
        console.error(error)
        return []
    })

}

export const retrieveUserProfile = async (request: UserProfileRequest): Promise<User> => {
    if (useStub) {
        return Stubs.getUserStub()
    }
    return await getUserProfile(request).then(response => {
        return MapperService.mapUserDataToUser(response.user)
    }).catch(error => {
        console.error(error)
        return {} as User
    })
}

export const retrieveTasksPreset = async (): Promise<TaskPreset[]> => {
    if (useStub) {
        return Stubs.getTaskPresetStub()
    }
    return await getTaskTemplates().then(response => {
        const templates = response.templates.filter(template => template.isActive)
        return templates.map(template => {
            return MapperService.mapTemplateToTaskPreset(template)
        })
    }).catch(error => {
        console.error(error)
        return []
    })
}