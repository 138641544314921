import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import PrivateRoute from './components/common/PrivateRoute';
import './App.css';
import LoginPage from './pages/common/LoginPage';
import {UserBar} from "./components/common/UserBar";
import {UserProvider} from "./contexts/OperatorContextProvider";
import ForgotPasswordPage from './pages/common/ForgotPasswordPage';
import './i18n';
import OperatorLayout from './layouts/OperatorLayout';
import SupervisorLayout from './layouts/SupervisorLayout';
import HRLayout from './layouts/HRLayout';
import ProviderLayout from './layouts/ProviderLayout';
import ClientLayout from './layouts/ClientLayout';
import {useToastContext} from "./contexts/ToastContext";
import {UserRole} from "./models/enums/user-role.enum";
import NotificationCheckPage from "./pages/common/NotificationCheckPage";
import {onMessage} from "@firebase/messaging";
import {messaging} from "./services/push-notification/firebaseConfig";
import ToastContent from "./components/common/ToastContent";
import {ERROR} from "./utils/constants.utils";
import RecoverCredentialPage from './pages/common/RecoverCredentialPage';

function App() {
    const {showToast} = useToastContext();

    useEffect(() => {
        onMessage(messaging, (payload: any) => {
            const notificationData = payload.data;
            const severity = ERROR as any;
            const summary = notificationData?.title;
            const detail = notificationData?.body;
            const image = notificationData?.image;
            const notification = {
                severity, summary, detail, sticky: true,
                content: <ToastContent severity={severity} summary={summary} detail={detail} image={image}/>
            };
            showToast(notification);
        });
    }, [showToast]);

    return (
        <Router>
            <UserProvider>
                <UserBar>
                    <div className="App">
                        <Routes>
                            <Route path="/" element={<LoginPage/>}/>

                            <Route element={<PrivateRoute allowedRoles={[UserRole.Operator]}/>}>
                                <Route path="/operator/*" element={<OperatorLayout/>}/>
                            </Route>

                            <Route element={<PrivateRoute allowedRoles={[UserRole.Supervisor]}/>}>
                                <Route path="/supervisor/*" element={<SupervisorLayout/>}/>
                            </Route>

                            <Route element={<PrivateRoute allowedRoles={[UserRole.HR]}/>}>
                                <Route path="/hr/*" element={<HRLayout/>}/>
                            </Route>

                            <Route element={<PrivateRoute allowedRoles={[UserRole.Provider]}/>}>
                                <Route path="/hses_provider/*" element={<ProviderLayout/>}/>
                            </Route>

                            <Route element={<PrivateRoute allowedRoles={[UserRole.Client]}/>}>
                                <Route path="/hses_client/*" element={<ClientLayout/>}/>
                            </Route>

                            <Route path="/forgotPassword" element={<ForgotPasswordPage/>}/>
                            <Route path="/notification-check" element={<NotificationCheckPage/>}/>

                            <Route path="/recovery/:userId/:recoveryCode" element={<RecoverCredentialPage/>}/>
                        </Routes>
                    </div>
                </UserBar>
            </UserProvider>
        </Router>
    );
}

export default App;
