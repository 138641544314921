import React from "react";
import CalendarComponent from "../../components/calendars/shift-calendar/ShiftCalendar";
import {useTranslation} from "react-i18next";


const CalendarPage: React.FC = () => {
  const { t } = useTranslation();
  return (
      <div className="page-container">
          <div className="page-title">
        <h2>{t("calendarPage.titleCalendar")}</h2>
      </div>
      <div className="flex space-x-4 mb-2 mt-6">
        <div className="w-6/12 ">
          <p className="title-table">{t("calendarPage.shiftWeek")}</p>
        </div>
        <div className="w-5/12 ">
          <p className="title-table">
          {t("calendarPage.textRequest")}
          </p>
        </div>
      </div>
      <CalendarComponent />
    </div>
  );
};

export default CalendarPage;
