import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import UtilityCard from "./UtilityCard";
import {useNavigate} from "react-router-dom";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {DOCUMENTS} from "../../utils/constants.utils";
import {UploadedDocument} from "../../models/uploaded-document.interface";
import {FiDownload} from "react-icons/fi";
import {getIconByFileName} from "../../utils/icons.utils";
import {retrieveUploadedDocuments} from "../../services/data-manager/data-manager.service";
import {shortDocumentName} from "../../utils/text-formatting.utils";
import {downloadDocument} from "../../services/document-manager/document-manager.service";

interface UserDocumentsProps {
    userId?: string;
}

const UserDocuments: React.FC<UserDocumentsProps> = ({userId}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {level} = useUserContext();

    const [documents, setDocuments] = useState<UploadedDocument[]>([]);

    const menuOptions = [
        {
            label: t('moreInfo'),
            onClick: () => {
                navigate(`/${level}/${DOCUMENTS}`, {
                    state: {userId}
                });
            }
        }
    ]


    useEffect(() => {
        if (userId) {
            retrieveUploadedDocuments({userId}).then((documents) => {
                setDocuments(documents);
            })
        }
    }, [userId])


    return (
        <UtilityCard title={t('userDocuments.title')} menuOptions={menuOptions}
                     content={
                         <div className="space-y-1">
                             {
                                 documents.map((document, index) => (
                                     <div key={index} className='primary-container p-1 '>
                                         <div className="flex w-full items-center justify-between gap-3">

                                             <div>
                                                 {getIconByFileName(document.name)({
                                                     size: 24,
                                                     color: "burlywood",
                                                     style: {marginRight: "0.6rem"}
                                                 })}{" "}
                                             </div>
                                             <div className="w-full">
                                                 {shortDocumentName(document.name)}
                                             </div>
                                             <div className="whitespace-no-wrap">
                                                 {document.date.toLocaleDateString()}
                                             </div>

                                             <div className="whitespace-no-wrap">
                                                 {document.category}
                                             </div>

                                             <div className="cursor-pointer"
                                                  onClick={() => downloadDocument(document)}
                                             >
                                                 <FiDownload size={24}/>
                                             </div>
                                         </div>
                                     </div>
                                 ))
                             }
                         </div>
                     }
        />
    )
}

export default UserDocuments;