import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Employee} from "../../models/employee.interface";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";
import {Calendar} from "primereact/calendar";
import {FormEvent} from "primereact/ts-helpers";
import {endOfDay, midnight} from "../../utils/date.utils";
import GeoFilterSelector from "../map/GeoSearch/GeoFilterSelector";
import {CircleArea} from "../../models/geo.interface";

interface FiltersSelectorProps {
    showDate?: boolean;
    showTime?: boolean;
    showSeverity?: boolean;
    showTypology?: boolean;
    showOperators?: boolean;
    showGps?: boolean;

    onDateChange?: (period: string) => void;
    onStartTimeChange?: (date: Date) => void;
    onEndTimeChange?: (date: Date) => void;
    onSeveritiesChange?: (severities: string[]) => void;
    onTypologiesChange?: (typologies: string[]) => void;
    onOperatorsChange?: (operatorsIds: string[]) => void;
    onGpsChange?: (location: CircleArea | null) => void;

    typologies?: string[];
    operators?: Employee[];
}

const FiltersSelector: React.FC<FiltersSelectorProps> = (props) => {

    const { t } = useTranslation();

    const filters = {
        date: {
            title: t("filters.date.title"),
            options: [
                t("filters.date.today"),
                t("filters.date.week"),
                t("filters.date.month"),
                t("filters.date.year")
            ]
        },
        time: {
            title: t("filters.time.title"),
            from: t("filters.time.from"),
            to: t("filters.time.to")
        },
        severities: {
            title: t("filters.severities.title"),
            options: [
                t("filters.severities.minor"),
                t("filters.severities.medium"),
                t("filters.severities.high")
            ]
        },
        typology: {
            title: t("filters.typology.title"),
        },
        operators: {
            title: t("filters.operators.title"),
        }
    }

    const [showTimeOptions, setShowTimeOptions] = useState<boolean>(false);
    const [selectedDates, setSelectedDates] = useState<Date[]>([]);
    const [selectedStartTime, setSelectedStartTime] = useState<Date>(midnight());
    const [selectedEndTime, setSelectedEndTime] = useState<Date>(endOfDay());
    const [selectedSeverities, setSelectedSeverities] = useState<string[]>();
    const [selectedTypologies, setSelectedTypologies] = useState<string[]>();
    const [selectedOperators, setSelectedOperators] = useState<Employee[]>();

    const timeSelector = useRef<HTMLDivElement>(null);
    const startTimePicker = useRef<Calendar>(null);
    const endTimePicker = useRef<Calendar>(null);

    const handleDateChange = (event: MultiSelectChangeEvent) => {
        const value = event.value;
        setSelectedDates(value);
        const period = value[0];
        if (props.onDateChange) {
            props.onDateChange(period);
        }
    }

    const handleStartTimeChange = (event: FormEvent<(Date | null)>) => {
        if (!event.target.value) {
            return;
        }
        const value = event.target.value;
        const date = new Date(value);
        setSelectedStartTime(date);
        if (props.onStartTimeChange) {
            props.onStartTimeChange(date);
        }
    }
    const handleEndTimeChange = (event: FormEvent<(Date | null)>) => {
        if (!event.target.value) {
            return;
        }
        const value = event.target.value;
        const date = new Date(value);
        setSelectedEndTime(date);
        if (props.onEndTimeChange) {
            props.onEndTimeChange(date);
        }
    }

    const handleSeverityChange = (event: MultiSelectChangeEvent) => {
        const value = event.value;
        setSelectedSeverities(value);
        if (props.onSeveritiesChange) {
            props.onSeveritiesChange(value);
        }
    }

    const handleTypologyChange = (event: MultiSelectChangeEvent) => {
        const value = event.value;
        setSelectedTypologies(value);
        if (props.onTypologiesChange) {
            props.onTypologiesChange(value);
        }
    }

    const handleOperatorsChange = (event: MultiSelectChangeEvent) => {
        const value = event.value;
        setSelectedOperators(value);
        if (props.onOperatorsChange) {
            props.onOperatorsChange(value.map((operator: Employee) => operator.id));
        }
    }

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (
            timeSelector.current && !timeSelector.current.contains(event.target as Node) &&
            startTimePicker.current && !startTimePicker.current.getOverlay()?.contains(event.target as Node) &&
            endTimePicker.current && !endTimePicker.current.getOverlay()?.contains(event.target as Node)
        ) {
            setShowTimeOptions(false);
        }
    }, [timeSelector]);

    const handleGpsChange = (location: CircleArea | null) => {
        if (props.onGpsChange) {
            props.onGpsChange(location);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    const selectedEmployeeItem = (option: Employee) => {
        return (
            <div className="flex items-center justify-center">
                <div>{option?.name} {option?.surname}</div>
            </div>
        );
    };

    return (
        <div className="options-selector flex flex-row">
            {
                props.showDate &&
                <MultiSelect className="w-36 max-h-10 option-button-item"
                    style={{ maxWidth: "6rem" }}
                    showSelectAll={false}
                    selectionLimit={1}
                    placeholder={filters.date.title}
                    value={selectedDates}
                    options={filters.date.options}
                    onChange={handleDateChange}
                />
            }
            {
                props.showTime &&
                <div className="option-button-item w-24" ref={timeSelector}>
                    <button className="w-full h-4 p-0 m-0 text-left"
                        onClick={() => setShowTimeOptions(!showTimeOptions)}>
                        {filters.time.title}
                    </button>
                    {
                        showTimeOptions &&
                        <div className="dropdown-content translate-y-2 -translate-x-6">
                            <div className="flex flex-col p-3 space-y-3">
                                <Calendar className="w-full" ref={startTimePicker}
                                    placeholder={filters.time.from}
                                    timeOnly selectionMode="single"
                                    value={selectedStartTime}
                                    onChange={handleStartTimeChange}
                                />
                                <Calendar className="w-full" ref={endTimePicker}
                                    placeholder={filters.time.to}
                                    timeOnly selectionMode="single"
                                    value={selectedEndTime}
                                    onChange={handleEndTimeChange}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
            {
                props.showSeverity &&
                <MultiSelect className="w-36 max-h-10 option-button-item"
                    placeholder={filters.severities.title}
                    value={selectedSeverities}
                    options={filters.severities.options}
                    onChange={handleSeverityChange}
                />
            }
            {
                props.showTypology &&
                <MultiSelect className="w-36 max-h-10 option-button-item"
                    style={{ maxWidth: "6rem" }}
                    placeholder={filters.typology.title}
                    value={selectedTypologies}
                    options={props.typologies}
                    onChange={handleTypologyChange}
                />
            }
            {
                props.showOperators &&
                <MultiSelect className="w-36 h-10 option-button-item"
                    style={{ maxWidth: "6rem" }}
                    placeholder={filters.operators.title}
                    value={selectedOperators}
                    options={props.operators}
                    optionLabel="id"
                    onChange={handleOperatorsChange}
                    itemTemplate={selectedEmployeeItem}
                    selectedItemTemplate={selectedEmployeeItem}
                />
            }
            {
                props.showGps &&
                <GeoFilterSelector
                    onCircleAreaSelected={handleGpsChange}
                />
            }
        </div>
    )
}

export default FiltersSelector;