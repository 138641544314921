import React from "react";
import { useForgotStepper } from "./ForgotPasswordStepperContext";
import { ForgotLevelStep } from "./ForgotLevelStep";
import { ForgotEmailStep } from "./ForgotEmailStep";
import { ForgotFinalStep } from "./ForgotFinalStep";
import { UserRole } from "../../../models/enums/user-role.enum";
import { ForgotPhoneStep } from "./ForgotPhoneStep";

export const ForgotStepper = () => {
    const { step, level } = useForgotStepper();
    
    return <div>
        {step === 0 && <ForgotLevelStep />}

        {step === 1 && (level === UserRole.HR || level === UserRole.Client || level === UserRole.Provider) && <ForgotEmailStep />}
        {step === 1 && (level === UserRole.Operator || level === UserRole.Supervisor) && <ForgotPhoneStep />}

        {step === 2 && <ForgotFinalStep />}
    </div>
};