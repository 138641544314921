import React, {useCallback, useEffect, useState} from "react";
import FilterButton from "../graphs/FilterButton";
import {useTranslation} from "react-i18next";
import {WorkReport} from "../../models/work-reports.interface";
import {FiDownload} from "react-icons/fi";
import {Employee} from "../../models/employee.interface";
import {Severity} from "../../models/enums/severity.enum";
import {FaAngleDown} from "react-icons/fa6";
import {BlobProvider} from "@react-pdf/renderer";
import IncidentNotificationReportsSummary from "../pdf/summaries/IncidentNotificationReportsSummary";

interface IncidentNotificationReportsProps {
    employee: Employee;
    reports: WorkReport[];
}

const IncidentNotificationReports: React.FC<IncidentNotificationReportsProps> = ({employee, reports}) => {

    const {t} = useTranslation();

    const TODAY_FILTER = t("incidentNotificationReports.filters.today");
    const THIS_WEEK_FILTER = t("incidentNotificationReports.filters.week");
    const THIS_MONTH_FILTER = t("incidentNotificationReports.filters.month");
    const THIS_YEAR_FILTER = t("incidentNotificationReports.filters.year");

    const STATUS = t("incidentNotificationReports.statuses.status");
    const CONFIRMED = t("incidentNotificationReports.statuses.confirmed");
    const DECLINED = t("incidentNotificationReports.statuses.declined");
    const PENDING = t("incidentNotificationReports.statuses.pending");

    const SEVERITY = t("incidentNotificationReports.severities.severity");
    const MINOR = t("incidentNotificationReports.severities.minor");
    const MEDIUM = t("incidentNotificationReports.severities.medium");
    const HIGH = t("incidentNotificationReports.severities.high");

    const NONE = t("incidentNotificationReports.none");

    const NO_FILTER = t("incidentNotificationReports.filters.all");

    const filters = [
        TODAY_FILTER,
        THIS_WEEK_FILTER,
        THIS_MONTH_FILTER,
        THIS_YEAR_FILTER,
        STATUS,
        SEVERITY,
        NO_FILTER
    ]

    const statuses = [NONE, CONFIRMED, DECLINED, PENDING];
    const severities = [NONE, MINOR, MEDIUM, HIGH];

    const [employeeProfile, setEmployeeProfile] = useState<Employee>({} as Employee);
    const [noFilter, setNoFilter] = useState<boolean>(false);
    const [dateFilter, setDateFilter] = useState<string>(TODAY_FILTER);
    const [statusFilter, setStatusFilter] = useState<string | null>();
    const [severityFilter, setSeverityFilter] = useState<string | null>();

    const [showStatusDropdown, setShowStatusDropdown] = useState<boolean>(false);
    const [showSeverityDropdown, setShowSeverityDropdown] = useState<boolean>(false);

    const [workReports, setWorkReports] = useState<WorkReport[]>([]);
    const [filteredWorkReports, setFilteredWorkReports] = useState<WorkReport[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getSeverityColor = (severity: Severity) => {
        switch (severity) {
            case Severity.High:
                return 'bg-red-500';
            case Severity.Minor:
                return 'bg-green-500';
            default:
                return 'bg-yellow-500';
        }
    }

    const getStatusColor = (report: WorkReport) => {
        if (report.confirmed) {
            return 'bg-green-500';
        }
        if (report.declined) {
            return 'bg-red-500';
        }

        return 'bg-[var(--primary-color-400)]';
    }

    const filterWorkReports = useCallback(() => {
        setFilteredWorkReports(
            workReports.filter((report) => {
                if (noFilter) {
                    return true;
                }
                const today = new Date();
                switch (statusFilter) {
                    case CONFIRMED:
                        if (!report.confirmed) {
                            return false;
                        }
                        break;
                    case DECLINED:
                        if (!report.declined) {
                            return false;
                        }
                        break;
                    case PENDING:
                        if (report.confirmed || report.declined) {
                            return false;
                        }
                        break;
                    default:
                        break;
                }

                const reportSeverity = report.severity.toLowerCase();
                switch (severityFilter) {
                    case MINOR:
                        if (reportSeverity !== Severity.Minor) {
                            return false;
                        }
                        break;
                    case MEDIUM:
                        if (reportSeverity !== Severity.Medium) {
                            return false;
                        }
                        break;
                    case HIGH:
                        if (reportSeverity !== Severity.High) {
                            return false;
                        }
                        break;
                    default:
                        break;
                }

                switch (dateFilter) {
                    case TODAY_FILTER:
                        if (report.date.getDate() !== today.getDate()) {
                            return false;
                        }
                        break;
                    case THIS_WEEK_FILTER:
                        if (report.date.getDate() < today.getDate() - today.getDay()) {
                            return false;
                        }
                        break;
                    case THIS_MONTH_FILTER:
                        if (report.date.getMonth() !== today.getMonth()) {
                            return false;
                        }
                        break;
                    case THIS_YEAR_FILTER:
                        if (report.date.getFullYear() !== today.getFullYear()) {
                            return false;
                        }
                        break;
                    default:
                        return true;
                }
                return true;
            })
        );
    }, [CONFIRMED, DECLINED, HIGH, MEDIUM, MINOR, PENDING, THIS_MONTH_FILTER, THIS_WEEK_FILTER, THIS_YEAR_FILTER, TODAY_FILTER, dateFilter, noFilter, severityFilter, statusFilter, workReports]);

    useEffect(() => {
        setDateFilter(TODAY_FILTER);
        setWorkReports(reports);
        setEmployeeProfile(employee);
        filterWorkReports();
        setIsLoading(false);
    }, [TODAY_FILTER, employee, filterWorkReports, reports]);

    return (
        <div>
            <div className="flex my-8">
                <h2 className="font-2xl flex-grow">{t("incidentNotificationReports.title")}</h2>
                <div className="w-48 ">
                    {
                        !isLoading && filteredWorkReports && filteredWorkReports.length > 0 &&
                        <BlobProvider document={<IncidentNotificationReportsSummary reports={filteredWorkReports}/>}>
                            {({url, blob}) => (
                                url &&
                                <a href={url} target="_blank" className="primary-button uppercase bordered-button h-8"
                                   rel="noreferrer">
                                    <FiDownload size={20} className="mx-2"/>
                                    {t('incidentNotificationReports.downloadAll')}
                                </a>
                            )}
                        </BlobProvider>
                    }
                </div>
            </div>
            <div className="card-container">
                <div className="options-selector">
                    {filters.map((option, index) => (
                        <div key={index}>
                            <FilterButton
                                key={option}
                                label={
                                    <div className="flex justify-center items-center">
                                        {option}
                                        {
                                            (option === STATUS || option === SEVERITY) &&
                                            <FaAngleDown size={16}/>
                                        }
                                    </div>
                                }
                                isActive={
                                    (option === NO_FILTER && noFilter) ||
                                    ((!noFilter) && (
                                        (option === dateFilter) ||
                                        (option === STATUS && statusFilter !== NONE) ||
                                        (option === SEVERITY && severityFilter !== NONE)
                                    ))
                                }
                                onClick={() => {
                                    if (option === NO_FILTER) {
                                        setNoFilter(!noFilter);
                                    } else if (option === STATUS) {
                                        setShowStatusDropdown(!showStatusDropdown);
                                    } else if (option === SEVERITY) {
                                        setShowSeverityDropdown(!showSeverityDropdown);
                                    } else {
                                        setDateFilter(option);
                                    }
                                }}
                            />
                            {(option === THIS_YEAR_FILTER || option === SEVERITY) &&
                                <span className="vertical-separator ml-3"/>}
                            {option === STATUS && showStatusDropdown && (
                                <div className="dropdown-content -translate-x-4 min-h-36 max-w-28">
                                    {statuses && statuses.map(status => (
                                        <div key={status}
                                             className={`dropdown-item ${statusFilter === status ? 'bg-[var(--primary-color-300)] text-color-black' : ''}`}
                                             onClick={() => {
                                                 setStatusFilter(status)
                                                 setShowStatusDropdown(false)
                                             }}>
                                            {status}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {option === SEVERITY && showSeverityDropdown && (
                                <div className="dropdown-content -translate-x-4 min-h-36 max-w-28">
                                    {severities && severities.map(severity => (
                                        <div key={severity}
                                             className={`dropdown-item ${severityFilter === severity ? 'bg-[var(--primary-color-300)] text-color-black' : ''}`}
                                             onClick={() => {
                                                 setSeverityFilter(severity)
                                                 setShowSeverityDropdown(false)
                                             }}>
                                            {severity}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="card-content min-h-[36rem] max-h-[36rem] overflow-auto text-sm">
                    <div className="flex justify-between items-center flex-col w-full space-y-2">
                        {filteredWorkReports.map((report, index) => (
                            <div key={index}
                                 className="primary-container flex justify-between items-center w-full p-2">

                                <div
                                    className={`w-16 text-center rounded p-1 uppercase ${getSeverityColor(report.severity)}`}>
                                    {t(`incidentNotificationReports.severities.${report.severity}`)}
                                </div>
                                <div>{t(`incidentNotificationReports.categories.${report.category}`)}</div>
                                <div className={`w-24 text-center rounded p-1 uppercase ${getStatusColor(report)}`}>
                                    {report.confirmed &&
                                        <span>{t('incidentNotificationReports.statuses.confirmed')}</span>}
                                    {report.declined &&
                                        <span>{t('incidentNotificationReports.statuses.declined')}</span>}
                                    {!report.confirmed && !report.declined &&
                                        <span>{t('incidentNotificationReports.statuses.pending')}</span>}
                                </div>
                                <div>{t('incidentNotificationReports.by')} {employeeProfile.name} {employeeProfile.surname}</div>
                                <div>{report.date.toLocaleString()}</div>
                                <div>
                                    {
                                        filteredWorkReports && filteredWorkReports.length > 0 && report &&
                                        <BlobProvider
                                            document={<IncidentNotificationReportsSummary reports={[report]}/>}>
                                            {({url, blob}) => (
                                                url &&
                                                <a href={url} target="_blank"
                                                   className="primary-button uppercase bordered-button h-8"
                                                   rel="noreferrer">
                                                    <FiDownload size={20} className="mx-2"/>
                                                    {t('incidentNotificationReports.download')}
                                                </a>
                                            )}
                                        </BlobProvider>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default IncidentNotificationReports;