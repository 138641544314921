import {pdf} from "@react-pdf/renderer";
import IncidentNotificationDocument from "../components/pdf/documents/IncidentNotificationDocument";
import {ClaimFile} from "../services/backend/api/documents/models/claim-file.interface";
import {IncidentNotificationFile} from "../services/backend/api/documents/models/incident-notification-file.interface";
import {ShiftFile} from "../services/backend/api/documents/models/shift-file.interface";
import {IncidentVerificationFile} from "../services/backend/api/documents/models/incident-verification-file.interface";
import IncidentVerificationDocument from "../components/pdf/documents/IncidentVerificationDocument";
import SupervisorShiftDocument from "../components/pdf/documents/SupervisorShiftDocument";
import OperatorShiftDocument from "../components/pdf/documents/OperatorShiftDocument";
import ClaimDocument from "../components/pdf/documents/ClaimDocument";
import CommunicationSummary from "../components/pdf/summaries/CommunicationSummary";
import {Communication} from "../models/communication.interface";
import TimesheetDocument from "../components/pdf/documents/TimesheetDocument";
import {ShiftItem} from "../services/backend/api/shifts/models/shift-item.interface";

export const generateIncidentNotificationDocument = async (file: IncidentNotificationFile) => {
    return await pdf(<IncidentNotificationDocument incidentNotification={file.notification}/>).toBlob();
}

export const generateIncidentVerificationDocument = async (file: IncidentVerificationFile) => {
    return await pdf(<IncidentVerificationDocument report={file.report}/>).toBlob();
}

export const generateSupervisorShiftDocument = async (file: ShiftFile) => {
    if (!file.shift) {
        console.error("Shift Item is not defined");
        return;
    }
    return await pdf(<SupervisorShiftDocument shiftItem={file.shift}/>).toBlob();
}

export const generateOperatorShiftDocument = async (file: ShiftFile) => {
    if (!file.shift) {
        console.error("Shift Item is not defined");
        return;
    }
    return await pdf(<OperatorShiftDocument shiftItem={file.shift}/>).toBlob();
}

export const generateClaimDocument = async (file: ClaimFile) => {
    return await pdf(<ClaimDocument log={file.log}/>).toBlob();
}

export const generateCommunicationDocument = async (communication: Communication) => {
    return await pdf(<CommunicationSummary communication={communication}/>).toBlob();
}

export const generateTimesheetDocument = async (data: ShiftItem[]) => {
    return await pdf(<TimesheetDocument data={data}/>).toBlob();
}