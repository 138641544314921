import React from 'react'
import {Text} from "@react-pdf/renderer";
import ClassicDocument from "../components/ClassicDocument";
import {ReportEntity} from "../../../models/entities/report-entity.interface";
import {formatBool, formatSeverity} from "../../../utils/text-formatting.utils";
import {formatDate, formatTime} from "../../../utils/date.utils";

interface IncidentVerificationDocumentProps {
    report: ReportEntity;
}

const IncidentVerificationDocument: React.FC<IncidentVerificationDocumentProps> = ({report}) => {

    return (
        <ClassicDocument
            title={"Incident Verification"}
            subtitle={`ID: ${report.reportingId}`}
            content={
                <Text>
                    {`
                        Incident Name: ${report.incidentTypology.incidentName.en}
                        Created At: ${formatDate(new Date(report.incidentTypology.createdAt))}
                        Severity: ${report.incidentTypology.severity}
                        Report Operator Date: ${formatDate(new Date(report.reportOperatorDate))}
                        Report Operator Time: ${formatTime(report.reportOperatorTime)}
                        Incident Verification End Date: ${formatDate(new Date(report.incidentVerificationStartDate))}
                        Incident Verification End Time: ${formatTime(report.incidentVerificationStartTime)}
                        Incident Verification End Date: ${formatDate(new Date(report.incidentVerificationEndDate))}
                        Incident Verification End Time: ${formatTime(report.incidentVerificationEndTime)}
                        Severity Change Status: ${report.severityChangeStatus}
                        Severity Change Value: ${formatSeverity(report.severityChangeValue)}
                        Misreported: ${formatBool(report.misreported)}
                        Solved: ${formatBool(report.solved)}
                        Help: ${formatBool(report.help)}
                        Notes: ${report.notes}    
                        
                        - Location Details:
                        Latitude: ${report.location.lat}
                        Longitude: ${report.location.lng}
                        
                        - Supervisor Details:
                        Name: ${report.supervisor.firstName}
                        Surname: ${report.supervisor.lastName}
                        Username: ${report.supervisor.userName}
                        ID: ${report.supervisor.userId}
                        
                        - Operator Details:
                        Name: ${report.operator.firstName}
                        Surname: ${report.operator.lastName}
                        Username: ${report.operator.userName}
                        ID: ${report.operator.userId}
                    `}
                </Text>
            }
        />
    )
}
export default IncidentVerificationDocument