import { InputText } from "primereact/inputtext"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { isSecurePassword } from "../../../utils/validation.utils"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { ChangeWebCredentialRequest } from "../../../services/backend/api/general/requests/change-web-credential.request"
import { changeWebCredential } from "../../../services/backend/api/general/general.apis"
import { useNavigate } from "react-router-dom"
import Loader from "../LoaderComponent";
import {useToastContext} from "../../../contexts/ToastContext";

interface ChangeCredentialStepProps {
    userId: string
    recoveryCode: string
}

const ChangeCredentialStep = ({ userId, recoveryCode }: ChangeCredentialStepProps) => {

    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
    const [passwordError, setPasswordError] = useState<boolean>(true)
    const [passwordErrorText, setPasswordErrorText] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const {errorToast, successToast} = useToastContext()

    const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }

    const onConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value)
    }

    const handleRecover = async () => {
        try {
            setIsLoading(true)
            const request: ChangeWebCredentialRequest = { userId, code_recovery: recoveryCode, password, confirm_password: confirmPassword }
            await changeWebCredential(request)
            successToast(t('credentialChangeSuccess'))
            navigate("/")
        } catch (error: any) {
            console.log(error)
            errorToast(t(error.message));
        } finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        if (password !== confirmPassword) {
            setPasswordError(true)
            setPasswordErrorText(t('passwordsDoNotMatch'))
            return
        }

        if (!isSecurePassword(password)) {
            setPasswordError(true)
            setPasswordErrorText(t('passwordIsNotSecure'))
            return
        }

        setPasswordError(false)
        setPasswordErrorText("")
    }, [password, confirmPassword, t])

    if (!userId || !recoveryCode) {
        return null
    }

    return <div className="flex flex-col items-center justify-center gap-6 bg-[#2D2D2D] py-4 px-10 rounded-lg max-w-[600px] shadow-md">
        <h1 className="text-white text-2xl font-bold">{t('changePassword')}</h1>
        <p className="text-white text-sm">{t('changePasswordDescription')}</p>

        {
            !isLoading && <>
                <div className="relative w-full">
                    <InputText
                        type={showPassword ? "text" : "password"}
                        className={`w-full ${passwordError ? 'border-red-500 border-2' : ''}`}
                        placeholder={t('newPassword')}
                        value={password}
                        onChange={onPasswordChange}
                        disabled={isLoading}
                    />
                    <button
                        type="button"
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                        style={{ cursor: isLoading ? 'default' : 'pointer' }}
                        onClick={() => setShowPassword(!showPassword)}
                        disabled={isLoading}
                    >
                        {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                    </button>
                </div>
                <div className="relative w-full">
                    <InputText
                        type={showConfirmPassword ? "text" : "password"}
                        className={`w-full ${passwordError ? 'border-red-500 border-2' : ''}`}
                        placeholder={t('confirmNewPassword')}
                        value={confirmPassword}
                        onChange={onConfirmPasswordChange}
                        disabled={isLoading}
                    />
                    <button type="button"
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                        style={{ cursor: isLoading ? 'default' : 'pointer' }}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        disabled={isLoading}
                    >
                        {showConfirmPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                    </button>
                </div>
            </>
        }

        {
            isLoading &&
            <div style={{ width: 200, height: 200 }} className='flex justify-center items-center'>
                <Loader key="loader" />
            </div>
        }

        {passwordError && <p className="text-red-500 text-sm">{passwordErrorText}</p>}
        <button
            onClick={handleRecover}
            className={`primary-button ${passwordError ? 'opacity-50' : 'opacity-100'}`}
            style={{ cursor: passwordError ? 'default' : 'pointer' }}
            disabled={passwordError}
        >
            {t('changePassword')}
        </button>
    </div>
}

export default ChangeCredentialStep