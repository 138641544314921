import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {RouteItem} from "../../models/route-item.interface";
import {
    CALENDAR,
    CLAIM,
    CLAIM_MANAGEMENT,
    COMMUNICATION,
    DOCUMENTS,
    EMPLOYEES,
    GOAL,
    HOME,
    PROFILE,
    TASK,
    WORKER_OVERVIEW,
    TERMINATED_WORKER
} from "../../utils/constants.utils";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {FaBullseye} from "react-icons/fa";
import {AiOutlineNotification, AiOutlineSafety} from "react-icons/ai";
import {CiCalendarDate, CiFileOn, CiUser} from "react-icons/ci";
import { FaUserAltSlash } from "react-icons/fa";
import {GoTasklist} from "react-icons/go";
import {IoHomeOutline, IoPeopleOutline} from "react-icons/io5";
import {GrOverview} from "react-icons/gr";
import {TiDocumentText} from "react-icons/ti";
import {UserRole} from "../../models/enums/user-role.enum";

const MenuNav: React.FC = () => {
    const [activeTab, setActiveTab] = useState<string>(""); // Stato per tener traccia della tab attiva
    const location = useLocation();
    const {level} = useUserContext();

    const routes: RouteItem[] = [
        {path: HOME, icon: IoHomeOutline, allowedRoles: [UserRole.Client, UserRole.Provider, UserRole.HR]},
        {path: PROFILE, icon: CiUser, allowedRoles: [UserRole.Operator, UserRole.Supervisor]},
        {path: CLAIM, icon: AiOutlineSafety, allowedRoles: [UserRole.Operator, UserRole.Supervisor]},
        {path: GOAL, icon: FaBullseye, allowedRoles: [UserRole.Client]},
        {path: TASK, icon: GoTasklist, allowedRoles: [UserRole.Provider]},
        {path: EMPLOYEES, icon: IoPeopleOutline, allowedRoles: [UserRole.HR]},
        {path: CLAIM_MANAGEMENT, icon: TiDocumentText, allowedRoles: [UserRole.HR]},
        {path: WORKER_OVERVIEW, icon: GrOverview, allowedRoles: [UserRole.HR, UserRole.Provider]},
        {
            path: CALENDAR,
            icon: CiCalendarDate,
            allowedRoles: [UserRole.Operator, UserRole.Provider, UserRole.HR, UserRole.Supervisor]
        },
        {
            path: DOCUMENTS,
            icon: CiFileOn,
            allowedRoles: [UserRole.Operator, UserRole.Supervisor, UserRole.Provider, UserRole.Client,]
        },
        {
            path: COMMUNICATION,
            icon: AiOutlineNotification,
            allowedRoles: [UserRole.HR, UserRole.Provider, UserRole.Client,]
        },
        {
            path: TERMINATED_WORKER,
            icon: FaUserAltSlash,
            allowedRoles: [UserRole.HR]
        }
    ]

    useEffect(() => {
        // Ottieni il nome della tab dalla route e aggiorna lo stato activeTab
        const tabName = location.pathname.split("/").pop();
        setActiveTab(tabName || ""); // Se non ci sono tab nella route, impostalo su una stringa vuota
    }, [location.pathname]);

    const handleNavigation = (tab: string) => {
        setActiveTab(tab); // Imposta la tab attiva quando si cambia tab
    };

    return (
        <nav>
            <ul>
                {routes.map((route) => {

                    const Icon = route.icon;

                    if (route.allowedRoles && !route.allowedRoles.includes(level)) {
                        return null;
                    }

                    return (
                        <li key={route.path}>
                            <NavLink
                                to={route.path}
                                className="text-blue-500 inline-block"
                                onClick={() => handleNavigation(route.path)}
                            >
                                <div
                                    className={`circle-img ${activeTab === route.path ? "img-active" : ""}`}
                                >
                                    <Icon size={30} color="whitesmoke"/>
                                </div>
                            </NavLink>
                        </li>
                    )
                        ;
                })
                }
            </ul>
        </nav>
    );
};

export default MenuNav;
