import React from 'react'
import GraphSummaryDocument from "../components/GraphSummaryDocument";

interface IncidentsSummaryProps {
    data: any;
}

const IncidentsSummary: React.FC<IncidentsSummaryProps> = ({data}) => {
    return (
        <GraphSummaryDocument title="Incidents Summary" subtitle="Incidents Data" data={data}/>
    )
}
export default IncidentsSummary