import React, {useState} from "react";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ErrorComponent from "../common/ErrorComponent";
import {FiUpload} from "react-icons/fi";
import {FaTimes} from "react-icons/fa";
import {IoFolderOpenSharp} from "react-icons/io5";
import {getFileUploadUrl, uploadFile} from "../../services/backend/api/documents/documents.apis";
import {FileUploadUrlRequest} from "../../services/backend/api/documents/requests/file-upload-url.request";

const UpdateFile: React.FC<{
    closeModal: () => void;
    folderName: string;
    category: string;
    numFiles: number;
}> = ({closeModal, folderName, category, numFiles}) => {
    const {t} = useTranslation();
    const [uploadedFileName, setUploadedFileName] = useState<string>("");
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);
    const navigate = useNavigate();
    const {level} = useUserContext();

    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);

    const {id} = useUserContext();

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const fileSizeLimit = 3 * 1024 * 1024; // 3MB
            if (file.size > fileSizeLimit) {
                setErrorMessage(t("sizeUploadError"));
                setShowErrorModal(true);
                return;
            }
            setUploadedFileName(file.name);
            setFileUploaded(true);
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            setUploadedFileName(file.name);
            setFileUploaded(true);
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const uploadDocument = async () => {
        try {
            const request: FileUploadUrlRequest = {
                category: category,
                filename: uploadedFileName,
                mimetype: null,
                userId: id
            }
            const response = await getFileUploadUrl(request);
            const {signedUrl} = response;

            const file = document.getElementById("fileInput") as HTMLInputElement;
            const formData = new FormData();
            formData.append("file", file.files![0]);

            await uploadFile({url: signedUrl, file: file.files![0]});
            closeModal();
        } catch (error: any) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="flex justify-end">
                    <FaTimes
                        className="cursor-pointer"
                        size={28} color="burlywood"
                        onClick={closeModal}
                    />
                </div>
                <div>
                    <h4>{t("documentPage.uploadNewDocument")}</h4>
                </div>

                <div
                    className="file-upload"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    <label htmlFor="fileInput">
                        <input
                            id="fileInput"
                            type="file"
                            accept=".jpg,.jpeg,.png,.bmp,.pdf,.xls,.xlsx,.doc,.docx"
                            onChange={handleFileUpload}
                        />
                        {uploadedFileName ? (
                            <div>
                                <p className="text-base">{uploadedFileName}</p>
                            </div>
                        ) : (
                            <div>
                                <FiUpload
                                    className="mb-4 mt-5 ml-auto mr-auto"
                                    size={30} color="whitesmoke"
                                    onClick={closeModal}
                                />

                                <div>
                                    <p className="text-base mb-1">
                                        {t("documentPage.istruction")}
                                    </p>
                                    <p style={{fontSize: 10}}>jpg, jpeg, png, bmp, png</p>
                                    <p style={{fontSize: 10}}>pdf, xls, xlsx, doc, docx</p>
                                </div>
                            </div>
                        )}
                    </label>
                </div>

                <div className="update-info">
                    <p className="mb-3">{t("documentPage.textUploading")}</p>
                    <div
                        className="custom-button padding-section-document "
                        style={{
                            border: "1px solid #FEDC98",
                            borderRadius: "15px",
                            textTransform: "unset",
                            width: "200px",
                            margin: "1rem auto",
                            textAlign: "start",
                            pointerEvents: "none",
                        }}
                    >
                        <div className="mb-0 flex justify-between">
                            <IoFolderOpenSharp
                                size={32} color="whitesmoke"
                            />
                        </div>
                        <p className="title-style text-base">{folderName}</p>
                        <p className="text-sm">
                            {numFiles} {t("documentPage.files")}
                        </p>
                    </div>
                    <button
                        className="primary-button uppercase m-auto mt-6 button-yellow"
                        style={{
                            textTransform: "unset",
                            opacity: uploadedFileName ? 1 : 0.5,
                            borderColor: fileUploaded ? "#FEDC98" : "red",
                            cursor: fileUploaded ? "pointer" : "not-allowed",
                        }}
                        onClick={uploadDocument}
                    >
                        {t("documentPage.uploadFile")}
                    </button>
                </div>
            </div>
            {errorMessage && showErrorModal && <ErrorComponent message={errorMessage}/>}
        </div>
    );
};

export default UpdateFile;
