import {Line, StyleSheet, Svg, View} from "@react-pdf/renderer";
import {defaultStyles} from "../styles/default.styles";
import React from "react";

const styles = StyleSheet.create({
    ...defaultStyles
});

const HorizontalSeparator: React.FC = () => {
    return (
        <View style={styles.separator}>
            <Svg height="8" width="595">
                <Line x1="0" y1="5" x2="500" y2="5" strokeWidth={2} stroke="darkgrey" strokeDasharray="3,3"/>
            </Svg>
        </View>
    )
}

export default HorizontalSeparator