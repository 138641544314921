import { Navigate, useParams } from "react-router-dom"
import ChangeCredentialStep from "../../components/common/ForgotPassword/ChangeCredentialStep"

const RecoverCredentialPage = () => {
    const { userId, recoveryCode } = useParams()

    if (!userId || !recoveryCode) {
        return <Navigate to="/login" />
    }
    
    return (
        <div className="h-screen w-screen flex items-center justify-center">
            <ChangeCredentialStep
                userId={userId}
                recoveryCode={recoveryCode}
            />
        </div>
    )
}

export default RecoverCredentialPage