import React, {useState} from 'react';
import {Communication} from "../../models/communication.interface";
import {useTranslation} from "react-i18next";
import {FaAngleDown} from "react-icons/fa6";
import {FiDownload} from "react-icons/fi";
import {HiOutlineUserCircle} from "react-icons/hi";
import {BlobProvider} from "@react-pdf/renderer";
import CommunicationSummary from "../pdf/summaries/CommunicationSummary";

interface CommunicationTableRowProps {
    communication: Communication;
}

const CommunicationTableRow: React.FC<CommunicationTableRowProps> = ({communication}) => {

    const {t} = useTranslation();
    const [isCommunicationExpanded, setCommunicationExpanded] = useState(false)

    return (
        <div
            key={communication.id}
            className={`bg-[var(--primary-color)] rounded-lg p-3 flex flex-row flex-wrap`}
        >
            <div
                className="flex justify-between items-center space-x-2 flex-col gap-4 md:flex-row md:gap-0 text-sm whitespace-nowrap w-full">
                <div
                    className="flex justify-between items-center space-x-2 flex-col gap-4 md:flex-row md:gap-0 text-sm whitespace-nowrap">
                    <HiOutlineUserCircle size={24} className="text-gray-400"/>
                    <div className="w-28 ellipsis">
                        <span className="text-gray-300 text-sm">{communication.receiver}</span>
                    </div>

                    <div className="w-28">
                        {communication.date.toLocaleDateString()}
                    </div>

                    <div className="w-28">
                        {communication.date.toLocaleTimeString()}
                    </div>
                </div>
                <div className="ellipsis w-max">
                    {communication.content}
                </div>

                <div className="flex justify-center items-center space-x-2">
                    <BlobProvider document={<CommunicationSummary communication={communication}/>}>
                        {({url, blob}) => (
                            url &&
                            <a href={url} target="_blank" className="primary-button uppercase bordered-button "
                               rel="noreferrer">
                                <FiDownload size={20} className="mx-2"/>
                                {t("communication.download")}
                            </a>
                        )}
                    </BlobProvider>
                    <div>
                        <FaAngleDown size={20} onClick={() => setCommunicationExpanded(!isCommunicationExpanded)}/>
                    </div>
                </div>
            </div>

            {
                isCommunicationExpanded &&
                <div className="accordion-container">
                    <p>{communication.content}</p>
                </div>
            }
        </div>
    )
}

export default CommunicationTableRow