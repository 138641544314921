import React, {useCallback, useState} from 'react';
import {UserType} from "../../models/types/user.type";
import {useTranslation} from 'react-i18next';
import WorkerTaskTable from './WorkerTask';
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {FaAngleDown, FaAngleUp} from "react-icons/fa6";
import {UserRole} from "../../models/enums/user-role.enum";
import {shortId} from "../../utils/text-formatting.utils";
import OperatorIcon from '../../assets/icons/operator.svg';
import SupervisorIcon from '../../assets/icons/supervisor.svg';

interface WorkerTableRowProps {
    user: UserType;
}

const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;

const WorkerTableRow: React.FC<WorkerTableRowProps> = ({user}) => {

    const {t} = useTranslation();
    const {level} = useUserContext();
    const [isWorkerDetailsVisible, setIsWorkerDetailsVisible] = useState(false);

    const onBreakStyle = useCallback((user: UserType) => {
        const {isActive, isOnBreak} = user
        if (isActive && !isOnBreak) {
            return 'bg-yellow-500 text-black font-bold'
        } else {
            return 'bg-unset border-2 border-gray-500 opacity-50'
        }
    }, [])

    const userLevel = useCallback((user: UserType) => {
        if (user.level === 'operator') {
            return t("Operator").toUpperCase()
        } else if (user.level === 'supervisor') {
            return t("Supervisor").toUpperCase()
        } else {
            return ''
        }
    }, [t])

    const getProfilePictureUrl = (id?: string) => {
        if (!id) {
            return ''
        }
        return `${S3_BUCKET_URL}/${id}`
    }

    return (
        <div className="primary-container">
            <div
                key={user.userId}
                className={`bg-[var(--primary-color)] rounded-lg p-3 flex items-center justify-start flex-col gap-4 lg:flex-row lg:gap-0`}
            >
                <div className="flex items-center space-x-2 flex-col gap-4 md:flex-row md:gap-0 ">
                    <div className="text-gray-400 w-8">
                        {
                            user.level === UserRole.Operator
                                ? <img src={OperatorIcon} alt="operator"/>
                                : <img src={SupervisorIcon} alt="supervisor"/>
                        }
                    </div>
                    <div className="w-28 ellipsis">
                        <span className="text-gray-300 text-sm">{shortId(user.userId)}</span>
                    </div>

                    <div
                        className="bg-[var(--secondary-color)] border-2 text-center  px-2 py-1 rounded-lg text-xs w-24 overflow-hidden">
                        {userLevel(user)}
                    </div>

                    <div className="w-48 overflow-hidden flex items-center justify-center">
                    <span className=" whitespace-no-wrap overflow-hidden overflow-ellipsis block">
                        {`${user.firstName} ${user.lastName}`.trim() || user.userName}
                    </span>
                    </div>
                    <div className="w-48 flex flex-row justify-center items-center space-x-2">
                        <div className="w-48 flex justify-center md:justify-start items-center space-x-2">
                        <span className={`text-xs px-2 py-1 rounded-lg  
                                ${user.isActive && !user.isLeave ?
                            'bg-green-600 border-2 border-green-600 text-[color:var(--text-color-secondary)]'
                            :
                            'bg-unset border-2 border-gray-500 opacity-50'
                        }`
                        }>
                            {t("provider_graph.Active").toUpperCase()}
                        </span>

                            {
                                user.level === 'operator' &&
                                <span className={`text-xs px-2 py-1 rounded-lg ${onBreakStyle(user)}`}>
                                {t("provider_graph.OnBreak").toUpperCase()}
                            </span>
                            }

                        </div>
                    </div>

                </div>
                {level === UserRole.HR && user.tasks.length > 0 && (
                    <div className="flex items-center space-x-4 text-sm w-full flex-grow justify-start">
                        <span>{t('shiftStarted')}</span>
                        {/*TODO:*/}
                        <span>9:05 AM </span>
                        <span className="text-yellow-600">+5 MIN </span>
                    </div>
                )}
                {user.level === 'operator' && user.tasks.length > 0 && (
                    <WorkerTaskTable user={user}/>
                )}
                <div className="ml-2 flex-grow flex justify-end items-center"
                     onClick={() => setIsWorkerDetailsVisible(!isWorkerDetailsVisible)}
                >
                    {
                        isWorkerDetailsVisible
                            ? <>
                                <FaAngleUp size={24}/>
                            </>
                            : <>
                                <FaAngleDown size={24}/>
                            </>
                    }
                </div>
            </div>
            {
                isWorkerDetailsVisible &&
                <div className="accordion-container">
                    <div className="flex justify-content items-center px-4">
                        <img src={getProfilePictureUrl(user.photo)} alt="user" className="rounded-full w-36 "/>
                        <div className="w-full flex justify-end item-center flex-col">
                            {
                                user.tasks.map((task, index) => (
                                    <p className="text-right" key={index}>{task.taskName.en}</p>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default WorkerTableRow