import React, {useCallback, useEffect, useState} from "react";
import {formatFileSize} from "../../utils/file.utils";
import Loader from "../common/LoaderComponent";
import {useTranslation} from "react-i18next";
import {FiDownload} from "react-icons/fi";
import {getIconByFileName} from "../../utils/icons.utils";
import {downloadDocument, getCategoryDocuments} from "../../services/document-manager/document-manager.service";
import {DocumentCategory} from "../../models/enums/document-category.enum";
import {formatDate, formatTime} from "../../utils/date.utils";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {UserRole} from "../../models/enums/user-role.enum";
import {formatSeverity} from "../../utils/text-formatting.utils";
import {getTranslation} from "../../utils/language.utils";


interface CategoryFileProps {
    category?: DocumentCategory;
    refresh?: boolean;
    userId?: string;
}

const DETAILED_CATEGORY = [
    DocumentCategory.IncidentNotifications,
    DocumentCategory.IncidentVerifications
]

const CategoryFiles: React.FC<CategoryFileProps> = ({refresh, category, userId}) => {
    const {t} = useTranslation();
    const [files, setFiles] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {level} = useUserContext();

    const getFileCategory = (file: any) => {
        if (!category) {
            return "";
        }

        if (DETAILED_CATEGORY.includes(category)) {
            let severity;
            if (category === DocumentCategory.IncidentNotifications) {
                severity = file?.severity;
            } else if (category === DocumentCategory.IncidentVerifications) {
                severity = file?.report?.incidentTypology?.severity;
            } else {
                return "";
            }
            severity = severity?.toString();
            if (!severity) {
                return "";
            }
            return formatSeverity(severity);
        }

        if (file.category) {
            return file.category;
        }
        return "";
    }

    const getFileTypology = (file: any) => {
        if (!category) {
            return "";
        }

        if ([DocumentCategory.IncidentNotifications, DocumentCategory.IncidentVerifications].includes(category)) {
            let incidentName;
            if (category === DocumentCategory.IncidentNotifications) {
                incidentName = file?.notification?.incidentTypology?.incidentName;
            } else if (category === DocumentCategory.IncidentVerifications) {
                incidentName = file?.report?.incidentTypology?.incidentName;
            } else {
                return "";
            }
            if (!incidentName) {
                return "";
            }
            return getTranslation(incidentName);
        }

        return "";
    }

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        let fetchedFiles: any[];
        if (level === UserRole.HR && !userId) {
            fetchedFiles = [];
        } else {
            fetchedFiles = await getCategoryDocuments(category, userId);
        }
        setFiles(fetchedFiles);
        setIsLoading(false);
    }, [category, level, userId]);

    useEffect(() => {
        fetchData().then(() => console.log("Data fetched"));
    }, [refresh, fetchData]);

    return (
        <div className="w-full">
            <>
                {isLoading ? (
                    <Loader/>
                ) : (
                    <>
                        {files.length > 0 ? (
                            <>
                                <div
                                    className="row-file px-6 mt-4"
                                    style={{fontWeight: "bold", marginBottom: "15px"}}
                                >
                                    <div className="text-lg" style={{flex: 2}}>
                                        {t("name")}
                                    </div>
                                    <div className="text-lg" style={{flex: 1}}>
                                        {t("documentPage.size")}
                                    </div>
                                    {
                                        category && DETAILED_CATEGORY.includes(category) && (
                                            <>
                                                <div className="text-lg" style={{flex: 1}}>
                                                    {t("documentPage.category")}
                                                </div>
                                                <div className="text-lg" style={{flex: 1}}>
                                                    {t("documentPage.typology")}
                                                </div>
                                            </>
                                        )
                                    }
                                    <div className="text-lg" style={{flex: 1}}>
                                        {t("documentPage.updateDate")}
                                    </div>
                                    <div style={{flex: 1}}></div>
                                </div>
                                <div
                                    className="container-box-generic"
                                    style={{alignItems: "start", height: "auto", minHeight: "unset"}}
                                >
                                    {files.map((file, index) => (
                                        <div className="row-file" key={index}>
                                            <div className="flex items-center " style={{flex: 2}}>
                                                {getIconByFileName(file.name)({
                                                    size: 30,
                                                    color: "burlywood",
                                                    style: {marginRight: "0.6rem"}
                                                })}{" "}
                                                <p className="overflow-ellipsis whitespace-nowrap max-w-80 overflow-hidden">
                                                    {file.name}
                                                </p>
                                            </div>
                                            <div style={{flex: 1}}>{formatFileSize(file.size)}</div>
                                            {
                                                category && DETAILED_CATEGORY.includes(category) && (
                                                    <>
                                                        <div style={{flex: 1}}>
                                                            {getFileCategory(file)}
                                                        </div>
                                                        <div style={{flex: 1}}>
                                                            {getFileTypology(file)}
                                                        </div>
                                                    </>
                                                )
                                            }

                                            <div style={{flex: 1}}>
                                                {
                                                    file.date
                                                        ? <>
                                                            {formatDate(file.date)} {" "} {formatTime(file.date)}
                                                        </>
                                                        : <>
                                                            {formatDate(file.uploadDate)} {" "} {formatTime(file.uploadDate)}
                                                        </>
                                                }
                                            </div>

                                            <div style={{flex: 1}} className="">
                                                <button
                                                    className="flex justify-center items-center custom-button"
                                                    style={{height: 37}} onClick={async () => {
                                                    await downloadDocument(file)
                                                }}
                                                >
                                                    <FiDownload size={20} className="mx-2"/>
                                                    {t("documentPage.download")}
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <div className="flex ml-5 mt-10">
                                <p>{t("documentPage.noData")}</p>
                            </div>
                        )}
                    </>
                )}
            </>
        </div>
    );
};

export default CategoryFiles;
