import React, {useCallback, useEffect, useRef, useState} from "react";
import Calendar from "react-calendar";

import Loader from "../../common/LoaderComponent";
import ShiftCalendarRequestMenu from "./ShiftCalendarRequestMenu";
import ShiftCalendarLegend from "./ShiftCalendarLegend";
import InfoDay from "../../InfoDay";
import {WeekCalendarItemInterface} from "../../../models/week-calendar-item.interface";
import {PendingCalendarItem} from "../../../models/pending-calendar-item.interface";
import {CalendarRequest, isFreeDay} from "../../../utils/date.utils";
import ShiftCalendarWeekList from "./ShiftCalendarWeekList";
import {useTranslation} from "react-i18next";

import ErrorComponent from "../../common/ErrorComponent";
import {
  getCalendarLabels,
  getMonthShifts,
  getWeekShifts,
  proposeShift
} from "../../../services/backend/api/shifts/shifts.apis";
import {ProposeShiftRequest} from "../../../services/backend/api/shifts/requests/propose-shift.request";
import {MonthShiftsRequest} from "../../../services/backend/api/shifts/requests/month-shifts.request";

const ShiftCalendar: React.FC = () => {
  const {i18n} = useTranslation();
  const locale = i18n.language === "ar" ? "ar-SA" : "en-US";

   
  const [date, setDate] = useState(new Date());
  const [, setSelectedDate] = useState(new Date());
  const [weekCalendarDay, setWeekCalendarDay] =
      useState<WeekCalendarItemInterface | null>(null);
  const [weeksData, setWeeksData] = useState<WeekCalendarItemInterface[]>([]);
  const [monthData, setMonthData] = useState<WeekCalendarItemInterface[]>([]);
  const [pendingMonthData, setPendingMonthData] = useState<
      PendingCalendarItem[]
  >([]);
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [confirmClicked, setConfirmClicked] = useState<boolean>(false);
  const [isListVisible, setIsListVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMonthLoading, setIsMonthLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  

  const dropdownRef = useRef<HTMLDivElement>(null);
  const circleRef = useRef<HTMLDivElement>(null);
  const [labels, setLabels] = useState<string[]>([]);
  const [translationLabels, setTranslationLabels] = useState<{ [key: string]: string }>({});

  const [canClickOutside, setCanClickOutside] = useState(true);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        if (canClickOutside) {
          setIsDropdownOpen(false);
          setIsListVisible(false);
          setSelectedOption(null);
          setConfirmClicked(false);
          setSelectedDay(null);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, canClickOutside]);

  useEffect(() => {
    const fetchLabels = async () => {
      try {
        const response = await getCalendarLabels();
        const lng = i18n.language;

        const translationLabels = response.message.translationLabels[lng];

        const labels = Object.keys(translationLabels).filter(
          (l) => l !== "Work"
        );
        setLabels(labels);
        setTranslationLabels(translationLabels);
      } catch (error) {
        console.error("Error fetching labels:", error);
      }
    };
    fetchLabels().then(() => console.log("Labels fetched"));
  }, [i18n.language]);

  useEffect(() => {
    const fetchWeekData = async () => {
      try {
        setIsLoading(true);
        const responseWeek = await getWeekShifts();
        setWeeksData(responseWeek.weekCalendar as WeekCalendarItemInterface[]);
      } catch (error) {
        console.error(
          "Errore durante il recupero dei dati della settimana:",
          error
        );
        /* setErrorMessage(
          "Si è verificato un errore durante il recupero dei dati della settimana."
        ); */
      } finally {
        setIsLoading(false);
      }
    };
    fetchWeekData().then(r => console.log("Week data fetched"));
  }, []);

  const fetchMonthData = useCallback(async () => {
    try {
      setIsMonthLoading(true);
      const month = date?.getMonth() || new Date().getMonth();
      const year = date?.getFullYear() || new Date().getFullYear();
      const request: MonthShiftsRequest = {month, year};
      const responseMonth = await getMonthShifts(request);
      setMonthData(responseMonth.monthCalendar as WeekCalendarItemInterface[]);
      setPendingMonthData(responseMonth.pending);
    } catch (error) {
      console.error("Errore durante il recupero dei dati del mese:", error);
      /* setErrorMessage(
        "Si è verificato un errore durante il recupero dei dati del mese."
      ); */
    } finally {
      setIsMonthLoading(false);
    }
  }, [date]);

  useEffect(() => {
    fetchMonthData().then(r => console.log("Month data fetched"));
  }, [date, fetchMonthData]);

  const onChange = (value: Date) => {
    setSelectedDay((prevSelectedDay) => {
      const dateStr = value.toDateString();
      const isDaySelected = prevSelectedDay?.toDateString() === dateStr;
      if (isDaySelected) {
        return null;
      } else {
        return value;
      }
    });
  };

  const hasValidShifts = (date: Date) => {
    const selectedDay = monthData.find(
      (day) => new Date(day.date).toDateString() === date.toDateString()
    );
    return (
      selectedDay?.shifts.some(
        (shift) =>
          shift.startTime !== "" &&
          shift.endTime !== "" &&
          shift.label === "Work"
      ) ?? false
    );
  };

  const getDayClassName = (date: Date) => {
    if (date === null || date === undefined) return "";


    // Verifica se la data è precedente o uguale alla data di inizio della settimana
    const weekStartDate = new Date(weeksData[0]?.date);
    weekStartDate.setDate(weekStartDate.getDate() - 1);
    const isBeforeWeekStartDate = date < weekStartDate;

    // Se la data è precedente o uguale alla data di inizio della settimana, non assegnare alcuna classe
    if (isBeforeWeekStartDate) {
      return "";
    }

    // Altrimenti, continua con la logica delle classi come nel tuo codice originale
    const isSelecteMonth = monthData.some(
      (day) =>
        new Date(date).toDateString() === new Date(day.date).toDateString()
    );

    /* const thereIsPendingRequest = pendingMonthData?.some(
      (pendingRequest) =>
        new Date(pendingRequest.startDate).toDateString() === new Date(date).toDateString()
    ); */

    const thereIsPendingRequest = pendingMonthData?.some((pendingRequest) => {
      const pendingDateLocal = new Date(pendingRequest.startTime).toDateString()
      const targetDateLocal = date.toDateString()
      return pendingDateLocal === targetDateLocal;
    });

    const hasMoreThanOneShift = (monthData.find((day) =>
      new Date(day.date).toDateString() === date.toDateString()
    )?.shifts.filter(shift => shift.label !== 'Work') ?? []).length >= 1 &&
       (monthData.find((day) =>
      new Date(day.date).toDateString() === date.toDateString()
    )?.shifts ?? []).length >= 2;
    
    const hasValidShift = hasValidShifts(date);
    
    const isFree = isFreeDay(date, monthData);
    const isDaySelected = selectedDay?.toDateString() === date.toDateString();
    

    if (
      isDaySelected &&
      isSelecteMonth &&
      hasValidShift &&
      !thereIsPendingRequest
    ) {
      return "react-calendar__tile--dayWork active-class";
    } else if (isSelecteMonth && hasValidShift && !thereIsPendingRequest && !hasMoreThanOneShift) {
      return "react-calendar__tile--dayWork";
    } else if (isDaySelected) {
      return "active-class";
    } else if (isFree && !thereIsPendingRequest) {
      return "free-days";
    } else if (hasMoreThanOneShift) {
      return "doubleShift-days";
    } else if (thereIsPendingRequest) {
      return "pending-days";
    } else {
      return "";
    }
  };

  const handleDayPress = (day: any) => {
    setWeekCalendarDay(day);
    /* se deseleziono allo svuotamelo */
    if (weekCalendarDay && weekCalendarDay.date === day.date) {
      setWeekCalendarDay(null);
      return;
    }
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsListVisible(false);
    setConfirmClicked(false);
  };

  const handleConfirmClick = async (request: CalendarRequest) => {
    const { startTime, endTime, label } = request;
    setIsListVisible(true);
    setConfirmClicked(true);
    console.log("start");

    try {
      const request: ProposeShiftRequest = {
        startTime, endTime, label
      }
      await proposeShift(request);
    } catch (error) {
      console.error("Error proposing shift-calendar:", error);
      setErrorMessage(
        (error as Error).message ||
          "Si è verificato un errore durante il tentativo. Riprova più tardi."
      );
      setShowErrorModal(true);
    } finally {
      setTimeout(() => {
        setIsDropdownOpen(false);
        setSelectedOption(null);
        setConfirmClicked(false);
        setSelectedDay(null);
      }, 4000);
      fetchMonthData().then(r => console.log("Month data fetched"));
    }
  };

  return (
    <div className="flex flex-col md:flex-row space-x-4 space-y-4 md:space-y-0">
      <div
        className="md:w-6/12 container-box-generic p-4"
        style={{ padding: "1.5rem" }}
      >
        {isLoading && <Loader />}
        {!isLoading && weeksData.length > 0 && (
            <ShiftCalendarWeekList
            weeksData={weeksData}
            translationLabels={translationLabels}
            handleDayPress={handleDayPress}
            selectedDay={weekCalendarDay}
          />
        )}
      </div>

      <div className="md:w-5/12 container-box-generic p-4">
        {isMonthLoading && <Loader />}
        {!isMonthLoading && monthData.length > 0 && (
          <>
            <Calendar
              onChange={(value) => {
                setSelectedDate(value as Date);
                onChange(value as Date);
              }}
              onActiveStartDateChange={(value) => {
                setDate(value.activeStartDate as Date);
              }}
              value={date}
              calendarType="iso8601"
              locale={locale}
              formatDay={(locale, date) => date.getDate().toString()}
              prev2Label={null}
              next2Label={null}
              prevLabel={new Date().getMonth() === date.getMonth() ? "" : "<"}
              nextLabel={">"}
              tileClassName={({ date }) => getDayClassName(date)}
              minDate={
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  new Date().getDate()
                )
              }
            />
            <div className="flex justify-between mt-6 w-full content-end">
              <div className="flex flex-col w-full">
                <InfoDay selectedDay={selectedDay} />
                <ShiftCalendarLegend/>
              </div>

              {selectedDay && (
                <div className="custom-component-wrapper">
                  <div
                    className="circle mt-2"
                    ref={circleRef}
                    onClick={() => {
                      setIsDropdownOpen((prevState) => !prevState);
                      if (!isDropdownOpen) {
                        setSelectedOption(null);
                        setConfirmClicked(false);
                      }
                    }}
                  >
                    {isDropdownOpen ? "-" : "+"}
                  </div>
                  {isDropdownOpen && !errorMessage && (
                      <ShiftCalendarRequestMenu
                      dropdownRef={dropdownRef}
                      isListVisible={isListVisible}
                      selectedDay={selectedDay}
                      selectedOption={selectedOption}
                      confirmClicked={confirmClicked}
                      labels={labels}
                      translationLabels={translationLabels}
                      handleOptionClick={handleOptionClick}
                      handleConfirmClick={handleConfirmClick}
                      canClickOutsideUpdate={setCanClickOutside}
                    />
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {errorMessage && showErrorModal && (
        <ErrorComponent message={errorMessage} />
      )}
    </div>
  );
};
export default ShiftCalendar;
