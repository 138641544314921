import React, {useMemo} from "react";
import {Marker, Popup, useMapEvents} from "react-leaflet";
import L, {LatLng} from "leaflet";
import 'leaflet/dist/leaflet.css';


interface LocationMarkerProps {
    position: LatLng;
    onLocationSelected?: (location: LatLng) => void;
    icon: L.Icon;
    text?: string;
    draggable?: boolean;
}


const LocationMarker: React.FC<LocationMarkerProps> = ({onLocationSelected, position, icon, text, draggable}) => {
    const updatePosition = (e: any) => {
        if (onLocationSelected) {
            onLocationSelected(e.latlng);
        }
    }

    useMapEvents({
        click(e) {
            updatePosition(e);
        }
    })

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                if (onLocationSelected) {
                    onLocationSelected(position);
                }
            },
        }),
        [onLocationSelected, position],
    )

    return (
        <Marker position={position} icon={icon} draggable={draggable} eventHandlers={eventHandlers}>
            <Popup>
                <div className="flex flex-col justify-center items-center text-center">
                    {text}
                    <p>
                        {position.lat}, {position.lng}
                    </p>
                </div>
            </Popup>
        </Marker>
    );
}

export default LocationMarker;