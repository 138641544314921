import React from 'react'
import {Text} from "@react-pdf/renderer";
import ClassicDocument from "../components/ClassicDocument";
import {ShiftItem} from "../../../services/backend/api/shifts/models/shift-item.interface";
import {formatDate, formatTime} from "../../../utils/date.utils";

interface OperatorShiftDocumentProps {
    shiftItem: ShiftItem;
}

const OperatorShiftDocument: React.FC<OperatorShiftDocumentProps> = ({shiftItem}) => {
    return (
        <ClassicDocument
            title={"Operator Shift"}
            subtitle={`ID: ${shiftItem.shiftId}`}
            content={
                <Text>
                    {`
                        - Operator Details:
                        Name: ${shiftItem.user?.firstName}
                        Surname: ${shiftItem.user?.lastName}
                        Username: ${shiftItem.user?.userName}
                        ID: ${shiftItem.user?.userId}
                        
                        - Shift Details:
                        Label: ${shiftItem.label}      
                        Start Date: ${formatDate(new Date(shiftItem.startDate))}
                        Start Time: ${formatTime(shiftItem.startTime)}
                        End Date: ${formatDate(new Date(shiftItem.endDate))}
                        End Time: ${formatTime(shiftItem.endTime)}
                        User Start: ${formatDate(new Date(shiftItem.userStart))}
                        User End: ${formatDate(new Date(shiftItem.userEnd))}
                    `}
                </Text>
            }
        />
    )
}
export default OperatorShiftDocument