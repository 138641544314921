import React, { useCallback, useState } from "react";
import WorkersOverview from "../../components/worker-overview/WorkersOverview";
import IncidentsGraph from "../../components/graphs/IncidentsGraph";
import GoalsGraph from "../../components/graphs/GoalsGraph";
import ReportGraph from "../../components/graphs/ReportGraph";
import TasksGraph from "../../components/graphs/TasksGraph";
import { useUserContext } from "../../contexts/OperatorContextProvider";
import PendingRequests from "../../components/PendingRequests";
import { useTranslation } from "react-i18next";
import { UserRole } from "../../models/enums/user-role.enum";
import AttendancesGraph from "../../components/graphs/AttendancesGraph";
import LeavesGraph from "../../components/graphs/LeavesGraph";
import ClaimsGraph from "../../components/graphs/ClaimsGraph";

enum LayoutMode {
    ALL_GRAPHS = "all-graphs",
    ONLY_WORKERS = "only-workers"
}

const HomePage: React.FC = () => {
    const { t } = useTranslation();
    const [layoutScreen, setLayoutScreen] = useState(LayoutMode.ALL_GRAPHS);
    const handleShowDetail = useCallback((state: boolean) => setLayoutScreen(state ? LayoutMode.ONLY_WORKERS : LayoutMode.ALL_GRAPHS), []);
    const isOnlyWorkersView = layoutScreen === LayoutMode.ONLY_WORKERS;
    const { level } = useUserContext();
    const primaryView = level !== UserRole.HR;

    return (
        <div className="home-page-container">
            <div>
                {
                    primaryView ?
                        <div className={`w-full grid grid-cols-3 gap-4 ${isOnlyWorkersView ? 'hidden' : ''}`}>
                            <div>
                                <IncidentsGraph />
                            </div>
                            <div>
                                <ReportGraph />
                            </div>
                            <div>
                                <ClaimsGraph />
                            </div>
                            <div className="col-span-2">
                                <WorkersOverview onShowDetail={handleShowDetail} />
                            </div>
                            <div>
                                <TasksGraph />
                            </div>
                        </div>
                        :
                        <>
                            <div className={`w-full grid grid-cols-2 gap-4 max-w-full mb-4`}>
                                <div>
                                    <TasksGraph />
                                </div>
                                <div>
                                    <AttendancesGraph />
                                </div>
                                <div>
                                    <ClaimsGraph />
                                </div>
                                <div>
                                    <LeavesGraph />
                                </div>
                            </div>
                            <div className="w-full col-span-2">
                                <PendingRequests />
                            </div>
                        </>
                }
            </div>
        </div>
    );
};

export default HomePage;