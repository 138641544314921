import React, {useState} from "react";
import {Button} from "primereact/button";
import {useToastContext} from "../../contexts/ToastContext";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";
import ToastContent from "../../components/common/ToastContent";
import {SeverityType} from "../../models/types/severity.type";
import {INFO, SEVERITIES} from "../../utils/constants.utils";

const NotificationCheckPage: React.FC = () => {
    const {showToast} = useToastContext();
    const [severity, setSeverity] = useState<SeverityType>(INFO);
    const [summary, setSummary] = useState<string>("Check");
    const [detail, setDetail] = useState<string>("This is a notification check");
    const [checkAllSeverities, setCheckAllSeverities] = useState<boolean>(true);

    const checkNotification = () => {
        const notification = {
            severity, summary, detail, life: 10000,
            content: <ToastContent severity={severity} summary={summary} detail={detail}/>
        };
        if (!checkAllSeverities) {
            showToast(notification);
        } else {
            SEVERITIES.forEach((severity) => {
                showToast({...notification, severity: severity as SeverityType});
            });
        }
    }

    return (
        <div className="page-container p-12">
            <div className="page-title flex justify-center items-center">
                <h2>Notification Check Page</h2>
            </div>
            <div className="flex flex-col justify-center items-center pt-4">
                <div>
                    <p className="text-left">
                        This page do nothing, This page is for demonstration purposes only.
                        This page produce a test notification locally.
                        To check the notification, fill the form below and click the check button.
                    </p>
                </div>

                <div className="pt-12 flex flex-col justify-center items-center space-y-4">

                    <div className="flex align-items-center">
                        <Checkbox inputId="all-severities-flag"
                                  onChange={() => setCheckAllSeverities(!checkAllSeverities)}
                                  checked={checkAllSeverities}/>
                        <label htmlFor="all-severities-flag" className="ml-2">Check all severities</label>
                    </div>
                    <Dropdown value={severity} options={Object.values(SEVERITIES)}
                              checkmark={true} highlightOnSelect={false} className="w-full"
                              onChange={(e) => setSeverity(e.target.value as SeverityType)}
                    />

                    <InputText value={summary} size='medium' className="w-full"
                               placeholder={"Summary"}
                               onChange={(e) => setSummary(e.target.value)}/>

                    <InputTextarea value={detail} rows={10} cols={80}
                                   placeholder={"Detail"}
                                   onChange={(e) => setDetail(e.target.value)}/>

                    <Button className="h-10" onClick={checkNotification}>Check</Button>
                </div>

            </div>
        </div>
    )
        ;
};

export default NotificationCheckPage;
