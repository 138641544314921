import {
    DOCUMENTS_CONTRACTS,
    DOCUMENTS_HR,
    DOCUMENTS_HR_CLAIMS,
    DOCUMENTS_HR_OPERATOR_CONTRACT,
    DOCUMENTS_HR_SUPERVISOR_CONTRACT,
    DOCUMENTS_INSURANCES,
    DOCUMENTS_MEDICAL,
    DOCUMENTS_PAYROLLS,
    DOCUMENTS_REPORT,
    DOCUMENTS_REPORT_INCIDENT_NOTIFICATION,
    DOCUMENTS_REPORT_INCIDENT_VERIFICATION,
    DOCUMENTS_REPORT_OPERATOR_SHIFT,
    DOCUMENTS_REPORT_SUPERVISOR_SHIFT,
    DOCUMENTS_TIMESHEET,
    RoutePath
} from "../../utils/constants.utils";

export enum DocumentCategory {
    Contracts = 'contracts',
    Insurances = 'insurances',
    // TODO: Rename to leave_medical_report when fixed in the backend
    LeaveMedicalReport = 'leave_medial_report',
    Payrolls = 'payrolls',
    Reports = 'reports',
    IncidentNotifications = 'incident_notifications',
    IncidentVerifications = 'incident_verifications',
    SupervisorShifts = 'supervisor_shifts',
    OperatorShifts = 'operator_shifts',
    HR = 'hr',
    Claims = 'claims',
    SupervisorContracts = 'supervisor_contracts',
    OperatorContracts = 'operator_contracts',
    Timesheet = 'timesheet',
}


export namespace DocumentCategory {

    export type SubcategoriesType = {
        [key in DocumentCategory]: DocumentCategory[];
    };

    export const subcategories: SubcategoriesType = {
        [DocumentCategory.Contracts]: [],
        [DocumentCategory.Insurances]: [],
        [DocumentCategory.LeaveMedicalReport]: [],
        [DocumentCategory.Payrolls]: [],
        [DocumentCategory.Reports]: [
            DocumentCategory.IncidentNotifications,
            DocumentCategory.IncidentVerifications,
            DocumentCategory.SupervisorShifts,
            DocumentCategory.OperatorShifts
        ],
        [DocumentCategory.IncidentNotifications]: [],
        [DocumentCategory.IncidentVerifications]: [],
        [DocumentCategory.SupervisorShifts]: [],
        [DocumentCategory.OperatorShifts]: [],
        [DocumentCategory.HR]: [
            DocumentCategory.Claims,
            DocumentCategory.SupervisorContracts,
            DocumentCategory.OperatorContracts
        ],
        [DocumentCategory.Claims]: [],
        [DocumentCategory.SupervisorContracts]: [],
        [DocumentCategory.OperatorContracts]: [],
        [DocumentCategory.Timesheet]: []
    }

    export const getSubcategories = (category: DocumentCategory): DocumentCategory[] => {
        return subcategories[category];
    }

    export type DocumentCategoryRoutesType = {
        [key in DocumentCategory]: RoutePath;
    };

    export const routes: DocumentCategoryRoutesType = {
        [DocumentCategory.Contracts]: DOCUMENTS_CONTRACTS,
        [DocumentCategory.Insurances]: DOCUMENTS_INSURANCES,
        [DocumentCategory.LeaveMedicalReport]: DOCUMENTS_MEDICAL,
        [DocumentCategory.Payrolls]: DOCUMENTS_PAYROLLS,
        [DocumentCategory.Reports]: DOCUMENTS_REPORT,
        [DocumentCategory.IncidentNotifications]: DOCUMENTS_REPORT_INCIDENT_NOTIFICATION,
        [DocumentCategory.IncidentVerifications]: DOCUMENTS_REPORT_INCIDENT_VERIFICATION,
        [DocumentCategory.SupervisorShifts]: DOCUMENTS_REPORT_SUPERVISOR_SHIFT,
        [DocumentCategory.OperatorShifts]: DOCUMENTS_REPORT_OPERATOR_SHIFT,
        [DocumentCategory.HR]: DOCUMENTS_HR,
        [DocumentCategory.Claims]: DOCUMENTS_HR_CLAIMS,
        [DocumentCategory.SupervisorContracts]: DOCUMENTS_HR_SUPERVISOR_CONTRACT,
        [DocumentCategory.OperatorContracts]: DOCUMENTS_HR_OPERATOR_CONTRACT,
        [DocumentCategory.Timesheet]: DOCUMENTS_TIMESHEET
    }

    export const getRoute = (category: DocumentCategory): RoutePath => {
        return routes[category];
    }

    export const virtualCategories: DocumentCategory[] = [
        DocumentCategory.Reports,
        DocumentCategory.IncidentNotifications,
        DocumentCategory.IncidentVerifications,
        DocumentCategory.SupervisorShifts,
        DocumentCategory.OperatorShifts,
        DocumentCategory.Claims,
        DocumentCategory.HR,
        DocumentCategory.Timesheet,
    ]

    export const isVirtualCategory = (category: DocumentCategory): boolean => {
        return virtualCategories.includes(category)
    }
}