import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {Button} from "primereact/button";
import {FaRegEdit} from "react-icons/fa";

interface UserInputProps {
    label: string
    children: React.ReactNode,
    editable?: boolean
    onEdit?: () => void
    editLabel?: string
    compact?: boolean
}

const UserInput: React.FC<UserInputProps> = ({label, children, editable, compact, onEdit, editLabel}) => {
    const {t} = useTranslation();

    const [isEditing, setIsEditing] = useState<boolean>(false);

    let width: string;
    let labelWidth: string;
    let childrenWidth: string;
    let editButtonWidth: string;

    if (compact) {
        if (editable) {
            width = 'grid-cols-8';
            labelWidth = 'col-span-2';
            childrenWidth = 'col-span-4';
            editButtonWidth = 'col-span-2';
        } else {
            width = 'grid-cols-8';
            labelWidth = 'col-span-2';
            childrenWidth = 'col-span-4';
            editButtonWidth = 'col-span-1';
        }
    } else {
        width = 'grid-cols-8';
        labelWidth = 'col-span-1';
        childrenWidth = 'col-span-6';
        editButtonWidth = 'col-span-1';
    }

    return (
        <div className={`${width} items-center grid gap-4`}>
            <div className={`${labelWidth} font-semibold text-lg leading-4`}>
                <p>{label}</p>
            </div>
            <div className={`px-4 ${childrenWidth}`}>
                {children}
            </div>
            {
                editable && (
                    <div className={`${editButtonWidth}`}>
                        <Button className="space-x-1 h-8"
                                onClick={() => {
                                    setIsEditing(!isEditing);
                                    if (onEdit) {
                                        onEdit();
                                    }
                                }}
                        >
                            <FaRegEdit size={14}></FaRegEdit>
                            {
                                editLabel
                                    ? <p>{editLabel}</p>
                                    : isEditing
                                        ? <p>{t('userProfile.save')}</p>
                                        : <p>{t('userProfile.edit')}</p>
                            }
                        </Button>
                    </div>
                )
            }
        </div>
    )
}

export default UserInput;