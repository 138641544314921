import {Navigate, Route, Routes} from "react-router-dom";
import MenuNav from "../components/common/MenuNav";
import CalendarPage from "../pages/common/CalendarPage";
import ProfilePage from "../pages/common/ProfilePage";
import ClaimPage from "../pages/operator/ClaimPage";
import {
    CALENDAR,
    CLAIM,
    DEFAULT_PATH,
    DOCUMENTS,
    DOCUMENTS_CONTRACTS,
    DOCUMENTS_INSURANCES,
    DOCUMENTS_MEDICAL,
    DOCUMENTS_PAYROLLS,
    DOCUMENTS_TIMESHEET,
    NOTIFICATION,
    PROFILE
} from "../utils/constants.utils";
import NotificationPage from "../pages/common/NotificationPage";
import DocumentsArchivePage from "../pages/common/DocumentsArchivePage";
import {DocumentCategory} from "../models/enums/document-category.enum";

const OperatorLayout = () => {
    return (
        <div className="flex">
            <div className="my-10 mx-6">
                <MenuNav/>
            </div>
            <div className="w-full">
                <Routes>
                    <Route path={DEFAULT_PATH} element={<Navigate to={PROFILE} replace={true}/>}/>
                    <Route path={PROFILE} element={<ProfilePage/>}/>
                    <Route path={CALENDAR} element={<CalendarPage/>}/>
                    <Route path={CLAIM} element={<ClaimPage/>}/>

                    <Route path={NOTIFICATION} element={<NotificationPage/>}/>

                    <Route path={DOCUMENTS}
                           element={<DocumentsArchivePage/>}/>
                    <Route path={DOCUMENTS_CONTRACTS}
                           element={<DocumentsArchivePage category={DocumentCategory.Contracts}/>}/>
                    <Route path={DOCUMENTS_PAYROLLS}
                           element={<DocumentsArchivePage category={DocumentCategory.Payrolls}/>}/>
                    <Route path={DOCUMENTS_MEDICAL}
                           element={<DocumentsArchivePage category={DocumentCategory.LeaveMedicalReport}/>}/>
                    <Route path={DOCUMENTS_INSURANCES}
                           element={<DocumentsArchivePage category={DocumentCategory.Insurances}/>}/>
                    <Route path={DOCUMENTS_TIMESHEET}
                           element={<DocumentsArchivePage category={DocumentCategory.Timesheet}/>}/>
                </Routes>
            </div>
        </div>
    );
};

export default OperatorLayout