import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FaCircle} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {USER} from "../utils/constants.utils";
import {useUserContext} from "../contexts/OperatorContextProvider";
import {PendingRequest} from "../models/pending-request.interface";
import {retrievePendingRequests} from "../services/data-manager/data-manager.service";
import {PendingRequestType} from "../models/enums/pending-request-type.enum";
import Loader from "./common/LoaderComponent";

const PendingRequests: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const {level} = useUserContext()
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const CLAIM_REQUESTS: string = t('pendingRequests.claimRequests')
    const LEAVE_REQUESTS: string = t('pendingRequests.leaveRequests')
    const ALL_REQUESTS: string = t('pendingRequests.allRequests')

    const filters = [
        CLAIM_REQUESTS,
        LEAVE_REQUESTS,
        ALL_REQUESTS,
    ]
    const [selectedFilter, setSelectedFilter] = useState(ALL_REQUESTS)
    const [pendingRequests, setPendingRequests] = useState<PendingRequest[]>([])
    const [filteredPendingRequests, setFilteredRequests] = useState<PendingRequest[]>([])

    const managePendingRequest = (request: PendingRequest) => {
        console.log(`Manage Pending Request ${request.id}`)
        navigate(`/${level}/${USER}`, {state: {data: {userId: request.userId}}})
    }

    const getFilterType = useCallback((filter: string): PendingRequestType => {
        switch (filter) {
            case CLAIM_REQUESTS:
                return PendingRequestType.Claims
            case LEAVE_REQUESTS:
                return PendingRequestType.Leaves
            default:
                return PendingRequestType.All
        }
    }, [CLAIM_REQUESTS, LEAVE_REQUESTS])

    useEffect(() => {
        const pendingType: PendingRequestType = PendingRequestType.All
        retrievePendingRequests({pendingType}).then((requests) => {
            setPendingRequests(requests)
            setIsLoading(false)
        })
    }, []);

    useEffect(() => {
        if (selectedFilter === ALL_REQUESTS) {
            setFilteredRequests(pendingRequests)
        } else if (pendingRequests) {
            setFilteredRequests(pendingRequests.filter((request) => request.requestType === getFilterType(selectedFilter)))
        }
    }, [ALL_REQUESTS, getFilterType, pendingRequests, selectedFilter])

    return (
        <div className="card-container">
            <h2 className="card-title">{t('pendingRequests.title')}</h2>
            <div className="options-selector">
                {filters.map((filter, index) => (
                    <button key={index} onClick={() => setSelectedFilter(filter)}
                            className={`option-button-item ${filter === selectedFilter ? 'bg-white text-gray-900' : 'bg-transparent text-white hover:bg-gray-700'}`}
                    >
                        {filter}
                    </button>
                ))}
            </div>
            <div className="card-content flex flex-col items-center">
                {
                    isLoading
                        ? <div style={{width: 200, height: 200}} className='flex justify-center items-center'>
                            <Loader key="loader"/>
                        </div>
                        : filteredPendingRequests.map((request, index) => (
                            <div key={index} className="pending-request-item">
                                <div className="flex items-center space-x-2">
                                    <FaCircle size={16} color="orange"/>
                                    <div className="ellipsis">
                                        {request.operatorName} requested {" "}
                                        <span className="font-bold">{request.requestType}</span>
                                    </div>
                                </div>
                                <button onClick={() => managePendingRequest(request)}
                                        className={'option-button-item'}>
                                    {t('pendingRequests.manage')}
                                </button>
                            </div>
                        ))
                }
            </div>
        </div>
    );
}
export default PendingRequests;
