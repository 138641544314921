import {initializeApp} from "@firebase/app";
import {getMessaging, getToken, onMessage} from "@firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyB0VaMUjLXx_z7ygs0wX4lDUuq1yQaL6UE",
    authDomain: "xx-sec.firebaseapp.com",
    projectId: "xx-sec",
    storageBucket: "xx-sec.appspot.com",
    messagingSenderId: "1097836066955",
    appId: "1:1097836066955:web:81a3da3448931c6b343c8e",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export {messaging, getToken, onMessage};