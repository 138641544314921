import React from 'react'
import {Text} from "@react-pdf/renderer";
import ClassicDocument from "../components/ClassicDocument";
import {ShiftItem} from "../../../services/backend/api/shifts/models/shift-item.interface";
import {formatDate, formatTime} from "../../../utils/date.utils";

interface TimesheetDocumentProps {
    data: ShiftItem[];
}

const TimesheetDocument: React.FC<TimesheetDocumentProps> = ({data}) => {

    const formatDetails = (data: ShiftItem[]) => {
        return data.map((item, index) => {
            return `
            - Shift ID: ${item.shiftId}
              Start: ${formatDate(new Date(item.startDate))} ${formatTime(item.startTime)}
              End: ${formatDate(new Date(item.endDate))} ${formatTime(item.endTime)}
            `
        })
    }
    return (
        <ClassicDocument
            title={"Timesheet"}
            subtitle={`User ID: ${data[0]?.userId || "No data available"}`}
            content={
                <Text>
                    {`
                        Timesheet Details:
                        ${formatDetails(data).join("\n")}
                    `}
                </Text>
            }
        />
    )
}
export default TimesheetDocument