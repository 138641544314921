import {COMMUNICATIONS, consumeApi, POST, USERS} from "../../backend.service";
import {SendCommunicationRequest} from "./requests/send-communication.request";
import {SendCommunicationResponse} from "./responses/send-communication.response";
import {GetCommunicationArchiveResponse} from "./responses/get-communications.response";
import {GroupMembersRequest} from "./requests/group-members.request";
import {GroupMembersResponse} from "./responses/group-members.response";
import {GetCommunicationArchiveRequest} from "./requests/get-communication-archive.request";
import {formatDate} from "../../../../utils/date.utils";
import {CountCommunicationsByCategoryRequest} from "./requests/count-communications-by-category.request";
import {CountCommunicationsByCategoryResponse} from "./responses/count-communications-by-category.response";

export const sendCommunication = async (request: SendCommunicationRequest): Promise<SendCommunicationResponse> => {
    return await consumeApi(POST, `${COMMUNICATIONS}/bulk/send`, "Send Communication", request, true);
};

export const sendClientCommunication = async (request: SendCommunicationRequest): Promise<SendCommunicationResponse> => {
    return await consumeApi(POST, `${COMMUNICATIONS}/client/send`, "Send Client Communication", request, true);
};

export const getCommunicationArchive = async (request: GetCommunicationArchiveRequest): Promise<GetCommunicationArchiveResponse> => {
    return await consumeApi(POST, `${COMMUNICATIONS}/archive`, "Communication Archive", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate),
    }, true);
};

export const getClientCommunicationArchive = async (request: GetCommunicationArchiveRequest): Promise<GetCommunicationArchiveResponse> => {
    return await consumeApi(POST, `${COMMUNICATIONS}/client/view_all`, "Client Communication Archive", {
        ...request,
        startDate: formatDate(request.startDate),
        endDate: formatDate(request.endDate),
    }, true);
};

export const getGroupMembers = async (request: GroupMembersRequest): Promise<GroupMembersResponse> => {
    return await consumeApi(POST, `${USERS}/level`, "Communication Group Members", request, true);
};

export const countCommunicationsByCategory = async (request: CountCommunicationsByCategoryRequest): Promise<CountCommunicationsByCategoryResponse> => {
    return await consumeApi(POST, `${COMMUNICATIONS}/${request.category}/count`, "Count Communications by Category", {}, true);
}

export const notReadCommunicationsCounter = async (): Promise<CountCommunicationsByCategoryResponse> => {
    return await consumeApi(POST, `${COMMUNICATIONS}/notRead`, "Not Read Communications Counter by Category", {}, true);
}

export const notReadManagerCommunicationsCounter = async (): Promise<CountCommunicationsByCategoryResponse> => {
    return await consumeApi(POST, `${COMMUNICATIONS}/manager/notRead`, "Not Read Manager Communications Counter by Category", {}, true);
}