import {FaRegFilePdf} from "react-icons/fa6";
import {IconType} from "react-icons";
import {AiOutlineFileExcel, AiOutlineFileJpg, AiOutlineFileWord} from "react-icons/ai";
import {HiOutlineDocument} from "react-icons/hi";

export const getIconByFileType = (fileType: string): IconType => {
    switch (fileType.toLowerCase()) {
        case "doc":
            return AiOutlineFileWord;
        case "docx":
            return AiOutlineFileWord;
        case "xls":
            return AiOutlineFileExcel;
        case "xlsx":
            return AiOutlineFileExcel;
        case "csv":
            return AiOutlineFileExcel;
        case "jpg":
            return AiOutlineFileJpg;
        case "jpeg":
            return AiOutlineFileJpg;
        case "pdf":
            return FaRegFilePdf;
        default:
            return HiOutlineDocument;
    }
};

export const getIconByFileName = (filename: string): IconType => {
    const fileType = filename.split(".").pop();
    return getIconByFileType(fileType || "");
};