import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import UtilityCard from "./UtilityCard";
import {useNavigate} from "react-router-dom";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {CLAIM_MANAGEMENT} from "../../utils/constants.utils";
import {FaAngleDown, FaAngleUp, FaCircle} from "react-icons/fa6";
import {Claim} from "../../models/claim.interface";
import {IoDocumentTextOutline} from "react-icons/io5";
import {retrieveClaimsByEmployee} from "../../services/data-manager/data-manager.service";
import {oneYearAgo, today} from "../../utils/date.utils";

interface UserClaimsProps {
    userId?: string;
}

const UserClaims: React.FC<UserClaimsProps> = ({userId}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {level} = useUserContext();

    const [claims, setClaims] = useState<Claim[]>([]);
    const [visibleClaimDetails, setVisibleClaimDetails] = useState<string[]>([]);

    const areClaimDetailsVisible = (id: string) => visibleClaimDetails.includes(id);

    const menuOptions = [
        {
            label: t('moreInfo'),
            onClick: () => {
                navigate(`/${level}/${CLAIM_MANAGEMENT}`, {
                    state: {claims, userId}
                });
            }
        }
    ]

    const getClaims = useCallback(async (userId: string) => {
        const request = {startDate: oneYearAgo(), endDate: today(), userId}
        retrieveClaimsByEmployee(request).then((claims) => {
            console.log(claims);
            setClaims(claims);
        });
    }, [])

    useEffect(() => {
        if (userId) {
            getClaims(userId).then(() => console.log("User claims fetched"));
        }
    }, [getClaims, userId])

    return (
        <UtilityCard title={t('userClaims.title')} menuOptions={menuOptions}
                     content={
                         <div className="space-y-1">
                             {
                                 claims.map((claim, index) => (
                                     <div key={index} className='primary-container p-1 '>
                                         <div className="flex w-full items-center justify-between gap-3">

                                             <div>
                                                 <IoDocumentTextOutline size={24} color="var(--primary-color)"
                                                                        className="rounded-full bg-[burlywood] p-1 mr-2"/>
                                             </div>
                                             <div className="w-full">
                                                 {claim.type}
                                             </div>
                                             <div className="whitespace-no-wrap">
                                                 {claim.date.toLocaleDateString()}
                                             </div>
                                             <div className="flex items-center uppercase min-w-28 justify-between">
                                                 <FaCircle size={16} color={claim.isPending ? "orange" : "darkgreen"}/>
                                                 <div>
                                                     {
                                                         claim.isPending ? t('pending') : t('managed')
                                                     }
                                                 </div>
                                             </div>

                                             <div className="flex">
                                                 {
                                                     areClaimDetailsVisible(claim.id)
                                                         ? <div
                                                             onClick={() => setVisibleClaimDetails(visibleClaimDetails.filter((claimId) => claimId !== claim.id))}>
                                                             <FaAngleUp size={24}/>
                                                         </div>
                                                         : <div
                                                             onClick={() => setVisibleClaimDetails([...visibleClaimDetails, claim.id])}>
                                                             <FaAngleDown size={24}/>
                                                         </div>
                                                 }
                                             </div>
                                         </div>
                                         {
                                             areClaimDetailsVisible(claim.id) &&
                                             <div className="accordion-container">
                                                 <p>{claim.id}</p>
                                                 <p>{claim.type}</p>
                                             </div>
                                         }
                                     </div>
                                 ))
                             }
                         </div>
                     }
        />
    )
}

export default UserClaims;