import React from 'react'
import SummaryDocument from "../components/SummaryDocument";
import {LeaveRequest} from "../../../models/leave-request.interface";
import {shortId} from "../../../utils/text-formatting.utils";

interface LeaveRequestsSummaryProps {
    leaves: LeaveRequest[];
}

const LeaveRequestsSummary: React.FC<LeaveRequestsSummaryProps> = ({leaves}) => {
    const formattedLeaves = leaves.map(leave => {
        return {
            ...leave,
            id: shortId(leave.id),
            userId: shortId(leave.userId),
        }
    })
    return (
        <SummaryDocument title="Leave Requests Summary" subtitle="Leave Requests" documents={formattedLeaves}
                         printableKeys={["id", "date", "userId", "category"]}
        />
    )
}
export default LeaveRequestsSummary