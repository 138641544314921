import {useEffect, useState} from "react";
import GeoSearchMap from "./GeoSearchMap";
import {CircleArea} from "../../../models/geo.interface";
import {MdGpsFixed, MdGpsOff} from "react-icons/md";
import {IoIosCloseCircleOutline} from "react-icons/io";
import L, {LatLng} from "leaflet";
import {getMetersLabelByLanguange} from "../../../utils/geo.utils";
import i18next from "i18next";

interface GeoSearchFilterProps {
    onCircleAreaSelected?: (area: CircleArea | null) => void;
}

const DEFAULT_ZOOM = 13;
const DEFAULT_LATITUDE = 24.873;
const DEFAULT_LONGITUDE = 46.619;

const GeoFilterSelector: React.FC<GeoSearchFilterProps> = ({ onCircleAreaSelected }) => {

    const [showMap, setShowMap] = useState(false);
    const [selectedArea, setSelectedArea] = useState<CircleArea | null>(null);
    const [startCenter, setStartCenter] = useState<LatLng>(
        new L.LatLng(
            selectedArea?.lat || DEFAULT_LATITUDE,
            selectedArea?.lng || DEFAULT_LONGITUDE
        )
    );
    const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);


    const handleClose = () => {
        setShowMap(false);
    }

    const handleLocationSelected = (location: CircleArea | null) => {
        if (onCircleAreaSelected) {
            onCircleAreaSelected(location);
        }
        setSelectedArea(location);
    }

    const handleZoomChange = (zoom: number) => {
        setZoom(zoom);
    }

    useEffect(() => {
        if (selectedArea) {
            setStartCenter(new L.LatLng(selectedArea.lat, selectedArea.lng));
        }
    }, [selectedArea]);

    const [ln, setLn] = useState<string>(i18next.language || 'en'); // Imposta la lingua iniziale
    useEffect(() => {
        const handleLanguageChange = (lng: string) => setLn(lng);
        i18next.on("languageChanged", handleLanguageChange);

        return () => {
            i18next.off("languageChanged", handleLanguageChange);
        };
    }, []);

    return (
        <>
            <div className={`px-2 py-2 m-1 text-sm rounded-lg border border-whitesmoke transition-colors duration-300 ease-in-out cursor-pointer whitespace-nowrap 
                    ${showMap
                    ? `bg-white text-gray-900`
                    : `bg-transparent text-white hover:bg-gray-700 flex flex-row gap-2 justify-center items-center w-auto`}`}
            >
                {selectedArea ? <IoIosCloseCircleOutline className="cursor-pointer" onClick={() => handleLocationSelected(null)} /> : null}
                {
                    <div
                        className="flex flex-row gap-2 justify-center items-center overflow-ellipsis whitespace-nowrap overflow-hidden max-w-12"
                        onClick={() => setShowMap(true)}>
                        {selectedArea ? <MdGpsFixed /> : <MdGpsOff />}
                        {selectedArea ? `(${selectedArea.lat.toFixed(4)}, ${selectedArea.lng.toFixed(4)}) - ${selectedArea.radius}${getMetersLabelByLanguange(ln)}` : '(0,0)'}
                    </div>
                }
            </div>
            {
                showMap && (
                    <GeoSearchMap
                        visible={showMap}
                        selectedArea={selectedArea}
                        zoom={zoom}
                        center={startCenter}
                        onZoomChange={handleZoomChange}
                        onLocationSelected={handleLocationSelected}
                        onClose={handleClose}
                    />
                )
            }
        </>
    );
};

export default GeoFilterSelector;