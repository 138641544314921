import React from "react";

interface TimeButtonProps {
    label: React.ReactNode;
    isActive: boolean;
    onClick: () => void;
    defaultBackgroundColor?: string;
    defaultTextColor?: string;
    activeBackgroundColor?: string;
    activeTextColor?: string;
}

const FilterButton: React.FC<TimeButtonProps> = (props) => {

    const defaultBackgroundColor = props.defaultBackgroundColor || 'bg-transparent';
    const defaultTextColor = props.defaultTextColor || 'text-white';
    const activeBackgroundColor = props.activeBackgroundColor || 'bg-white';
    const activeTextColor = props.activeTextColor || 'text-gray-900';

    return (
        <button
            onClick={props.onClick}
            className={`option-button-item 
                ${props.isActive
                ? `${activeBackgroundColor} ${activeTextColor}`
                : `${defaultBackgroundColor} ${defaultTextColor} hover:bg-gray-700`}`
            }
        >
            {props.label}
        </button>
    )
}

export default FilterButton;