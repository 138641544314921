import {UserRole} from "./user-role.enum";

export enum CommunicationReceiver {
    All = "communication_all",
    Operator = "communication_operator",
    Supervisor = "communication_supervisor",
    HR = "communication_hr",
    Provider = "communication_hses_provider",
    Client = "communication_hses_client",
}


export namespace CommunicationReceiver {

    export const getAvailableReceivers = (level: UserRole): CommunicationReceiver[] => {
        switch (level) {
            case UserRole.Client:
                return [CommunicationReceiver.Provider];
            case UserRole.Provider:
                return [CommunicationReceiver.Supervisor, CommunicationReceiver.Operator, CommunicationReceiver.HR, CommunicationReceiver.Client];
            case UserRole.HR:
                return [CommunicationReceiver.Supervisor, CommunicationReceiver.Operator, CommunicationReceiver.Provider, CommunicationReceiver.Client];
            case UserRole.Supervisor:
                return [CommunicationReceiver.Operator, CommunicationReceiver.HR];
            case UserRole.Operator:
                return [CommunicationReceiver.Supervisor, CommunicationReceiver.HR];
            default:
                return [];
        }
    }

    export const getRole = (receiver: CommunicationReceiver): UserRole => {
        switch (receiver) {
            case CommunicationReceiver.Client:
                return UserRole.Client;
            case CommunicationReceiver.Provider:
                return UserRole.Provider;
            case CommunicationReceiver.HR:
                return UserRole.HR;
            case CommunicationReceiver.Supervisor:
                return UserRole.Supervisor;
            case CommunicationReceiver.Operator:
                return UserRole.Operator;
            default:
                return UserRole.None;
        }
    }
}