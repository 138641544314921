import React from 'react'
import GraphSummaryDocument from "../components/GraphSummaryDocument";

interface AttendancesSummaryProps {
    data: any;
}

const AttendancesSummary: React.FC<AttendancesSummaryProps> = ({data}) => {
    return (
        <GraphSummaryDocument title="Attendances Summary" subtitle="Report Data" data={data}/>
    )
}
export default AttendancesSummary