import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import AsyncStorage from '@react-native-async-storage/async-storage';
import ErrorComponent from '../../components/common/ErrorComponent';
import Loader from "../../components/common/LoaderComponent";
import {useTranslation} from 'react-i18next';
import {BiLogInCircle} from "react-icons/bi";
import {requestForToken} from "../../services/push-notification/firebaseMessaging";
import {login} from "../../services/backend/api/general/general.apis";
import {LoginRequest} from "../../services/backend/api/general/requests/login.request";


const LoginPage = () => {
    const {t} = useTranslation();
    const [userName, setUsername] = useState("");
    const [pin, setPin] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const {setName} = useUserContext();
    const {setId} = useUserContext();
    const {setLevel} = useUserContext();

    const handleUsernameChange = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setUsername(event.target.value);
    };

    const handlePinChange = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setPin(event.target.value);
    };

    const handleLogin = async () => {
        try {
            setIsLoading(true);

            const deviceId = "..."; // Ottenere l'ID del dispositivo
            const token = await requestForToken() || "";
            if (token === "") {
                console.error("Notification token is unavailable");
            } else {
                console.log("Notification token: ", token);
            }
            const request: LoginRequest = {
                userName,
                password: pin,
                deviceId,
                fcmToken: token
            };
            const userData = await login(request);
            if (userData && !userData.error) {
                const session = userData.session;
                await AsyncStorage.setItem("session", session);

                setName(userName);
                setId(userData.userId);
                console.log("User ID: ", userData.userId);
                setLevel(userData.userLevel);

                const levelOperator = userData.userLevel
                navigate(levelOperator);
            } else {
                setErrorMessage(
                    userData.message ||
                    "Si è verificato un errore durante il tentativo di accesso. Riprova più tardi."
                );
                setShowErrorModal(true);
                setUsername("");
                setPin("");
            }
        } catch (error) {
            setUsername("");
            setPin("");
            console.error("Errore durante il login:", error);
            setErrorMessage(
                error?.toString() ||
                "Si è verificato un errore durante il tentativo di accesso. Riprova più tardi."
            );
            setShowErrorModal(true);
        } finally {
            setIsLoading(false);
        }
    };

    const buttonStyle = {
        borderLeft: `2px solid ${userName === "" || pin === "" ? "red" : "white"}`,
        borderRight: `2px solid ${userName === "" || pin === "" ? "red" : "white"}`,
        opacity: userName === "" || pin === "" ? 0.4 : 1,
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setErrorMessage("");
        }, 5000);

        return () => clearTimeout(timer);
    }, [errorMessage]);

    return (
        <div
            className="flex flex-col items-center justify-center height-page m-auto"
            style={{width: "330px"}}
        >
            {isLoading && <Loader/>}

            {!isLoading && (
                <>
                    <div className={`container-box-login ${isLoading ? "hidden" : ""}`}>
                        <p className="text-sm text-center mb-5" dangerouslySetInnerHTML={{__html: t('loginTitle')}}>
                        </p>
                        <label className="text-sm mb-1" htmlFor="Username">
                            {t('userIdLabel')}
                        </label>
                        <input
                            type="text"
                            placeholder={t('userIdLabel')}
                            value={userName}
                            onChange={handleUsernameChange}
                            className="input-field"
                        />
                        <label className="text-sm mb-1" htmlFor="password">
                            {t('passwordLabel')}
                        </label>
                        <input
                            type="password"
                            placeholder={t('passwordLabel')}
                            value={pin}
                            onChange={handlePinChange}
                            className="input-field"
                        />
                    </div>
                    <button
                        onClick={handleLogin}
                        className={`primary-button uppercase ${isLoading ? "hidden" : ""}`}
                        style={buttonStyle}
                        disabled={userName === "" || pin === ""}
                    >
                        <BiLogInCircle
                            style={{marginRight: 10}}
                            size={28} color="whitesmoke"
                        />
                        {t('loginButton')}
                    </button>

                    <Link to="/forgotPassword" className="text-sm mt-10 underline">
                        {t('forgotPassword')}
                    </Link>
                </>
            )}
            {errorMessage && showErrorModal && <ErrorComponent message={errorMessage}/>}

            {/* {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>} */}
        </div>
    );
};

export default LoginPage;
