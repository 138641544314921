import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {defaultStyles} from "../styles/default.styles";
import HorizontalSeparator from "./HorizontalSeparator";
import React from "react";

const styles = StyleSheet.create({
    ...defaultStyles
});

const Footer: React.FC = () => {
    return (
        <View style={styles.footer}>
            <HorizontalSeparator/>
            <View>
                <Text>The content of this document is confidential and intended solely for the use of authorized
                    personnel. Unauthorized distribution, sharing, or copying of this document or its contents is
                    strictly prohibited. If you are not an authorized recipient, please notify the sender immediately
                    and delete all copies of this document from your systems.</Text>
            </View>
            <HorizontalSeparator/>
        </View>
    )
}

export default Footer