interface Country {
    code: string
    name: string
}

const getCountries = (lang = 'en') => {
    const A = 65
    const Z = 90
    const countryName = new Intl.DisplayNames([lang], {type: 'region'});
    const countries: Country[] = []
    for (let i = A; i <= Z; ++i) {
        for (let j = A; j <= Z; ++j) {
            let code = String.fromCharCode(i) + String.fromCharCode(j)
            let name = countryName.of(code)
            if (code !== name && name) {
                countries.push({code, name})
            }
        }
    }
    return countries
}

export const findCountryCodeByName = (name: string) => {
    return getCountries().find(country => country.name === name)
}

export const findCountryNameByCode = (code: string): string => {
    const targetCode = code.toUpperCase()
    return getCountries().find(country => country.code === targetCode)?.name || ''
}

export const COUNTRIES: string[] = getCountries().map(country => country.name)