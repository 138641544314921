import {StyleSheet} from "@react-pdf/renderer";

export const defaultStyles = StyleSheet.create({
    page: {color: 'dimgrey', textAlign: 'center', backgroundColor: 'whitesmoke', fontFamily: 'Helvetica', fontSize: 12},
    container: {textAlign: 'center', marginVertical: 90, marginHorizontal: 40},
    header: {textAlign: 'left', fontSize: 18, position: 'absolute', top: 20, left: 30},
    logo: {width: 70, height: 70, backgroundColor: 'grey', borderRadius: 12},
    title: {textAlign: 'center', fontSize: 28},
    subtitle: {textAlign: 'left', fontSize: 20, marginVertical: 10},
    content: {flexDirection: 'row', flexGrow: 1, alignItems: 'center', justifyContent: 'center'},
    textSection: {
        flexDirection: 'row',
        flexGrow: 1,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        textAlign: 'left',
        fontSize: 14,
        marginVertical: 10
    },
    separator: {padding: 10, width: '100%', textAlign: 'center'},
    footer: {textAlign: 'left', fontSize: 8, position: 'absolute', bottom: 30, margin: 30},
    table: {width: '90vw', borderStyle: 'solid', borderWidth: 1, padding: 8},
    tableHeader: {display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'},
    tableBody: {display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'},
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '1px dashed grey'
    },
    headerCell: {
        textAlign: 'left',
        width: '100%',
        padding: 4,
        justifyContent: 'flex-start',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold'
    },
    rowCell: {textAlign: 'left', width: '100%', padding: 4, justifyContent: 'flex-start'},
});

