import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Loader from "../common/LoaderComponent";
import {BlobProvider} from "@react-pdf/renderer";
import {FiDownload} from "react-icons/fi";
import TasksSummary from "../pdf/summaries/TasksSummary";
import {tasksGraph} from "../../services/backend/api/graphs/graphs.apis";
import FiltersSelector from "./FiltersSelector";
import {Employee} from "../../models/employee.interface";
import {TasksGraphRequest} from "../../services/backend/api/graphs/requests/tasks-graph.request";
import {retrieveEmployees, retrieveTasksPreset} from "../../services/data-manager/data-manager.service";
import {TaskPreset} from "../../models/task-preset";
import {CircleArea} from '../../models/geo.interface';
import {midnight, today} from "../../utils/date.utils";

interface GraphDataItem {
    name: string;
    value: number;
    color: string;
}

interface TasksGraphProps {
    compact?: boolean;
    tasksGraphData?: any;
}

const TasksGraph: React.FC<TasksGraphProps> = ({compact, tasksGraphData}) => {
    const {t, i18n} = useTranslation();
    const [activeTab, setActiveTab] = useState(t("provider_graph.Today"));
    const [rangeOptions, setRangeOptions] = useState([
        t("provider_graph.Today"),
        t("provider_graph.ThisWeek"),
        t("provider_graph.ThisMonth"),
        t("provider_graph.ThisYear")
    ]);

    const [maxValue, setMaxValue] = useState(100);

    const [graphData, setGraphData] = useState<GraphDataItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const formatDate = (date: Date): string => {
        return date.toISOString().split('T')[0];
    };

    const [startDate, setStartDate] = useState<Date>(midnight());
    const [endDate, setEndDate] = useState<Date>(today());
    const [typologies, setTypologies] = useState<string[]>([]);
    const [operators, setOperators] = useState<string[]>([]);
    const [availableOperators, setAvailableOperators] = useState<Employee[]>([]);
    const [availableTypologies, setAvailableTypologies] = useState<TaskPreset[]>([]);
    const [selectedTypologies, setSelectedTypologies] = useState<TaskPreset[]>();
    const [selectedLocation, setSelectedLocation] = useState<CircleArea | null>(null);

    const handleTypologiesChange = (values: string[]) => {
        const selected = availableTypologies.filter((typology) => values.includes(typology.title));
        setSelectedTypologies(selected);
    }

    useEffect(() => {
        retrieveEmployees().then((employees) => {
            setAvailableOperators(employees);
        });
    }, []);

    const setDateRangeFromString = (tab: string) => {
        const today = new Date();
        setActiveTab(tab);
        switch (tab) {
            case t("provider_graph.Today"):
                setStartDate(today);
                setEndDate(today);
                break;
            case t("provider_graph.ThisWeek"):
                const startWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
                setStartDate(startWeek);
                setEndDate(today);
                break;
            case t("provider_graph.ThisMonth"):
                const startMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                setStartDate(startMonth);
                setEndDate(today);
                break;
            case t("provider_graph.ThisYear"):
                const startYear = new Date(today.getFullYear(), 0, 1);
                setStartDate(startYear);
                setEndDate(today);
                break;
            default:
                console.log("Invalid tab");
                setStartDate(today);
                setEndDate(today);
                break;
        }
    };

    useEffect(() => {
        retrieveTasksPreset().then(presets => {
            setAvailableTypologies(presets);
        })
    }, []);

    useEffect(() => {
        const fetchReportGraph = async () => {
            try {
                setIsLoading(true);
                console.log("Fetching incident graph data from ", startDate, " to ", endDate);
                let tasksData = tasksGraphData;
                let operatorIds: string[] | undefined = operators;
                if (operatorIds && operatorIds.length === 0) {
                    operatorIds = undefined;
                }
                let taskTemplateIds: string[] | undefined = selectedTypologies?.map((typology) => typology.id);
                if (taskTemplateIds && taskTemplateIds.length === 0) {
                    taskTemplateIds = undefined;
                }
                if (!tasksData) {
                    const request: TasksGraphRequest = {
                        startTime: startDate,
                        endTime: endDate,
                        position: selectedLocation || undefined,
                        operatorIds: operatorIds,
                        taskTemplateIds: taskTemplateIds
                    }
                    tasksData = await tasksGraph(request);
                }

                const newGraphData = [
                    {name: 'Completed', value: tasksData.taskStatus.completed, color: '#4CAF50'},
                    {name: 'Ongoing', value: tasksData.taskStatus.ongoing, color: '#03A9F4'},
                    {name: 'Suspended', value: tasksData.taskStatus.suspended, color: '#FFC107'},
                ];
                setGraphData(newGraphData);

                const tasksCounts = tasksData.taskStatus.completed + tasksData.taskStatus.ongoing + tasksData.taskStatus.suspended;
                const totalTasks = tasksCounts === 0 ? 1 : tasksCounts;

                setMaxValue(totalTasks); // Usa almeno 100 come valore minimo

                setIsLoading(false);
            } catch
                (error) {
                console.error("Errore durante il recupero dei dati delle Report:", error);
                setGraphData([
                    {name: 'Completed', value: 0, color: '#4CAF50'},
                    {name: 'Ongoing', value: 0, color: '#03A9F4'},
                    {name: 'Suspended', value: 0, color: '#FFC107'},
                ]);
                setMaxValue(100);
                setIsLoading(false);
            }
        };

        if (startDate && endDate) {
            fetchReportGraph().then(r => console.log('Report Graph fetched'));
        }
    }, [startDate, endDate, tasksGraphData, operators, selectedTypologies, selectedLocation]);

    useEffect(() => {
        const handleLanguageChange = () => {
            setRangeOptions(
                [t("provider_graph.Today"), t("provider_graph.ThisWeek"), t("provider_graph.ThisMonth"), t("provider_graph.ThisYear")]
            )
        };
        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n, t]);

    const renderArc = (radius: number, strokeWidth: number, value: number, color: string, index: number) => {
        const circumference = radius * 2 * Math.PI;
        const percentage = (value / maxValue) * 100;
        const strokeDashoffset = circumference - (percentage / 100) * circumference;

        return (
            <circle
                key={index}
                className="arc-animation"
                stroke={color}
                fill="transparent"
                strokeWidth={strokeWidth}
                strokeDasharray={`${circumference} ${circumference}`}
                strokeLinecap="round"
                r={radius}
                cx={100}
                cy={100}
                style={{
                    strokeDashoffset: circumference,
                    transition: `stroke-dashoffset 1s ease-in-out ${index * 0.2}s`,
                    '--target-dash-offset': strokeDashoffset
                } as React.CSSProperties}
            />
        );
    };

    return (
        <div className="card-container"
             style={{
                 padding: compact ? "0" : "0.6rem"
             }}
        >
            {
                !compact &&
                <h2 className="card-title">{t("provider_graph.Tasks")}</h2>
            }
            <FiltersSelector
                showDate={true}
                showTime={true}
                showTypology={true}
                showOperators={true}
                showGps={true}
                onDateChange={(period) => setDateRangeFromString(period)}
                onStartTimeChange={(date) => setStartDate(date)}
                onEndTimeChange={(date) => setEndDate(date)}
                onTypologiesChange={(values) => handleTypologiesChange(values)}
                onOperatorsChange={(values) => setOperators(values)}
                onGpsChange={(location: CircleArea | null) => {
                    setSelectedLocation(location)
                }}
                typologies={availableTypologies.map((typology: TaskPreset) => typology.title)}
                operators={availableOperators}
            />

            <div className="card-content">
                <div className="graph"
                     style={{
                         height: compact ? "10.8rem" : "16rem",
                     }}
                >
                    {isLoading ? (
                        <Loader/>
                    ) : (
                        <svg
                            height={compact ? "160" : "200"}
                            width={compact ? "160" : "200"}
                            viewBox="0 0 200 200"
                            className="rotate-45"
                        >
                            <circle cx="100" cy="100" r="95" stroke="#4a5568" strokeWidth="10" fill="transparent"/>
                            <circle cx="100" cy="100" r="75" stroke="#4a5568" strokeWidth="10" fill="transparent"/>
                            <circle cx="100" cy="100" r="55" stroke="#4a5568" strokeWidth="10" fill="transparent"/>
                            {graphData.map((data, index) =>
                                renderArc(95 - index * 20, 10, data.value, data.color, index)
                            )}
                        </svg>
                    )}

                    <div className="w-2/5 p-4">

                        <div className="flex items-center mb-2">
                            <div className="w-4 h-4 mr-2" style={{backgroundColor: '#4CAF50'}}></div>
                            <span className="text-white text-sm">{t("provider_graph.Completed")}</span>
                        </div>

                        <div className="flex items-center mb-2">
                            <div className="w-4 h-4 mr-2" style={{backgroundColor: '#03A9F4'}}></div>
                            <span className="text-white text-sm">{t("provider_graph.OnGoing")}</span>
                        </div>

                        <div className="flex items-center mb-2">
                            <div className="w-4 h-4 mr-2" style={{backgroundColor: '#FFC107'}}></div>
                            <span className="text-white text-sm">{t("provider_graph.Suspended")}</span>
                        </div>
                    </div>
                </div>

            </div>

            <BlobProvider document={<TasksSummary data={graphData}/>}>
                {({url, blob}) => (
                    url &&
                    <a href={url} target="_blank" className="primary-button uppercase bordered-button "
                       rel="noreferrer">
                        <FiDownload size={20} className="mx-2"/>
                        {t("documentPage.download")}
                    </a>
                )}
            </BlobProvider>
        </div>
    );
};

export default TasksGraph;