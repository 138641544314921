import {CommunicationReceiver} from "../models/enums/communication-receiver.enum";

export const HOME: string = "home"
export const TERMINATED_WORKER: string = "terminated-worker"
export const CALENDAR: string = "calendar"
export const PROFILE: string = "profile"
export const DOCUMENTS: string = "documents"
export const DOCUMENTS_CONTRACTS: string = `${DOCUMENTS}/contracts`
export const DOCUMENTS_PAYROLLS: string = `${DOCUMENTS}/payrolls`
export const DOCUMENTS_MEDICAL: string = `${DOCUMENTS}/medical`
export const DOCUMENTS_INSURANCES: string = `${DOCUMENTS}/insurances`
export const DOCUMENTS_REPORT: string = `${DOCUMENTS}/report`
export const DOCUMENTS_REPORT_INCIDENT_NOTIFICATION: string = `${DOCUMENTS_REPORT}/incident-notification`
export const DOCUMENTS_REPORT_INCIDENT_VERIFICATION: string = `${DOCUMENTS_REPORT}/incident-verification`
export const DOCUMENTS_REPORT_SUPERVISOR_SHIFT: string = `${DOCUMENTS_REPORT}/supervisor-shift`
export const DOCUMENTS_REPORT_OPERATOR_SHIFT: string = `${DOCUMENTS_REPORT}/operator-shift`
export const DOCUMENTS_HR: string = `${DOCUMENTS}/hr`
export const DOCUMENTS_HR_CLAIMS: string = `${DOCUMENTS_HR}/claims`
export const DOCUMENTS_HR_SUPERVISOR_CONTRACT: string = `${DOCUMENTS_HR}/supervisor-contract`
export const DOCUMENTS_HR_OPERATOR_CONTRACT: string = `${DOCUMENTS_HR}/operator-contract`
export const DOCUMENTS_TIMESHEET: string = `${DOCUMENTS_HR}/timesheet`
export const CLAIM: string = "claim"
export const CLAIM_MANAGEMENT: string = `${CLAIM}/management`
export const NOTIFICATION: string = "notification"
export const COMMUNICATION: string = "communication"
export const COMMUNICATION_OPERATOR: string = `${COMMUNICATION}/${CommunicationReceiver.Operator}`
export const COMMUNICATION_SUPERVISOR: string = `${COMMUNICATION}/${CommunicationReceiver.Supervisor}`
export const COMMUNICATION_HR: string = `${COMMUNICATION}/${CommunicationReceiver.HR}`
export const COMMUNICATION_HSES_PROVIDER: string = `${COMMUNICATION}/${CommunicationReceiver.Provider}`
export const COMMUNICATION_HSES_CLIENT: string = `${COMMUNICATION}/${CommunicationReceiver.Client}`
export const GOAL: string = "goal"
export const TASK: string = "task"
export const EMPLOYEES: string = "employees"
export const LEAVES: string = 'leaves'
export const WORK_REPORTS: string = 'work-reports'
export const USER: string = "user"
export const USER_CREATE: string = `${USER}/create`
export const WORKER_OVERVIEW: string = `worker/overview`
export type RoutePath =
    | typeof HOME
    | typeof CALENDAR
    | typeof PROFILE
    | typeof DOCUMENTS
    | typeof DOCUMENTS_CONTRACTS
    | typeof DOCUMENTS_PAYROLLS
    | typeof DOCUMENTS_MEDICAL
    | typeof DOCUMENTS_INSURANCES
    | typeof DOCUMENTS_REPORT
    | typeof DOCUMENTS_REPORT_INCIDENT_NOTIFICATION
    | typeof DOCUMENTS_REPORT_INCIDENT_VERIFICATION
    | typeof DOCUMENTS_REPORT_SUPERVISOR_SHIFT
    | typeof DOCUMENTS_REPORT_OPERATOR_SHIFT
    | typeof DOCUMENTS_HR
    | typeof DOCUMENTS_HR_CLAIMS
    | typeof DOCUMENTS_HR_SUPERVISOR_CONTRACT
    | typeof DOCUMENTS_HR_OPERATOR_CONTRACT
    | typeof DOCUMENTS_TIMESHEET
    | typeof CLAIM
    | typeof CLAIM_MANAGEMENT
    | typeof NOTIFICATION
    | typeof COMMUNICATION
    | typeof COMMUNICATION_OPERATOR
    | typeof COMMUNICATION_SUPERVISOR
    | typeof COMMUNICATION_HR
    | typeof COMMUNICATION_HSES_PROVIDER
    | typeof COMMUNICATION_HSES_CLIENT
    | typeof GOAL
    | typeof TASK
    | typeof EMPLOYEES
    | typeof LEAVES
    | typeof WORK_REPORTS
    | typeof USER
    | typeof USER_CREATE
    | typeof WORKER_OVERVIEW

export const DEFAULT_PATH: string = "/"

export const ALL = "all";

const TODAY = new Date();
const EXPECTED_START_DATE = new Date();
const EXPECTED_END_DATE = new Date();
const EXPECTED_HOURS_DURATION = 8;

TODAY.setHours(0, 0, 0, 0);
EXPECTED_START_DATE.setHours(9, 0, 0, 0);
EXPECTED_END_DATE.setHours(17, 0, 0, 0);

export {TODAY, EXPECTED_START_DATE, EXPECTED_END_DATE, EXPECTED_HOURS_DURATION}

export const SUCCESS = 'success';
export const INFO = 'info';
export const WARN = 'warn';
export const ERROR = 'error';
export const SECONDARY = 'secondary';
export const CONTRAST = 'contrast';

export const SEVERITIES = [SUCCESS, INFO, WARN, ERROR, SECONDARY, CONTRAST];