import React, {useCallback, useEffect, useRef, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import CategoryFiles from "../../components/documents/CategoryFiles";
import UpdateFile from "../../components/documents/UpdateFile";
import {useTranslation} from "react-i18next";
import {Employee} from "../../models/employee.interface";
import {UserRole} from "../../models/enums/user-role.enum";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {DocumentCategory} from "../../models/enums/document-category.enum";
import {IoFolderOpenSharp} from "react-icons/io5";
import {HiOutlineDotsVertical} from "react-icons/hi";
import {OverlayPanel} from "primereact/overlaypanel";
import {retrieveEmployee} from "../../services/data-manager/data-manager.service";
import {
    downloadAllCategoryFiles,
    getCategoryDocumentsCounter
} from "../../services/document-manager/document-manager.service";


interface DocumentsArchivePageProps {
    category?: DocumentCategory;
}

const DocumentsArchivePage: React.FC<DocumentsArchivePageProps> = ({category}) => {
    const {t} = useTranslation();

    const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
    const [uploadModalTitle, setUploadModalTitle] = useState<string>("");
    const [uploadModalCategory, setUploadModalCategory] = useState<string>("");
    const [uploadModalNumberFile, setUploadModalNumberFile] = useState<number>(0);

    const [employee, setEmployee] = useState<Employee | null>(null);
    const [availableCategories, setAvailableCategories] = useState<DocumentCategory[]>([]);

    const [userId, setUserId] = useState<string>();
    const {state} = useLocation();
    const {level} = useUserContext();

    const categoryMenuOverlayPanel = useRef<OverlayPanel>(null)

    type FilesCounterType = { [key in DocumentCategory]: number };
    const [filesCounter, setFilesCounter] = useState<FilesCounterType>();
    const [selectedMenuCategory, setSelectedMenuCategory] = useState<DocumentCategory>();

    const [refreshRecentFiles, setRefreshRecentFiles] = useState<boolean>(false);

    const showCategoryMenuOverlayPanel = (e: any, category: DocumentCategory) => {
        categoryMenuOverlayPanel.current?.toggle(e)
        e.preventDefault();
        e.stopPropagation();
        setSelectedMenuCategory(category);
    };

    const hideCategoryMenuOverlayPanel = () => {
        categoryMenuOverlayPanel.current?.hide();
        setSelectedMenuCategory(undefined);
    }

    const isUploadAllowed = (category: string) => {
        if (level === UserRole.Client || level === UserRole.Provider) {
            return false
        }

        if (level === UserRole.Operator || level === UserRole.Supervisor) {
            return category === DocumentCategory.LeaveMedicalReport;
        }

        if (level === UserRole.HR) {
            return true
        }
    };

    const getCategoryRoute = (category: DocumentCategory): string => {
        return `/${level}/${DocumentCategory.getRoute(category)}`;
    }

    const getSubcategories = (category: DocumentCategory): DocumentCategory[] => {
        return DocumentCategory.getSubcategories(category);
    }

    const showUploadModal = (category: DocumentCategory) => {
        setUploadModalTitle(t(`documentsArchivePage.categories.${category}`));
        setUploadModalCategory(category);
        if (filesCounter && filesCounter[category]) {
            setUploadModalNumberFile(filesCounter[category]);
        } else {
            setUploadModalNumberFile(0);
        }
        setIsUploadModalOpen(true);
    }

    const getMainCategories = useCallback((): DocumentCategory[] => {
        // TODO: fetch categories from backend if needed
        switch (level) {
            case UserRole.Client:
                return [DocumentCategory.Reports, DocumentCategory.Contracts, DocumentCategory.Insurances];
            case UserRole.Provider:
                return [DocumentCategory.Reports, DocumentCategory.HR, DocumentCategory.Contracts, DocumentCategory.Insurances];
            case UserRole.Operator:
                return [DocumentCategory.Payrolls, DocumentCategory.Contracts, DocumentCategory.LeaveMedicalReport, DocumentCategory.Insurances, DocumentCategory.Timesheet];
            case UserRole.Supervisor:
                return [DocumentCategory.Payrolls, DocumentCategory.Contracts, DocumentCategory.LeaveMedicalReport, DocumentCategory.Insurances, DocumentCategory.Timesheet];
            case UserRole.HR:
                // TODO: check HR/HR->operator  categories
                return [DocumentCategory.Contracts, DocumentCategory.Payrolls, DocumentCategory.Insurances, DocumentCategory.Timesheet];
            default:
                return [];
        }
    }, [level])

    const getFileCounters = useCallback(async () => {
        const categoriesFilesCounter: FilesCounterType = {} as FilesCounterType;
        for (const category of availableCategories) {
            await getCategoryDocumentsCounter(category, userId)
                .then((count) => {
                    categoriesFilesCounter[category] = count;
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    categoriesFilesCounter[category] = 0;
                })
        }
        setFilesCounter({...categoriesFilesCounter});
    }, [availableCategories, userId])


    useEffect(() => {
        getFileCounters().then(() => console.log("Files counter fetched"));
    }, [getFileCounters]);

    useEffect(() => {
        let userId = state?.userId;
        if (userId) {
            setUserId(userId);
            retrieveEmployee({userId}).then(employee => {
                setEmployee(employee);
            });
            // TODO: fetch documents by userId
        }

        let categories: DocumentCategory[];
        if (!category) {
            categories = getMainCategories();
        } else {
            categories = getSubcategories(category);
        }
        categories = categories.filter((c) => c !== category);
        setAvailableCategories(categories)

    }, [category, getMainCategories, state?.userId])

    return (
        <div className="page-container mr-12">
            <div className="page-title flex justify-between">
                {
                    employee
                        ?
                        <h2>{`${employee.name} ${employee.surname}`} &gt; {t('documentsArchivePage.title')}</h2>
                        : <h2>{t('documentsArchivePage.title')}</h2>
                }
                {
                    category && availableCategories.length === 0 &&
                    isUploadAllowed(category) &&
                    <div>
                        <button className="custom-button button-yellow"
                                onClick={() => {
                                    showUploadModal(category);
                                }}
                        >
                            {t('documentsArchivePage.upload')}
                        </button>
                    </div>
                }
            </div>

            <div className="flex space-x-12 w-full pt-2">
                {
                    availableCategories.map((category, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <div className="w-full" key={category}>
                                <NavLink to={getCategoryRoute(category)}>
                                    <div
                                        className="custom-button padding-section-document style-section-document button-yellow">
                                        <div>
                                            <div className="mb-3 flex justify-between">
                                                <IoFolderOpenSharp
                                                    size={32} color="whitesmoke"
                                                />
                                                <div className="relative">
                                                    <HiOutlineDotsVertical
                                                        size={24} color="whitesmoke"
                                                        onClick={(e) => showCategoryMenuOverlayPanel(e, category)}
                                                    />
                                                </div>
                                            </div>
                                            <p className="title-style text-2xl">
                                                {t(`documentsArchivePage.categories.${category}`)}
                                            </p>
                                        </div>
                                        <p className="text-xl">
                                            <>
                                                {(filesCounter && filesCounter[category] !== null) ? filesCounter[category] : t('documentsArchivePage.calculating')}
                                                {" "}
                                                {t('documentsArchivePage.files')}
                                            </>
                                        </p>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className="pr-12 flex w-full">
                <div className="w-full">
                    <h3 className="m-5 title-style text-2xl w-full">
                        {availableCategories.length === 0 && category
                            ? t(`documentsArchivePage.categories.${category}`)
                            : t("documentPage.recentUpload")}
                    </h3>
                </div>
            </div>
            {
                <CategoryFiles refresh={refreshRecentFiles} category={category} userId={userId}/>
            }

            {
                <OverlayPanel ref={categoryMenuOverlayPanel} showCloseIcon
                              className='primary-container border border-solid border-yellow-500 min-w-40 p-2
                              translate-y-52 -translate-x-32'>
                    <div className="flex flex-col cursor-pointer text-[color:var(--text-color)]">
                        {
                            selectedMenuCategory && isUploadAllowed(selectedMenuCategory) &&
                            <div onClick={() => {
                                showUploadModal(selectedMenuCategory)
                            }}>
                                {t('documentsArchivePage.upload')}
                            </div>
                        }
                        <div onClick={() => selectedMenuCategory && downloadAllCategoryFiles(selectedMenuCategory)}>
                            {t('documentsArchivePage.downloadAll')}
                        </div>
                        <div onClick={hideCategoryMenuOverlayPanel}>
                            {t('documentsArchivePage.close')}
                        </div>
                    </div>
                </OverlayPanel>
            }

            {isUploadModalOpen && (
                <UpdateFile
                    folderName={uploadModalTitle}
                    category={uploadModalCategory}
                    closeModal={() => {
                        setIsUploadModalOpen(false)
                        setRefreshRecentFiles(!refreshRecentFiles)
                    }}
                    numFiles={uploadModalNumberFile}
                />
            )}
        </div>
    );
};

export default DocumentsArchivePage;
