import {PrimeReactPTOptions} from "primereact/api";
import Tailwind from 'primereact/passthrough/tailwind';
import {classNames} from "primereact/utils";

const TRANSITIONS = {
    overlay: {
        timeout: 150,
        classNames: {
            enter: 'opacity-0 scale-75',
            enterActive: 'opacity-100 !scale-100 transition-transform transition-opacity duration-150 ease-in',
            exit: 'opacity-100',
            exitActive: '!opacity-0 transition-opacity duration-150 ease-linear'
        }
    },
    toggleable: {
        timeout: 500,
        classNames: {
            enter: 'max-h-0',
            enterActive:
                '!max-h-[1000px] overflow-hidden transition-[max-height] duration-500 ease-in',
            exit: 'max-h-[1000px]',
            exitActive:
                '!max-h-0 overflow-hidden transition-[max-height] duration-500 ease-out',
        }
    }
};

const DesignSystem: PrimeReactPTOptions = {
    ...Tailwind,
    global: {
        css: `
        .progressbar-value-animate::after {
            will-change: left, right;
            animation: p-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        }
        .progressbar-value-animate::before {
            will-change: left, right;
            animation: p-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        }
        @keyframes p-progressbar-indeterminate-anim {
            0% {
                left: -35%;
                right: 100%;
            }
            60% {
                left: 100%;
                right: -90%;
            }
            100% {
                left: 100%;
                right: -90%;
            }
        }
    ` as any
    },
    calendar: {
        root: ({props}: any) => ({
            className: classNames('inline-flex max-w-full relative', {
                'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
                'transparent-calendar': props.inline,
            })
        }),
        input: {
            root: ({parent}: any) => ({
                className: classNames('font-sans text-base p-3 border gray-300 transition-colors duration-200 appearance-none', 'grey-500', {
                    'rounded-lg': !parent.props.showIcon,
                    'r-0 rounded-l-lg': parent.props.showIcon
                })
            })
        },
        dropdownButton: {
            root: ({props}: any) => ({
                className: classNames({'rounded-l-none': props.icon})
            })
        },
        panel: ({props}: any) => ({
            className: classNames(
                {
                    'min-w-full': !props.timeOnly,
                },
                {
                    'shadow-md 0 absolute bg-[var(--primary-color)]': !props.inline,
                    'inline-block overflow-x-auto 0 p-2 rounded-lg': props.inline
                })
        }),
        header: {
            className: classNames('navigation-header flex items-center justify-between', 'navigation-header p-2   font-semibold m-0 gray-300  rounded-t-lg')
        },
        previousButton: {
            className: classNames(
                'flex items-center justify-center cursor-pointer overflow-hidden relative',
                'w-8 h-8   0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
                'dark:hover:text-white/80 hover:transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 '
            )
        },
        title: {
            className: classNames('leading-8 text-4xl w-[80%]')
        },
        monthTitle: {
            className: classNames('  transition duration-200 font-semibold p-2', 'mr-2')
        },
        yearTitle: {
            className: classNames('  transition duration-200 font-semibold p-2')
        },
        nextButton: {
            className: classNames(
                'flex items-center justify-center cursor-pointer overflow-hidden relative',
                'w-8 h-8   0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
                'dark:hover:text-white/80 hover:transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 '
            )
        },
        table: {
            className: classNames('0 w-full', 'my-2')
        },
        tableHeaderCell: {
            className: classNames('p-2 0')
        },
        weekDay: {
            className: classNames(' ')
        },
        day: {
            className: classNames('p-2')
        },
        dayLabel: ({context}: any) => ({
            className: classNames(
                'w-10 h-10 rounded-full border-0 transition-shadow duration-200 transparent',
                'flex items-center justify-center mx-auto overflow-hidden relative',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
                {
                    'opacity-60 cursor-default': context.disabled,
                    'cursor-pointer': !context.disabled
                },
                {
                    'bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80': !context.selected && !context.disabled,
                    'text-blue-200 bg-gray-800 hover:bg-blue-600': context.selected && !context.disabled
                }
            )
        }),
        monthPicker: {
            className: classNames('my-2')
        },
        month: ({context}: any) => ({
            className: classNames(
                'w-1/3 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
                'p-2 transition-shadow duration-200 rounded-lg',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
                {
                    '  bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80': !context.selected && !context.disabled,
                    'text- grey-700 hover:bg- grey-200': context.selected && !context.disabled
                }
            )
        }),
        yearPicker: {
            className: classNames('my-2')
        },
        year: ({context}: any) => ({
            className: classNames(
                'w-1/2 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
                'p-2 transition-shadow duration-200 rounded-lg',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
                {
                    '  bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80': !context.selected && !context.disabled,
                    'text- grey-700 hover:bg- grey-200': context.selected && !context.disabled
                }
            )
        }),
        timePicker: {
            className: classNames('flex justify-center items-center', 't-1 solid gray-300 p-2')
        },
        separatorContainer: {
            className: classNames('flex items-center flex-col px-2',)
        },
        separator: {
            className: classNames('text-xl')
        },
        hourPicker: {
            className: classNames('flex items-center flex-col px-2')
        },
        minutePicker: {
            className: classNames('flex items-center flex-col px-2')
        },
        ampmPicker: {
            className: classNames('flex items-center flex-col px-2')
        },
        incrementButton: {
            className: classNames(
                'flex items-center justify-center cursor-pointer overflow-hidden relative',
                'w-8 h-8 0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
                'dark:hover:text-white/80 hover:transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 '
            )
        },
        decrementButton: {
            className: classNames(
                'flex items-center justify-center cursor-pointer overflow-hidden relative',
                'w-8 h-8 0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
                'dark:hover:text-white/80 hover:transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 '
            )
        },
        groupContainer: {
            className: classNames('flex')
        },
        group: {
            className: classNames('flex-1', 'l gray-300 pr-0.5 pl-0.5 pt-0 pb-0', 'first:pl-0 first:l-0')
        },
        transition: TRANSITIONS.overlay
    },
    button: {
        root: ({props, context}: any) => ({
            className: classNames(
                'primary-button bordered-button items-center cursor-pointer inline-flex overflow-hidden relative select-none text-center align-bottom',
                'transition duration-200 ease-in-out',
                'focus:outline-none focus:outline-offset-0',
                {
                    'text-white   grey-500 dark: grey-400 hover:bg- grey-600 dark:hover:bg- grey-500 grey-600 dark:grey-500 focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                        !props.link && props.severity === null && !props.text && !props.outlined && !props.plain,
                    'text- grey-600 bg-transparent transparent focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                    props.link
                },
                {
                    'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(176,185,198,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(203,213,225,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                        props.severity === 'secondary',
                    'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(136,234,172,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(134,239,172,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                        props.severity === 'success',
                    'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                        props.severity === 'info',
                    'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(250,207,133,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(252,211,77,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                        props.severity === 'warning',
                    'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(212,170,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(216,180,254,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                        props.severity === 'help',
                    'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(247,162,162,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(252,165,165,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                        props.severity === 'danger'
                },
                {
                    'text-white  bg-gray-500 dark:bg-gray-400 gray-500 dark:gray-400 hover:bg-gray-600 dark:hover:bg-gray-500 hover:gray-600 dark:hover:gray-500':
                        props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
                    'text-white  bg-green-500 dark:bg-green-400 green-500 dark:green-400 hover:bg-green-600 dark:hover:bg-green-500 hover:green-600 dark:hover:green-500':
                        props.severity === 'success' && !props.text && !props.outlined && !props.plain,
                    'text-white   grey-500 dark: grey-400 hover:bg- grey-600 grey-600 dark:hover:bg- grey-500 dark:grey-500':
                        props.severity === 'info' && !props.text && !props.outlined && !props.plain,
                    'text-white  bg-orange-500 dark:bg-orange-400 orange-500 dark:orange-400 hover:bg-orange-600 dark:hover:bg-orange-500 hover:orange-600 dark:hover:orange-500':
                        props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
                    'text-white  bg-purple-500 dark:bg-purple-400 purple-500 dark:purple-400 hover:bg-purple-600 dark:hover:bg-purple-500 hover:purple-600 dark:hover:purple-500':
                        props.severity === 'help' && !props.text && !props.outlined && !props.plain,
                    'text-white  bg-red-500 dark:bg-red-400 red-500 dark:red-400 hover:bg-red-600 dark:hover:bg-red-500 hover:red-600 dark:hover:red-500':
                        props.severity === 'danger' && !props.text && !props.outlined && !props.plain
                },
                {'shadow-lg': props.raised},
                {'rounded-md': !props.rounded, 'rounded-full': props.rounded},
                {
                    'bg-transparent transparent': props.text && !props.plain,
                    'text- grey-500 dark:text- grey-400 hover:bg- grey-300/20': props.text && (props.severity === null || props.severity === 'info') && !props.plain,
                    'text-gray-500 dark:text-gray-400 hover:bg-gray-300/20': props.text && props.severity === 'secondary' && !props.plain,
                    'text-green-500 dark:text-green-400 hover:bg-green-300/20': props.text && props.severity === 'success' && !props.plain,
                    'text-orange-500 dark:text-orange-400 hover:bg-orange-300/20': props.text && props.severity === 'warning' && !props.plain,
                    'text-purple-500 dark:text-purple-400 hover:bg-purple-300/20': props.text && props.severity === 'help' && !props.plain,
                    'text-red-500 dark:text-red-400 hover:bg-red-300/20': props.text && props.severity === 'danger' && !props.plain
                },
                {'shadow-lg': props.raised && props.text},
                {
                    'text-gray-500 hover:bg-gray-300/20': props.plain && props.text,
                    'text-gray-500 gray-500 hover:bg-gray-300/20': props.plain && props.outlined,
                    'text-white bg-gray-500 gray-500 hover:bg-gray-600 hover:gray-600': props.plain && !props.outlined && !props.text
                },
                {
                    'bg-transparent border': props.outlined && !props.plain,
                    'text- grey-500 dark:text- grey-400  grey-500 dark: grey-400 hover:bg- grey-300/20': props.outlined && (props.severity === null || props.severity === 'info') && !props.plain,
                    'text-gray-500 dark:text-gray-400 gray-500 dark:gray-400 hover:bg-gray-300/20': props.outlined && props.severity === 'secondary' && !props.plain,
                    'text-green-500 dark:text-green-400 green-500 dark:green-400 hover:bg-green-300/20': props.outlined && props.severity === 'success' && !props.plain,
                    'text-orange-500 dark:text-orange-400 orange-500 dark:orange-400 hover:bg-orange-300/20': props.outlined && props.severity === 'warning' && !props.plain,
                    'text-purple-500 dark:text-purple-400 purple-500 dark:purple-400 hover:bg-purple-300/20': props.outlined && props.severity === 'help' && !props.plain,
                    'text-red-500 dark:text-red-400 red-500 dark:red-400 hover:bg-red-300/20': props.outlined && props.severity === 'danger' && !props.plain
                },
                {
                    'px-4 py-3 text-base': props.size === null,
                    'text-xs py-2 px-3': props.size === 'small',
                    'text-xl py-3 px-4': props.size === 'large'
                },
                {'flex-column': props.iconPos === 'top' || props.iconPos === 'bottom'},
                {'opacity-60 pointer-events-none cursor-default': context.disabled}
            )
        }),
        label: ({props}: any) => ({
            className: classNames(
                'flex-1',
                'duration-200',
                'font-bold',
                {
                    'hover:underline': props.link
                },
                {'invisible w-0': props.label == null}
            )
        }),
        icon: ({props}: any) => ({
            className: classNames('mx-0', {
                'mr-2': props.iconPos === 'left' && props.label != null,
                'ml-2 order-1': props.iconPos === 'right' && props.label != null,
                'mb-2': props.iconPos === 'top' && props.label != null,
                'mt-2 order-2': props.iconPos === 'bottom' && props.label != null
            })
        }),
        loadingIcon: ({props}: any) => ({
            className: classNames('mx-0', {
                'mr-2': props.loading && props.iconPos === 'left' && props.label != null,
                'ml-2 order-1': props.loading && props.iconPos === 'right' && props.label != null,
                'mb-2': props.loading && props.iconPos === 'top' && props.label != null,
                'mt-2 order-2': props.loading && props.iconPos === 'bottom' && props.label != null
            })
        }),
        badge: ({props}: any) => ({
            className: classNames({'ml-2 w-4 h-4 leading-none flex items-center justify-center': props.badge})
        })
    },
    dialog: {
        root: ({state}: any) => ({
            className: classNames('primary-container padded-m rounded-lg shadow-lg 0', 'max-h-[90%] transform scale-100', 'm-0 w-[50vw]', 'dark:border ', {
                'transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0': state.maximized
            })
        }),
        header: {
            className: classNames('bg-transparent flex items-center justify-between shrink-0', 't-0  rounded-tl-lg rounded-tr-lg p-6', 'font-bold text-lg'),
        },
        headerTitle: {},
        headerIcons: {
            className: classNames('flex items-center')
        },
        closeButton: {
            className: classNames(
                'flex items-center justify-center overflow-hidden relative',
                'w-8 h-8 text-gray-500 0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
                'hover:transparent hover:bg-gray-200',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]', // focus
                'dark:hover:text-white/80 dark:hover:transparent dark:hover:bg-gray-800/80 dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]'
            )
        },
        closeButtonIcon: {
            className: classNames('w-4 h-4 inline-block')
        },
        content: ({state}: any) => ({
            className: classNames('padded-s overflow-y-auto', '  px-6 pb-8 pt-0', 'rounded-bl-lg rounded-br-lg', '   ', {
                grow: state.maximized
            })
        }),
        footer: {
            className: classNames('shrink-0 ', 't-0   px-6 pb-6 text-right rounded-b-lg', '  ')
        },
        mask: ({state}: any) => ({
            className: classNames('transition duration-200', {'bg-black/40': state.containerVisible})
        }),
        transition: ({props}) => ({
            timeout: 150,
            className: classNames(props.position === 'top'
                ? {
                    enterFromClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
                    enterActiveClass: 'transition-all duration-200 ease-out',
                    leaveActiveClass: 'transition-all duration-200 ease-out',
                    leaveToClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0'
                }
                : props.position === 'bottom'
                    ? {
                        enterFromClass: 'opacity-0 scale-75 translate-y-full',
                        enterActiveClass: 'transition-all duration-200 ease-out',
                        leaveActiveClass: 'transition-all duration-200 ease-out',
                        leaveToClass: 'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0'
                    }
                    : props.position === 'left' || props.position === 'top-left' || props.position === 'bottom-left'
                        ? {
                            enterFromClass: 'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
                            enterActiveClass: 'transition-all duration-200 ease-out',
                            leaveActiveClass: 'transition-all duration-200 ease-out',
                            leaveToClass: 'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0'
                        }
                        : props.position === 'right' || props.position === 'top-right' || props.position === 'bottom-right'
                            ? {
                                enterFromClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                                enterActiveClass: 'transition-all duration-200 ease-out',
                                leaveActiveClass: 'transition-all duration-200 ease-out',
                                leaveToClass: 'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0'
                            }
                            : {
                                enterFromClass: 'opacity-0 scale-75',
                                enterActiveClass: 'transition-all duration-200 ease-out',
                                leaveActiveClass: 'transition-all duration-200 ease-out',
                                leaveToClass: 'opacity-0 scale-75'
                            }
            )
        })
    },
    toast: {
        root: {
            className: classNames('w-96')
        },
        message: ({state, index}: any) => ({
            className: classNames('my-4 rounded-md w-full border-solid border-0 border-l-4 border-white', {
                'bg-blue-500': state.messages[index] && state.messages[index].message.severity === 'info',
                'bg-green-500': state.messages[index] && state.messages[index].message.severity === 'success',
                'bg-amber-500': state.messages[index] && state.messages[index].message.severity === 'warn',
                'bg-red-500': state.messages[index] && state.messages[index].message.severity === 'error'
            })
        }),
        content: {
            className: classNames('flex items-center py-5 px-7')
        },
        icon: {
            className: classNames('w-6 h-6', 'text-lg mr-2')
        },
        text: {
            className: classNames('text-base font-normal flex flex-col flex-1 grow shrink ml-4')
        },
        summary: {
            className: classNames('font-bold block')
        },
        detail: {
            className: classNames('mt-1 block')
        },
        closeButton: {
            className: classNames('w-8 h-8 rounded-full bg-transparent transition duration-200 ease-in-out', 'ml-auto overflow-hidden relative', 'flex items-center justify-center', 'hover:bg-white/30')
        },
        transition: {
            timeout: 300,
            enterFromClass: 'opacity-0 translate-x-0 translate-y-2/4 translate-z-0',
            enterActiveClass: 'transition-transform transition-opacity duration-300',
            leaveFromClass: 'max-h-40',
            leaveActiveClass: 'transition-all duration-500 ease-in',
            leaveToClass: 'max-h-0 opacity-0 mb-0 overflow-hidden'
        }
    },
    accordion: {
        root: {
            className: classNames('mb-1')
        },
        accordiontab: {
            root: 'mb-1',
            header: ({props}: any) => ({
                className: classNames(
                    {'select-none pointer-events-none cursor-default opacity-60': props?.disabled} // Condition
                )
            }),
            headerAction: ({context}: any) => ({
                className: classNames(
                    'flex items-center cursor-pointer relative no-underline select-none', // Alignments
                    'p-5 transition duration-200 ease-in-out rounded-t-md font-bold transition-shadow duration-200', // Padding and transition
                    'border border-gray-300 bg-gray-100 text-gray-600', // Borders and colors
                    'dark:bg-gray-900 dark:border-blue-900/40 dark:text-white/80 dark:hover:bg-gray-800/80 dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]', // Dark mode
                    'hover:border-gray-300 hover:bg-gray-200 hover:text-gray-800', // Hover
                    'focus:outline-none focus:outline-offset-0 focus:shadow-[inset_0_0_0_0.2rem_rgba(191,219,254,1)]', // Focus
                    {
                        'rounded-br-md rounded-bl-md': !context.selected,
                        'rounded-br-0 rounded-bl-0 text-gray-800': context.selected
                    } // Condition
                )
            }),
            headerIcon: 'inline-block mr-2',
            headerTitle: 'leading-none',
            content: {
                className: classNames(
                    'p-5 border border-gray-300 bg-white text-gray-700 border-t-0 rounded-tl-none rounded-tr-none rounded-br-lg rounded-bl-lg',
                    'dark:bg-gray-900 dark:border-blue-900/40 dark:text-white/80' // Dark mode
                )
            },
            transition: TRANSITIONS.toggleable
        }
    },
    overlaypanel: {
        root: {
            className: classNames(
                'bg-white text-gray-700 border-2 rounded-md shadow-lg',
                'z-40 transform origin-center',
                'absolute left-0 top-0 mt-3',
            )
        },
        closeButton: {
            className: classNames('flex items-center justify-center overflow-hidden absolute top-0 right-0 w-6 h-6 rounded-full bg-[var(--primary-color)] text-[color:var(--text-color)]')
        },
        content: {
            className: classNames('p-1 items-center flex')
        },
        transition: TRANSITIONS.overlay
    },
    inputtextarea: {
        root: ({context}: any) => ({
            className: classNames(
                'm-0',
                'font-sans text-base p-3 border transition-colors duration-200 appearance-none rounded-lg bg-whitesmoke text-[color:var(--text-color-secondary)]',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
                {'opacity-60 select-none pointer-events-none cursor-default': context.disabled}
            )
        })
    },
    inputmask: {
        root: {
            className: classNames('font-sans text-base py-3 px-3 border hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)] transition duration-200 ease-in-out appearance-none rounded-md')
        }
    },
    inputtext: {
        root: ({props, context}: any) => ({
            className: classNames(
                'm-0',
                'w-full',
                'font-sans border transition-colors duration-200 appearance-none rounded-lg bg-transparent text-[color:var(--text-color)]',
                {
                    'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]': !context.disabled,
                    'opacity-60 select-none pointer-events-none cursor-default': context.disabled
                },
                {
                    'text-lg p-4': props.size === 'large',
                    'text-base p-3 ': props.size == null || props.size === 'medium',
                    'text-xs p-2': props.size === 'small',
                }
            )
        })
    },
    password: {
        root: ({props}: any) => ({
            className: classNames('inline-flex relative', {
                'opacity-60 select-none pointer-events-none cursor-default': props.disabled
            })
        }),
        panel: {
            className: classNames('p-5 bg-[var(--primary-color)] text-[color:var(--text-color)] shadow-md rounded-md')
        },
        meter: {
            className: classNames('mb-2 bg-[var(--primary-color)] text-[color:var(--text-color)]  h-3')
        },
        meterLabel: ({state, props}: any) => ({
            className: classNames(
                'transition-width duration-1000 ease-in-out h-full',
                {
                    'bg-red-500': state.meter?.strength === 'weak',
                    'bg-orange-500': state.meter?.strength === 'medium',
                    'bg-green-500': state.meter?.strength === 'strong'
                },
                {'pr-[2.5rem] ': props.toggleMask}
            )
        }),
        showIcon: {
            className: classNames('absolute top-1/2 -mt-2', 'right-3')
        },
        hideIcon: {
            className: classNames('absolute top-1/2 -mt-2', 'right-3')
        },
        input: {
            className: classNames('w-full')
        },
        iconField: {
            className: classNames('w-full')
        },
        transition: TRANSITIONS.overlay
    },
    progressbar: {
        root: {
            className: classNames('overflow-hidden relative', 'border-0')
        },
        value: ({props}: any) => ({
            className: classNames('border-0 m-0 bg-blue-500', {
                'transition-width duration-1000 ease-in-out absolute items-center border-0 flex h-full justify-center overflow-hidden w-0': props.mode !== 'indeterminate',
                'progressbar-value-animate before:absolute before:top-0 before:left-0 before:bottom-0 before:bg-inherit after:absolute after:top-0 after:left-0 after:bottom-0 after:bg-inherit after:delay-1000': props.mode === 'indeterminate'
            })
        }),
        label: {
            className: classNames('inline-flex', 'text-white leading-6')
        }
    },
    dropdown: {
        root: ({props}: any) => ({
            className: classNames(
                'cursor-pointer inline-flex relative select-none ',
                'border transition-colors duration-200 ease-in-out rounded-md',
                'bg-transparent',
                'w-full',
                'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
                {'opacity-60 select-none pointer-events-none cursor-default': props.disabled}
            )
        }),
        input: ({props}: any) => ({
            className: classNames(
                'cursor-pointer block flex flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap relative',
                'bg-transparent border-0 text-[color:var(--text-color)]',
                'p-3 transition duration-200 bg-transparent rounded appearance-none font-sans text-base',
                'focus:outline-none focus:shadow-none',
                {'pr-7': props.showClear}
            )
        }),
        trigger: {
            className: classNames('flex items-center justify-center shrink-0', 'bg-transparent rounded-tr-lg rounded-br-lg')
        },
        wrapper: {
            className: classNames('h-40 overflow-auto', 'border-0 rounded-md shadow-lg', 'bg-[var(--primary-color)]')
        },
        list: {
            className: classNames('py-3 list-none m-0')
        },
        item: ({context}: any) => ({
            className: classNames(
                'flex items-center justify-content p-3',
                'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
                'm-0 border-0  transition-shadow duration-200 rounded-none',
                'hover:bg-gray-500 hover:text-[color:var(--text-color)]',
                {
                    'text-[color:var(--text-color)]': !context.focused && !context.selected,
                    'text-[color:var(--text-color-secondary)] bg-blue-100': context.focused && !context.selected,
                    'text-[color:var(--text-color-secondary)] bg-blue-400': context.focused && context.selected,
                    'text-[color:var(--text-color-secondary)] bg-blue-50': !context.focused && context.selected,
                    'opacity-60 select-none pointer-events-none cursor-default': context.disabled
                }
            )
        }),
        itemGroup: {
            className: classNames('m-0 p-3 font-bold', 'cursor-auto')
        },
        header: {
            className: classNames('p-3 border-b border-gray-300 mt-0 rounded-tl-lg rounded-tr-lg')
        },
        filterContainer: {
            className: classNames('relative')
        },
        filterInput: {
            className: classNames(
                'bg-[var(--primary-color)]',
                'pr-7 -mr-7',
                'w-full',
                'font-sans text-base py-3 px-3 border border-gray-300 transition duration-200 rounded-lg appearance-none',
                'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]'
            )
        },
        filterIcon: {
            className: classNames('-mt-2 absolute top-1/2')
        },
        clearIcon: {
            className: classNames('right-12 -mt-2 absolute top-1/2')
        },
        transition: TRANSITIONS.overlay
    },
    fileupload: {
        input: {
            className: classNames('hidden')
        },
        buttonbar: {
            className: classNames('flex flex-wrap', 'bg-gray-50 dark:bg-gray-800 p-5 border border-solid border-gray-300 dark:border-blue-900/40 text-gray-700 dark:text-white/80 rounded-tr-lg rounded-tl-lg gap-2 border-b-0')
        },
        basicButton: {
            className: classNames('primary-button bordered-button items-center cursor-pointer p-3 px-5 rounded-md text-base', 'overflow-hidden relative')
        },
        chooseButton: {
            className: classNames('primary-button bordered-button items-center cursor-pointer p-3 px-5 rounded-md text-base', 'overflow-hidden relative')
        },
        chooseIcon: {
            className: classNames('mr-2 inline-block')
        },
        chooseButtonLabel: {
            className: classNames('flex-1 font-bold')
        },
        uploadButton: {
            icon: {
                className: classNames('mr-2')
            }
        },
        cancelButton: {
            icon: {
                className: classNames('mr-2')
            }
        },
        content: {
            className: classNames('relative', 'bg-white dark:bg-gray-900 p-8 border border-gray-300 dark:border-blue-900/40 text-gray-700 dark:text-white/80 rounded-b-lg')
        },
        file: {
            className: classNames('flex items-center flex-wrap', 'p-4 border border-gray-300 dark:border-blue-900/40 rounded gap-2 mb-2', 'last:mb-0')
        },
        thumbnail: {
            className: classNames('shrink-0')
        },
        fileName: {
            className: classNames('mb-2')
        },
        fileSize: {
            className: classNames('mr-2')
        },
        uploadIcon: {
            className: classNames('mr-2')
        }
    },
    checkbox: {
        root: {
            className: classNames('cursor-pointer inline-flex relative select-none align-bottom', 'w-6 h-6')
        },
        input: {
            className: classNames('absolute appearance-none top-0 left-0 size-full p-0 m-0 opacity-0 z-10 outline-none cursor-pointer')
        },
        box: ({props, context}: any) => ({
            className: classNames(
                'flex items-center justify-center',
                'border-2 w-6 h-6 rounded-lg transition-colors duration-200',
                {
                    'border-gray-300 bg-transparent ': !context.checked,
                    'border-blue-500 bg-white ': context.checked
                },
                {
                    'hover:border-blue-500 dark:hover:border-blue-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]': !props.disabled,
                    'cursor-default opacity-60': props.disabled
                }
            )
        }),
        icon: {
            className: classNames('w-4 h-4 transition-all duration-200 text-white text-base dark:text-gray-900')
        }
    },
    multiselect: {
        root: ({props}: any) => ({
            className: classNames('w-full', 'inline-flex cursor-pointer select-none', 'bg-transparent border border-gray-400 transition-colors duration-200 ease-in-out rounded-md', {
                'opacity-60 select-none pointer-events-none cursor-default': props.disabled
            })
        }),
        labelContainer: {
            className: classNames('overflow-hidden flex flex-auto cursor-pointer')
        },
        label: ({props}: any) => ({
            className: classNames('text-[color:var(--text-color)] block overflow-hidden whitespace-nowrap cursor-pointer overflow-ellipsis', 'transition duration-200', {
                // '!p-3': props.display !== 'chip' && (props.value === null || props.value === undefined),
                // '!py-1.5 px-3': props.display === 'chip' && props.value !== null
            })
        }),
        token: {
            className: classNames('py-1 px-2 mr-2 bg-gray-300  text-gray-700  rounded-full', 'cursor-default inline-flex items-center')
        },
        removeTokenIcon: {
            className: classNames('ml-2')
        },
        trigger: {
            className: classNames('flex items-center justify-center shrink-0', 'bg-transparent rounded-tr-lg rounded-br-lg')
        },
        panel: {
            className: classNames('bg-[var(--primary-color)] text-[color:var(--text-color)] border-0 rounded-md shadow-lg')
        },
        header: {
            className: classNames('bg-transparent p-3 border-b border-gray-300 rounded-t-lg', 'flex items-center justify-between')
        },
        headerCheckboxContainer: {
            className: classNames('inline-flex cursor-pointer select-none align-bottom relative', 'mr-2', 'w-6 h-6')
        },
        headerCheckbox: {
            root: ({props}: any) => ({
                className: classNames(
                    'flex items-center justify-center',
                    'border-2 w-6 h-6 text-gray-600 rounded-lg transition-colors duration-200',
                    'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] ',
                    {
                        'border-gray-300 bg-transparent': !props?.checked,
                        'border-blue-500 bg-white': props?.checked
                    }
                )
            })
        },
        closeButton: {
            className: classNames(
                'flex items-center justify-center overflow-hidden relative',
                'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
                'hover:text-gray-700 hover:border-transparent hover:bg-gray-200',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] '
            )
        },
        closeIcon: {className: classNames('w-4 h-4 inline-block')},
        wrapper: {
            className: classNames('max-h-[200px] overflow-auto', 'bg-transparent border-0 rounded-md shadow-lg')
        },
        list: {
            className: classNames('py-3 list-none m-0')
        },
        item: () => ({
            className: classNames('flex p-1')
        }),
        checkboxContainer: {
            className: classNames('inline-flex cursor-pointer select-none align-bottom relative', 'mr-2', 'w-6 h-6')
        },
        itemGroup: {
            className: classNames('m-0 p-3 text-gray-800 bg-transparent font-bold', 'cursor-auto')
        },
        filterContainer: {
            className: classNames('relative')
        },
        filterInput: {
            root: {
                className: classNames(
                    'pr-7 -mr-7',
                    'w-full',
                    'font-sans text-base text-gray-700 bg-white py-3 px-3 border border-gray-300 transition duration-200 rounded-lg appearance-none',
                    'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
                )
            }
        },
        filterIcon: {
            className: classNames('-mt-2 absolute top-1/2')
        },
        clearIcon: {
            className: classNames('text-gray-500 right-12 -mt-2 absolute top-1/2')
        },
        transition: TRANSITIONS.overlay
    },
    divider: {
        root: ({props}: any) => ({
            className: classNames(
                'flex relative',
                {
                    'w-full my-5 mx-0 py-0 px-5 before:block before:left-0 before:absolute before:top-1/2 before:w-full before:border-t before:border-gray-300': props.layout === 'horizontal',
                    'min-h-full mx-4 md:mx-5 py-5 before:block before:min-h-full before:absolute before:left-1/2 before:top-0 before:transform before:-translate-x-1/2 before:border-l before:border-gray-300 ':
                        props.layout === 'vertical'
                },
                {
                    'before:border-solid': props.type === 'solid',
                    'before:border-dotted': props.type === 'dotted',
                    'before:border-dashed': props.type === 'dashed'
                }
            )
        }),
        content: {
            className: classNames('px-1 bg-white z-10')
        }
    },
};

export default DesignSystem;