import React from "react";

const StepIndicator: React.FC<{ step: number }> = ({ step }) => {
  return (
    <div className="flex items-center justify-center mb-5">
      <div className={`step-circle ${step >= 1 ? "active" : ""}`}>1</div>
      <div className="step-line"></div>
      <div className={`step-circle ${step >= 2 ? "active" : ""}`}>2</div>
      <div className="step-line"></div>
      <div className={`step-circle ${step >= 3 ? "active" : ""}`}>3</div>
    </div>
  );
};

export default StepIndicator;
