import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Loader from "../../components/common/LoaderComponent";
import {Communication} from "../../models/communication.interface";
import CommunicationTableRow from "../../components/communication/CommunicationTableRow";
import {CiSearch} from "react-icons/ci";
import {retrieveCommunicationArchive} from "../../services/data-manager/data-manager.service";
import {
    GetCommunicationArchiveRequest
} from "../../services/backend/api/communications/requests/get-communication-archive.request";
import {UserRole} from "../../models/enums/user-role.enum";
import {CommunicationReceiver} from "../../models/enums/communication-receiver.enum";
import {oneYearAgo, today} from "../../utils/date.utils";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {isContained} from "../../utils/generic.utils";

const CommunicationSectionPage: React.FC = () => {
    const {t} = useTranslation();
    const path = useLocation()
    const [section, setSection] = useState('General')
    const [isLoading, setIsLoading] = useState(true);
    const [searchValue, setSearchValue] = useState<string>();
    const {level} = useUserContext();

    const LAST_DAY_FILTER = t('communication.dateFilters.lastDay')
    const LAST_WEEK_FILTER = t('communication.dateFilters.lastWeek')
    const LAST_MONTH_FILTER = t('communication.dateFilters.lastMonth')
    const NO_FILTER = t('communication.dateFilters.all')

    const dateFilters = [
        LAST_DAY_FILTER,
        LAST_WEEK_FILTER,
        LAST_MONTH_FILTER,
        NO_FILTER,
    ]

    const [communications, setCommunications] = useState<Communication[]>([])
    const [filter, setFilter] = useState(t('communication.dateFilters.all'));
    const [filteredCommunications, setFilteredCommunications] = useState<Communication[]>([]);

    const searchCommunication = (e: any) => {
        let value = e.target.value
        value = value.trim()
        if (!value || value === '') {
            value = undefined
        }
        setSearchValue(value)
    }

    const filterCommunications = useCallback(() => {
        // TODO: Add filter logic
        let filtered = communications.filter(communication => {
            const filterDate = new Date()
            switch (filter) {
                case LAST_DAY_FILTER:
                    filterDate.setDate(filterDate.getDate() - 1)
                    break
                case LAST_WEEK_FILTER:
                    filterDate.setDate(filterDate.getDate() - 7)
                    break
                case LAST_MONTH_FILTER:
                    filterDate.setMonth(filterDate.getMonth() - 1)
                    break
                default:
                    return true
            }
            return communication.date >= filterDate
        });

        if (searchValue) {
            filtered = filtered.filter(communication => {
                return isContained(communication.content, searchValue) ||
                    isContained(communication.receiver, searchValue) ||
                    isContained(communication.date.toLocaleDateString(), searchValue)
            })
        }
        setFilteredCommunications(filtered)
    }, [LAST_DAY_FILTER, LAST_MONTH_FILTER, LAST_WEEK_FILTER, communications, filter, searchValue]);


    useEffect(() => {
        const receiverLevel = CommunicationReceiver.getRole(section as CommunicationReceiver) as UserRole
        if (receiverLevel === UserRole.None) {
            return
        }
        const request: GetCommunicationArchiveRequest = {
            startDate: oneYearAgo(),
            endDate: today(),
            level: receiverLevel,
        }
        retrieveCommunicationArchive(request, level).then(communications => {
            communications.sort((a, b) => b.date.getTime() - a.date.getTime())
            setCommunications(communications)
        })
    }, [level, section]);

    useEffect(() => {
        let pathname = path?.pathname?.split('/')
        const section = pathname[pathname?.length - 1].split('?')[0]
        setSection(section)
        setIsLoading(false)
    }, [path?.pathname])

    useEffect(() => {
        filterCommunications();
        setIsLoading(false);
    }, [filter, filterCommunications]);

    const handleFilterChange = useCallback((option: string) => {
        setFilter(option);
    }, []);

    return (
        <div className="page-container">
            <div className="page-title">
                <h2> {t('communication.archiveTitle')} &gt; {t(`communication.${CommunicationReceiver.getRole(section as CommunicationReceiver)}`)}</h2>
            </div>
            <div className="rounded-container w-full">
                <div className='page-content max-w-3xl'>

                    <div className='page-content max-w-3xl'>
                        <div
                            className="bg-[var(--primary-color)] grid grid-cols-2 sm:grid-cols-3 w-full lg:w-auto md:grid-cols-5 gap-3 p-2 mb-4 rounded-lg">
                            <div className="flex items-center justify-center search-bar">
                                <span className="search-icon"><CiSearch size={32}/></span>
                                <input type='search' className="search-input" onInput={searchCommunication}/>
                            </div>
                            {dateFilters.map((option) => (
                                <button
                                    key={option}
                                    className={`px-3 py-1 rounded-lg ellipsis flex items-center justify-center
                                    ${filter === option ?
                                        'bg-white border-2 border-white-500 text-gray-900'
                                        :
                                        'bg-unser border-2 border-white-500 text-white'}`
                                    }
                                    onClick={() => handleFilterChange(option)}
                                >
              <span className="truncate">
                {option}
              </span>
                                </button>
                            ))}
                        </div>
                    </div>

                </div>

                <div className="space-y-2 w-5/6">
                    {
                        isLoading ?
                            <div style={{width: 200, height: 200}} className='flex justify-center items-center'>
                                <Loader key="loader"/>
                            </div> :
                            <>
                                {filteredCommunications.length > 0 ? (
                                    filteredCommunications.map((communication) => {
                                            return <CommunicationTableRow communication={communication}/>
                                        }
                                    )
                                ) : (
                                    <div className='text-center py-10'>{t('provider_graph.NoData')}</div>
                                )}
                            </>
                    }
                </div>
            </div>
        </div>

    );
};

export default CommunicationSectionPage;
