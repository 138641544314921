import React from 'react'
import {Text} from "@react-pdf/renderer";
import ClassicDocument from "../components/ClassicDocument";
import {IncidentNotificationEntity} from "../../../models/entities/incident-notification-entity.interface";
import {formatDate, formatTime} from "../../../utils/date.utils";

interface IncidentNotificationDocumentProps {
    incidentNotification: IncidentNotificationEntity;
}

const IncidentNotificationDocument: React.FC<IncidentNotificationDocumentProps> = ({incidentNotification}) => {
    return (
        <ClassicDocument
            title={"Incident Notification"}
            subtitle={`ID: ${incidentNotification.incidentTypology.incidentId}`}
            content={
                <Text>
                    {`
                        Incident Name: ${incidentNotification.incidentTypology.incidentName.en}
                        Created At: ${formatDate(new Date(incidentNotification.incidentTypology.createdAt))}
                        Severity: ${incidentNotification.incidentTypology.severity}
                        
                        - Location Details:
                        Latitude: ${incidentNotification.location.lat}
                        Longitude: ${incidentNotification.location.lng}
                        
                        - Supervisor Details:
                        Name: ${incidentNotification.supervisor.firstName}
                        Surname: ${incidentNotification.supervisor.lastName}
                        Username: ${incidentNotification.supervisor.userName}
                        ID: ${incidentNotification.supervisor.userId}
                        
                        - Operator Details:
                        Name: ${incidentNotification.operator.firstName}
                        Surname: ${incidentNotification.operator.lastName}
                        Username: ${incidentNotification.operator.userName}
                        ID: ${incidentNotification.operator.userId}
                        
                        - Other Details:
                        Report Operator Time: ${formatTime(incidentNotification.reportOperatorTime)}
                    `}
                </Text>
            }
        />
    )
}
export default IncidentNotificationDocument