import {consumeApi, POST, PUT, USERS_DOCS, USERS_DOCUMENT} from "../../backend.service";
import {DeleteFilesRequest} from "./requests/delete-files.request";
import {DeleteFilesResponse} from "./responses/delete-files.response";
import {UploadFileRequest} from "./requests/upload-file.request";
import {UploadFileResponse} from "./responses/upload-file.response";
import {RecentFilesResponse} from "./responses/recent-files.response";
import {FileUploadUrlRequest} from "./requests/file-upload-url.request";
import {FileUploadUrlResponse} from "./responses/file-upload-url.response";
import {FilesByCategoryRequest} from "./requests/files-by-category.request";
import {FilesByCategoryResponse} from "./responses/files-by-category.response";
import {SupervisorShiftsFilesResponse} from "./responses/supervisor-shifts-files.response";
import {OperatorShiftsFilesResponse} from "./responses/operator-shifts-files.response";
import {IncidentVerificationFilesResponse} from "./responses/incident-verification-files.response";
import {IncidentNotificationFilesResponse} from "./responses/incident-notification-files.response";
import {ClaimsFilesResponse} from "./responses/claims-files.response";
import {UserFilesRequest} from "./requests/user-files.request";
import {UserFilesResponse} from "./responses/user-files.response";
import {RecentFilesByCategoriesResponse} from "./responses/recent-files-by-categories.response";
import {RecentFilesByCategoriesRequest} from "./requests/recent-files-by-categories.request";
import {CountRequest} from "./models/countable.interface";
import {SupervisorContractsFilesResponse} from "./responses/supervisor-contracts-files.response";
import {OperatorContractsFilesResponse} from "./responses/operator-contracts-files.response";


export const getFilesByCategory = async (request: FilesByCategoryRequest): Promise<FilesByCategoryResponse> => {
    return await consumeApi(POST, `${USERS_DOCS}/category`, "Files By Category", request, true);
};

export const getFileUploadUrl = async (request: FileUploadUrlRequest): Promise<FileUploadUrlResponse> => {
    return await consumeApi(POST, `${USERS_DOCS}/upload`, "File Upload Url", request, true);
};

export const uploadFile = async (request: UploadFileRequest): Promise<UploadFileResponse> => {
    return await consumeApi(PUT, request.url, "Upload File", request.file);
};

export const getRecentFiles = async (): Promise<RecentFilesResponse> => {
    return await consumeApi(POST, `${USERS_DOCS}/recent`, "Recent Files", {}, true);
};

export const getRecentFilesByCategories = async (request: RecentFilesByCategoriesRequest): Promise<RecentFilesByCategoriesResponse> => {
    // TODO: change the endpoint
    return await consumeApi(POST, `${USERS_DOCS}/recent`, "Recent Files", {}, true);
};

export const deleteFiles = async (request: DeleteFilesRequest): Promise<DeleteFilesResponse> => {
    return await consumeApi(POST, `${USERS_DOCS}/delete`, "Delete Files", request, true);
};

export const getSupervisorShiftsFiles = async (request: CountRequest): Promise<SupervisorShiftsFilesResponse> => {
    return await consumeApi(POST, `${USERS_DOCUMENT}/report/supervisorshifts`, "Supervisor Shifts Files", request, true);
};

export const getOperatorShiftsFiles = async (request: CountRequest): Promise<OperatorShiftsFilesResponse> => {
    return await consumeApi(POST, `${USERS_DOCUMENT}/report/operatorshifts`, "Operator Shifts Files", request, true);
};

export const getSupervisorContractsFiles = async (request: CountRequest): Promise<SupervisorContractsFilesResponse> => {
    return await consumeApi(POST, `${USERS_DOCUMENT}/contract/supervisor`, "Supervisor Contract Files", request, true);
};

export const getOperatorContractsFiles = async (request: CountRequest): Promise<OperatorContractsFilesResponse> => {
    return await consumeApi(POST, `${USERS_DOCUMENT}/contract/operator`, "Operator Contract Files", request, true);
};

export const getIncidentVerificationFiles = async (request: CountRequest): Promise<IncidentVerificationFilesResponse> => {
    return await consumeApi(POST, `${USERS_DOCUMENT}/report/incidentverification`, "Incident Verification Files", request, true);
};

export const getIncidentNotificationFiles = async (request: CountRequest): Promise<IncidentNotificationFilesResponse> => {
    return await consumeApi(POST, `${USERS_DOCUMENT}/report/incidentnotification`, "Incident Notification Files", request, true);
};

export const getClaimsFiles = async (request: CountRequest): Promise<ClaimsFilesResponse> => {
    return await consumeApi(POST, `${USERS_DOCUMENT}/report/claims`, "Claims Files", request, true);
};

export const getUserFiles = async (request: UserFilesRequest): Promise<UserFilesResponse> => {
    return await consumeApi(POST, `${USERS_DOCS}/recent`, "Files By Category", request, true);
}