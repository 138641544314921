/*
Lunghezza minima di 8 caratteri
Contiene almeno una lettera maiuscola
Contiene almeno una lettera minuscola
Contiene almeno un numero
Contiene almeno un carattere speciale
*/
export function isSecurePassword(password: string) {
    // Controllo lunghezza
    if (password === null || password === undefined || password.length < 8) {
        return false;
    }

    // Controllo presenza lettera maiuscola
    if (!/[A-Z]/.test(password)) {
        return false;
    }

    // Controllo presenza lettera minuscola
    if (!/[a-z]/.test(password)) {
        return false;
    }

    // Controllo presenza numero
    if (!/[0-9]/.test(password)) {
        return false;
    }

    // Controllo presenza carattere speciale
    if (!/[!@#$%^&*]/.test(password)) {
        return false;
    }

    //Secure password
    return true;
}

export function isCorrectPin(pin: string) {
    return pin.length === 6 && !isSimpleSequence(pin) && !isAllDigitsSame(pin);
}

function isSimpleSequence(pin: string) {
    return pin === '123456' || pin === '654321';
}

function isAllDigitsSame(pin: string) {
    var firstDigit = pin[0];
    for (var i = 1; i < pin.length; i++) {
        if (pin[i] !== firstDigit) {
            return false;
        }
    }
    return true;
}

//Standard email validation regex (https://emailregex.com/)
export function isValidEmail(email: string) {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
}

export function isValidPhone(phone: string) {
    const phoneRegex = /^(\+?\d{1,3})[-\s]{0,}(\(?\d{3}\)?[-\s]{0,}\d{3}[-\s]{0,}\d{2}[-\s]{0,}\d{2})$/;
    return phoneRegex.test(phone);
}