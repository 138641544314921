import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Notification} from "../../models/notification.interface";
import NotificationTableRow from "../../components/common/NotificationTableRow";
import {
    retrieveCenterNotifications,
    retrieveManagerCenterNotifications
} from "../../services/data-manager/data-manager.service";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {UserRole} from "../../models/enums/user-role.enum";
import {
    ManagerNotificationCenterRequest
} from "../../services/backend/api/notifications/requests/manager-notification-center.request";
import {oneYearAgo, today} from "../../utils/date.utils";
import Loader from "../../components/common/LoaderComponent";

const SECONDS_IN_A_DAY = 86400000 // 24 * 60 * 60 * 1000
const SECONDS_IN_A_WEEK = 604800000 // SECONDS_IN_A_DAY * 7
const SECONDS_IN_A_MONTH = 2592000000 // SECONDS_IN_A_DAY * 30


const NotificationPage: React.FC = () => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    const TO_READ: string = t('notification.filters.toRead')
    const READ: string = t('notification.filters.read')
    const LAST_DAY: string = t('notification.filters.lastDay')
    const LAST_WEEK: string = t('notification.filters.lastWeek')
    const LAST_MONTH: string = t('notification.filters.lastMonth')
    const ALL: string = t('notification.filters.all')
    const NO_FILTER: string = 'NO_FILTER'

    const filters = [
        TO_READ,
        READ,
        LAST_DAY,
        LAST_WEEK,
        LAST_MONTH,
        ALL
    ]
    const [selectedFilter, setSelectedFilter] = useState<string>(ALL)
    const [selectedDateFilter, setSelectedDateFilter] = useState<string>(ALL)
    const [notifications, setNotifications] = useState<Notification[]>([])
    const [filteredNotifications, setFilteredNotifications] = useState<Notification[]>([])
    const [toReadCounter, setToReadCounter] = useState<Number>(0)
    const {level} = useUserContext()

    const filterNotification = useCallback((notification: Notification) => {
        if (selectedFilter === ALL) {
            return true
        }
        if (selectedFilter === READ && !notification.isRead) {
            return false
        }
        if (selectedFilter === TO_READ && notification.isRead) {
            return false
        }
        const today = new Date()
        const notificationDate = new Date(notification.date)
        const diff = today.getTime() - notificationDate.getTime()
        let maxTime = 0

        switch (selectedDateFilter) {
            case LAST_DAY:
                maxTime = SECONDS_IN_A_DAY
                break
            case LAST_WEEK:
                maxTime = SECONDS_IN_A_WEEK
                break
            case LAST_MONTH:
                maxTime = SECONDS_IN_A_MONTH
                break
            default:
                return true
        }

        return diff < maxTime
    }, [ALL, LAST_DAY, LAST_MONTH, LAST_WEEK, READ, TO_READ, selectedDateFilter, selectedFilter])

    const setFilter = (filter: string) => {
        if (filter === ALL || filter === TO_READ || filter === READ) {
            if (filter === selectedFilter) {
                setSelectedFilter(NO_FILTER)
                return
            }
            setSelectedFilter(filter)
            return
        }
        if (filter === selectedDateFilter) {
            setSelectedDateFilter(NO_FILTER)
            return
        }
        setSelectedDateFilter(filter)
    }

    const onRead = (notification: Notification) => () => {
        notification.isRead = true
        setNotifications([...notifications])
    }

    useEffect(() => {
        if (level === UserRole.Client || level === UserRole.Provider || level === UserRole.HR) {
            const request: ManagerNotificationCenterRequest = {
                startDate: oneYearAgo(),
                endDate: today()
            }
            retrieveManagerCenterNotifications(request).then(notifications => {
                notifications = notifications.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                setNotifications(notifications)
                setIsLoading(false)
            })
        } else {
            retrieveCenterNotifications().then(notifications => {
                notifications = notifications.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                setNotifications(notifications)
                setIsLoading(false)
            })
        }
    }, [level]);

    useEffect(() => {
        if (selectedFilter === ALL) {
            setFilteredNotifications(notifications)
        } else {
            const filtered = notifications.filter(notification => filterNotification(notification))
            setFilteredNotifications(filtered)
        }
        setToReadCounter(notifications.filter(notification => !notification.isRead).length)
    }, [ALL, filterNotification, notifications, selectedFilter])

    return (
        <div className="page-container h-full mr-8">
            <div className="card-container ">
                <div className="options-selector">
                    {filters.map((filter, index) => (
                        <>
                        <div key={index}>
                            <button onClick={() => setFilter(filter)}
                                    className={`option-button-item 
                                    ${(filter === selectedFilter || (filter === selectedDateFilter && selectedFilter !== ALL))
                                        ? 'bg-white text-gray-900'
                                        : 'bg-transparent text-white hover:bg-gray-700'}`
                                    }
                            >
                                {filter === TO_READ &&
                                    <span
                                        className="rounded-full bg-[darkred] px-2 py-1 mr-1">{toReadCounter.toString()}</span>}
                                {filter}
                            </button>
                        </div>
                            {(filter === READ || filter === LAST_MONTH) && <span className="vertical-separator"/>}
                        </>
                    ))}
                </div>
                <div className="page-content">
                    <div className="notification-table">
                        {isLoading ? <Loader/> :
                            filteredNotifications.map((notification) =>
                                <NotificationTableRow notification={notification} onRead={onRead(notification)}/>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationPage;