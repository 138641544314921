import React, {useEffect, useMemo, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {CalendarRequest, formatDayAndMonthDate, formatTime} from "../../../utils/date.utils";
import {Checkbox} from "@mui/material";
import {useTranslation} from "react-i18next";

interface CalendarRequestMenuProps {
  dropdownRef: React.RefObject<HTMLDivElement>;
  isListVisible: boolean;
  selectedDay: Date;
  selectedOption: string | null;
  confirmClicked: boolean;
  handleOptionClick: (option: string) => void;
  labels: string[];
  translationLabels: { [key: string]: string };
  canClickOutsideUpdate: (value: boolean) => void;
  handleConfirmClick: (request: CalendarRequest) => void;
}

const ShiftCalendarRequestMenu: React.FC<CalendarRequestMenuProps> = ({
  dropdownRef,
  isListVisible,
  selectedDay,
  selectedOption,
  confirmClicked,
  handleConfirmClick,
  handleOptionClick,
  canClickOutsideUpdate,
  labels,
  translationLabels
}) => {
  const [isAllDay, setIsAllDay] = useState(false);
  const [startTime, setStartTime] = useState<Dayjs | null | undefined>(
    dayjs("2022-04-17T15:30")
  );
  const [endTime, setEndTime] = useState<Dayjs | null>(
    dayjs("2022-04-17T15:30")
  );
  useEffect(() => {
    if (selectedDay) {
      setStartTime(dayjs(selectedDay));
      setEndTime(dayjs(selectedDay));
    }
  }, [selectedDay, translationLabels]);
  const { t, i18n } = useTranslation();
  const locale = i18n.language === "ar" ? "ar-SA" : "en-US";
  const [step, setStep] = useState(1);
  const [openStartMenu, setOpenStartMenu] = useState(false);
  const [openEndMenu, setOpenEndMenu] = useState(false);
  const canClickOutside = useMemo(() => {
    return !openStartMenu && !openEndMenu;
  }, [openStartMenu, openEndMenu]);

  useEffect(() => {
    canClickOutsideUpdate(canClickOutside);
  }, [canClickOutside, canClickOutsideUpdate]);

  function selectAllDay(date: Dayjs) {
    const startDate = new Date(date.year(), date.month(), date.date());
    const endDate = new Date(date.year(), date.month(), date.date(), 24);
    setStartTime(dayjs(startDate));
    setEndTime(dayjs(endDate));
    setIsAllDay(true);
  }

  const handleAllDayChange = () => {
    if (!isAllDay) {
      selectAllDay(dayjs(selectedDay));
    }
    setIsAllDay(!isAllDay);
  };

  const handleRequestClick = () => {
    if (selectedOption !== null) {
      const request: CalendarRequest = {
        startTime: startTime?.toISOString() || "",
        endTime: endTime?.toISOString() || "",
        label: selectedOption,
      };
      handleConfirmClick(request);
    }
  };

  const goToLabels = () => {
    setStep(2);
  };

  return (
    <div className="dropdown-menu" ref={dropdownRef}>
      {!isListVisible && !confirmClicked && (
        <>
          {!isAllDay && step === 1 && (
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker", "TimePicker"]}>
                  <div className="flex flex-col">
                    <TimePicker
                      label="Start time"
                      value={startTime}
                      onChange={(newValue) => setStartTime(newValue)}
                      onOpen={() => setOpenStartMenu(true)}
                      onClose={() => setOpenStartMenu(false)}
                      open={openStartMenu}
                    />
                    <div style={{ height: 15 }}></div>

                    <TimePicker
                      label="End time"
                      value={endTime}
                      onChange={(newValue) => {
                        // Controlla se newValue è null o undefined
                        if (newValue == null) {
                            return;
                        }
                        
                        // Converte startTime in un oggetto dayjs
                        const start = dayjs(startTime);
                    
                        // Estrai ore e minuti da start e newValue
                        const startHour = start.hour();
                        const startMinute = start.minute();
                        const newHour = newValue.hour();
                        const newMinute = newValue.minute();
                    
                        // Crea oggetti Day.js solo con l'orario per il confronto
                        const startOnlyTime = dayjs().hour(startHour).minute(startMinute).second(0);
                        const newOnlyTime = dayjs().hour(newHour).minute(newMinute).second(0);
                    
                        // Verifica se newValue è il giorno successivo a start
                        const isNextDay = newValue.isAfter(start, 'day');
                    
                        // Confronta solo le ore e i minuti
                        if (startOnlyTime.isBefore(newOnlyTime)) {
                            if (isNextDay) {
                                setEndTime(newValue.subtract(24, 'hour'));
                            } else {
                                setEndTime(newValue);
                            }
                        } else {
                            if (isNextDay) {
                                setEndTime(newValue);
                            } else {
                                setEndTime(newValue.add(24, 'hour'));
                            }
                        }
                    }}
                      onOpen={() => setOpenEndMenu(true)}
                      onClose={() => setOpenEndMenu(false)}
                      open={openEndMenu}
                    />
                    <div style={{ height: 15 }}></div>
                  </div>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          )}
          {step === 1 && (
            <>
              <div className="flex justify-between items-center">
                <label htmlFor="allDayCheckbox">{t("calendarPage.allDay")}</label>
                <Checkbox
                  id="allDayCheckbox"
                  checked={isAllDay}
                  onChange={handleAllDayChange}
                  className=" border-2 border-white rounded-2xl "
                />
              </div>
              <div style={{ height: 15 }}></div>
            </>
          )}

          {!selectedOption && step === 1 && (
            <div className="button-request" onClick={goToLabels}>
              <p>{t("calendarPage.request")}</p>
              <p>&gt;</p>
            </div>
          )}
        </>
      )}
      {step === 2 && (
        <ul>
           {labels.map((label) => (
            <li
              key={label}
              onClick={() => {
                handleOptionClick(label);
                setStep(3);
              }}
            >
              {translationLabels[label]}
            </li>
          ))}
        </ul>
      )}
      {selectedOption && !confirmClicked && step === 3 && (
        <div>
          {!isAllDay ? (
            <>
              <div style={{ fontSize: "0.9rem" }}>
                {formatDayAndMonthDate(startTime, locale)} - {formatDayAndMonthDate(endTime, locale)}
              </div>
              <div style={{ fontSize: "0.9rem" }}>
                {formatTime(startTime, locale)} - {formatTime(endTime, locale)}
              </div>
            </>
          ) : (
            <div style={{ fontSize: "0.9rem" }}>{t("calendarPage.allDay")}</div>
          )}
          <div className="detailRequest">{t("calendarPage.request")}:{translationLabels[selectedOption]}</div>
          <div style={{ borderTop: "1px solid white" }}>
            <button
              onClick={handleRequestClick}
              className="primary-button uppercase mt-3 bordered-button"
              style={{
                height: "25px",
                fontSize: "15px",
              }}
            >
              {t("confirmButton")}
            </button>
          </div>
        </div>
      )}
      {selectedOption && confirmClicked && (
        <div>
          {t("calendarPage.textRequestSend")}

        </div>
      )}
    </div>
  );
};

export default ShiftCalendarRequestMenu;
