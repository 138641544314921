import React from 'react'
import GraphSummaryDocument from "../components/GraphSummaryDocument";
import {Claim} from "../../../models/claim.interface";
import {formatDate} from "../../../utils/date.utils";
import {shortId} from "../../../utils/text-formatting.utils";

interface ClaimsOverviewSummaryProps {
    claims: Claim[];
}

const ClaimsOverviewSummary: React.FC<ClaimsOverviewSummaryProps> = ({claims}) => {
    const data = claims.map(claim => {
        return {
            id: shortId(claim.id),
            employee: claim.employee.name + " " + claim.employee.surname,
            type: claim.type,
            date: formatDate(new Date(claim.date)),
            isPending: claim.isPending ? "Yes" : "No",
            isManaged: claim.isManaged ? "Yes" : "No",
        }
    })

    return (
        <GraphSummaryDocument title="Claims Overview Summary" subtitle="Claims Overview Data" data={data}/>
    )
}
export default ClaimsOverviewSummary