import React from 'react'
import {StyleSheet, Text, View} from '@react-pdf/renderer';
import ClassicDocument from "./ClassicDocument";
import {defaultStyles} from "../styles/default.styles";
import {camelCaseToTitleCase} from "../../../utils/text-formatting.utils";


const styles = StyleSheet.create({
    ...defaultStyles,
});


interface GraphSummaryDocumentProps {
    title: string;
    subtitle: string;
    data: any;
}

const GraphSummaryDocument: React.FC<GraphSummaryDocumentProps> = ({title, subtitle, data}) => {

    return (
        <ClassicDocument
            title={title}
            subtitle={subtitle}
            content={
                <>
                    <View style={styles.table}>
                        <View style={styles.tableHeader}>
                            {
                                data.length > 0 &&
                                Object.keys(data[0]).map((key: string) => (
                                    <Text style={styles.headerCell} key={key}>{camelCaseToTitleCase(key)}</Text>
                                ))
                            }
                        </View>

                        {
                            data.length > 0 &&
                            data.map((item: any, index: any) => (
                                <View style={styles.tableRow} key={index}>
                                    {
                                        Object.keys(item).map((key: string) => (
                                            <Text style={styles.rowCell} key={key}>{item[key]}</Text>
                                        ))
                                    }
                                </View>
                            ))
                        }
                    </View>
                </>
            }
        />
    )
}
export default GraphSummaryDocument