import React, {useEffect} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {useUserContext} from "../../contexts/OperatorContextProvider";

function isTokenValid(token: any) {
    return !(token == null || token === "");
}

interface PrivateRouteProps {
  allowedRoles: string[];
}

const PrivateRoute : React.FC<PrivateRouteProps> = ({ allowedRoles }) => {
  const navigate = useNavigate();
  const { level } = useUserContext();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        let sessionToken = await AsyncStorage.getItem("session");
        const isAuthenticated = sessionToken && isTokenValid(sessionToken) && allowedRoles.includes(level);
        
        if (!isAuthenticated) {
          navigate("/");
          return null; // Ritorna null se non autenticato per evitare di renderizzare il componente
        }
        // Setta un timeout per rimuovere il token dopo 5 minuti
        //const tokenExpirationTime = 5 * 60 * 1000; // 5 minuti in millisecondi
        const tokenExpirationTime = 6 * 60 * 60 * 1000; // 6 ore in millisecondi
        setTimeout(async () => {
          await AsyncStorage.removeItem("session");
          sessionToken = await AsyncStorage.getItem("session");
          if (!isTokenValid(sessionToken)) {
            navigate("/");
          }
        }, tokenExpirationTime);
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };
      fetchToken().then(r => console.log("Token fetched"));
  }, [allowedRoles, level, navigate]);

  return <Outlet />;
};

export default PrivateRoute;
