import React, {useState} from "react";
import {MapContainer, TileLayer} from "react-leaflet";
import L, {LatLng} from "leaflet";
import 'leaflet/dist/leaflet.css';
import {OpenStreetMapProvider} from "leaflet-geosearch";
import SearchLocation from "./SearchLocation";
import LocationMarker from "./LocationMarker";
import MarkerIcon from "../../assets/icons/marker.svg";
import CenterLocation from "./CenterLocation";

const DEFAULT_LATITUDE = 24.873;
const DEFAULT_LONGITUDE = 46.619;
const DEFAULT_ZOOM = 13;
const DEFAULT_CENTER: LatLng = new LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE);

interface MapProps {
    onLocationSelected?: (location: LatLng) => void;
}

const Map: React.FC<MapProps> = ({onLocationSelected}) => {
    const [position, setPosition] = useState<LatLng>(DEFAULT_CENTER)

    const markerIcon = new L.Icon({
        iconUrl: MarkerIcon,
        iconRetinaUrl: MarkerIcon,
        iconSize: new L.Point(36, 36),
        iconAnchor: new L.Point(18, 36),
        popupAnchor: new L.Point(0, -36),
        className: 'bg-transparent',
    });

    const onNewLocationSelected = (location: LatLng) => {
        setPosition(location);
        if (onLocationSelected) {
            onLocationSelected(location);
        }
    }

    return (
        <div className="map-container">
            <MapContainer center={DEFAULT_CENTER} zoom={DEFAULT_ZOOM} scrollWheelZoom={true}>
                <CenterLocation defaultCenter={DEFAULT_CENTER} onLocationSelected={onNewLocationSelected}/>
                <SearchLocation onLocationSelected={onNewLocationSelected} provider={new OpenStreetMapProvider({})}/>
                <LocationMarker position={position} onLocationSelected={onNewLocationSelected} icon={markerIcon}
                                draggable={true} text="Selected location"/>
                <TileLayer
                    attribution='&copy; CNES, Distribution Airbus DS, © Airbus DS, © PlanetObserver (Contains Copernicus Data) | &copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.jpg"
                />
            </MapContainer>
        </div>
    );
};

export default Map;