import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import UserProfile from "../../components/user/UserProfile";
import UserWorkReports from "../../components/user/UserWorkReports";
import UserClaims from "../../components/user/UserClaims";
import UserLeaves from "../../components/user/UserLeaves";
import UserDocuments from "../../components/user/UserDocuments";
import {useLocation} from "react-router-dom";
import UserGraphs from "../../components/user/UserGraphs";
import {UserRole} from "../../models/enums/user-role.enum";
import {Employee} from "../../models/employee.interface";
import {retrieveEmployee} from "../../services/data-manager/data-manager.service";
import Loader from "../../components/common/LoaderComponent";

const UserDetailPage: React.FC = () => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    const [userId, setUserId] = useState<string>();
    const [employee, setEmployee] = useState<Employee>({} as Employee);
    const {state} = useLocation();

    useEffect(() => {
        let data = state?.data;
        if (data?.userId) {
            const userId = data.userId;
            setUserId(userId);
            retrieveEmployee({userId}).then(employee => {
                setEmployee(employee);
                setIsLoading(false);
            });
        } else {
            // TODO: This should be an error (?)
            console.log('Could not find user id in location state');
        }
    }, [state?.data])

    return (

        <div className="page-container mr-12">
            <div className="page-title">
                {
                    (employee.name && employee.surname)
                        ? <h2>{t('userDetailPage.title')} &gt; {`${employee.name} ${employee.surname}`}</h2>
                        : <h2>{t('userDetailPage.title')}</h2>
                }
            </div>
            <div className="page-content w-full pr-8">
                <div
                    className={`w-full grid gap-4 ${employee.type === UserRole.Operator ? 'grid-cols-3' : 'grid-cols-2'}`}>

                    {
                        employee.type === UserRole.Operator &&
                        <div className="col-span-1 space-y-4 ">
                            <div className="secondary-container ">
                                {isLoading ? <Loader/> :
                                    <UserGraphs userId={userId}/>
                                }
                            </div>
                        </div>
                    }


                    <div className="col-span-1 space-y-4 ">
                        <div className="secondary-container h-44">
                            {isLoading ? <Loader/> :
                                <UserWorkReports userId={userId}/>
                            }
                        </div>
                        <div className="secondary-container h-44">
                            {isLoading ? <Loader/> :
                                <UserLeaves userId={userId}/>
                            }
                        </div>
                    </div>

                    <div className="col-span-1 space-y-4 ">
                        <div className="secondary-container h-44">
                            {isLoading ? <Loader/> :
                                <UserClaims userId={userId}/>
                            }
                        </div>
                        <div className="secondary-container h-44">
                            {isLoading ? <Loader/> :
                                <UserDocuments userId={userId}/>
                            }
                        </div>
                    </div>
                </div>

                <div className="col-span-3 w-full my-4">
                    <div className='secondary-container w-full overflow-auto'
                         style={{maxHeight: '25rem'}}
                    >
                        {isLoading ? <Loader/> :
                            <UserProfile userId={userId} compact={true}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
        ;
};

export default UserDetailPage;
