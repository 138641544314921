import {MultiLanguage} from "../services/backend/shared/multi-language.interface";
import i18n from "i18next";

export const getActiveLanguage = () => {
    return i18n.language
}

export const getTranslation = (languages: MultiLanguage) => {
    let lang = getActiveLanguage()
    if (!languages[lang as keyof MultiLanguage]) {
        lang = languages.en
    }
    return languages[lang as keyof MultiLanguage]
}