import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Circle, Popup, useMapEvent } from 'react-leaflet';
import L, { LatLng, LeafletMouseEvent } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import SearchLocation from '../SearchLocation';
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { CircleArea } from '../../../models/geo.interface';
import { MdRemoveCircle } from "react-icons/md";
import 'leaflet.path.drag';
import 'leaflet-editable';

const DEFAULT_RADIUS = 1000;

interface GeoSearchMapProps {
    visible: boolean;
    selectedArea: CircleArea | null;
    zoom: number;
    center: LatLng;
    onZoomChange?: (zoom: number) => void;
    onLocationSelected?: (location: CircleArea | null) => void;
    onClose?: () => void;
}

const GeoSearchMap: React.FC<GeoSearchMapProps> = ({
    visible,
    selectedArea,
    zoom,
    onZoomChange,
    center,
    onLocationSelected,
    onClose,
}) => {
    const [position, setPosition] = useState<LatLng | null>((selectedArea?.lng && selectedArea?.lat) ? new L.LatLng(selectedArea.lat, selectedArea.lng) : null);
    const [radius, setRadius] = useState<number | null>(selectedArea?.radius ?? null);
    const circleRef = useRef<L.Circle>(null);
    const popupRef = useRef<L.Popup>(null);

    const onNewLocationSelected = (location: LatLng) => setPosition(location);

    const mapRef = useRef<HTMLDivElement>(null);
    const onMapClick = (e: LeafletMouseEvent) => {
        // If the circle is not selected, set the position and radius of new circle area
        if (selectedCircle === false) {
            setPosition(e.latlng);
            setRadius(radius ?? DEFAULT_RADIUS);
            return
        }

        if (!position) return;

        // Increase dimention of the circle when clicking outside it
        const mouseClickLatLng = new L.LatLng(e.latlng.lat, e.latlng.lng);
        const circleCenterLatLng = new L.LatLng(position.lat, position.lng);
        const distance = mouseClickLatLng.distanceTo(circleCenterLatLng);
        console.log("Debugging distance: ", distance);
        setRadius(distance);
    };

    // Auto-close map when clicking outside the map popup
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (mapRef.current && !mapRef.current.contains(event.target as Node)) {
                if (onClose) onClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    // Remove circle area selected when clicking on the remove button inside the popup
    const removeCircleArea = (e: React.MouseEvent) => {
        e.stopPropagation();
        setPosition(null);
        setRadius(null);
        setSelectedCircle(false);
        if (onLocationSelected) {
            onLocationSelected(null);
        }
    }

    // When radius or position changes, update parent component with the new circle area
    useEffect(() => {
        if (onLocationSelected && position && radius) {
            onLocationSelected({ lng: position.lng, lat: position.lat, radius: radius });
        }
    }, [radius, position]);

    // Handle circle color change when clicked
    const [pathOptions, setPathOptions] = useState({ color: 'black', fillColor: 'black', fillOpacity: 0.2 });
    const [selectedCircle, setSelectedCircle] = useState<boolean>(false);

    // Handle circle color change when clicked (black -> green)
    useEffect(() => {
        selectedCircle === false ?
            setPathOptions(prev => ({ ...prev, color: 'black', fillColor: 'black' })) :
            setPathOptions(prev => ({ ...prev, color: 'green', fillColor: 'green' }));
    }, [selectedCircle]);

    // Handle when click on circle
    const onCircleMouseDown = (event: LeafletMouseEvent) => {
        if (!selectedCircle) {
            setSelectedCircle(true);
            return;
        }

        if (!position) return;

        // Decrease dimension of the circle when clicking inside it
        const mouseClickLatLng = new L.LatLng(event.latlng.lat, event.latlng.lng);
        const circleCenterLatLng = new L.LatLng(position.lat, position.lng);
        const distance = mouseClickLatLng.distanceTo(circleCenterLatLng);
        setRadius(distance);
    }

    // Open popup when mouse is over the circle (We can close popup by clicking his cross icon)
    const onCircleMouseOver = (event: LeafletMouseEvent) => {
        console.log("Debugging mouse over: ", event);
        if (popupRef.current && circleRef.current) {
            popupRef.current.setLatLng(event.latlng).openOn(event.target._map);
        }
    }

    const onZoomChangeHandler = (zoom: number) => {
        console.log("Debugging zoom change: ", zoom);
        if (onZoomChange) onZoomChange(zoom);
    }

    return (
        <div ref={mapRef} className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80vw] h-[80vh] bg-white rounded-lg shadow-lg transition-all duration-300 border-2 border-black flex justify-center items-center z-[9999] ${visible ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}`}>
            <MapContainer
                center={center}
                zoom={zoom}
                scrollWheelZoom={true}
                className='rounded-lg'
                style={{ height: '100%', width: '100%' }}
            >

                <MapClickHandler onMapClick={onMapClick} />
                <MapZoomHandler onZoomChange={onZoomChangeHandler} />

                <SearchLocation onLocationSelected={onNewLocationSelected} provider={new OpenStreetMapProvider({})} />

                {
                    position && radius &&
                    <Circle
                        ref={circleRef}
                        center={position}
                        radius={radius}
                        pathOptions={pathOptions}
                        eventHandlers={{
                            mousedown: onCircleMouseDown,
                            /* mouseout: onCircleMouseOut, */
                            mouseover: onCircleMouseOver
                        }}
                    >
                        <Popup ref={popupRef} keepInView={true}>
                            <div className="flex flex-col gap-2 justify-center items-center">
                                <p className='text-sm font-bold'>{radius.toFixed(2)}m</p>
                                <MdRemoveCircle color='red' className='cursor-pointer w-5 h-5' onClick={removeCircleArea} />
                            </div>
                        </Popup>

                    </Circle>
                }

                <TileLayer
                    attribution='&copy; CNES, Distribution Airbus DS, © Airbus DS, © PlanetObserver (Contains Copernicus Data) | &copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.jpg"
                />
            </MapContainer>
            <div className="absolute top-4 right-4 z-[9999]">
                <CloseButton onClick={onClose} />
            </div>
        </div>
    );
};

export default GeoSearchMap;

// Handle map click
const MapClickHandler: React.FC<{ onMapClick: (e: LeafletMouseEvent) => void }> = ({ onMapClick }) => {
    useMapEvent('click', onMapClick);
    return null;
};

const MapZoomHandler: React.FC<{ onZoomChange: (zoom: number) => void }> = ({ onZoomChange }) => {
    useMapEvent('zoomend', (e) => {
        if (onZoomChange) onZoomChange(e.target.getZoom());
    });
    return null;
}


const CloseButton: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
    return (
        <button
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={onClick}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                />
            </svg>
        </button>
    );
};
