import {consumeApi, POST, TASKS} from "../../backend.service";
import {CreateGoalRequest} from "./requests/create-goal.request";
import {CreateGoalResponse} from "./responses/create-goal.response";
import {ClientGoalsResponse} from "./responses/client-goals.response";
import {SetGoalDueDateRequest} from "./requests/set-goal-due-date.request";
import {SetGoalDueDateResponse} from "./responses/set-goal-due-date.response";
import {ProviderGoalsResponse} from "./responses/provider-goals.response";
import {formatDate} from "../../../../utils/date.utils";
import {ClientMonthlyGoalsRequest} from "./requests/client-monthly-goals.request";
import {ClientMonthlyGoalsResponse} from "./responses/client-monthly-goals.response";
import {ProviderMonthlyGoalsRequest} from "./requests/provider-monthly-goals.request";
import {ProviderMonthlyGoalsResponse} from "./responses/provider-monthly-goals.response";
import {DeleteGoalRequest} from "./requests/delete-goal.request";
import {DeleteGoalResponse} from "./responses/delete-goal.response";

export const createGoal = async (request: CreateGoalRequest): Promise<CreateGoalResponse> => {
    return await consumeApi(POST, `${TASKS}/client/create/goal`, "Client Create Goal", request, true);
};

export const getClientGoals = async (): Promise<ClientGoalsResponse> => {
    return await consumeApi(POST, `${TASKS}/client/goal/get`, "Client Goals", {}, true);
};

export const getClientMonthlyGoals = async (request: ClientMonthlyGoalsRequest): Promise<ClientMonthlyGoalsResponse> => {
    return await consumeApi(POST, `${TASKS}/client/goal/get_by_month`, "Client Monthly Goals", {
        ...request,
        date: formatDate(request.date)
    }, true);
};

export const getProviderGoals = async (): Promise<ProviderGoalsResponse> => {
    return await consumeApi(POST, `${TASKS}/goal/provider/get`, "Provider Goals", {}, true);
};

export const getProviderMonthlyGoals = async (request: ProviderMonthlyGoalsRequest): Promise<ProviderMonthlyGoalsResponse> => {
    return await consumeApi(POST, `${TASKS}/goal/provider/get_by_month`, "Provider Monthly Goals", {
        ...request,
        date: formatDate(request.date)
    }, true);
};

export const setGoalDueDate = async (request: SetGoalDueDateRequest): Promise<SetGoalDueDateResponse> => {
    return await consumeApi(POST, `${TASKS}/client/goal/assign_due_date`, "Client Set Goal Due Date", {
        ...request,
        dueDate: formatDate(request.dueDate)
    }, true);
};

export const deleteGoal = async (request: DeleteGoalRequest): Promise<DeleteGoalResponse> => {
    return await consumeApi(POST, `${TASKS}/client/goal/delete`, "Client Delete Goal", request, true);
};