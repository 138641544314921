import {useTranslation} from "react-i18next";
import React from "react";
import UserProfile from "../../components/user/UserProfile";

const NewUserPage: React.FC = () => {
    const {t} = useTranslation();

    return (

        <div className="page-container mr-12">
            <div className="page-title">
                <h2>{t('newUserPage.title')}</h2>
            </div>
            <div className="page-content ">
                <div className="w-4/6 visible-height secondary-container">
                    <UserProfile/>
                </div>
            </div>
        </div>
    );
};

export default NewUserPage;
