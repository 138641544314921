import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import UtilityCard from "./UtilityCard";
import {useNavigate} from "react-router-dom";
import {useUserContext} from "../../contexts/OperatorContextProvider";
import {WORK_REPORTS} from "../../utils/constants.utils";
import {WorkReport} from "../../models/work-reports.interface";
import {Severity} from "../../models/enums/severity.enum";
import {FaCircle} from "react-icons/fa6";
import {retrieveEmployee, retrieveUserWorkReports} from "../../services/data-manager/data-manager.service";
import {Employee} from "../../models/employee.interface";
import {EmployeeWorkReportsRequest} from "../../services/backend/api/shifts/requests/employee-work-reports.request";
import {oneYearAgo, today} from "../../utils/date.utils";

interface UserWorkReportsProps {
    userId?: string;
}

const UserWorkReports: React.FC<UserWorkReportsProps> = ({userId}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {level} = useUserContext();

    const [workReports, setWorkReports] = useState<WorkReport[]>([]);
    const [employee, setEmployee] = useState<Employee>({} as Employee);

    const menuOptions = [
        {
            label: t('moreInfo'),
            onClick: () => {
                navigate(`/${level}/${WORK_REPORTS}`, {
                    state: {userId}
                });
            }
        }
    ]

    const getSeverityColor = (severity: Severity) => {
        switch (severity) {
            case Severity.High:
                return 'text-red-500';
            case Severity.Minor:
                return 'text-green-500';
            default:
                return '';
        }
    }

    useEffect(() => {
        if (userId) {
            retrieveEmployee({userId}).then(employee => {
                setEmployee(employee);
                const request: EmployeeWorkReportsRequest = {
                    userId: userId,
                    startDate: oneYearAgo(),
                    endDate: today()
                }
                retrieveUserWorkReports(request).then(reports => {
                    setWorkReports(reports);
                });
            });
        } else {
            // TODO: This should never happen (?)
            console.error('No userId provided');
        }
    }, [userId]);

    return (
        <UtilityCard title={t('userWorkReports.title')} menuOptions={menuOptions}
                     content={
                         <div className="space-y-1">
                             {
                                 workReports.map((report, index) => (
                                     <div key={index}
                                          className='primary-container p-1 flex items-center space-x-4 justify-between'>
                                         <div className="flex items-center space-x-1">
                                             {report.isNew &&
                                                 <div className="flex items-center space-x-1">
                                                     <FaCircle size={16} color="orange"/>
                                                     <span>New</span>
                                                     <span className={getSeverityColor(report.severity)}>
                                                         {t(`userWorkReports.severities.${report.severity}`)}
                                                     </span>
                                                 </div>
                                             }
                                             <span>{report.name}</span>
                                         </div>
                                         <div className="whitespace-no-wrap">
                                             {report.date.toLocaleDateString()} - {report.date.toLocaleTimeString()}
                                         </div>
                                     </div>
                                 ))
                             }
                         </div>
                     }
        />
    )
}

export default UserWorkReports;