import React from "react";
import {useTranslation} from "react-i18next";


const ShiftCalendarLegend: React.FC = () => {
  const { t } = useTranslation();

    const legendItems = [
        { name:  t("legend.work"), className: "icon-legend lg-work" },
        { name: t("legend.selectRequest"), className: "icon-legend lg-request" },
        { name: t("legend.requestPending"), className: "icon-legend lg-pending" },
        { name: t("legend.holidayLeave"), className: "icon-legend lg-holiday" },
      ];
      
    return (
        <div className="legend">
        {legendItems.map((item) => (
          <div key={item.className} className="legend-item flex mt-2">
            <div className={`legend-color ${item.className}`}></div>
            <p className="legend-name">{item.name}</p>
          </div>
        ))}
      </div>
    )
}
export default ShiftCalendarLegend;