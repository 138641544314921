import React from 'react'
import SummaryDocument from "../components/SummaryDocument";
import {WorkReport} from "../../../models/work-reports.interface";

interface IncidentNotificationReportsSummaryProps {
    reports: WorkReport[];
    reportId?: string;
}

const IncidentNotificationReportsSummary: React.FC<IncidentNotificationReportsSummaryProps> = ({reports}) => {
    return (
        <SummaryDocument title="Incidents Notification Reports Summary" subtitle="Incidents Reports"
                         documents={reports}
                         printableKeys={["name", "date", "severity", "category", "startDate", "endDate"]}
        />
    )
}
export default IncidentNotificationReportsSummary