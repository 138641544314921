import React, {useEffect, useState} from "react";
import ErrorComponent from "../../components/common/ErrorComponent";

import {isCorrectPin, isSecurePassword} from "../../utils/validation.utils";
import StepIndicator from "../../components/common/StepIndicator";
import {useTranslation} from "react-i18next";
import {FaCheck, FaEye, FaEyeSlash, FaTimes} from "react-icons/fa";
import {CiMail} from "react-icons/ci";
import {editProfile, getProfile} from "../../services/backend/api/general/general.apis";
import {EditProfileRequest} from "../../services/backend/api/general/requests/edit-profile.request";

const ProfilePage: React.FC = () => {
    const {t} = useTranslation();
    const [profileData, setProfileData] = useState<any>({});
    const [modifiedPassword, setModifiedPassword] = useState("");
    const [modifiedPin, setModifiedPin] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPin, setShowPin] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [showSendRequest, setShowSendRequest] = useState(false);
    const [isPasswordModified, setIsPasswordModified] = useState(false);
    const [isPinModified, setIsPinModified] = useState(false);
    const [confirmPin, setConfirmPin] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPinConditions, setShowPinConditions] = useState(false);
    const [step, setStep] = useState(1);
    const [showPasswordConditions, setShowPasswordConditions] = useState(false);

    // Stati locali per la correttezza
    const [isPinCorrect, setIsPinCorrect] = useState(false);
    const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
    const [pinTouched, setPinTouched] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                setIsLoading(true);
                const profile = await getProfile();
                setProfileData(profile.user);
                setIsLoading(false);
            } catch (error) {
                console.error(
                    "Errore durante il recupero dei dati del profilo:",
                    error
                );
            }
        };

        fetchProfileData().then(() => console.log("Data fetched"));
    }, []);

    useEffect(() => {
        // Verifica se il PIN è corretto
        setIsPinCorrect(modifiedPin === confirmPin);
    }, [confirmPin, modifiedPin]);

    useEffect(() => {
        // Verifica se la Password è corretta
        setIsPasswordCorrect(modifiedPassword === confirmPassword);
    }, [confirmPassword, modifiedPassword]);

    const confirmChanges = async () => {
        try {
            const request: EditProfileRequest = {
                userId: profileData.userId,
                password: modifiedPassword,
                pin: modifiedPin,
            };
            const edit = await editProfile(request);
            if (edit && !edit.error) {
                setStep(step + 1);
                setShowSendRequest(true);
            } else {
                setErrorMessage(
                    edit.message ||
                    "Si è verificato un errore durante il salvataggio delle modifiche."
                );
                setShowErrorModal(true);
            }
        } catch (error) {
            console.error("Errore durante il salvataggio delle modifiche:", error);
            setErrorMessage(
                String(error) ||
                "Si è verificato un errore durante il tentativo di accesso. Riprova più tardi."
            );
            setShowErrorModal(true);
            setTimeout(() => {
                window.location.reload();
            }, 4000);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowPin = () => {
        setShowPin(!showPin);
    };

    const handlePinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setModifiedPin(event.target.value);
        setIsPinModified(true);
        setPinTouched(true);
        if (event.target.value === "") {
            setIsPinModified(false);
        }
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setModifiedPassword(event.target.value);
        setIsPasswordModified(true);
        setPasswordTouched(true);
        if (event.target.value === "") {
            setIsPasswordModified(false);
        }
    };

    const isValidPin = (pin: string) => {
        return pin && isCorrectPin(pin);
    };

    const isValidPassword = (password: string) => {
        return password && isSecurePassword(password);
    };

    const handleSaveChanges = async () => {
        if (
            (isValidPin(modifiedPin) && !modifiedPassword) ||
            (isValidPassword(modifiedPassword) && !modifiedPin) ||
            (isValidPin(modifiedPin) && isValidPassword(modifiedPassword))
        ) {
            setShowConfirmationBox(true);
            setStep(step + 1);
        } else {
            setErrorMessage("Il PIN o la password non soddisfano i requisiti.");
            setShowErrorModal(true);
        }
    };

    return (
        <div className="page-container">
            <div className="page-title">
                <h2> {t("profile")}</h2>
            </div>
            <div className="container-box-generic w-full mb-10">
                <div className="content-form">
                    <div className="row-input">
                        <div className="form-input">
                            <label className="label-form w-1/6" htmlFor="name">
                                {t("name")}
                            </label>
                            <input
                                type="text"
                                value={isLoading ? "Loading...." : profileData?.firstName || ""}
                                className="input-form w-5/6 cursor-not-allowed opacity-50"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row-input">
                        <div className="form-input">
                            <label className="label-form w-1/6" htmlFor="surname">
                                {t("profilePage.surname")}
                            </label>
                            <input
                                type="text"
                                value={isLoading ? "Loading...." : profileData?.lastName || ""}
                                className="input-form w-5/6 cursor-not-allowed opacity-50"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row-input">
                        <div className="form-input">
                            <label className="label-form w-1/6" htmlFor="id">
                                {t("profilePage.ID")}
                            </label>
                            <input
                                type="text"
                                value={isLoading ? "Loading...." : profileData?.userId || ""}
                                className="input-form w-5/6 cursor-not-allowed opacity-50"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row-input">
                        <div className="form-input">
                            <label className="label-form w-1/6" htmlFor="username">
                                {t("profilePage.username")}
                            </label>
                            <input
                                type="text"
                                value={isLoading ? "Loading...." : profileData?.userName || ""}
                                className="input-form w-5/6 cursor-not-allowed opacity-50"
                                readOnly
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-box-generic w-full mb-10">
                {!showSendRequest && (
                    <>
                        {!showConfirmationBox ? (
                            // Fase di modifica
                            <>
                                {/* primo */}
                                <StepIndicator step={step}/>
                                <div className="mb-10">
                                    <p
                                        className="text-sm text-center"
                                        dangerouslySetInnerHTML={{
                                            __html: t("profilePage.stepOne"),
                                        }}
                                    ></p>
                                </div>
                                <div className="content-form relative">
                                    <div className="row-input">
                                        <div className="form-input">
                                            <label className="label-form w-1/6" htmlFor="pin">
                                                {t("profilePage.pin")}
                                            </label>
                                            <div className="input-form w-5/6 relative">
                                                <input
                                                    type={showPin ? "text" : "password"}
                                                    value={modifiedPin || ""}
                                                    onChange={handlePinChange}
                                                    placeholder={t("profilePage.newPin")}
                                                    onFocus={() => setShowPinConditions(true)}
                                                    onBlur={() => setShowPinConditions(false)}
                                                />

                                                <div
                                                    className="password-toggle absolute top-0 right-0 h-full flex items-center px-2">
                                                    <div onClick={toggleShowPin}>
                                                        {showPin ? <FaEyeSlash/> : <FaEye/>}
                                                    </div>

                                                    {pinTouched && modifiedPin.length > 0 && (
                                                        <div onClick={toggleShowPin}
                                                             className={`ml-2 ${isCorrectPin(modifiedPin) ? "text-green-500" : "text-red-500"}`}>
                                                            {isCorrectPin(modifiedPin) ? <FaCheck/> : <FaTimes/>}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {showPinConditions && (
                                                <div
                                                    className="box-conditions absolute bottom-10 p-2"
                                                    dangerouslySetInnerHTML={{
                                                        __html: t("profilePage.conditionPin"),
                                                    }}
                                                ></div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row-input">
                                        <div className="form-input">
                                            <label className="label-form w-1/6" htmlFor="password">
                                                {t("passwordLabel")}
                                            </label>
                                            <div className="input-form w-5/6 relative ">
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    value={modifiedPassword || ""}
                                                    placeholder={t("profilePage.newPassword")}
                                                    onChange={handlePasswordChange}
                                                    onFocus={() => setShowPasswordConditions(true)}
                                                    onBlur={() => setShowPasswordConditions(false)}
                                                />

                                                <div
                                                    className="password-toggle absolute top-0 right-0 h-full flex items-center px-2">
                                                    <div onClick={toggleShowPassword}>
                                                        {showPassword ? <FaEyeSlash/> : <FaEye/>}
                                                    </div>
                                                    {passwordTouched && modifiedPassword.length > 0 && (
                                                        <div onClick={toggleShowPin}
                                                             className={`ml-2 ${isSecurePassword(modifiedPassword) ? "text-green-500" : "text-red-500"}`}>
                                                            {isSecurePassword(modifiedPassword) ? <FaCheck/> :
                                                                <FaTimes/>}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {showPasswordConditions && (
                                            <div
                                                className="box-conditions absolute top-20 p-2"
                                                dangerouslySetInnerHTML={{
                                                    __html: t("profilePage.conditionPassword"),
                                                }}
                                            ></div>
                                        )}
                                    </div>
                                </div>
                                <button
                                    onClick={handleSaveChanges}
                                    className="primary-button uppercase mt-10"
                                    style={{
                                        borderLeft:
                                            "2px solid " +
                                            ((isValidPin(modifiedPin) && !modifiedPassword) ||
                                            (isValidPassword(modifiedPassword) && !modifiedPin) ||
                                            (isValidPin(modifiedPin) &&
                                                isValidPassword(modifiedPassword))
                                                ? "white"
                                                : "red"),
                                        borderRight:
                                            "2px solid " +
                                            ((isValidPin(modifiedPin) && !modifiedPassword) ||
                                            (isValidPassword(modifiedPassword) && !modifiedPin) ||
                                            (isValidPin(modifiedPin) &&
                                                isValidPassword(modifiedPassword))
                                                ? "white"
                                                : "red"),
                                        opacity:
                                            (isValidPin(modifiedPin) && !modifiedPassword) ||
                                            (isValidPassword(modifiedPassword) && !modifiedPin) ||
                                            (isValidPin(modifiedPin) &&
                                                isValidPassword(modifiedPassword))
                                                ? 1
                                                : 0.4,
                                        pointerEvents:
                                            (isValidPin(modifiedPin) && !modifiedPassword) ||
                                            (isValidPassword(modifiedPassword) && !modifiedPin) ||
                                            (isValidPin(modifiedPin) &&
                                                isValidPassword(modifiedPassword))
                                                ? "auto"
                                                : "none",
                                    }}
                                >
                                    {t("confirmButton")}
                                </button>
                            </>
                        ) : (
                            // Fase di conferma
                            <>
                                {/* secondo */}
                                <StepIndicator step={step}/>
                                <div className="mb-10">
                                    <p
                                        className="text-sm text-center"
                                        dangerouslySetInnerHTML={{
                                            __html: t("profilePage.stepOne"),
                                        }}
                                    ></p>
                                </div>
                                <div className="content-form">
                                    {isPinModified && (
                                        <div className="row-input">
                                            <div className="form-input">
                                                <label className="label-form w-1/6" htmlFor="pin">
                                                    {t("profilePage.confirmPin")}
                                                </label>
                                                <input
                                                    type="text"
                                                    value={confirmPin}
                                                    className="input-form w-5/6"
                                                    onChange={(e) => setConfirmPin(e.target.value)}
                                                />
                                                {confirmPin && (
                                                    <div onClick={toggleShowPin}
                                                         className={`ml-2 ${isPinCorrect ? "text-green-500" : "text-red-500"}`}>
                                                        {isPinCorrect ? <FaCheck/> : <FaTimes/>}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {isPasswordModified && (
                                        <div className="row-input">
                                            <div className="form-input">
                                                <label className="label-form w-1/6" htmlFor="password">
                                                    {t("profilePage.confirmPassword")}
                                                </label>
                                                <input
                                                    type="password"
                                                    value={confirmPassword}
                                                    className="input-form w-5/6"
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                                {confirmPassword && (
                                                    <div onClick={toggleShowPin}
                                                         className={`ml-2 ${isPasswordCorrect ? "text-green-500" : "text-red-500"}`}>
                                                        {isPasswordCorrect ? <FaCheck/> : <FaTimes/>}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <button
                                        onClick={confirmChanges}
                                        className="primary-button uppercase mt-10 m-auto"
                                        disabled={
                                            (isPinModified && !isPinCorrect) ||
                                            (isPasswordModified && !isPasswordCorrect)
                                        }
                                        style={{
                                            opacity:
                                                (isPinModified && !isPinCorrect) ||
                                                (isPasswordModified && !isPasswordCorrect)
                                                    ? 0.4
                                                    : 1,
                                            pointerEvents:
                                                (isPinModified && !isPinCorrect) ||
                                                (isPasswordModified && !isPasswordCorrect)
                                                    ? "none"
                                                    : "auto",
                                            borderLeft:
                                                "2px solid " +
                                                ((isPinModified && !isPinCorrect) ||
                                                (isPasswordModified && !isPasswordCorrect)
                                                    ? "red"
                                                    : "white"),
                                            borderRight:
                                                "2px solid " +
                                                ((isPinModified && !isPinCorrect) ||
                                                (isPasswordModified && !isPasswordCorrect)
                                                    ? "red"
                                                    : "white"),
                                        }}
                                    >
                                        {t("profilePage.sendRequest")}
                                    </button>
                                </div>
                            </>
                        )}
                    </>
                )}

                {showSendRequest && (
                    <>
                        {/* terzo */}
                        <StepIndicator step={step}/>
                        <div className="mb-10">

                            <CiMail size={28}/>

                            <p className="text-sm text-center">
                <span
                    dangerouslySetInnerHTML={{
                        __html: t("profilePage.firstPartSuccess"),
                    }}
                ></span>{" "}
                                {profileData.email}{" "}
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("profilePage.secondPartSuccess"),
                                    }}
                                ></span>
                            </p>
                        </div>
                        <div className="content-form">
                            <button
                                onClick={() => window.location.reload()}
                                className="primary-button uppercase mt-10 m-auto bordered-button"
                                disabled={
                                    (isPinModified && modifiedPin !== confirmPin) ||
                                    (isPasswordModified && modifiedPassword !== confirmPassword)
                                }
                            >
                                OK
                            </button>
                        </div>
                    </>
                )}
            </div>
            {errorMessage && showErrorModal && (
                <ErrorComponent message={errorMessage}/>
            )}
        </div>
    );
};

export default ProfilePage;
