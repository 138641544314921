import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Calendar} from "primereact/calendar";
import {HiOutlineUserCircle} from "react-icons/hi";
import {Supervisor} from "../../models/supervisor.interface";
import {FormEvent} from "primereact/ts-helpers";
import {retrieveShiftsEmployeesByDay} from "../../services/data-manager/data-manager.service";
import {Operator} from "../../models/operator.interface";
import {formatShiftTime} from "../../utils/date.utils";
import {ShiftsBetweenDatesRequest} from "../../services/backend/api/shifts/requests/shifts-between-dates.request";
import {UserRole} from "../../models/enums/user-role.enum";
import {shortId} from "../../utils/text-formatting.utils";
import Loader from "../../components/common/LoaderComponent";

const ShiftCalendarPage: React.FC = () => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    const [supervisorsOnShifts, setSupervisorsOnShifts] = useState<Supervisor[]>([]);
    const [operatorsOnShifts, setOperatorsOnShifts] = useState<Operator[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    const onDateSelect = (event: FormEvent) => {
        const date: Date = event.value as Date || new Date();
        setSelectedDate(date);
    }

    useEffect(() => {
        const startDate = new Date(selectedDate);
        startDate.setDate(startDate.getDate() - 1);
        const request: ShiftsBetweenDatesRequest = {
            startDate: startDate,
            endDate: selectedDate
        }

        retrieveShiftsEmployeesByDay(request).then(response => {
            setSupervisorsOnShifts(response.filter(supervisor => supervisor.level === UserRole.Supervisor));
            setOperatorsOnShifts(response.filter(supervisor => supervisor.level === UserRole.Operator));
            setIsLoading(false);
        })

    }, [selectedDate]);

    return (

        <div className="page-container ">
            <div className="page-title">
                <h2>{t('shiftCalendarPage.title')}</h2>
            </div>
            <div className="page-content">
                <div className='w-full grid grid-cols-7 gap-8 visible-height'>
                    <div className="col-span-3 secondary-container padded-m ">
                        <Calendar inline className='w-full px-2' onChange={onDateSelect} selectionMode="single"
                                  value={selectedDate}/>
                    </div>
                    <div className="col-span-4 space-y-4 pr-8">
                        <div className="min-h-32 space-y-4 secondary-container padded-m ">
                            <p className="font-semibold text-2xl">
                                {t('shiftCalendarPage.supervisorsOnShift')}
                                <span className="ml-2 text-cyan-500">{selectedDate.toLocaleDateString()}</span>
                            </p>
                            <div className="space-y-2 h-56 overflow-auto p-2">
                                {
                                    isLoading ? <Loader/> :
                                    supervisorsOnShifts.map(supervisor => (
                                        <div key={supervisor.id}
                                             className='flex items-center justify-between py-2 padded-s primary-container'>
                                            <div className='flex items-center p-2 justify-start w-full'>
                                                <HiOutlineUserCircle size={24}/>
                                                <p className='ml-2 semi'>{supervisor.name}{" "}{supervisor.surname}</p>
                                            </div>
                                            <div className='flex items-center p-2 justify-start w-full'>
                                                <p>{shortId(supervisor.id)}</p>
                                            </div>
                                            <div className='flex items-center p-2 justify-center w-full'>
                                                <p>{formatShiftTime(supervisor.shifts, selectedDate)}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="min-h-32 space-y-4 secondary-container padded-m ">
                            <div className="min-h-32">
                                <p className="font-semibold text-2xl">
                                    {t('shiftCalendarPage.operatorsOnShift')}
                                    <span className="ml-2 text-cyan-500">{selectedDate.toLocaleDateString()}</span>
                                </p>
                                <div className="space-y-2 max-h-96 overflow-auto p-2">
                                    {
                                        isLoading ? <Loader/> :
                                        operatorsOnShifts.map(operator => (
                                            <div key={operator.id}
                                                 className='flex items-center justify-between py-2 padded-s primary-container'>
                                                <div className='flex items-center p-2 justify-start w-full'>
                                                    <HiOutlineUserCircle size={24}/>
                                                    <p className='ml-2 semi'>{operator.name}{" "}{operator.surname}</p>
                                                </div>
                                                <div className='flex items-center p-2 justify-start w-full'>
                                                    <p>{shortId(operator.id)}</p>
                                                </div>
                                                <div className='flex items-center p-2 justify-center w-full'>
                                                    <p>{formatShiftTime(operator.shifts, selectedDate)}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShiftCalendarPage;
