import {
    getClaimsFiles,
    getFilesByCategory,
    getIncidentNotificationFiles,
    getIncidentVerificationFiles,
    getOperatorContractsFiles,
    getOperatorShiftsFiles,
    getRecentFiles,
    getSupervisorContractsFiles,
    getSupervisorShiftsFiles
} from "../backend/api/documents/documents.apis";
import {DocumentCategory} from "../../models/enums/document-category.enum";
import JSZip from "jszip";
import {downloadBlob, downloadFileByLink, fileSizeToBytes} from "../../utils/file.utils";
import {
    generateClaimDocument,
    generateIncidentNotificationDocument,
    generateIncidentVerificationDocument,
    generateOperatorShiftDocument,
    generateSupervisorShiftDocument,
    generateTimesheetDocument
} from "../../utils/pdf-document.utils";
import {mapVirtualFileToFile} from "../data-manager/mapper.service";
import {retrieveUploadedDocuments} from "../data-manager/data-manager.service";
import {getTimesheet} from "../backend/api/shifts/shifts.apis";
import {oneMonthAgo, today} from "../../utils/date.utils";


export const getCategory = async (category: DocumentCategory, forceCount?: boolean, userId?: string): Promise<any> => {
    console.log(`Retrieving category documents: ${category} - ${forceCount} - ${userId}`);

    const isVirtualCategory = DocumentCategory.isVirtualCategory(category);
    if (!isVirtualCategory) {
        let response;
        if (category === DocumentCategory.SupervisorContracts) {
            response = await getSupervisorContractsFiles({forceCount});
            return response;
        } else if (category === DocumentCategory.OperatorContracts) {
            response = await getOperatorContractsFiles({forceCount});
            return response;
        } else {
            response = await getFilesByCategory({dirName: category, forceCount, userId});
            return response;
        }
    }
    console.log(`Retrieving virtual category documents: ${category}`);

    const request = {forceCount}

    switch (category) {
        case DocumentCategory.SupervisorShifts:
            return await getSupervisorShiftsFiles(request);
        case DocumentCategory.OperatorShifts:
            return await getOperatorShiftsFiles(request)
        case DocumentCategory.IncidentVerifications:
            return await getIncidentVerificationFiles(request);
        case DocumentCategory.IncidentNotifications:
            return await getIncidentNotificationFiles(request);
        case DocumentCategory.Claims:
            return await getClaimsFiles(request);
        case DocumentCategory.HR:
            return [];
        case DocumentCategory.Timesheet:
            const timesheet = await getTimesheet({startDate: oneMonthAgo(), endDate: today()});
            return {
                content: [{
                    data: timesheet.shifts,
                    name: "Timesheet.pdf",
                    category: DocumentCategory.Timesheet,
                    uploadDate: new Date(),
                    size: 0,
                }]
            };
        default:
            console.log("Unknown category");
            return await getRecentDocuments(userId);
    }
}

export const getCategoryDocuments = async (category?: DocumentCategory, userId?: string): Promise<any[]> => {
    let response;
    console.log(`Retrieving category documents: ${category} - ${userId}`);
    if (!category) {
        response = await getRecentDocuments(userId);
    } else {
        response = await getCategory(category, false, userId);
    }
    if (!response) {
        return [];
    }
    if (response.content) {
        console.log("Content:", response.content);
        return response.content.map((file: any) => {
            return {
                ...file,
                uploadDate: new Date(file.uploadDate),
                size: fileSizeToBytes(file.size),
            }
        });
    }
    if (response.files && category) {
        const files = response.files;
        return files.map((file: any) => {
            const mappedFile = mapVirtualFileToFile(file, category);
            return {...file, ...mappedFile}
        });
    }
    return response;
}

export const getCategoryDocumentsCounter = async (category: DocumentCategory, userId?: string): Promise<number> => {
    const categories = DocumentCategory.getSubcategories(category);
    if (categories.length === 0) {
        categories.push(category);
    }
    let counter = 0;
    for (let i = 0; i < categories.length; i++) {
        const subcategory = categories[i];
        const response = await getCategory(subcategory, true, userId);
        let count = response?.count || 0;
        count += response?.quantity || 0;
        if (count === 0) {
            count += response?.files?.length || 0;
            count += response?.content?.length || 0;
        }
        counter += count
    }
    return counter;
}

export const getRecentDocuments = async (userId?: string): Promise<any> => {
    if (userId) {
        return await retrieveUploadedDocuments({userId});
    }
    return await getRecentFiles();
}

export const downloadDocument = async (file: any) => {
    if (file?.link) {
        await downloadFileByLink(file.link, file.name);
        return;
    }
    let blob = await generateCategoryFileBlob(file);

    if (blob) {
        downloadBlob(blob, file.name);
    }
}

const generateCategoryFileBlob = async (file: any) => {
    switch (file.category) {
        case DocumentCategory.IncidentNotifications:
            return await generateIncidentNotificationDocument(file);
        case DocumentCategory.IncidentVerifications:
            return await generateIncidentVerificationDocument(file);
        case DocumentCategory.SupervisorShifts:
            return await generateSupervisorShiftDocument(file);
        case DocumentCategory.OperatorShifts:
            return await generateOperatorShiftDocument(file);
        case DocumentCategory.Claims:
            return await generateClaimDocument(file);
        case DocumentCategory.Timesheet:
            return await generateTimesheetDocument(file.data);
        default:
            console.error("Unknown virtual category");
            return null;
    }
}

export const downloadAllCategoryFiles = async (category: DocumentCategory) => {
    try {
        const files = await getCategoryDocuments(category);
        if (!files || files.length === 0) {
            console.error("No files to download");
            return;
        }
        const zip = new JSZip();

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            let blob = null;
            if (file.link) {
                blob = await fetch(file.link).then((response) => response.blob());
            } else {
                blob = await generateCategoryFileBlob(file);
            }

            if (!blob) {
                console.error("Error downloading file:", file.name);
                continue;
            }
            zip.file(file.name, blob);
        }

        const content = await zip.generateAsync({type: "blob"});
        const url = window.URL.createObjectURL(content);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${category}.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    } catch (error: any) {
        console.error("Error downloading files:", error);
    }
};