import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {defaultStyles} from "../styles/default.styles";
import React from "react";
import HorizontalSeparator from "./HorizontalSeparator";
import BaseDocument from "./BaseDocument";

const styles = StyleSheet.create({
    ...defaultStyles
});

interface BaseDocumentProps {
    title: string;
    subtitle: string;
    content: any;
}

const ClassicDocument: React.FC<BaseDocumentProps> = ({title, subtitle, content}) => {
    return (
        <BaseDocument children={
            <>
                <View style={styles.title}>
                    <Text>{title}</Text>
                </View>
                <HorizontalSeparator/>
                <View style={styles.subtitle}>
                    <Text>{subtitle}</Text>
                </View>
                <View style={styles.content}>
                    <View style={styles.textSection}>
                        {content}
                    </View>
                </View>
                <HorizontalSeparator/>
            </>
        }/>
    )
}

export default ClassicDocument