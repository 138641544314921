import React, {useEffect, useState} from "react";
import {Claim} from "../../models/claim.interface";
import FilterButton from "../graphs/FilterButton";
import {useTranslation} from "react-i18next";
import {BlobProvider} from "@react-pdf/renderer";
import ClaimsOverviewSummary from "../pdf/summaries/ClaimsOverviewSummary";
import {FiDownload} from "react-icons/fi";
import {toCamelCase} from "../../utils/text-formatting.utils";
import {ProgressBar} from "primereact/progressbar";
import {ClaimCategory} from "../../models/enums/claim-category.enum";

interface ClaimOverviewGraphDataItem {
    category: ClaimCategory;
    categoryName: string;
    count: number;
}

interface ClaimOverviewProps {
    compact?: boolean;
    claimHistory: Claim[];
}


const MAX_PROGRESS_VALUE = 20;

const ClaimOverview: React.FC<ClaimOverviewProps> = ({claimHistory, compact}) => {

    const {t} = useTranslation();

    const TODAY_FILTER = t("claimOverview.filters.today");
    const THIS_WEEK_FILTER = t("claimOverview.filters.week");
    const THIS_MONTH_FILTER = t("claimOverview.filters.month");
    const THIS_YEAR_FILTER = t("claimOverview.filters.year");

    const filters = [
        TODAY_FILTER,
        THIS_WEEK_FILTER,
        THIS_MONTH_FILTER,
        THIS_YEAR_FILTER
    ]

    const [filter, setFilter] = useState(TODAY_FILTER);

    const [claims, setClaims] = useState<Claim[]>(claimHistory);
    const [filteredClaims, setFilteredClaims] = useState<Claim[]>([]);
    const [graphData, setGraphData] = useState<ClaimOverviewGraphDataItem[]>([]);

    const getProgressBarPercentage = (value: number) => {
        return value * 100 / MAX_PROGRESS_VALUE
    }

    const getProgressBarColor = (value: number) => {
        const percentage = getProgressBarPercentage(value);
        if (percentage < 50) {
            return "bisque"
        } else if (percentage < 80) {
            return "orange"
        } else {
            return "firebrick"
        }
    }

    useEffect(() => {
        switch (filter) {
            case TODAY_FILTER:
                setFilteredClaims(claims.filter(claim => claim.date.getDate() === new Date().getDate()));
                break;
            case THIS_WEEK_FILTER:
                setFilteredClaims(claims.filter(claim => claim.date.getDate() >= new Date().getDate() - new Date().getDay()));
                break;
            case THIS_MONTH_FILTER:
                setFilteredClaims(claims.filter(claim => claim.date.getMonth() === new Date().getMonth()));
                break;
            case THIS_YEAR_FILTER:
                setFilteredClaims(claims.filter(claim => claim.date.getFullYear() === new Date().getFullYear()));
                break;
            default:
                setFilteredClaims(claims);
        }
    }, [filter, claims, TODAY_FILTER, THIS_WEEK_FILTER, THIS_MONTH_FILTER, THIS_YEAR_FILTER]);

    useEffect(() => {
        const data = Object.values(ClaimCategory).map((category) => {
            return {
                category: category,
                categoryName: t(`claimOverview.claimCategories.${toCamelCase(category)}`),
                count: filteredClaims.filter(claim => claim.type === category).length
            } as ClaimOverviewGraphDataItem;
        });
        setGraphData(data);
    }, [claimHistory, filteredClaims, t]);

    useEffect(() => {
        setClaims(claimHistory);
    }, [claimHistory]);

    return (
        <div className="card-container"
             style={{
                 padding: compact ? "0" : "0.6rem"
             }}>
            {
                !compact &&
                <h2 className="card-title">{t("claimOverview.title")}</h2>
            }
            <div className="options-selector">
                {filters.map((option) => (
                    <FilterButton
                        key={option}
                        label={option}
                        isActive={filter === option}
                        onClick={() => setFilter(option)}
                    />
                ))}
            </div>
            <div className="card-content"
                 style={{
                     padding: compact ? "0.4rem" : "0.6rem"
                 }}
            >
                <div className="">
                    <div className="flex justify-between items-center flex-col w-full rounded-container "
                         style={{
                             border: compact ? "none" : "1px solid var(--border-color)",
                             padding: compact ? "0.6rem" : "1rem"
                         }}
                    >
                        <div className={`${compact ? ' flex-wrap' : 'flex flex-wrap'} `}
                             style={{
                                 columnCount: compact ? 2 : 1,
                                 columnFill: "auto",
                                 columnGap: "4rem",
                                 height: compact ? '10rem' : '100%',
                                 fontSize: compact ? "0.8rem" : "1rem"
                             }}>

                            {
                                graphData.map((item, index) => {
                                    return (
                                        <div key={index} className="flex flex-row w-full items-center">
                                            <div className={`uppercase p-1 ${compact ? 'w-3/6' : 'w-2/6'}`}>
                                                <h3>{item.categoryName}</h3>
                                            </div>
                                            <div
                                                className={`p-1 flex flex-row justify-center items-center ${compact ? 'w-3/6' : 'w-4/6'}`}>
                                                <div className="border py-2 px-4 bg-[var(--primary-color)]">
                                                    {item.count}
                                                </div>
                                                <div className="py-0 px-2 flex-grow">
                                                    <ProgressBar value={getProgressBarPercentage(item.count)}
                                                                 showValue={false} className="h-10"
                                                                 color={getProgressBarColor(item.count)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <BlobProvider document={<ClaimsOverviewSummary claims={filteredClaims}/>}>
                {({url, blob}) => (
                    url &&
                    <a href={url} target="_blank" className="primary-button uppercase bordered-button "
                       rel="noreferrer">
                        <FiDownload size={20} className="mx-2"/>
                        {t("documentPage.download")}
                    </a>
                )}
            </BlobProvider>
        </div>
    );
};

export default ClaimOverview;