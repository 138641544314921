import React, {useEffect, useRef, useState} from "react";
import {useMap} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L, {LatLng} from "leaflet";

interface CenterLocationProps {
    defaultCenter: LatLng;
    onLocationSelected?: (location: LatLng) => void;
}

const CenterLocation: React.FC<CenterLocationProps> = ({defaultCenter, onLocationSelected}) => {

    const ref = useRef<HTMLDivElement>(null);
    const [lat, setLat] = useState<number>(defaultCenter.lat);
    const [lng, setLng] = useState<number>(defaultCenter.lng);

    const map = useMap();

    const setMapCenter = (event: any) => {
        if (lat && lng) {
            map.setView([lat, lng], map.getZoom());
        }
        if (onLocationSelected) {
            onLocationSelected({lat, lng} as LatLng);
        }
        L.DomEvent.preventDefault(event);
        L.DomEvent.stopPropagation(event);
    };

    useEffect(() => {
        if (ref.current) {
            L.DomEvent.disableClickPropagation(ref.current);
        }
    });

    return (
        <>
            <div ref={ref} className="leaflet-control-container">
                <div className="leaflet-top leaflet-right">
                    <div
                        className="leaflet-bar leaflet-control bg-white flex flex-col items-center p-1 text-[color:var(--text-color-secondary)] space-y-2">
                        <div className="flex w-full">
                            <div className="w-16">
                                Latitude:
                            </div>
                            <input className="glass leaflet-bar-part leaflet-bar-part-single w-20 px-1"
                                   type="number"
                                   value={lat}
                                   onChange={(e) => setLat(parseFloat(e.target.value))}
                            />
                        </div>
                        <div className="flex w-full">
                            <div className="w-16">
                                Longitude:
                            </div>
                            <input className="glass leaflet-bar-part leaflet-bar-part-single w-20 px-1"
                                   type="number"
                                   value={lng}
                                   onChange={(e) => setLng(parseFloat(e.target.value))}
                            />
                        </div>
                        <button onClick={(event) => setMapCenter(event)}>Center</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CenterLocation;