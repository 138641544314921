import {useTranslation} from "react-i18next";
import React, {createRef, useCallback, useEffect, useState} from "react";
import {Employee} from "../../models/employee.interface";
import {LeaveRequest} from "../../models/leave-request.interface";
import UserLeaveRequest from "../../components/user/UserLeaveRequest";
import {LeaveRequestCategory} from "../../models/enums/leave-request-category.enum";
import {FaAngleDown} from "react-icons/fa6";
import {Calendar} from "primereact/calendar";
import {FormEvent} from "primereact/ts-helpers";
import {FiDownload} from "react-icons/fi";
import {retrieveEmployee, retrieveLeaveRequests} from "../../services/data-manager/data-manager.service";
import {useLocation} from "react-router-dom";
import {oneYearAgo, today} from "../../utils/date.utils";
import Loader from "../../components/common/LoaderComponent";
import {BlobProvider} from "@react-pdf/renderer";
import LeaveRequestDocument from "../../components/pdf/documents/LeaveRequestDocument";
import LeaveRequestsSummary from "../../components/pdf/summaries/LeaveRequestsSummary";
import {uploadLeaveDocument} from "../../services/backend/api/shifts/shifts.apis";
import {UploadLeaveDocumentRequest} from "../../services/backend/api/shifts/requests/upload-leave-document.request";
import {UploadFileRequest} from "../../services/backend/api/documents/requests/upload-file.request";
import {uploadFile} from "../../services/backend/api/documents/documents.apis";
import {useToastContext} from "../../contexts/ToastContext";
import {FileUpload, FileUploadHandlerEvent} from "primereact/fileupload";


interface LeavesPageProps {
}

const LeavesPage: React.FC<LeavesPageProps> = () => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const {successToast, errorToast} = useToastContext();
    const [selectedFile, setSelectedFile] = useState<File>();

    const {state} = useLocation();
    const [employee, setEmployee] = useState<Employee>({} as Employee);
    const [leavesRequests, setLeavesRequests] = useState<LeaveRequest[]>([]);
    const [filteredLeavesRequests, setFilteredLeavesRequests] = useState<LeaveRequest[]>([]);
    const [newLeaveRequest, setNewLeaveRequest] = useState<LeaveRequest>();
    const categorySelector = createRef<HTMLDivElement>();
    const [userId, setUserId] = useState<string>('');

    const NO_FILTER = t('leavesPage.filters.all')
    const CATEGORY_FILTER = t('leavesPage.filters.category')
    const [categoryListVisible, setCategoryListVisible] = useState<boolean>(false);

    const categories = [
        t(`leavesPage.categories.${LeaveRequestCategory.SickLeave}`),
        t(`leavesPage.categories.${LeaveRequestCategory.PersonalLeave}`),
        t(`leavesPage.categories.${LeaveRequestCategory.AnnualLeave}`),
        t(`leavesPage.categories.${LeaveRequestCategory.NationalHoliday}`),
        t(`leavesPage.categories.${LeaveRequestCategory.ShiftChange}`),
        t(`leavesPage.categories.${LeaveRequestCategory.Work}`),
    ];

    const [selectedFilter, setFilter] = useState<string>(NO_FILTER);
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [selectedStartDate, setSelectedStartDate] = useState<Date>(oneYearAgo());
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(today());

    const uploadHandler = (event: FileUploadHandlerEvent, leaveRequest: LeaveRequest) => {
        const fileToUpload = event.files[0];
        setSelectedFile(fileToUpload);
        uploadLeaveRequestFile(leaveRequest)
    };

    const uploadLeaveRequestFile = (leaveRequest: LeaveRequest) => {
        if (!selectedFile) {
            return;
        }
        console.log('Upload File', leaveRequest);
        const request: UploadLeaveDocumentRequest = {
            requestId: leaveRequest.id,
            mimetype: 'application/pdf',
            filename: selectedFile.name
        }
        uploadLeaveDocument(request).then(async response => {
            const uploadFileRequest: UploadFileRequest = {
                url: response.signedUrl,
                file: selectedFile
            }
            await uploadFile(uploadFileRequest).then((response) => {
                console.log("File uploaded successfully");
                successToast(t('messages.uploadLeaveFileSuccess'));
            }).catch((error) => {
                console.log(error);
                errorToast(t('messages.uploadLeaveFileError'));
            })

        })
    }

    const onStartDateSelect = (event: FormEvent) => {
        const date: Date = event.value as Date || new Date();
        setSelectedStartDate(date);
    }

    const onEndDateSelect = (event: FormEvent) => {
        const date: Date = event.value as Date || new Date();
        setSelectedEndDate(date);
    }

    const getStatusColor = (isPending: boolean) => {
        if (isPending) {
            return 'bg-yellow-500 text-black';
        } else {
            return 'bg-green-500 text-white';
        }
    }

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (categorySelector.current && !categorySelector.current.contains(event.target as Node)) {
            setCategoryListVisible(false);
        }
    }, [categorySelector]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    const filterLeavesRequests = useCallback(() => {
        let filtered = leavesRequests.filter(leaveRequest => {
            if (selectedFilter === NO_FILTER) return true
            if (selectedFilter === CATEGORY_FILTER && selectedCategory) {
                if (leaveRequest.category !== selectedCategory) {
                    return false;
                }
            }
            return true;
        });

        if (selectedStartDate) {
            filtered = filtered.filter(leaveRequest => leaveRequest.date >= selectedStartDate)
        }
        if (selectedEndDate) {
            filtered = filtered.filter(leaveRequest => leaveRequest.date <= selectedEndDate)
        }
        filtered.sort((a, b) => a.date.getTime() - b.date.getTime());
        filtered.sort((a, b) => a.isPending ? -1 : 1);
        return filtered;
    }, [CATEGORY_FILTER, NO_FILTER, leavesRequests, selectedCategory, selectedEndDate, selectedFilter, selectedStartDate]);

    const fetchLeaveRequests = useCallback(() => {
        const request = {
            userId: userId,
            startDate: oneYearAgo(),
            endDate: today()
        }
        retrieveLeaveRequests(request).then((response) => {
            setLeavesRequests(response);
            setNewLeaveRequest(response.find(leaveRequest => leaveRequest.isNew));
            setIsLoading(false);
        });
    }, [userId])

    useEffect(() => {
        const filtered = filterLeavesRequests();
        setFilteredLeavesRequests(filtered);
    }, [filterLeavesRequests]);

    useEffect(() => {
        if (state?.userId && state.userId.length > 0) {
            setUserId(state.userId);
            retrieveEmployee({userId: state?.userId}).then(employee => {
                setEmployee(employee);
            });
            fetchLeaveRequests();
        } else {
            // TODO: This should never happen (?)
            console.error('No userId provided');
        }
    }, [fetchLeaveRequests, state.userId, userId]);


    return (
        <div className="page-container mr-12">
            <div className="page-title">
                <h2>{`${employee?.name} ${employee?.surname}`} &gt; {t('leavesPage.title')}</h2>
            </div>
            <div className='page-content space-y-4'>
                {
                    newLeaveRequest &&
                    <div className="secondary-container h-44">
                        <UserLeaveRequest leaveRequest={newLeaveRequest} onChange={() => fetchLeaveRequests()}/>
                    </div>
                }
                <div className="secondary-container py-4 px-8 overflow-auto"
                     style={{
                         maxHeight: newLeaveRequest ? '38rem' : '100%',
                         minHeight: newLeaveRequest ? '36rem' : '47rem'
                     }}
                >
                    <div className="grid grid-cols-8 items-center">
                        <div className="col-span-2">
                        </div>
                        <div className="col-span-4 px-14">
                            <div className="options-selector ">
                                <Calendar className='h-10 ' onChange={onStartDateSelect} selectionMode="single"
                                          value={selectedStartDate}
                                          placeholder={t('leavesPage.filters.fromDate')}
                                />
                                <div className="">
                                    <Calendar className='h-10 ' onChange={onEndDateSelect} selectionMode="single"
                                              value={selectedEndDate}
                                              placeholder={t('leavesPage.filters.toDate')}
                                    />
                                </div>
                                <button onClick={() => setFilter(NO_FILTER)}
                                        className={`option-button-item 
                                    ${(NO_FILTER === selectedFilter)
                                            ? 'bg-white text-gray-900'
                                            : 'bg-transparent text-white hover:bg-gray-700'}`
                                        }
                                >{NO_FILTER}
                                </button>
                                <div>
                                    <button onClick={() => setCategoryListVisible(!categoryListVisible)}
                                            className={`option-button-item inline-flex items-center justify-between
                                    ${(CATEGORY_FILTER === selectedFilter)
                                                ? 'bg-white text-gray-900'
                                                : 'bg-transparent text-white hover:bg-gray-700'}`
                                            }
                                    >
                                        {CATEGORY_FILTER}
                                        <FaAngleDown size={16}/>
                                    </button>
                                    {
                                        categoryListVisible &&
                                        <div className="dropdown-content" ref={categorySelector}>
                                            {
                                                categories.map(category => (
                                                    <div key={category} className={`dropdown-item 
                                                        ${category === selectedCategory ? 'bg-[var(--primary-color-300)] text-color-black' : ''}
                                                    `}
                                                         onClick={() => {
                                                             setFilter(CATEGORY_FILTER)
                                                             setSelectedCategory(category)
                                                             setCategoryListVisible(!categoryListVisible)
                                                         }}
                                                    >
                                                        {category}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-span-2 flex justify-end px-4">
                            <div className="w-48 ">
                                <BlobProvider document={<LeaveRequestsSummary leaves={filteredLeavesRequests}/>}>
                                    {({url, blob}) => (
                                        url &&
                                        <a href={url} target="_blank"
                                           className="primary-button uppercase bordered-button h-8"
                                           rel="noreferrer">
                                            <FiDownload size={20} className="mx-2"/>
                                            {t('leavesPage.downloadAll')}
                                        </a>
                                    )}
                                </BlobProvider>
                            </div>
                        </div>
                    </div>

                    <div className="card-content flex flex-col items-center space-y-2">
                        {
                            isLoading ? <Loader/> :
                            filteredLeavesRequests.map((leave, index) => (
                                <div key={index}
                                     className='primary-container p-2 flex items-center space-x-4 justify-between w-full'>
                                    <div className="flex items-center space-x-1 flex-grow">
                                        <span>{t(`leavesPage.categories.${leave.category}`)} {t('userLeaves.leaveDate')} {leave.date.toLocaleDateString()}</span>
                                    </div>

                                    <div className="w-48 max-h-8">
                                        <FileUpload className="small-button"
                                                    mode="basic" accept=".pdf"
                                                    maxFileSize={1000000} chooseLabel={t('leavesPage.uploadFile')}
                                                    auto={true} multiple={false} customUpload={true}
                                                    onValidationFail={() => errorToast(t('messages.sizeUploadError'))}
                                                    uploadHandler={(event: FileUploadHandlerEvent) => uploadHandler(event, leave)}
                                        />
                                    </div>
                                    <div
                                        className={`min-w-32 rounded-md p-1 text-sm text-center ${getStatusColor(leave.isPending)}`}>
                                        {leave.isPending ? t('userLeaves.pending') : t('userLeaves.approved')}
                                    </div>
                                    <div className="min-w-48">
                                        {leave.date.toLocaleString()}
                                    </div>

                                    <div className="w-48">
                                        <BlobProvider document={<LeaveRequestDocument leave={leave}/>}>
                                            {({url, blob}) => (
                                                url &&
                                                <a href={url} target="_blank"
                                                   className="primary-button uppercase bordered-button h-8"
                                                   rel="noreferrer">
                                                    <FiDownload size={20} className="mx-2"/>
                                                    {t('leavesPage.download')}
                                                </a>
                                            )}
                                        </BlobProvider>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeavesPage;