import React from "react";
import {WeekCalendarItemInterface} from "../../../models/week-calendar-item.interface";
import {formatDayDate, formatTime, isFreeDay,} from "../../../utils/date.utils";
import {useTranslation} from "react-i18next";


interface CalendarWeekListProps {
    weeksData: WeekCalendarItemInterface[];
  translationLabels: { [key: string]: string };
    selectedDay: WeekCalendarItemInterface | null;
    handleDayPress: (day: WeekCalendarItemInterface) => void;
}

const ShiftCalendarWeekList: React.FC<CalendarWeekListProps> = ({
  weeksData,
  selectedDay,
  translationLabels,
  handleDayPress,
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language === "ar" ? "ar-SA" : "en-US";
  const formatDateRange = () => {
    if (!weeksData || weeksData.length === 0) return "";
    const validDates = weeksData.filter((day) => day.date);
    if (validDates.length === 0) return "";
    const startDate = new Date(validDates[0].date);
    const endDate = new Date(validDates[validDates.length - 1].date);
    const startShortMonth = startDate.toLocaleString(locale, {
      month: "long",
    });
    const endShortMonth = endDate.toLocaleString(locale, { month: "long" });
    const startDay = formatDayDate(startDate);
    const endDay = formatDayDate(endDate);

    if (startDate.getMonth() === endDate.getMonth()) {
      return `${startShortMonth} ${startDay} - ${endDay}`;
    } else {
      return `${startShortMonth} ${startDay} - ${endShortMonth} ${endDay}`;
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? text.substring(0, maxLength - 3) + "..."
      : text;
  };

  return (
    <div className="calendar-container">
      <h2 className="title-month">{formatDateRange()}</h2>
      <ul className="weekdays-list">
        {weeksData
            .reduce<WeekCalendarItemInterface[]>((uniqueDays, currentDay) => {
            // Verifica se il giorno è già presente nell'array dei giorni unici
            const existingDayIndex = uniqueDays.findIndex(
              (day) => day.date === currentDay.date
            );
            if (existingDayIndex === -1) {
              // Se il giorno non esiste già nell'array, aggiungilo insieme ai suoi turni
              uniqueDays.push({
                date: currentDay.date,
                shifts: currentDay.shifts,
              });
            } else {
              // Se il giorno è già presente nell'array, unisci i turni del giorno corrente a quelli esistenti
              uniqueDays[existingDayIndex].shifts = uniqueDays[
                existingDayIndex
              ].shifts.concat(currentDay.shifts);
            }
            return uniqueDays;
          }, [])
          .map((uniqueDay, index) => {
            return (
              <li key={index} className="weekday">
                <div className="day-info">
                  <span className="title-style">{`${formatDayDate(uniqueDay.date)}${
                    uniqueDay.shifts.some(
                      (shift) => shift.startDate !== shift.endDate
                    )
                      ? "/" +
                        formatDayDate(
                          uniqueDay.shifts.find(
                            (shift) =>
                              new Date(shift.startDate).getDate() !==
                              new Date(shift.endDate).getDate()
                          )?.endDate
                        )
                      : ""
                  }`}</span>
                  <span className="day-text">
                    {uniqueDay.shifts.some(
                      (shift) => shift.startDate !== shift.endDate
                    )
                      ? `${new Date(uniqueDay.date).toLocaleString(locale, {
                          weekday: "short",
                        })}/${new Date(
                          uniqueDay.shifts.find(
                            (shift) =>
                              new Date(shift.startDate).getDate() !==
                              new Date(shift.endDate).getDate()
                          )?.endDate || uniqueDay.date
                        ).toLocaleString(locale, {
                          weekday: "short",
                        })}`
                      : `${new Date(uniqueDay.date).toLocaleString(locale, {
                          weekday: "short",
                        })}`}
                  </span>
                </div>
                <div
                  className="day-shifts"
                  onClick={() => handleDayPress(uniqueDay)}
                >
                  {/* Verifica se il giorno è stato selezionato */}
                  {selectedDay && selectedDay.date === uniqueDay.date ? (
                    <div className="info-box">
                      {/* Verifica se ci sono turni per il giorno selezionato */}
                      {selectedDay.shifts.length > 0 ? (
                        selectedDay.shifts.map((shift, index) => (
                          <div
                            key={shift.shiftId + index}
                            className="info-text"
                          >
                             - {truncateText(translationLabels[shift.label] || "", 110)}
                          </div>
                        ))
                      ) : (
                        <div className="info-text">- Day Off</div>
                      )}
                    </div>
                  ) : (
                    <div
                      className={`info-box ${
                        isFreeDay(new Date(uniqueDay.date), weeksData)
                          ? "free-day"
                          : ""
                      }`}
                    >
                      {uniqueDay.shifts.length > 0 ? (
                        uniqueDay.shifts.map((shift, index) => {
                            // const label = shift.label || "Day Off";
                          return (
                            <div key={index} className="info-text">
                              {shift.startTime &&
                              shift.endTime &&
                              shift.label === "Work"
                                ?  `${formatTime(shift.startTime, locale)} - ${formatTime(shift.endTime, locale)}`
                                : t("legend.leave")}
                            </div>
                          );
                        })
                      ) : (
                        <div className="info-text">
                          {t("legend.holidayLeave")}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ShiftCalendarWeekList;
