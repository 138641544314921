import React, {useCallback, useEffect, useState} from "react";
import FilterButton from "../graphs/FilterButton";
import {useTranslation} from "react-i18next";
import {WorkReport} from "../../models/work-reports.interface";
import {Employee} from "../../models/employee.interface";
import {getTimeDifference} from "../../utils/date.utils";
import {EXPECTED_END_DATE, EXPECTED_HOURS_DURATION, EXPECTED_START_DATE} from "../../utils/constants.utils";
import {Severity} from "../../models/enums/severity.enum";
import {FaAngleDown} from "react-icons/fa6";
import {BlobProvider} from "@react-pdf/renderer";
import EndShiftReportsSummary from "../pdf/summaries/EndShiftReportsSummary";
import {FiDownload} from "react-icons/fi";

interface EndShiftReportsProps {
    employee: Employee;
    reports: WorkReport[];
}

const EndShiftReports: React.FC<EndShiftReportsProps> = ({employee, reports}) => {

    const {t} = useTranslation();

    const TODAY_FILTER = t("endShiftReports.filters.today");
    const THIS_WEEK_FILTER = t("endShiftReports.filters.week");
    const THIS_MONTH_FILTER = t("endShiftReports.filters.month");
    const THIS_YEAR_FILTER = t("endShiftReports.filters.year");
    const NO_FILTER = t("endShiftReports.filters.all");

    const SEVERITY = t("endShiftReports.severities.severity");
    const MINOR = t("endShiftReports.severities.minor");
    const MEDIUM = t("endShiftReports.severities.medium");
    const HIGH = t("endShiftReports.severities.high");
    const NONE = t("endShiftReports.none");

    const filters = [
        TODAY_FILTER,
        THIS_WEEK_FILTER,
        THIS_MONTH_FILTER,
        THIS_YEAR_FILTER,
        SEVERITY,
        NO_FILTER
    ]

    const severities = [NONE, MINOR, MEDIUM, HIGH];

    const [employeeProfile, setEmployeeProfile] = useState<Employee>({} as Employee);
    const [noFilter, setNoFilter] = useState<boolean>(false);
    const [dateFilter, setDateFilter] = useState<string>(TODAY_FILTER);

    const [severityFilter, setSeverityFilter] = useState<string | null>();
    const [showSeverityDropdown, setShowSeverityDropdown] = useState<boolean>(false);

    const [workReports, setWorkReports] = useState<WorkReport[]>([]);
    const [filteredWorkReports, setFilteredWorkReports] = useState<WorkReport[]>([]);

    const getTimeDifferenceLabel = (startDate?: Date, endDate?: Date): string => {
        if (!startDate || !endDate) {
            return '';
        }
        const diff = getTimeDifference(startDate, endDate);
        const hours = Math.floor(diff / 1000 / 60 / 60);
        const minutes = Math.floor(diff / 1000 / 60) % 60;
        return `${hours}h ${minutes}m`;
    }

    const getSeverityColor = (report: WorkReport): string => {
        const diff = report.endDate.getTime() - report.startDate.getTime();
        const minutes = Math.floor(diff / 1000 / 60) - EXPECTED_HOURS_DURATION * 60;
        if (minutes >= 5) {
            return 'bg-green-500 text-black';
        }
        if (minutes < -5) {
            return 'bg-orange-500 text-black';
        }
        if (minutes < -35) {
            return 'bg-red-500 text-white';
        }
        return 'bg-white text-black';
    }

    const getMinutesDelay = (date: Date, start: boolean): number => {
        const expectedDate = start ? EXPECTED_START_DATE : EXPECTED_END_DATE;
        const diff = date.getTime() - expectedDate.getTime();
        return Math.floor(diff / 1000 / 60) % 60;
    }

    const getDelayLabel = (date: Date, start: boolean): string => {
        const minutesDelay = getMinutesDelay(date, start);
        return `${minutesDelay} MIN`;
    }

    const getDelayColor = (date: Date, start: boolean): string => {
        const minutesDelay = getMinutesDelay(date, start)
        if (start) {
            if (minutesDelay > 35) {
                return 'bg-red-500 text-white';
            }
            if (minutesDelay > 0) {
                return 'bg-orange-500 text-black';
            } else {
                return 'bg-green-500 text-black';
            }
        } else {
            if (minutesDelay < -35) {
                return 'bg-red-500 text-white';
            }
            if (minutesDelay < 0) {
                return 'bg-orange-500 text-black';
            } else {
                return 'bg-green-500 text-black';
            }
        }
    }

    const filterWorkReports = useCallback(() => {
        setFilteredWorkReports(
            workReports.filter((report) => {
                if (noFilter) {
                    return true;
                }
                const today = new Date();

                const reportSeverity = report.severity.toLowerCase();
                switch (severityFilter) {
                    case MINOR:
                        if (reportSeverity !== Severity.Minor) {
                            return false;
                        }
                        break;
                    case MEDIUM:
                        if (reportSeverity !== Severity.Medium) {
                            return false;
                        }
                        break;
                    case HIGH:
                        if (reportSeverity !== Severity.High) {
                            return false;
                        }
                        break;
                    default:
                        break;
                }

                switch (dateFilter) {
                    case TODAY_FILTER:
                        if (report.date.getDate() !== today.getDate()) {
                            return false;
                        }
                        break;
                    case THIS_WEEK_FILTER:
                        if (report.date.getDate() < today.getDate() - today.getDay()) {
                            return false;
                        }
                        break;
                    case THIS_MONTH_FILTER:
                        if (report.date.getMonth() !== today.getMonth()) {
                            return false;
                        }
                        break;
                    case THIS_YEAR_FILTER:
                        if (report.date.getFullYear() !== today.getFullYear()) {
                            return false;
                        }
                        break;
                    default:
                        return true;
                }
                return true;
            })
        );
    }, [HIGH, MEDIUM, MINOR, THIS_MONTH_FILTER, THIS_WEEK_FILTER, THIS_YEAR_FILTER, TODAY_FILTER, dateFilter, noFilter, severityFilter, workReports]);

    useEffect(() => {
        setDateFilter(TODAY_FILTER);
        setWorkReports(reports);
        setEmployeeProfile(employee);
        filterWorkReports();
    }, [TODAY_FILTER, employee, filterWorkReports, reports]);

    return (
        <div>
            <div className="flex my-8">
                <h2 className="font-2xl flex-grow">{t("endShiftReports.title")}</h2>
                <div className="w-48 ">
                    {
                        filteredWorkReports && filteredWorkReports.length > 0 &&
                        <BlobProvider document={<EndShiftReportsSummary reports={filteredWorkReports}/>}>
                            {({url, blob}) => (
                                url &&
                                <a href={url} target="_blank" className="primary-button uppercase bordered-button h-8"
                                   rel="noreferrer">
                                    <FiDownload size={20} className="mx-2"/>
                                    {t('endShiftReports.downloadAll')}
                                </a>
                            )}
                        </BlobProvider>
                    }
                </div>
            </div>
            <div className="card-container">
                <div className="options-selector">
                    {filters.map((option, index) => (
                        <div key={index}>
                            <FilterButton
                                key={option}
                                label={
                                    <div className="flex justify-center items-center">
                                        {option}
                                        {
                                            (option === SEVERITY) &&
                                            <FaAngleDown size={16}/>
                                        }
                                    </div>
                                }
                                isActive={
                                    (option === NO_FILTER && noFilter) ||
                                    ((!noFilter) && (
                                        (option === dateFilter) ||
                                        (option === SEVERITY && severityFilter !== NONE)
                                    ))
                                }
                                onClick={() => {
                                    if (option === NO_FILTER) {
                                        setNoFilter(!noFilter);
                                    } else if (option === SEVERITY) {
                                        setShowSeverityDropdown(!showSeverityDropdown)
                                    } else {
                                        setDateFilter(option);
                                    }
                                }}
                            />
                            {(option === THIS_YEAR_FILTER || option === SEVERITY) &&
                                <span className="vertical-separator ml-3"/>}
                            {option === SEVERITY && showSeverityDropdown && (
                                <div className="dropdown-content -translate-x-4 min-h-36 max-w-28">
                                    {severities && severities.map(severity => (
                                        <div key={severity}
                                             className={`dropdown-item ${severityFilter === severity ? 'bg-[var(--primary-color-300)] text-color-black' : ''}`}
                                             onClick={() => {
                                                 setSeverityFilter(severity)
                                                 setShowSeverityDropdown(false)
                                             }}>
                                            {severity}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="card-content min-h-[36rem] max-h-[36rem] overflow-auto text-sm">
                    <div className="flex justify-between items-center flex-col w-full space-y-2">
                        {filteredWorkReports.map((report, index) => (
                            <div key={index}
                                 className="primary-container flex justify-between items-center w-full p-2">

                                <div className={`w-16 text-center rounded p-1 uppercase ${getSeverityColor(report)}`}>
                                    {getTimeDifferenceLabel(report.startDate, report.endDate)}
                                </div>

                                <div>{t('endShiftReports.started')} {report.startDate.toLocaleTimeString()}</div>
                                <div
                                    className={`w-16 text-center rounded p-1 uppercase ${getDelayColor(report.startDate, true)}`}>
                                    {getDelayLabel(report.startDate, true)}
                                </div>

                                <div>{t('endShiftReports.ended')} {report.endDate.toLocaleTimeString()}</div>
                                <div
                                    className={`w-16 text-center rounded p-1 uppercase ${getDelayColor(report.endDate, false)}`}>
                                    {getDelayLabel(report.endDate, false)}
                                </div>

                                <div>{report.date.toLocaleDateString()}</div>
                                <div>
                                    {
                                        filteredWorkReports && filteredWorkReports.length > 0 && report &&
                                        <BlobProvider document={<EndShiftReportsSummary reports={[report]}/>}>
                                            {({url, blob}) => (
                                                url &&
                                                <a href={url} target="_blank"
                                                   className="primary-button uppercase bordered-button h-8"
                                                   rel="noreferrer">
                                                    <FiDownload size={20} className="mx-2"/>
                                                    {t('endShiftReports.download')}
                                                </a>
                                            )}
                                        </BlobProvider>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    )
        ;
};

export default EndShiftReports;