import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import ClaimHistory from "../../components/claim/ClaimHistory";
import ClaimOverview from "../../components/claim/ClaimOverview";
import {Claim} from "../../models/claim.interface";
import {useLocation} from "react-router-dom";
import {Employee} from "../../models/employee.interface";
import {
    retrieveClaimsByEmployee,
    retrieveEmployee,
    retrieveHRClaimsHistory
} from "../../services/data-manager/data-manager.service";
import {oneMonthAgo, today} from "../../utils/date.utils";
import Loader from "../../components/common/LoaderComponent";


const ClaimManagementPage: React.FC = () => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    const [claims, setClaims] = useState<Claim[]>([]);
    const [employee, setEmployee] = useState<Employee>();
    const {state} = useLocation();

    const getClaims = async (userId?: string) => {
        const request = {startDate: oneMonthAgo(), endDate: today()}
        if (userId) {
            retrieveClaimsByEmployee({...request, userId}).then((claims) => {
                setClaims(claims);
                setIsLoading(false);
            });
        } else {
            retrieveHRClaimsHistory(request).then((claims) => {
                setClaims(claims);
                setIsLoading(false);
            });
        }
    }

    useEffect(() => {
        const userId = state?.userId;
        if (userId) {
            retrieveEmployee({userId}).then((employeeProfile) => {
                setEmployee(employeeProfile);
            });
            const claims = state?.claims;
            if (claims) {
                const claimsData = claims as Claim[] || [];
                setClaims(claimsData);
                setIsLoading(false);
            } else {
                getClaims(userId).then(() => console.log("User claims fetched"));
            }
        } else {
            getClaims().then(() => console.log("Claims fetched"));
        }
    }, [state?.claims, state?.userId]);


    return (
        <div className="page-container">
            <div className="page-title">
                {
                    employee
                        ?
                        <h2>{`${employee.name} ${employee.surname}`} &gt; {t('claimManagementPage.employeeTitle')}</h2>
                        : <h2>{t('claimManagementPage.title')}</h2>
                }
            </div>
            <div className="page-content">
                <div className='w-full grid grid-cols-7 gap-4 pr-8 visible-height'>
                    <div className="col-span-5 secondary-container padded-m visible-height flex justify-center w-full">
                        {isLoading ? <Loader/> :
                            <ClaimHistory claimHistory={claims}/>
                        }
                    </div>
                    <div className="col-span-2 secondary-container padded-m visible-height">
                        {isLoading ? <Loader/> :
                            <ClaimOverview claimHistory={claims}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClaimManagementPage;
