import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import {HiOutlineUserCircle} from "react-icons/hi";
import {Employee} from "../../models/employee.interface";
import {UserRole} from "../../models/enums/user-role.enum";
import WeeklyCalendar from "../../components/calendars/weekly-calendar/WeeklyCalendar";
import {Checkbox} from "primereact/checkbox";
import {retrieveShiftsWeekStats, retrieveWorkersByDate} from "../../services/data-manager/data-manager.service";
import {WorkersByDateRequest} from "../../services/backend/api/shifts/requests/workers-by-date.request";
import {shortId} from "../../utils/text-formatting.utils";
import {ShiftsWeekStatsRequest} from "../../services/backend/api/shifts/requests/shifts-week-stats.request";
import {ShiftsWeekStatsResponse} from "../../services/backend/api/shifts/responses/shifts-week-stats.response";
import {ProgressBar} from "primereact/progressbar";
import {Divider} from "primereact/divider";
import {Shift} from "../../models/shift.interface";
import Loader from "../../components/common/LoaderComponent";

const ShiftAssignmentPage: React.FC = () => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    const [employeesOnShifts, setEmployeesOnShifts] = useState<Employee[]>([]);
    const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [weekStats, setWeekStats] = useState<ShiftsWeekStatsResponse>();
    const [shifts, setShifts] = useState<Shift[]>([]);

    const OPERATORS_FILTER = t('shiftAssignmentPage.filters.operators');
    const SUPERVISORS_FILTER = t('shiftAssignmentPage.filters.supervisors');
    const NO_FILTER = t('shiftAssignmentPage.filters.noFilter');

    const [selectedFilter, setSelectedFilter] = useState<string>(NO_FILTER);

    const filters = [NO_FILTER, OPERATORS_FILTER, SUPERVISORS_FILTER];

    const getSelectedDateShifts = useCallback(() => {
        const selectedDateValue = selectedDate.getDate();
        return shifts.filter(shift => {
            return shift.startDate.getDate() === selectedDateValue || shift.endDate.getDate() === selectedDateValue;
        });
    }, [selectedDate, shifts])

    const onShiftsChange = (shifts: Shift[]) => {
        setShifts(shifts);
    }

    useEffect(() => {
        const filteredEmployees = employeesOnShifts.filter(employee => {
            if (selectedFilter === NO_FILTER) {
                return true;
            }
            if (selectedFilter === OPERATORS_FILTER) {
                return employee.type === UserRole.Operator;
            }
            if (selectedFilter === SUPERVISORS_FILTER) {
                return employee.type === UserRole.Supervisor;
            }
            return true;
        });

        setFilteredEmployees(filteredEmployees);
    }, [NO_FILTER, OPERATORS_FILTER, SUPERVISORS_FILTER, employeesOnShifts, selectedFilter]);

    useEffect(() => {
        const selectedDateShifts = getSelectedDateShifts();
        const selectedDateEmployees = selectedDateShifts.map(shift => shift.operators.concat(shift.supervisor)).flat()
        employeesOnShifts.map(employeesOnShift => {
            employeesOnShift.assigned = selectedDateEmployees.find(employee => employee.id === employeesOnShift.id) !== undefined
            employeesOnShift.isOnLeave = false;
            return employeesOnShift;
        })
    }, [employeesOnShifts, getSelectedDateShifts]);

    useEffect(() => {
        const request: WorkersByDateRequest = {
            dateDate: selectedDate
        };
        retrieveWorkersByDate(request).then((employees) => {
            setEmployeesOnShifts(employees);
            setIsLoading(false);
        });
    }, [selectedDate]);

    useEffect(() => {
        const request: ShiftsWeekStatsRequest = {
            date: selectedDate
        };
        retrieveShiftsWeekStats(request).then((response) => {
            setWeekStats(response);
        });
    }, [selectedDate]);

    return (
        <div className="page-container mr-12">
            <div className="page-title">
                <h2>{t('shiftAssignmentPage.title')}</h2>
            </div>
            <div className="page-content">
                <div className="flex justify-center items-center w-full">
                    {
                        isLoading ? <Loader/> :
                            weekStats && (
                        <div className="flex justify-center items-center w-full">
                            <div className="flex min-w-44 flex-col">
                                <div>
                                    {t('shiftAssignmentPage.weekStats')}
                                </div>
                                <div className="flex justify-center items-center">
                                    <div className="flex-grow">
                                        <ProgressBar value={weekStats.week.worked / weekStats.week.total}
                                                     showValue={false} className="h-2 rounded-lg"
                                                     color="cyan-500"/>
                                    </div>
                                    <div className="min-w-10 text-right text-2xl">
                                        {weekStats.week.worked.toFixed(0)}h
                                    </div>
                                </div>
                            </div>
                            <div className="px-4">
                                <Divider layout="vertical" color="whitesmoke"/>
                            </div>
                            <div className="flex-grow justify-center items-center">
                                <div className="flex justify-center items-center">
                                    <div className="flex-grow  justify-center items-center">
                                        {t('shiftAssignmentPage.monthStats')}
                                    </div>
                                    <div className="min-w-20">
                                        {t('shiftAssignmentPage.monthStatsTotal')}
                                    </div>
                                </div>
                                <div className="flex justify-center items-center">
                                    <div className="flex-grow">
                                        <ProgressBar value={weekStats.month.worked / weekStats.month.total}
                                                     showValue={false} className="h-2 rounded-lg"
                                                     style={{border: 'var(--primary-color)'}}
                                                     color="cyan-500"/>
                                    </div>
                                    <div className="min-w-20 text-right text-2xl">
                                        {weekStats.month.worked.toFixed(0)}h
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className='w-full grid grid-cols-8 gap-4 visible-height'>
                    <div className="col-span-3 space-y-4 ">
                        <div className="space-y-4 secondary-container padded-m visible-height overflow-auto">
                            <div className="flex items-center justify-between">
                                <p className="font-semibold text-2xl">
                                    <span className="ml-2">{selectedDate.toLocaleDateString()}</span>
                                </p>
                                <div className="primary-container p-1">
                                    {filters.map((filter) => (
                                        <button key={filter} onClick={() => setSelectedFilter(filter)}
                                                className={`option-button-item ${filter === selectedFilter ? 'bg-white text-gray-900' : 'bg-transparent text-white hover:bg-gray-700'}`}
                                        >
                                            {filter}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="space-y-2 overflow-auto p-2">
                                {
                                    isLoading ? <Loader/> :
                                    filteredEmployees.map(employee => (
                                        <div key={employee.id}
                                             className='flex items-center justify-between p-2 primary-container'
                                             style={{
                                                 backgroundColor: employee.isOnLeave ? 'green' : 'var(--primary-color)',
                                                 opacity: employee.isOnLeave || employee.assigned ? 0.5 : 1
                                             }}
                                        >

                                            <div className='flex items-center  justify-start w-32 space-x-2'>
                                                <HiOutlineUserCircle size={24}/>
                                                <p>{employee.type}</p>
                                            </div>
                                            <div className='flex items-center justify-start w-48'>
                                                <p className='ml-2 semi'>{employee.name}{" "}{employee.surname}</p>
                                            </div>
                                            <div className='flex items-center justify-start '>
                                                <p>{shortId(employee.id)}</p>
                                            </div>
                                            <div className='flex items-center justify-end w-20'>
                                                {
                                                    employee.isOnLeave
                                                        ?
                                                        <p>
                                                            <span>{t('shiftAssignmentPage.onLeave')}</span>
                                                            {/*    TODO: Add dates*/}
                                                        </p>
                                                        : <Checkbox checked={employee.assigned}/>
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-span-5 secondary-container padded-m visible-height overflow-auto max-h-96">
                        <WeeklyCalendar onChange={setSelectedDate} onVisibleShiftsChange={onShiftsChange}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShiftAssignmentPage;
