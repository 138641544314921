import React, {createContext, ReactNode, useEffect, useState} from 'react';
import {UserRole} from "../models/enums/user-role.enum";

interface UserContextType {
    name: string;
    level: UserRole;
    password: string;
    id: string;
    urlPicture: string;
    setName: (name: string) => void;
    setLevel: (level: UserRole) => void;
    setPassword: (password: string) => void;
    setId: (id: string) => void;
    setUrlPicture: (url: string) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({children}) => {
    const [name, setName] = useState<string>('');
    const [level, setLevel] = useState<UserRole>(UserRole.None);
    const [password, setPassword] = useState<string>('');
    const [id, setId] = useState<string>('');
    const [urlPicture, setUrlPicture] = useState<string>('https://www.shareicon.net/data/2015/05/07/34736_operator_400x400.png');

    // Carica il nome dell'operatore dalla localStorage al caricamento della pagina
    useEffect(() => {
        const savedName = localStorage.getItem('operatorName');
        if (savedName) {
            setName(savedName);
        }
    }, []);

    const setNameHandler = (newName: string) => {
        setName(newName);
        // Salva il nome dell'operatore nella localStorage
        localStorage.setItem('operatorName', newName);
    };

    const setLevelHandler = (newLevel: UserRole) => {
        setLevel(newLevel);
        // Salva il livello dell'operatore nella localStorage
        localStorage.setItem('operatorLevel', newLevel);
    }

    useEffect(() => {
        const savedId = localStorage.getItem('operatorId');
        if (savedId) {
            setId(savedId);
        }
    }, []);

    const setIdHandler = (newId: string) => {
        setId(newId);
        // Salva ID dell'operatore nella localStorage
        localStorage.setItem('operatorId', newId);
    };

    const setPasswordHandler = (newPassword: string) => {
        setPassword(newPassword);
    };

    return (
        <UserContext.Provider value={{
            name,
            level,
            password,
            id,
            urlPicture,
            setName: setNameHandler,
            setLevel: setLevelHandler,
            setPassword: setPasswordHandler,
            setId: setIdHandler,
            setUrlPicture
        }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = (): UserContextType => {
    const context = React.useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within an UserProvider');
    }
    return context;
};
