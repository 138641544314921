import {UserEntity} from "../../models/entities/user-entity.interface";
import {UserData} from "../backend/shared/user-data.interface";
import {Employee, EmployeeType} from "../../models/employee.interface";
import {CenterNotification} from "../backend/api/notifications/responses/center-notifications.response";
import {Notification} from "../../models/notification.interface";
import {ArchiveCommunication} from "../backend/api/communications/responses/get-communications.response";
import {Communication} from "../../models/communication.interface";
import {Goal, Task} from "../../models/goal.interface";
import {ClientGoal} from "../backend/api/client/responses/client-goals.response";
import {PendingRequest} from "../../models/pending-request.interface";
import {Member} from "../backend/api/communications/responses/group-members.response";
import {GroupMember} from "../../models/group-member.interface";
import {UserRole} from "../../models/enums/user-role.enum";
import {ClaimCategory} from "../../models/enums/claim-category.enum";
import {HRClaim} from "../backend/api/claims/responses/hr-claims-history.response";
import {Claim} from "../../models/claim.interface";
import {ClaimHistoryItem} from "../backend/api/claims/responses/claims-history.response";
import {UploadedDocument} from "../../models/uploaded-document.interface";
import {DocumentCategory} from "../../models/enums/document-category.enum";
import {EmployeeWorkReport} from "../backend/api/shifts/responses/employee-work-reports.response";
import {WorkReport} from "../../models/work-reports.interface";
import {Severity} from "../../models/enums/severity.enum";
import {WorkReportCategory} from "../../models/enums/work-report-category.enum";
import {WorkReportItem} from "../backend/api/hr/responses/work-reports-list.response";
import {ShiftItem} from "../backend/api/shifts/models/shift-item.interface";
import {Shift} from "../../models/shift.interface";
import {File} from "../../models/file.interface";
import {fileSizeToBytes} from "../../utils/file.utils";
import {VirtualFile} from "../backend/api/documents/models/virtual-file.interface";
import {EmployeeClaim} from "../backend/api/claims/responses/claims-by-employee.response";
import {EmployeeProfile} from "../backend/shared/employee-profile";
import {EmployeePendingRequest} from "../backend/shared/employee-pending-request";
import {PendingRequestType} from "../../models/enums/pending-request-type.enum";
import {ProviderGoal} from "../backend/api/client/responses/provider-goals.response";
import {TaskEntity} from "../../models/entities/task-entity.interface";
import {Supervisor} from "../../models/supervisor.interface";
import {OperatorWithShifts} from "../backend/api/tasks/responses/supervisors-on-shift-by-day.response";
import {RealFile} from "../backend/api/documents/models/real-file.interface";
import {IncidentNotificationReport} from "../backend/api/shifts/responses/incident-notification-reports.response";
import {Operator} from "../../models/operator.interface";
import {ShiftsWeekData} from "../backend/api/shifts/responses/workers-by-date.response";
import {ShiftEmployee, WeekCalendar} from "../backend/api/shifts/responses/shifts-week-by-date.response";
import {AvailableUsers} from "../backend/api/shifts/responses/available-workers-between-dates.response";
import {ShiftUser, ShiftUsers} from "../backend/api/shifts/responses/shifts-between-dates.response";
import {getTranslation} from "../../utils/language.utils";
import {User} from "../../models/user.interface";
import {TaskPreset} from "../../models/task-preset";
import {TaskTemplate} from "../backend/api/tasks/responses/task-templates.response";
import {UserLeave} from "../backend/api/shifts/responses/user-leaves.response";
import {LeaveRequest} from "../../models/leave-request.interface";
import {LeaveRequestCategory} from "../../models/enums/leave-request-category.enum";
import {RawIncidentTypology} from "../../models/entities/incident-notification-entity.interface";
import {IncidentTypology} from "../../models/incident-typology.interface";
import {TerminatedEmployeeProfile} from "../backend/shared/terminated-employee-profile";

const ON_WORK = 'onwork'
const HANDLED = 'handled'
const PENDING = 'pending'

export const mapUserDataToUserEntity = (userData: UserData): UserEntity => {
    return {
        name: userData.firstName,
        surname: userData.lastName,
        phoneNumber: userData.phoneNumber,
        level: userData.level,
        photo: userData.photo,
        active: userData.active,
        username: userData.userName,
        type: userData.type,
        userId: userData.userId,
        email: userData.email,
        createdAt: userData.createdAt,
    }
}

export const mapUserDataToEmployee = (userData: UserData): Employee => {
    return {
        id: userData.userId,
        type: userData.level as EmployeeType,
        name: userData.firstName,
        surname: userData.lastName,
        hasRequestPending: false,
        isOnLeave: false,
        assigned: false
    }
}

export const mapEmployeeProfileToEmployee = (employeeProfile: EmployeeProfile): Employee => {
    return {
        id: employeeProfile.userId,
        type: employeeProfile.level as EmployeeType,
        name: employeeProfile.firstName,
        surname: employeeProfile.lastName,
        hasRequestPending: employeeProfile.pendingRequest.claims.length > 0 || employeeProfile.pendingRequest.leaves.length > 0,
        isOnLeave: false,  // TODO: Check this
        assigned: false // TODO: Check this
    }
}

export const mapTerminatedEmployeeProfileToEmployee = (terminatedEmployeeProfile: TerminatedEmployeeProfile): Employee => {
    return {
        id: terminatedEmployeeProfile.userId,
        type: terminatedEmployeeProfile.level as EmployeeType,
        name: terminatedEmployeeProfile.firstName,
        surname: terminatedEmployeeProfile.lastName,
        hasRequestPending: false,
        isOnLeave: false,
        assigned: false,
        terminatedAt: terminatedEmployeeProfile.terminatedAt
    }
}

export const mapUserEntityToEmployee = (userEntity: UserEntity): Employee => {
    return {
        assigned: false,
        hasRequestPending: false,
        isOnLeave: false,
        type: userEntity.level as EmployeeType,
        id: userEntity.userId,
        name: userEntity.surname,
        surname: userEntity.name
    }
}

export const mapCenterNotificationToNotification = (notification: CenterNotification): Notification => {
    return {
        id: notification.id,
        operatorName: notification.senderId,
        date: new Date(notification.sendDate),
        content: notification.body,
        isRead: notification.isRead === 'true'
    }
}

export const mapArchiveCommunicationToCommunication = (archiveCommunication: ArchiveCommunication): Communication => {
    return {
        id: archiveCommunication.id,
        content: archiveCommunication.body,
        receiver: archiveCommunication.receiverId,
        date: new Date(archiveCommunication.sendTime)
    }
}

export const mapClientGoalToGoal = (clientGoal: ClientGoal): Goal => {
    const clientTasks = clientGoal?.tasks || []
    return {
        id: clientGoal.goalId,
        content: getTranslation(clientGoal.goalName),
        date: new Date(clientGoal.createdAt),
        dueDate: (!clientGoal.dueDate || clientGoal.dueDate === '-') ? null : new Date(clientGoal.dueDate),
        tasks: clientTasks.map(task => mapTaskEntityToTask(task))
    }
}

export const mapProviderGoalToGoal = (providerGoal: ProviderGoal): Goal => {
    return {
        id: providerGoal.goalId,
        content: getTranslation(providerGoal.goalName),
        date: new Date(providerGoal.createdAt),
        dueDate: new Date(providerGoal.createdAt),
        tasks: providerGoal.tasks.map(task => mapTaskEntityToTask(task))
    }
}

export const mapTaskEntityToTask = (taskEntity: TaskEntity): Task => {
    const operators = taskEntity.operatorIds
    return {
        id: taskEntity.taskId,
        content: getTranslation(taskEntity.taskDescription),
        date: new Date(taskEntity.startDate),
        assignee: taskEntity.supervisorId,
        assigneeName: taskEntity.supervisor ? `${taskEntity.supervisor.firstName} ${taskEntity.supervisor.lastName}` : '',
        operators: Object.values(operators).map(operator => {
            return {
                id: operator.operator?.userName,
                name: operator.operator?.firstName,
                surname: operator.operator?.lastName
            }
        })
    }
}

export const mapMemberToGroupMember = (member: Member, level: UserRole): GroupMember => {
    return {
        id: member.userId,
        name: member.userName,
        role: level
    }
}

export const mapEmployeeClaimToClaim = (employeeClaim: EmployeeClaim): Claim => {
    return {
        id: employeeClaim.claimId,
        logId: employeeClaim.logId,
        employee: {
            id: employeeClaim.operator.userId,
            type: employeeClaim.operator.level as EmployeeType,
            name: employeeClaim.operator.firstName,
            surname: employeeClaim.operator.lastName,
            hasRequestPending: false,
            isOnLeave: false,
            assigned: false
        },
        type: getTranslation(employeeClaim.claim.claimName) as ClaimCategory,
        date: new Date(employeeClaim.createdAt),
        isPending: employeeClaim.claimStatus === ON_WORK,
        isManaged: employeeClaim.claimStatus === HANDLED,
        managedAt: employeeClaim.managedAt ? new Date(employeeClaim.managedAt) : undefined
    }
}

export const mapHRClaimToClaim = (hrClaim: HRClaim): Claim => {
    return {
        id: hrClaim.claimId,
        logId: hrClaim.logId,
        employee: {
            id: hrClaim.operator.userId,
            type: hrClaim.operator.level as EmployeeType,
            name: hrClaim.operator.firstName,
            surname: hrClaim.operator.lastName,
            hasRequestPending: false,
            isOnLeave: false,
            assigned: false
        },
        type: getTranslation(hrClaim.claim.claimName) as ClaimCategory,
        date: new Date(hrClaim.createdAt),
        isPending: hrClaim.claimStatus === ON_WORK,
        isManaged: hrClaim.claimStatus === HANDLED,
        notes: hrClaim.note,
        managedAt: hrClaim.managedAt ? new Date(hrClaim.managedAt) : undefined
    }
}

export const mapClaimHistoryItemToClaim = (claimHistoryItem: ClaimHistoryItem): Claim => {
    const employee = mapUserDataToEmployee(claimHistoryItem.userData)
    return {
        id: claimHistoryItem.claimId,
        type: claimHistoryItem.type as ClaimCategory,
        date: new Date(claimHistoryItem.date),
        isPending: claimHistoryItem.status === ON_WORK,
        isManaged: claimHistoryItem.status === HANDLED,
        employee
    }
}

export const mapRealFileToUploadedDocument = (realFile: RealFile): UploadedDocument => {
    let fileDate
    fileDate = realFile.uploadDate ? realFile.uploadDate : realFile.updateDate
    fileDate = new Date(Date.parse(fileDate))
    return {
        id: realFile.name,
        name: realFile.name,
        date: fileDate,
        category: realFile.category as DocumentCategory,
        size: realFile.size,
        link: realFile.link
    }
}

export const mapWorkReportItemToWorkReport = (workReportItem: WorkReportItem): WorkReport => {
    return {
        id: workReportItem.id,
        name: workReportItem.name,
        date: new Date(workReportItem.date),
        isNew: workReportItem.isNew,
        severity: workReportItem.severity as Severity,
        category: workReportItem.category as WorkReportCategory,
        confirmed: workReportItem.confirmed,
        declined: workReportItem.declined,
        startDate: new Date(workReportItem.startDate),
        endDate: new Date(workReportItem.endDate),
    }
}

export const mapIncidentsNotificationReportsToWorkReport = (incidentsNotificationReports: IncidentNotificationReport): WorkReport => {
    return {
        id: incidentsNotificationReports.reportingId,
        name: getTranslation(incidentsNotificationReports.incidentTypology.incidentName),
        date: new Date(incidentsNotificationReports.createdAt),
        isNew: false,  // TODO: Check this
        severity: Object.values(Severity)[incidentsNotificationReports.incidentTypology.severity],  // TODO: Check this
        category: WorkReportCategory.IncidentNotification,
        confirmed: false,  // TODO: Check this
        declined: false,  // TODO: Check this
        startDate: new Date(incidentsNotificationReports.incidentVerificationStartDate),
        endDate: new Date(incidentsNotificationReports.incidentVerificationEndDate),
    }
}

export const mapEmployeeWorkReportToWorkReport = (employeeWorkReport: EmployeeWorkReport): WorkReport => {
    return {
        id: employeeWorkReport.shiftId,
        name: employeeWorkReport.label, // TODO: Check this
        date: new Date(employeeWorkReport.startDate),
        isNew: false, // TODO: Check this
        severity: Severity.Medium,  // TODO: Check this
        category: WorkReportCategory.IncidentNotification as WorkReportCategory, // TODO: Check this
        confirmed: false, // TODO: Check this
        declined: employeeWorkReport.proposalStatus === "false", // TODO: Check this
        startDate: new Date(employeeWorkReport.startDate),
        endDate: new Date(employeeWorkReport.endDate),
    }
}

export const mapShiftItemToShift = (shiftItem: ShiftItem): Shift => {
    return {
        id: shiftItem.shiftId || '',
        startDate: new Date(shiftItem.startDate),
        endDate: new Date(shiftItem.endDate),
        supervisor: mapUserDataToEmployee(shiftItem.user || {} as UserData),
        operators: [],
        // TODO: Check operators
    }
}

export const mapVirtualFileToFile = (virtualFile: VirtualFile, category: DocumentCategory): File => {
    let fileDate
    if (virtualFile.uploadDate) {
        fileDate = new Date(virtualFile.uploadDate)
    } else if (virtualFile.updateDate) {
        fileDate = new Date(virtualFile.updateDate)
    } else {
        fileDate = new Date()
    }

    return {
        name: virtualFile.name,
        size: fileSizeToBytes(virtualFile.size),
        uploadDate: fileDate,
        link: undefined,
        category: category,
    }
}

export const mapEmployeePendingRequestToPendingRequest = (leaveRequest: EmployeePendingRequest, requestType: PendingRequestType): PendingRequest => {
    const user = leaveRequest.user || leaveRequest.operator || {} as UserData
    return {
        id: leaveRequest.requestId,
        operatorName: `${user.firstName} ${user.lastName}`,
        userId: leaveRequest.userId,
        requestType: requestType
    }
}

export const mapSupervisorWithShiftsToSupervisor = (supervisorWithShifts: OperatorWithShifts): Supervisor => {
    return {
        id: supervisorWithShifts.user.userId,
        name: supervisorWithShifts.user.firstname || supervisorWithShifts.user.userName,
        surname: supervisorWithShifts.user.surname || '',
        level: UserRole.Supervisor,
        shifts: supervisorWithShifts.shifts.map(shift => {
            return {
                shiftId: shift.shiftId,
                startTime: new Date(shift.startTime),
                endTime: new Date(shift.endTime),
                userStartTime: shift.userStartTime,
                userEndTime: shift.userEndTime
            }
        })
    }
}

export const mapOperatorWithShiftsToOperator = (operatorWithShifts: OperatorWithShifts): Operator => {
    return {
        id: operatorWithShifts.user.userId,
        name: operatorWithShifts.user.firstname || operatorWithShifts.user.userName,
        surname: operatorWithShifts.user.surname || '',
        level: UserRole.Operator,
        shifts: operatorWithShifts.shifts.map(shift => {
            return {
                shiftId: shift.shiftId,
                startTime: new Date(shift.startTime),
                endTime: new Date(shift.endTime),
                userStartTime: shift.userStartTime,
                userEndTime: shift.userEndTime
            }
        })
    }
}

export const mapShiftEmployeeToEmployee = (shiftEmployee: ShiftEmployee): Employee => {
    return {
        id: shiftEmployee.user.userId,
        type: shiftEmployee.user.level as EmployeeType,
        name: shiftEmployee.user.userName,
        surname: '',
        hasRequestPending: false,
        isOnLeave: false,
        assigned: false
    }
}

export const mapShiftsWeekDataToEmployees = (shiftsWeekData: ShiftsWeekData): Employee[] => {
    const employees: Employee[] = []
    shiftsWeekData.workers.operators.forEach(operator => {
        employees.push(mapShiftEmployeeToEmployee(operator))
    })
    shiftsWeekData.workers.supervisors.forEach(supervisor => {
        employees.push(mapShiftEmployeeToEmployee(supervisor))
    })

    return employees
}

export const mapWeekCalendarDataToShifts = (weekCalendar: WeekCalendar[]): Shift[] => {
    const shifts: Shift[] = []
    weekCalendar.forEach(item => {
        const supervisor = item.supervisor
        const supervisorShift = supervisor.shifts[0]
        const shift: Shift = {
            id: supervisorShift.shiftId,  // TODO: Check this
            startDate: new Date(supervisorShift.startTime),
            endDate: new Date(supervisorShift.endTime),
            supervisor: mapShiftEmployeeToEmployee(item.supervisor),
            operators: item.operators.map(operator => mapShiftEmployeeToEmployee(operator))
        }
        shifts.push(shift)
    })

    return shifts
}

export const mapAvailableUsersToEmployees = (availableUsers: AvailableUsers): Employee[] => {
    const employees: Employee[] = []
    availableUsers.operators.forEach(operator => {
        employees.push(mapUserDataToEmployee(operator))
    })
    availableUsers.supervisors.forEach(supervisor => {
        employees.push(mapUserDataToEmployee(supervisor))
    })

    return employees
}

export const mapShiftUserToSupervisors = (shiftUser: ShiftUser): Supervisor => {
    return {
        id: shiftUser.userId,
        name: shiftUser.firstName,
        surname: shiftUser.lastName,
        level: UserRole.Supervisor,
        shifts: shiftUser.shifts.map(shift => {
            return {
                shiftId: shift.shiftId,
                startTime: new Date(shift.startDate),
                endTime: new Date(shift.endDate),
                userStartTime: shift.userStart,
                userEndTime: shift.userEnd
            }
        })
    }
}

export const mapShiftUserToOperator = (shiftUser: ShiftUser): Operator => {
    return {
        id: shiftUser.userId,
        name: shiftUser.firstName,
        surname: shiftUser.lastName,
        level: UserRole.Operator,
        shifts: shiftUser.shifts.map(shift => {
            return {
                shiftId: shift.shiftId,
                startTime: new Date(shift.startDate),
                endTime: new Date(shift.endDate),
                userStartTime: shift.userStart,
                userEndTime: shift.userEnd
            }
        })
    }
}

export const mapShiftUsersToSupervisorsAndOperators = (shiftUsers: ShiftUsers): Array<Supervisor | Operator> => {
    const employees: Array<Supervisor | Operator> = []
    shiftUsers.supervisors.forEach(supervisor => {
        employees.push(mapShiftUserToSupervisors(supervisor))
    })
    shiftUsers.operators.forEach(operator => {
        employees.push(mapShiftUserToOperator(operator))
    })

    return employees
}

export const mapUserDataToUser = (userData: UserData): User => {
    return {
        id: userData.userId,
        name: userData.firstName,
        surname: userData.lastName,
        username: userData.userName,
        password: '',
        role: userData.level,
        nationality: userData?.nationality || 'it',
        language: "en", // TODO: map this
        birthDate: userData?.dateOfBirth ? new Date(userData.dateOfBirth) : new Date(),
        picture: userData.photo,
        familyName: userData.family_name,
        secondaryMiddleName: userData.secondary_middle_name
    }
}

export const mapTemplateToTaskPreset = (taskTemplate: TaskTemplate): TaskPreset => {
    return {
        id: taskTemplate.taskTemplateId,
        title: getTranslation(taskTemplate.taskName),
        description: getTranslation(taskTemplate.taskDescription)
    }
}

export const mapUserLeaveToLeaveRequest = (userLeave: UserLeave): LeaveRequest => {
    return {
        id: userLeave.requestId,
        userId: userLeave.userId,
        category: userLeave.label as LeaveRequestCategory,
        isPending: userLeave.statusRequest === PENDING,
        date: new Date(userLeave.startDate),
        isNew: userLeave.statusRequest === PENDING
    }
}

export const mapRawIncidentTypologyToIncidentTypology = (rawIncidentTypology: RawIncidentTypology): IncidentTypology => {
    return {
        incidentId: rawIncidentTypology.incidentId,
        severity: rawIncidentTypology.severity,
        createdAt: rawIncidentTypology.createdAt,
        isActive: rawIncidentTypology.isActive === 'true',
        incidentName: getTranslation(rawIncidentTypology.incidentName)
    }
}