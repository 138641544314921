import React from 'react'
import GraphSummaryDocument from "../components/GraphSummaryDocument";

interface ClaimsSummaryProps {
    data: any;
}

const ClaimsSummary: React.FC<ClaimsSummaryProps> = ({data}) => {
    return (
        <GraphSummaryDocument title="Claims Summary" subtitle="Report Data" data={data}/>
    )
}
export default ClaimsSummary